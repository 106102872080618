

export const PROFILE_GET_REQUEST = "PROFILE_GET_REQUEST";
export const PROFILE_GET_SUCCESS = "PROFILE_GET_SUCCESS";
export const PROFILE_GET_FAIL = "PROFILE_GET_FAIL";

export const PROFILE_GET_LICENSE_REQUEST = "PROFILE_GET_LICENSE_REQUEST";
export const PROFILE_GET_LICENSE_SUCCESS = "PROFILE_GET_LICENSE_SUCCESS";
export const PROFILE_GET_LICENSE_FAIL = "PROFILE_GET_LICENSE_FAIL";

export const PROFILE_UPDATE_REQUEST = "PROFILE_UPDATE_REQUEST";
export const PROFILE_UPDATE_SUCCESS = "PROFILE_UPDATE_SUCCESS";
export const PROFILE_UPDATE_FAIL = "PROFILE_UPDATE_FAIL";

export const PROFILE_DELETE_REQUEST = "PROFILE_DELETE_REQUEST";
export const PROFILE_DELETE_SUCCESS = "PROFILE_DELETE_SUCCESS";
export const PROFILE_DELETE_FAIL = "PROFILE_DELETE_FAIL";

export const PROFILE_UPDATE_LICENSE_REQUEST = 'PROFILE_UPDATE_LICENSE_REQUEST';
export const PROFILE_UPDATE_LICENSE_SUCCESS = 'PROFILE_UPDATE_LICENSE_SUCCESS';
export const PROFILE_UPDATE_LICENSE_FAIL = 'PROFILE_UPDATE_LICENSE_FAIL';

export const PROFILE_CLEAR = "PROFILE_CLEAR";
export const SET_USER_REQUEST = "SET_USER_REQUEST";
export const SET_USER_SUCCESS = "SET_USER_SUCCESS";
export const SET_USER_FAIL = "SET_USER_FAIL";
export const SET_USER_CLEAR = "SET_USER_CLEAR";

export const PUT_AVATAR_SUCCESS = "PUT_AVATAR_SUCCESS";
export const PUT_AVATAR_FAIL = "PUT_AVATAR_FAIL";
export const PUT_AVATAR_REQUEST = "PUT_AVATAR_REQUEST";
export const PUT_AVATAR_CLEAR = "PUT_AVATAR_CLEAR";

export const PROFILE_GET_TAGS_REQUEST = 'PROFILE_GET_TAGS_REQUEST';
export const PROFILE_GET_TAGS_SUCCESS = 'PROFILE_GET_TAGS_SUCCESS';
export const PROFILE_GET_TAGS_FAIL = 'PROFILE_GET_TAGS_FAIL';

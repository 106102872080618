import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {t} from "@helpers/i18n";
import {
  useParams
} from "react-router-dom";
import {toBase64} from "@services/utils";
import {Spinner} from "@components/Spinner";
import AnimatedLoader from '@components/Common/AnimatedLoader';

import DamageAdditionForm from "@components/Damage/DamageAdditional";

import * as damageActions from "@store/damages/actions";



import './DamageAdditionalUpload.style.scss';



const DamageAdditionalUpload = () => {

  /**
   * Redux selectors
   */
  const {damageLoading} = useSelector(state => state.damages);

  const [spinnerVisible, setSpinnerVisible] = useState(false);
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!damageLoading) {
      setSpinnerVisible(false);
    }
  }, [damageLoading]);

  const onDamageCreate = async (info) => {
    setSpinnerVisible(true);
    const { damageImages } = info;
    const allImagesAsBlob = await Promise.all(damageImages.map((file, index) => {
      return toBase64(file);
    }));
    const allImages = damageImages.map((image, index) => {
      return {
        'blob': allImagesAsBlob[index],
        'identifier': 'additionalDamageInformation_xx_'.concat((index+1).toString()),
        'desc': image.type,
        'name': `damage`,
        'category': 'damage',
        'uri': image.uri
      };
    });
    const information = {
      archived: info.archived ? 0 : 1,
      description: info.description,
      images: allImages
    };
    dispatch(damageActions.damageAddInformation({ id, information }));
  };

  if (damageLoading) {
    return (<AnimatedLoader />);
  }

  return (
    <div className={'additional-damage-upload'}>
      <h4 className={'title'}>{ t('damage_upload_additional_info')}</h4>
      <p>{ t('damage_upload_additional_info_subtitle')}</p>
      <DamageAdditionForm onDamageCreate={onDamageCreate}/>
      { spinnerVisible && <Spinner />}
    </div>
  );
};


export default DamageAdditionalUpload;

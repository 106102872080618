import * as dailyRentConstants from './constants';

export const dailyRentDetailsGet = payload => ({type: dailyRentConstants.DAILY_RENT_GET_DETAILS_REQUEST, payload});
export const dailyRentDetailsGetSuccess = payload => ({type: dailyRentConstants.DAILY_RENT_GET_DETAILS_SUCCESS, payload});
export const dailyRentDetailsGetFail = error => ({type: dailyRentConstants.DAILY_RENT_GET_DETAILS_FAIL, error});

export const dailyRentLoadMore = (payload) => ({type: dailyRentConstants.DAILY_RENT_LOAD_MORE_REQUEST, payload});
export const dailyRentLoadMoreSuccess = (payload) => ({type: dailyRentConstants.DAILY_RENT_LOAD_MORE_SUCCESS, payload});
export const dailyRentLoadMoreFail = (error) => ({type: dailyRentConstants.DAILY_RENT_LOAD_MORE_FAIL, error});

export const dailyRentGet = (payload) => ({type: dailyRentConstants.DAILY_RENT_GET_REQUEST, payload});
export const dailyRentSuccess = (payload) => ({type: dailyRentConstants.DAILY_RENT_GET_SUCCESS, payload});
export const dailyRentFail = (error) => ({type: dailyRentConstants.DAILY_RENT_GET_FAIL, error});

export const dailyRentHandoverReportGet = (payload) => ({ type: dailyRentConstants.DAILY_RENT_GET_HANDOVER_REPORT_REQUEST, payload });
export const dailyRentHandoverReportSuccess = (payload) => ({ type: dailyRentConstants.DAILY_RENT_GET_HANDOVER_REPORT_SUCCESS, payload });
export const dailyRentHandoverReportFail = (payload) => ({ type: dailyRentConstants.DAILY_RENT_GET_HANDOVER_REPORT_FAIL, payload });

export const dailyRentReturnReportGet = (payload) => ({ type: dailyRentConstants.DAILY_RENT_GET_RETURN_REPORT_REQUEST, payload });
export const dailyRentReturnReportSuccess = (payload) => ({ type: dailyRentConstants.DAILY_RENT_GET_RETURN_REPORT_SUCCESS, payload });
export const dailyRentReturnReportFail = (payload) => ({ type: dailyRentConstants.DAILY_RENT_GET_RETURN_REPORT_FAIL, payload });

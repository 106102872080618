
export const additionalInformationValidation = (damageInformation) => {

  const validateField = (fieldName) => {
    if (fieldName === 'damageImages') {
      if (damageInformation.damageImages && damageInformation.damageImages.length < 1) {
        return 'damage_images_error_one';
      }
    }
    if (!damageInformation[fieldName]) {
      return 'field_required';
    }
    return null;
  };

  const additionalInfoValidation = {
    description: validateField('description'),
    damageImages: validateField('damageImages'),
  };

  // the data is valid if none of the fields contains the error message
  additionalInfoValidation['isValid'] = Object.values(additionalInfoValidation).filter(value => !!value).length === 0;
  return additionalInfoValidation;

};

import React, {useEffect, useState} from 'react';
import SearchBarFilter from "@components/Common/SearchBar/withFilters";
import TimeTrackingCardList from "@components/TimeTracking/TimeTrackingCardList/TimeTrackingCardList.component";
import TrackingFilter from "@components/TimeTracking/Filter/TrackingFilter.component";
import {useHistory} from "react-router-dom";

const TimeTrackingWrapper = props => {
  const {
    trackings,
    loading,
    applyTrackingFilter,
    endReached,
    lastPage
  } = props;

  const [trackingSearch, setTrackingSearch] = useState('');
  const [page, setPage] = useState(0);
  const [trackingDates, setTrackingDates] = useState({
    dateFrom: null, dateTo: null
  });
  const [settings, setSettings] = useState({id: 0, title: 'between', value: true});
  const [showFilter, setShowFilter] = useState(false);
  const history = useHistory();

  /**
   * It takes the current page, and applies a filter to the tracking data
   */
  const initialFetch = () => {
    applyTrackingFilter({
      page: page,
      dateFrom: null,
      dateTo: null,
      search: ''
    });
  };

  useEffect(() => {
    initialFetch();
  }, []);

  /**
   * It sets the tracking search state, sets the page state to 0, and then calls the applyTrackingFilter function
   * @param [search] - The search string
   */
  const onTrackingSearch = (search = '') => {
    setTrackingSearch(search);
    setPage(0);

    applyTrackingFilter({
      page: 0,
      search: search,
      dateFrom: trackingDates.dateFrom,
      dateTo: trackingDates.dateTo
    });
  };

  /**
   * It sets the page to 0, and then calls the applyTrackingFilter function, passing in the page, dateFrom, dateTo, and search values
   */
  const applyFilter = () => {
    setPage(0);
    applyTrackingFilter({
      page: 0,
      dateFrom: trackingDates.dateFrom,
      dateTo: trackingDates.dateTo,
      search: trackingSearch
    });
  };

  /**
   * It sets the page to the current page + 1 and then calls the endReached function with the new page, the dateFrom, dateTo, and search.
   */
  const onEndReach = () => {
    setPage(page => page + 1);
    endReached({
      page: page + 1,
      dateFrom: trackingDates.dateFrom,
      dateTo: trackingDates.dateTo,
      search: trackingSearch
    });
  };

  /**
   * It takes in a type and a value, and then sets the trackingDates state to the current trackingDates state, but with the type property set to the value
   * @param type - The type of date you want to change.
   * @param value - The value of the date picker.
   */
  const onDateChange = (type, value) => {
    setTrackingDates({
      ...trackingDates,
      [type]: value
    });
  };

  /**
   * It sets the showFilter state to the opposite of what it currently is
   */
  const filterVisibilityChange = () => {
    setShowFilter(!showFilter);
  };

  /**
   * `onFilterChange` is a function that sets the settings state to the current settings state with the value property set to the opposite of the current value property
   */
  const onFilterChange = () => {
    setSettings({...settings, value: !settings.value});
  };

  /**
   * It takes in a date, a function that sets the selected card, and a driver id. It then pushes the user to the tracking page for that driver
   * @param date - the date of the card
   * @param setSelectedCard - This is a function that sets the selected card to the one that was clicked.
   * @param driverId - The id of the driver
   */
  const onTrackingClick = (date, setSelectedCard, driverId) => {
    history.push(`/employees/${driverId}/tracking`);
  };

  const trackingFilterProps = {
    visible: showFilter,
    filterValues: settings,
    trackingDates: trackingDates,
    onDateChange: onDateChange,
    filterChange: onFilterChange,
    applyFilter: applyFilter
  };

  return(
    <div className={'list-view grow'}>
      <SearchBarFilter
        search={trackingSearch}
        onSearch={(e) => onTrackingSearch(e.target.value)}
        onFilterPress={filterVisibilityChange}
        onClose={() => onTrackingSearch('')}
        children={<TrackingFilter {...trackingFilterProps} />}
      />
      <TimeTrackingCardList
        loading={loading}
        isLastPage={lastPage}
        trackings={trackings}
        isEndReached={onEndReach}
        onTrackingClick={onTrackingClick}
      />
    </div>
  );
};

export default React.memo(TimeTrackingWrapper);

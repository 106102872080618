import React from "react";

import ArrowLeftIcon from "mdi-react/ArrowLeftIcon";

import { Link } from "react-router-dom";

import "./PageHeader.styles.scss";

const PageHeader = ({ text, children, backLink = '/'}) => {

  const getLink = () => {
    return backLink;
  };

  return (
    <div className="page-header">
      <div className="back-container">
        <Link className="back-link" to={getLink()}>
          <ArrowLeftIcon />
          <span>{text}</span>
        </Link>
      </div>
      { children }
    </div>
  );
};


export default PageHeader;

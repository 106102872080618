import React, {useEffect, useState} from 'react';
import { Field, Formik } from "formik";
import { Button } from "@components/Common/Button";
import CloseIcon from "mdi-react/CloseIcon";
import {BUTTON_TYPES, EMAIL_REGEX} from "@constants";
import CmpDatePicker from "@components/Common/DatePicker";
import CheckIcon from "mdi-react/CheckIcon";
import { t } from "@helpers/i18n";
import { useSelector } from "react-redux";
import './AddDriver.styles.scss';
import {Api} from "@api";
import md5 from "js-md5";
import {Spinner} from "@components/Spinner";
import {Grid} from "@mui/material";
import {CmpInput} from "@components/Common/CmpInput/CmpInput.component";

const AddDriver = ({ visible, onClose, addDriverLicense }) => {

  const { auth } = useSelector((state) => state.auth);
  const [loader, setLoader] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [nameError, setNameError] = useState(false);
  const [surnameError, setSurnameError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  useEffect(()=>{
    if (!nameError && !surnameError && !emailError){
      setButtonDisabled(false);
    }else{
      setButtonDisabled(true);
    }
  },[nameError,surnameError,emailError]);

  useEffect(() => {
    setButtonDisabled(true);
  },[]);

  const validateSurname = (value) => {
    if (!value) {
      setSurnameError(true);
    } else if (value.length > 20) {
      setSurnameError(true);
    }else{
      setSurnameError(false);
    }
  };

  const validateName = (value) => {
    if (!value) {
      setNameError(true);
    } else if (value.length > 20) {
      setNameError(true);
    }else{
      setNameError(false);
    }
  };

  const validateEmail = (value) => {
    if (!value) {
      setEmailError(true);
    } else if (!value.match(EMAIL_REGEX)) {
      setEmailError(true);
    }else {
      setEmailError(false);
    }
  };

  const onAddDriver = async (values) => {
    setLoader(true);
    const userData = {
      email: values.email,
      phone_mobile: '',
      customerNumber: auth.user.customerNumber,
      name: values.name,
      surname: values.surname,
      password: md5(`${ values.name } ${ values.surname } ${ Math.random() * 10000 }`),
      username: md5(`${ values.name } ${ values.surname } ${ Math.random() * 10000 + new Date() }`)
    };
    try {
      const res = await Api.users.setUserCreate(userData);
      addDriverLicense(res.data.id);
      onClose();
      setLoader(false);
      setButtonDisabled(true);
    } catch (e) {

    }
  };

  return (visible &&
      <>
        {loader && <Spinner />}
        <div className={'grey-area'}/>
        <div className="add-driver-container">
          <h3 className={'driver-data'}>{t("driver_data")}</h3>
          <div className="close-button" onClick={onClose}>
            <CloseIcon size={16} />
          </div>
          <Formik
            enableReinitialize
            initialValues={
              {
                name: '',
                surname: '',
                street: '',
                zip: '',
                city: '',
                birthday: null,
                email: '',
              }
            }>
            {({ values, setFieldValue, touched }) => (
              <form className="driver-form" onSubmit={(e) => { e.preventDefault(); }}>
                <div className="form-field">
                  <Grid container  rowSpacing={1.5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}  alignItems="center">
                    <Grid item xs={5}>
                      {t("name").concat('*')}
                    </Grid>
                    <Grid item xs={7}>
                      <Field
                        name="name"
                        component={CmpInput}
                        validate={validateName}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      {t("surname").concat('*')}
                    </Grid>
                    <Grid item xs={7}>
                      <Field
                        name="surname"
                        component={CmpInput}
                        validate={validateSurname}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      {t("street_no")}
                    </Grid>
                    <Grid item xs={7}>
                      <Field
                        name="street"
                        component={CmpInput}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      {t("zip")}
                    </Grid>
                    <Grid item xs={7}>
                      <Field
                        name="zip"
                        component={CmpInput}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      {t("city")}
                    </Grid>
                    <Grid item xs={7}>
                      <Field
                        name="city"
                        component={CmpInput}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      {t("birthday")}
                    </Grid>
                    <Grid item xs={7}>
                      <CmpDatePicker
                        name="birthday"
                        value={values.birthday}
                        onChange={setFieldValue}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      {t("email_short").concat('*')}
                    </Grid>
                    <Grid item xs={7}>
                      <Field
                        name="email"
                        component={CmpInput}
                        validate={validateEmail}
                      />
                      {emailError && touched.email && <p className={'error-message'}>{t('invalid_address')}</p>}
                    </Grid>
                  </Grid>
                </div>
                <Button
                  className="is-success"
                  icon={<CheckIcon />}
                  name="success"
                  value="btn-success"
                  type={BUTTON_TYPES.SUCCESS}
                  text={t("save")}
                  isSmall
                  isDisabled={buttonDisabled}
                  onClick={() => onAddDriver(values)}
                />
              </form>
            )}
          </Formik>
        </div>
      </>);
};

export default AddDriver;

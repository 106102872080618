import React from "react";

import { t } from "@helpers/i18n";
import {mileageFormatter} from "@services/formatters";
import {calculateNextInspectionInDays, getVehicleInspectionIcon, getVehicleInspectionStatusName} from "@helpers/common/vehicles";

/** load styles **/
import './VehicleInspection.styles.scss';

const VehicleInspection = ({carInspectionStatus}) => {

  const {nextInspectionAtKm, nextInspectionAtDate, status} = carInspectionStatus;

  return(
    <div className={'inspection-container'}>
      <div className={'inspection-left'}>
        <div>{t('inspection_status')}:</div>
        <div>{t('next_inspection')}:</div>
        <div>{t('or_in')}:</div>
      </div>
      <div className={'inspection-right'}>
        <div>{status ? getVehicleInspectionStatusName(status) : '-'}</div>
        <div>{nextInspectionAtKm ? `${mileageFormatter(nextInspectionAtKm)} km` : '-'}</div>
        <div>
          {calculateNextInspectionInDays(nextInspectionAtDate)}
        </div>
      </div>
      <div className={'inspection-icon'}>
        {getVehicleInspectionIcon(status)}
      </div>
    </div>
  );
};

export default VehicleInspection;

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {t} from '@helpers/i18n';
import Modal from '@components/Common/Modal';
import {Button} from '@components/Common/Button';
import CheckIcon from 'mdi-react/CheckIcon';
import {BUTTON_TYPES} from '@constants';
import {Input, INPUT_TYPES} from '@components/Common/Input';
import './FinishTicketReasonModal.style.scss';
import {IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function FinishTicketReasonModalComponent(props) {

  const { visible, onClose, onSubmit } = props;

  const [reason, setReason] = useState('');
  const [formErrors, setFormErrors] = useState(null);

  /**
   * It sets the reason to the option that is passed in.
   * @param event - input change event.
   */
  const onChange = (event) => {
    setReason(event.target.value);
    if(event.target.value) {
      setFormErrors('');
    }
  };

  /**
   * Callback func when modal is closed
   **/
  const onModalClose = () => {
    setReason('');
    setFormErrors('');
    onClose();
  };

  /**
   * Finish Ticket action
   **/
  const onSaveClick = () => {
    if(!reason) {
      setFormErrors('field_required');
      return;
    }
    onSubmit(reason);
    onModalClose();
  };

  return (
    <Modal className={'ticket-finish-modal'} isVisible={visible} onClose={() => onModalClose(true)}>
      <div className={'finish-ticket-form'}>
        <p className="title">{t('state_reason')}</p>
        <Input
          className={'ticket-options'}
          type={INPUT_TYPES.TEXT_AREA}
          value={reason}
          onChange={onChange}>
        </Input>
        {formErrors && <p className={'error-message'}>{ t(formErrors) }</p>}
        <Button
          className="is-success"
          icon={<CheckIcon />}
          name="success"
          value="btn-success"
          type={BUTTON_TYPES.SUCCESS}
          text={t("confirm")}
          isSubmit
          isSmall
          onClick={onSaveClick}
        />
        <div className={'close-reason-modal'}>
          <IconButton
            component="span"
            onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    </Modal>
  );

}
FinishTicketReasonModalComponent.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default FinishTicketReasonModalComponent;

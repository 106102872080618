import React from 'react';
import PropTypes from 'prop-types';
/** load tt tool **/
import {t} from '@helpers/i18n';

const InvoicePrimaryContent = (props) => {

  const { invoice } = props;

  const { invoiceType, invoiceNumber } = invoice;

  return (
    <div className={'invoice-main-content'}>
      <p>{ invoiceType }</p>
      <p>{t('invoice_nr')} { invoiceNumber } </p>
    </div>
  );
};

InvoicePrimaryContent.propTypes = {
  invoice: PropTypes.object
};

InvoicePrimaryContent.defaultProps = {
  invoice: {}
};


export default InvoicePrimaryContent;

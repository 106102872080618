import React, { useCallback } from "react";

/* Importing the LazyLoadingList and TicketCard components from the components folder. */
import LazyLoadingList from "@components/LazyLoadingList";
import TicketCard from "@components/Ticket/Card";

import PropTypes from "prop-types";

const CardListTicket = (props) => {

  const {
    loading,
    tickets,
    lastPage,
    userRole,
    isEndReached,
    onTicketCardClick,
  } = props;

  const renderCardListTicket = (ticket, i) =>
    <TicketCard
      key={i}
      state={ticket?.state}
      fileNumber={ticket?.['government-reference']}
      licencePlate={ticket?.['licence-plate']}
      userAvatar={ticket?.user?.avatar}
      date={ticket?.['event-date']}
      driverName={ticket?.user?.name}
      driverSurname={ticket?.user?.surname}
      userRole={userRole}
      onClick={() => onTicketCardClick(ticket?.id)}
    />;

  /**
   * Render Lazy Loading Card list
   * @returns {JSX.Element}
   **/
  const renderContent = useCallback(() => {
    return (
      <LazyLoadingList
        data={tickets || []}
        loading={loading}
        isLastPage={lastPage}
        onEndReached={isEndReached}
        renderItem={renderCardListTicket}
      />
    );
  }, [loading, tickets]);

  return <>{renderContent()}</>;
};

export default CardListTicket;

CardListTicket.propTypes = {
  loading: PropTypes.bool,
  tickets: PropTypes.array,
  page: PropTypes.number,
  lastPage: PropTypes.bool,
  isEndReached: PropTypes.func,
  onTicketCardClick: PropTypes.func,
};

CardListTicket.defaultProps = {
  loading: false,
  carChecks: [],
  page: 0,
  lastPage: false,
  isEndReached: () => {},
};

import React from 'react';

import NavigationLink from './NavigationLink/NavigationLink.component';


const SideBarNavigation = ({ routes, activeIndex }) => {
  return routes.map((route, index) => {
    return (<NavigationLink key={index} route={route} />);
  });
};

export default SideBarNavigation;

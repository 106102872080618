import React from 'react';
import PropTypes from 'prop-types';
import {t} from '@helpers/i18n';
import CheckIcon from 'mdi-react/CheckIcon';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import './VehicleListEditTagModal.styles.scss';
import {Button} from '@components/Common/Button';
import {BUTTON_TYPES} from '@constants';
import TagsSearch from '@components/Vehicle/General/Tags/TagsSelect/TagsSelect.component';

const VehicleListEditTagModalComponent = (props) => {

  const {
    toggleEditTag,
    setSelectedTag,
    selectedTag,
    tags,
    onTagSelected,
    selectedVehiclesTags,
    selectedVehicles,
    onTagCheckBoxClick,
    saveTagsEdit,
    savingTags,
  } = props;

  return (
    <div className={'edit-tag-container'}>
      <div>
        <h2 className={'heading-tags'}>{t("vehicles_edit")}</h2>

        <button className={'close-edit-tag-modal'} onClick={toggleEditTag}>X</button>

        <div className={'tag-select-container'}>
          <TagsSearch onAddTag={onTagSelected}/>

          {selectedTag &&
          <div className={'tag-select-confirm'} onClick={onTagSelected}>
            <CheckIcon color={'green'} size={20}/>
          </div>
          }
        </div>

        <p className={'tags-checklist-header'}>{t("section_tags")}</p>
        <div className={'tags-check-list'}>
          {Object.keys(selectedVehiclesTags).map(tag => (
            <FormControlLabel
              style={{display: 'block'}}
              label={tag}
              key={tag}
              control={
                <Checkbox
                  indeterminate={!!selectedVehiclesTags[tag] && selectedVehiclesTags[tag] < selectedVehicles.length}
                  checked={!!selectedVehiclesTags[tag] && selectedVehiclesTags[tag] === selectedVehicles.length}
                  onChange={() => onTagCheckBoxClick(tag)}
                />
              }/>
          ))}
        </div>
      </div>

      <div className={'save-tag-button'}>
        <Button
          type={BUTTON_TYPES.SUCCESS}
          icon={<CheckIcon color={'white'}/>}
          text={t("confirm")}
          isLoading={!!savingTags}
          isDisabled={!!savingTags}
          isSmall
          onClick={saveTagsEdit}
        />
      </div>
    </div>
  );
};

VehicleListEditTagModalComponent.propTypes = {
  toggleEditTag: PropTypes.func.isRequired,
  setSelectedTag: PropTypes.func.isRequired,
  selectedTag: PropTypes.string.isRequired,
  savingTags: PropTypes.number.isRequired,
  tags: PropTypes.array.isRequired,
  onTagSelected: PropTypes.func.isRequired,
  selectedVehiclesTags: PropTypes.object.isRequired,
  onTagCheckBoxClick: PropTypes.func.isRequired,
  saveTagsEdit: PropTypes.func.isRequired,
  selectedVehicles: PropTypes.array.isRequired,
};

export default VehicleListEditTagModalComponent;

import * as invoicesConstants from './constants';

const initialState = {
  invoices: [],
  loading: false,
  loadingMore: false,
  lastPage: 0
};

export default function invoices(state = initialState, action) {
  switch (action.type) {
    case invoicesConstants.INVOICE_GET_REQUEST:
      return {
        ...state,
        loading: true,
        invoices: []
      };

    case invoicesConstants.INVOICE_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        invoices: action.payload,
        lastPage: action.payload.length > 0 ? action.payload[0].pageTotal : 1
      };

    case invoicesConstants.INVOICE_GET_FAIL:
      return {
        ...state,
        loading: false
      };

    case invoicesConstants.INVOICE_GET_MORE_REQUEST:
      return {
        ...state,
        loadingMore: true
      };

    case invoicesConstants.INVOICE_GET_MORE_SUCCESS:
      return {
        ...state,
        loadingMore: false,
        invoices: [...state.invoices, ...action.payload],
        lastPage: action.payload.length > 0 ? action.payload[0].pageTotal : 1
      };

    case invoicesConstants.INVOICE_GET_MORE_FAIL:
      return {
        ...state,
        loadingMore: false
      };

    case invoicesConstants.INVOICE_DETAIL_GET_REQUEST:
      return {
        ...state,
        loading: true
      };

    case invoicesConstants.INVOICE_DETAIL_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceDetail: action.payload
      };

    case invoicesConstants.INVOICE_DETAIL_GET_FAIL:
      return {
        ...state,
        loading: false
      };

    case invoicesConstants.INVOICE_PDF_GET_REQUEST:
      return {
        ...state,
        loading: true
      };

    case invoicesConstants.INVOICE_PDF_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        pdfFile: action.payload
      };

    case invoicesConstants.INVOICE_PDF_GET_FAIL:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
}

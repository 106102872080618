import * as garageConstants from '@store/garages/constants';

const initialState = {
  garages: [],
  loading: false
};

export default function garages(state = initialState, action)  {
  switch (action.type) {
    case garageConstants.GARAGES_GET_REQUEST:
      return {
        ...state,
        loading: true
      };

    case garageConstants.GARAGES_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        garages: action.payload
      };

    case garageConstants.GARAGES_GET_FAIL:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
};

import { to, withLegacyToken } from "@helpers/api";
import { requestMs } from "@helpers/api";

export const damages = {
  getDamages: (query) => requestMs.get(`/api3/user/${query}`),
  getDamage: (damageId) => requestMs.get(`api3/damage/${damageId}`),
  getDamageDetail: (damageId) => requestMs.get(`api3/api4/damages/${damageId}`),
  getDamageDetailImage: (id) => requestMs.get(`api3/api4/damages/${id}`),
  getImgByIdDamage: (payload) => requestMs.post(`api3/image`, { ...payload }),
  getDefaultPartlightPictures: (type, side) => {
    return withLegacyToken((legacyToken) => {
      return axios.get(to(`partlight/${type}/${side}/show`), {
        headers: {
          Authorization: legacyToken,
        },
      });
    });
  },
  createDamage: (params) => requestMs.post(`api3/v2/damage`, params),
  uploadImage: params => requestMs.post(`/api3/v2/image/${ params.imageId }`, params.data),
  finishDamage: params => requestMs.put(`api3/v2/damage/${ params.damageId }/complete`, {}),
  sendDamageReclamation: (params) =>
    requestMs.post(`api3/damage/${params.damageId}/reclamation`, params),

  exportDamage: (id) =>
    requestMs.get(`api3/damage/${id}/report/expert?language=de-DE`),
  createDamageInformation: (id, body) =>  requestMs.post(`api3/damage/${id}/information`, body),
  completeDamageInformation: (id, body) =>  requestMs.put(`api3/damage/information/${id}/complete`, body),
};

import * as ticketsConstants from '@store/tickets/constants';

export const ticketsGet = (payload) => ({type: ticketsConstants.TICKETS_GET_REQUEST, payload});
export const ticketsGetSuccess = (payload) => ({type: ticketsConstants.TICKETS_GET_SUCCESS, payload});
export const ticketsGetFail = (error) => ({type: ticketsConstants.TICKETS_GET_FAIL, error});

export const ticketsLoadMore = (payload) => ({type: ticketsConstants.TICKETS_LOAD_MORE, payload});
export const ticketsLoadMoreSuccess = (payload) => ({type: ticketsConstants.TICKETS_LOAD_MORE_SUCCESS, payload});
export const ticketsLoadMoreFail = (payload) => ({type: ticketsConstants.TICKETS_LOAD_MORE_FAIL, payload});

export const ticketSingleGet = (payload) => ({type: ticketsConstants.TICKET_SINGLE_GET_REQUEST, payload});
export const ticketSingleGetSuccess = (payload) => ({type: ticketsConstants.TICKET_SINGLE_GET_SUCCESS, payload});
export const ticketSingleGetFail = (error) => ({type: ticketsConstants.TICKET_SINGLE_GET_FAIL, error});

export const ticketDelete = payload => ({type: ticketsConstants.TICKET_DELETE_REQUEST, payload});
export const ticketDeleteSuccess = payload => ({type: ticketsConstants.TICKET_DELETE_SUCCESS, payload});
export const ticketDeleteFail = error => ({type: ticketsConstants.TICKET_DELETE_FAIL, error});

export const ticketReset = payload => ({type: ticketsConstants.TICKET_RESET_REQUEST, payload});
export const ticketResetSuccess = payload => ({type: ticketsConstants.TICKET_RESET_SUCCESS, payload});
export const ticketResetFail = error => ({type: ticketsConstants.TICKET_RESET_FAIL, error});

export const ticketComplete = payload => ({type: ticketsConstants.TICKET_COMPLETE_REQUEST, payload});
export const ticketCompleteSuccess = payload => ({type: ticketsConstants.TICKET_COMPLETE_SUCCESS, payload});
export const ticketCompleteFail = error => ({type: ticketsConstants.TICKET_COMPLETE_FAIL, error});

export const ticketObjected = payload => ({type: ticketsConstants.TICKET_OBJECT_REQUEST, payload});
export const ticketObjectedSuccess = payload => ({type: ticketsConstants.TICKET_OBJECT_SUCCESS, payload});
export const ticketObjectedFail = error => ({type: ticketsConstants.TICKET_OBJECT_FAIL, error});

export const ticketAssign = payload => ({type: ticketsConstants.TICKET_ASSIGN_REQUEST, payload});
export const ticketAssignSuccess = payload => ({type: ticketsConstants.TICKET_ASSIGN_SUCCESS, payload});
export const ticketAssignFail = error => ({type: ticketsConstants.TICKET_ASSIGN_FAIL, error});

export const ticketCreate = payload => ({type: ticketsConstants.CREATE_TICKET_REQUEST, payload});
export const ticketCreateSuccess = payload => ({type: ticketsConstants.CREATE_TICKET_SUCCESS, payload});
export const ticketCreateFail = error => ({type: ticketsConstants.CREATE_TICKET_FAIL, error});

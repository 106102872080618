import React from 'react';
import PropTypes from "prop-types";
import { t } from "@helpers/i18n";
import Close from "mdi-react/CloseIcon";

import './Upload.styles.scss';

const CmpUpload = ({ value, name, allowFileType, onChange, onRemove }) => {

  return (
    <div className="Upload">
      <div className={'upload-cmp'}>
        { !value && <p className={'title'}>{ t('file_upload_title') }</p> }
        <input
          className={'file-upload'}
          name={name}
          type="file"
          accept={allowFileType}
          onChange={onChange}/>
        { value &&
                <div className={'img-preview'}>
                  <img src={window.URL.createObjectURL(value)} />
                  <span className={'img-remove'} onClick={onRemove}><Close /></span>
                </div> }
      </div>
    </div>
  );
};

CmpUpload.propTypes = {
  value: PropTypes.object,
  allowFileType: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func
};

CmpUpload.defaultProps = {
  value: null,
  allowFileType: 'image/png, image/jpeg',
  name: 'file',
  onChange: () => {}
};


export default CmpUpload;

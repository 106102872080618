import React, {useEffect} from 'react';
import {t} from "@helpers/i18n";
import {DebounceInput} from "react-debounce-input";
import ArrowRightIcon from "mdi-react/ChevronDownIcon";
import { TailSpin } from "react-loader-spinner";
import ListLoader from "@components/Common/ListLoader";

const DEBOUNCE_TIMEOUT = 1000;


import './SearchSelect.styles.scss';

const SearchSelect = (props) => {

  const {
    inputProps,
    children,
    options,
    renderListItem,
    onSearch,
    onFocus,
    search,
    isLoading,
    dropdownVisible,
    toggleDropdown,
    onEndReached,
  } = props;

  const onChange = (e) => {
    const term = e.target.value;
    onSearch(term);
  };

  /**
   * Fires search event on dropdown opened
   */
  useEffect(() => {
    if(dropdownVisible) {
      onSearch(search);
    }
  }, [dropdownVisible]);

  const renderList = () => {
    if (dropdownVisible) {
      return (
        <div className="inner-dd-list" style={{maxHeight: "200px", overflowY: "auto"}}>
          {isLoading ?
            <div className="list-loading" style={{maxHeight: "200px", overflowY: "auto"}}>
              <TailSpin
                color="#FFF"
                height={20}
                width={20}
              />
            </div>
            :
            options.length ?
              <div className="no-nik">
                {
                  options.map((item, index) => {
                    return (renderListItem(item, index));
                  })
                }
                {options.length < options[0].totalItems &&
                  <ListLoader onIntersection={onEndReached}/>
                }
              </div>
              :
              <div className="inner-dd-list" style={{maxHeight: "200px", overflowY: "auto", paddingLeft:"9px"}}>
                <p> {t('card-list-empty-message')} </p>
              </div>
          }
        </div>
      );
    }
  };


  return (
    <div className={"select-search"}>
      <div className={"dropdown-list"}>
        {renderList()}
      </div>
      <div className={"search-wrapper"}>
        <DebounceInput
          debounceTimeout={DEBOUNCE_TIMEOUT}
          className={"search-input-field"}
          value={search}
          placeholder={`${t('search')}...`}
          {...inputProps}
          onFocus={onFocus}
          onChange={onChange}

        />
        {children}
        <div
          className={`arrow ${dropdownVisible ? "rotate" : ""}`}
          onClick={toggleDropdown}
        >
          <ArrowRightIcon/>
        </div>
      </div>
    </div>
  );
};

export default SearchSelect;

import React from 'react';
import Loader from "../../../assets/svg/loader.svg";
import ReCarousel from "react-elastic-carousel";

import './Carousel.styles.scss';

const Carousel = ({ slides, activeIndex, onSlideChange, imageSrcLink }) => {

  const BREAK_POINTS = [
    { width: 1, itemsToShow: 1, itemsToScroll: 1, pagination: false  },
    { width: 550, itemsToShow: 1, itemsToScroll: 1, pagination: false },
    { width: 768, itemsToShow: 1,  itemsToScroll: 1, pagination: false },
    { width: 1200, itemsToShow: 1,  itemsToScroll: 1, pagination: false }
  ];

  return (
    <ReCarousel
      itemsToShow={1}
      isRTL={false}
      breakPoints={BREAK_POINTS}
      initialActiveIndex={activeIndex}
      enableSwipe={false}
      enableMouseSwipe={false}
      showArrows
      onChange={onSlideChange}>
      {
        slides.map((slide, index) => {
          if (slide) {
            if(imageSrcLink) {
              return (<img key={index} className="car-check-image" src={slide} alt={'image'} />);
            }
            return (<img key={index} className="car-check-image" src={`data:image/jpeg;base64,${slide}`} alt={'image'} />);
          } else {
            return (<img key={index} className="loader-image" src={Loader} alt={'loader'} />);
          }
        })
      }
    </ReCarousel>
  );
};


export default Carousel;

import React from "react";

/** load icons **/
import CarIcon from "mdi-react/CarIcon";
import HomeOutlineIcon from "mdi-react/HomeOutlineIcon";
import FlashAlertIcon from "mdi-react/FlashAlertIcon";
import CarMultipleIcon from "mdi-react/CarMultipleIcon";
import AccountGroupIcon from "mdi-react/AccountGroupIcon";
import MapMarkerDistanceIcon from "mdi-react/MapMarkerDistanceIcon";
import CalendarBlankOutlineIcon from "mdi-react/CalendarBlankOutlineIcon";
import TicketAccountIcon from "mdi-react/TicketAccountIcon";
import EuroIcon from "mdi-react/EuroIcon";
import TimerOutlineIcon from "mdi-react/TimerOutlineIcon";
import CarKeyIcon from "mdi-react/CarKeyIcon";

export const sideMenuItems = [
  {
    key: "home",
    path: "/home",
    exact: true,
    icon: <HomeOutlineIcon />,
  },
  {
    key: "car-check",
    path: "/car-checks",
    exact: true,
    icon: <CarIcon />,
  },
  {
    key: "damages",
    path: "/damages",
    exact: true,
    icon: <FlashAlertIcon />,
  },
  {
    key: "employees",
    path: "/employees",
    exact: true,
    icon: <AccountGroupIcon />,
  },
  {
    key: "vehicles",
    path: "/vehicles",
    exact: true,
    icon: <CarMultipleIcon />,
  },
  {
    key: "tickets",
    path: "/tickets",
    exact: true,
    icon: <TicketAccountIcon />,
  },
  {
    key: "trips",
    path: "/trips",
    exact: true,
    icon: <MapMarkerDistanceIcon />,
  },
  {
    key: "reporting",
    path: "/reporting",
    exact: true,
    icon: <CarIcon />,
  },
  {
    key: "appointments",
    path: "/appointments",
    exact: true,
    icon: <CalendarBlankOutlineIcon />,
  },
  {
    key: "time_tracking",
    path: "/tracking",
    exact: true,
    icon: <TimerOutlineIcon />,
  },
  {
    key: "invoices",
    path: "/invoices",
    exact: true,
    icon: <EuroIcon />,
  },
  // {
  //   key: "daily_rent",
  //   path: "/daily-rent",
  //   exact: true,
  //   icon: <CarKeyIcon />,
  // },
];

import GasStationIcon from "mdi-react/GasStationIcon";
import { PRICE_LEVELS } from "constants";

import "./GasStationPopup.styles.scss";

const GasStationPopup = (props) => {
  const { priceLevel, brand, price } = props;

  const priceBody = (price) => {
    return `${price}`.slice(0, -1);
  };

  const priceTail = (price) => {
    return `${price}`.slice(-1);
  };

  return (
    <div className="gas-station-popup">
      <div className={classnames("info-container", priceLevel)}>
        <div className="info-header">
          <GasStationIcon />
          <div className="header-name">{brand}</div>
        </div>
        <div className="info-data">
          <div className="price-body">{priceBody(price)}</div>
          <div className="price-tail">{priceTail(price)}</div>
          <div className="data-units">/l</div>
        </div>
        <div className={classnames("triangle", priceLevel)} />
      </div>
    </div>
  );
};

GasStationPopup.propTypes = {
  priceLevel: PropTypes.oneOf(Object.values(PRICE_LEVELS)),
  brand: PropTypes.string,
  price: PropTypes.number,
};

GasStationPopup.defaultProps = {
  priceLevel: PRICE_LEVELS.HIGH,
  brand: "",
  price: 0,
};

export default GasStationPopup;

import dayjs from 'dayjs';

const dateFormat = 'YYYY-MM-DD';

const getMileagePerDayObject = (dailyReport) => {
  const parsedData = {};
  Object.keys(dailyReport).map(key => {
    dailyReport[key].map(vehicleDataPerDay => {
      const dayOfMonth = dayjs(vehicleDataPerDay?.date, dateFormat).date();
      parsedData[dayOfMonth] = {
        ...parsedData[dayOfMonth],
        name: dayOfMonth,
        [vehicleDataPerDay.licencePlate]: vehicleDataPerDay.drivenKilometer || 0,
      };
    });
  });
  return parsedData;
};

export {
  getMileagePerDayObject,
};

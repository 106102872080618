import CheckIcon from "mdi-react/CheckIcon";
import WarningYellow from "@assets/svg/vehicles/metro-warning-yellow.svg";
import WarningOrange from "@assets/svg/vehicles/metro-warning-orange.svg";
import WarningRed from "@assets/svg/vehicles/metro-warning-red.svg";
import {t} from "@helpers/i18n";

const VEHICLE_INSPECTION_STATES = {
  MAINTAINED: "ok",
  DUE_SOON: 'soon',
  URGENTLY_DUE: 'urgent',
  OVERDUE: 'overdue'
};

export const getVehicleInspectionState = (state) => {
  switch (state) {
    case VEHICLE_INSPECTION_STATES.MAINTAINED:
      return <>maintained <CheckIcon color={'#95C11E'} /></>;
    case VEHICLE_INSPECTION_STATES.DUE_SOON:
      return <>due soon <img src={WarningYellow} alt={'warning-yellow'} /></>;
    case VEHICLE_INSPECTION_STATES.URGENTLY_DUE:
      return <>urgently due <img src={WarningOrange} alt={'warning-orange'} /></>;
    case VEHICLE_INSPECTION_STATES.OVERDUE:
      return <>overdue <img src={WarningRed} alt={'warning-red'} /></>;
    default:
      return '-';
  }
};

export const getVehicleInspectionIcon = (state) => {
  switch (state) {
    case VEHICLE_INSPECTION_STATES.MAINTAINED:
      return <CheckIcon color={'#95C11E'} />;
    case VEHICLE_INSPECTION_STATES.DUE_SOON:
      return <img src={WarningYellow} alt={'warning-yellow'} />;
    case VEHICLE_INSPECTION_STATES.URGENTLY_DUE:
      return <img src={WarningOrange} alt={'warning-orange'} />;
    case VEHICLE_INSPECTION_STATES.OVERDUE:
      return <img src={WarningRed} alt={'warning-red'} />;
    default:
      return '';
  }
};

export const getVehicleInspectionStatusName = (state) => {
  switch (state) {
    case VEHICLE_INSPECTION_STATES.MAINTAINED:
      return t('maintained');
    case VEHICLE_INSPECTION_STATES.DUE_SOON:
      return t('due_soon');
    case VEHICLE_INSPECTION_STATES.URGENTLY_DUE:
      return t('urgently_due');
    case VEHICLE_INSPECTION_STATES.OVERDUE:
      return t('overdue');
    default:
      return '-';
  }
};

export const calculateNextInspectionInDays = (nextInspectionAtDate) => {
  return nextInspectionAtDate ? `${Math.round((new Date(nextInspectionAtDate) - new Date()) / (1000 * 60 * 60 * 24))}  ${t('days').toLowerCase()}` : '-';

};

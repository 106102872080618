import React, { useMemo } from 'react';
import LazyLoadingList from "@components/LazyLoadingList";
import DailyRentCard from "@components/DailyRent/Card/DailyRentCard.component";
import PropTypes from 'prop-types';

const DailyRentCardList = (props) => {
  const {
    loading,
    dailyRents,
    isLastPage,
    isEndReached,
    onRentClick
  } = props;

  const renderDailyRentCard = (rent, i) => (
    <DailyRentCard
      rent={rent}
      key={i}
      onRentClick={onRentClick}
    />
  );

  const renderContent = useMemo( () =>
    <LazyLoadingList
      data={dailyRents || []}
      loading={loading}
      isLastPage={isLastPage}
      onEndReached={isEndReached}
      renderItem={renderDailyRentCard}
    />
  , [loading, dailyRents]);

  return (
    <>
      {renderContent}
    </>
  );
};

DailyRentCardList.propTypes = {
  loading: PropTypes.bool,
  dailyRents: PropTypes.array,
  isLastPage: PropTypes.bool,
  isEndReached: PropTypes.func,
  onRentClick: PropTypes.func
};

DailyRentCardList.defaultProps = {
  loading: false,
  dailyRents: [],
  isLastPage: false,
  isEndReached: () => {},
  onRentClick: () => {}
};

export default React.memo(DailyRentCardList);

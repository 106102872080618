import { call, put, all, takeLatest } from "redux-saga/effects";
import { errorHandler } from "@helpers/saga";
import store from "store2";
import * as appConstants from "./constants";
import * as authConstants from "@store/auth/constants";
import {
  PROFILE_GET_SUCCESS,
} from "@constants";

function* appStart() {
  try {
    const auth = store.get("auth");
    const profile = store.get("profile");
    if (auth) {
      yield put({ type: authConstants.AUTH_LOGIN_SUCCESS, payload: auth });
      yield put({ type: PROFILE_GET_SUCCESS, payload: profile });
    } else {
      yield put({ type: authConstants.AUTH_LOGIN_FAIL });
    }
  } catch (err) {
    yield call(errorHandler, err);
  }
}

function* appStartSaga() {
  yield takeLatest(appConstants.APP_START, appStart);
}

export function* appSagas() {
  yield all([
    call(appStartSaga),
  ]);
}

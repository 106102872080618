import Select from "react-select";

import "./index.scss";
import classnames from "classnames";
import PropTypes from "prop-types";

export const INPUT_TYPES = {
  TEXT: "text",
  TEXT_AREA: "textArea",
  NUMBER: "number",
  DATE: "date",
  SELECT: "select",
  TEL: "tel",
};

export const INPUT_SIZES = {
  ONE_FIFTH: "one-fifth",
  FOUR_FIFTH: "four-fifth",
  QUARTER: "quarter",
  ONE_THIRD: "one-third",
  HALF: "half",
  TWO_THIRD: "two-thirds",
  THREE_QUARTERS: "three-quarters",
  FULL: "full",
  MARGIN_LEFT: "margin-left"
};

export const Input = (props) => {

  const renderInput = () => {
    const { type, inputType, placeholder, label, field, options, form, ...prop } = props;

    const dot = (color = '#ccc') => ({
      alignItems: 'center',
      display: 'flex',
    
      ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
      },
    });

    if (type === INPUT_TYPES.SELECT) {
      return (
        <Select
          className={"select-box"}
          classNamePrefix="select"
          isDisabled={false}
          isLoading={false}
          isClearable={true}
          isSearchable={true}
          placeholder={placeholder}
          name={name}
          options={options}
        />
      );
    } else if (type === INPUT_TYPES.TEXT_AREA) {
      return <textarea placeholder={placeholder} {...field} {...prop} />;
    }

    return <input placeholder={placeholder}  type={inputType} {...field} {...prop} />;
  };


  const { label, field, form, ...prop} = props;

  return (
    <div className={classnames("input", props.width, props.marginLeft)}>
      <p className="input-label">{label}</p>
      {renderInput()}
      {props.error}
    </div>
  );

};

Input.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  prop: PropTypes.object,
  width: PropTypes.oneOf(Object.values(INPUT_SIZES)),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string,
  })),
  inputType: PropTypes.string,
  placeholder: PropTypes.string,
};

Input.defaultProps = {
  field: null,
  form: null,
  prop: null,
  width: INPUT_SIZES.FULL,
  options: [],
  inputType: "text",
  placeholder: "",
};

import React from "react";
import PropTypes from 'prop-types';

import InfoOutlineIcon from "mdi-react/InfoOutlineIcon";

import "./NoticeBlock.styles.scss";

const NoticeBlock = (props) => {

  const { className, children } = props;

  return (
    <div className={`notice-block ${className}`}>
      <InfoOutlineIcon />
      { children }
    </div>
  );
};

NoticeBlock.propTypes = {
  children: PropTypes.node
};

NoticeBlock.defaultProps = {
  children: null
};

export default NoticeBlock;

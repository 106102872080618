import * as timeTrackingActions from "@store/tracking/actions";
import {call, put, takeLatest} from "redux-saga/effects";
import {errorHandler} from "@helpers/saga";
import * as timeTrackingConstants from "@store/tracking/constants";
import { Api } from "@api";


function* startTImeTracking(action) {
  try {
    const {successCallback} = action.payload;
    const {data, status} = yield call(Api.timeTracking.startTimeTracking);

    if (status === 200) {
      yield put(timeTrackingActions.startTimeTrackingSuccess({payload: data, alreadyStarted: false}));
      yield call(successCallback);
    } else if (status === 208) {
      yield put(timeTrackingActions.startTimeTrackingSuccess({payload: data, alreadyStarted: true}));
      yield call(successCallback);
    }

  } catch (err) {
    errorHandler(err);
    yield put(timeTrackingActions.startTimeTrackingFailure(err));
  }
}

function* stopTimeTracking(action) {
  try {
    const {successCallback} = action.payload;
    const {data} = yield call(Api.timeTracking.stopTimeTracking);

    yield put(timeTrackingActions.stopTimeTrackingSuccess({payload: data}));
    if (data.message === 'stopped') {
      yield call(successCallback);
    }

  } catch (err) {
    errorHandler(err);
    yield put(timeTrackingActions.stopTimeTrackingFailure(err));
  }
}

function* refreshTimeTracking(action) {
  try {
    yield put(timeTrackingActions.refreshTrackingStatusSuccess(action.payload));
  } catch (e) {
    errorHandler(e);
    yield put(timeTrackingActions.refreshTrackingStatusFail(e));
  }
}

const normalizeTracking = (data) => {
  return data.trackings.map((tracking) => {
    const trackingDriver = data.drivers.find(driver => driver.id === tracking.driverId);
    if (trackingDriver) {
      return {
        ...tracking,
        name: trackingDriver.name,
        surname: trackingDriver.surname,
        avatar: trackingDriver.avatar
      };
    } else {
      return tracking;
    }
  });
};

function* getTimeTracking(action) {
  try {
    const {page, search, dateFrom, dateTo} = action.payload;
    const {data} = yield call(Api.timeTracking.getTimeTracking, page, search, dateFrom, dateTo);

    yield put(timeTrackingActions.timeTrackingSuccess({drivers: data.drivers, trackings: normalizeTracking(data)}));

  } catch (err) {
    yield call(errorHandler, err);
    yield put(timeTrackingActions.timeTrackingFail(err));
  }
}

function* loadMoreTimeTracking(action) {
  try {
    const {page, search, dateFrom, dateTo} = action.payload;
    const {data} = yield call(Api.timeTracking.getTimeTracking,  page, search, dateFrom, dateTo);

    yield put(timeTrackingActions.timeTrackingLoadMoreSuccess({drivers: data.drivers, trackings: normalizeTracking(data)}));

  } catch (err) {
    yield call(errorHandler, err);
    yield put(timeTrackingActions.timeTrackingLoadMoreFail(err));
  }
}

function* getTimeTrackingUser(action) {
  try {
    const {userId, page, dateFrom, dateTo} = action.payload;
    const {data} = yield call(Api.timeTracking.getTimeTrackingUser, userId, page, dateFrom, dateTo);

    yield put(timeTrackingActions.timeTrackingUserSuccess({trackings: normalizeTracking(data), totalTimeInSeconds: data.totalTimeInSeconds}));

  } catch (err) {
    yield call(errorHandler, err);
    yield put(timeTrackingActions.timeTrackingUserFail(err));
  }
}

function* loadMoreTimeTrackingUser(action) {
  try {

    const {userId, page, dateFrom, dateTo} = action.payload;
    const {data} = yield call(Api.timeTracking.getTimeTrackingUser, userId, page, dateFrom, dateTo);

    yield put(timeTrackingActions.timeTrackingUserLoadMoreSuccess({trackings: normalizeTracking(data)}));

  } catch (err) {
    yield call(errorHandler, err);
    yield put(timeTrackingActions.timeTrackingUserLoadMoreFail(err));
  }
}

function* getTimeTrackingDetails(action) {
  try {
    const {userId, date} = action.payload;
    const {data} = yield call(Api.timeTracking.getTimeTrackingDetails, {userId, date});
    yield put({type: timeTrackingConstants.TIME_TRACKING_GET_DETAILS_SUCCESS, payload: data});
  } catch (err) {
    yield put({type: timeTrackingConstants.TIME_TRACKING_GET_DETAILS_FAIL});
  }
}

export function* timeTrackingSaga() {
  yield takeLatest(timeTrackingConstants.START_TIME_TRACKING_REQUEST, startTImeTracking);
  yield takeLatest(timeTrackingConstants.STOP_TIME_TRACKING_REQUEST, stopTimeTracking);
  yield takeLatest(timeTrackingConstants.REFRESH_TRACKING_STATUS_REQUEST, refreshTimeTracking);
  yield takeLatest(timeTrackingConstants.TIME_TRACKING_GET_REQUEST, getTimeTracking);
  yield takeLatest(timeTrackingConstants.TIME_TRACKING_LOAD_MORE_REQUEST, loadMoreTimeTracking);
  yield takeLatest(timeTrackingConstants.TIME_TRACKING_USER_GET_REQUEST, getTimeTrackingUser);
  yield takeLatest(timeTrackingConstants.TIME_TRACKING_USER_LOAD_MORE_REQUEST, loadMoreTimeTrackingUser);
  yield takeLatest(timeTrackingConstants.TIME_TRACKING_GET_DETAILS_REQUEST, getTimeTrackingDetails);
}

import { call, put, takeLatest } from "redux-saga/effects";
import { errorHandler } from "@helpers/saga";
import * as dailyRentConstants from "./constants";
import * as dailyRentActions from '@store/dailyRent/actions';
import { Api } from "@api";

function* getDailyRents(action) {
  try {
    const { userId, page, filter, search, dateFrom, dateTo } = action.payload;
    const { data } = yield call(Api.dailyRent.getDailyRents,
      { userId, search, page, filter, dateFrom, dateTo });

    yield put(dailyRentActions.dailyRentSuccess(data));

  } catch (err) {
    yield call(errorHandler, err);
    yield put(dailyRentActions.dailyRentFail(err));
  }
}

function* loadMoreDailyRents(action) {
  try {
    const { userId, page, filter, search, dateFrom, dateTo } = action.payload;
    const { data } = yield call(Api.dailyRent.getDailyRents,
      { userId, search, page, filter, dateFrom, dateTo });

    yield put(dailyRentActions.dailyRentLoadMoreSuccess(data));

  } catch (err) {
    yield call(errorHandler, err);
    yield put(dailyRentActions.dailyRentLoadMoreFail(err));
  }
}

export function* getDailyRentDetails(action) {
  try {
    const { dailyRentId, rentalType } = action.payload;
    const { data } = yield call(Api.dailyRent.getDailyRentDetails, {dailyRentId, rentalType});
    yield put(dailyRentActions.dailyRentDetailsGetSuccess(data));

  } catch (err) {
    yield call(errorHandler, err);
    yield put(dailyRentActions.dailyRentDetailsGetFail(err));
  }
}

export function* getDailyRentHandoverReport(action) {
  try {
    const { carCheckId } = action.payload;
    const { data } = yield call(Api.carchecks.getCarCheck, carCheckId);
    yield put(dailyRentActions.dailyRentHandoverReportSuccess(data));
  } catch (err) {
    yield call(errorHandler, err);
    yield put(dailyRentActions.dailyRentHandoverReportFail(err));
  }
}

export function* getDailyRentReturnReport(action) {
  try {
    const { carCheckId } = action.payload;
    const { data } = yield call(Api.carchecks.getCarCheck, carCheckId);
    yield put(dailyRentActions.dailyRentReturnReportSuccess(data));
  } catch (err) {
    yield call(errorHandler, err);
    yield put(dailyRentActions.dailyRentReturnReportFail(err));
  }
}
export function* dailyRentSagas() {
  yield takeLatest(dailyRentConstants.DAILY_RENT_GET_REQUEST, getDailyRents);
  yield takeLatest(dailyRentConstants.DAILY_RENT_LOAD_MORE_REQUEST, loadMoreDailyRents);
  yield takeLatest(dailyRentConstants.DAILY_RENT_GET_DETAILS_REQUEST, getDailyRentDetails);
  yield takeLatest(dailyRentConstants.DAILY_RENT_GET_HANDOVER_REPORT_REQUEST, getDailyRentHandoverReport);
  yield takeLatest(dailyRentConstants.DAILY_RENT_GET_RETURN_REPORT_REQUEST, getDailyRentReturnReport);
}

import React from 'react';



const ListItemView = ({ option }) => {

  return (<p>{ option.licensePlate }</p>);
};


export default ListItemView;

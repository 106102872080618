import React from 'react';
import PropTypes from 'prop-types';
import './DamageSummary.style.scss';
import {LicensePlate} from '@components/Car/LicensePlate';
import {Checkbox, FormControlLabel} from '@mui/material';
import {t} from '@helpers/i18n';
import dayjs from 'dayjs';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
import {timeFormatted} from "@services/formatters";


function DamageSummaryComponent(props) {
  const {
    damageLocation,
    vehicleInfo,
    thirdParty,
    profile,
    onTermsCheckboxClick,
    termsAccepted,
    error
  } = props;
  dayjs.extend(CustomParseFormat);

  return (
    <div className={'summary-main-container'}>
      <h3 className={'card-title'}>{t("summary")}</h3>

      <p className={'input-label'}>{t("reported_by")}</p>
      <p>{`${profile.name} ${profile.surname}`}</p>

      <p className={'input-label'}>{t('driver')}</p>
      <p>{damageLocation.driverName}</p>

      <div style={{margin: '10px 0'}}>
        <LicensePlate
          country="D"
          license={vehicleInfo.licensePlate}
        />
      </div>

      <p className={'input-label'}>{t("mileage")}</p>
      <p>{vehicleInfo.mileage}</p>

      <p className={'input-label'}>{t("type_of_damage")}</p>
      <p>{vehicleInfo.damageType}</p>

      <p className={'input-label'}>{t("what_happened")}</p>
      <p>{vehicleInfo.description}</p>

      {vehicleInfo.policeInvolved==='yes' &&
            <>
              <p className={'input-label'}>{t("police_information")}</p>
              <p>{vehicleInfo.policeInformation}</p>
            </>
      }

      <p className={'input-label'}>{t("accident_location")}</p>
      <p>
        {`${damageLocation.street} ${damageLocation.number}`} <br/>
        {`${damageLocation.zipcode} ${damageLocation.city}`} <br/>
        {`${damageLocation.country}`}
      </p>

      <p className={'input-label'}>{t("date_damage")}</p>
      <p>{dayjs(damageLocation.accidentDateAndTime).format('DD.MM.YYYY | HH:mm')}</p>

      {thirdParty.isThirdPartyInvolved &&
        <>
          <p className={'input-label'}>{t('opponent-exist')}</p>
          <div style={{margin: '10px 0'}}>
            <LicensePlate
              country="D"
              license={thirdParty.thirdPartyLicensePlate.toUpperCase()}
            />
          </div>
          <p>
            {(thirdParty.thirdPartyName || thirdParty.thirdPartySurname) &&
              <>
                {`${thirdParty.thirdPartyName} ${thirdParty.thirdPartySurname}`} <br/>
              </>
            }
            {(thirdParty.thirdPartyStreet || thirdParty.thirdPartyStreetNum) &&
              <>
                {`${thirdParty.thirdPartyStreet} ${thirdParty.thirdPartyStreetNum}`} <br/>
              </>
            }
            {(thirdParty.thirdPartyZipCode || thirdParty.thirdPartyCity) &&
              <>
                {`${thirdParty.thirdPartyZipCode} ${thirdParty.thirdPartyCity}`} <br/>
              </>
            }
            {thirdParty.thirdPartyCountry &&
              <>
                {`${thirdParty.thirdPartyCountry}`} <br/>
              </>
            }
            {thirdParty.thirdPartyPhone &&
              <>
                {`${thirdParty.thirdPartyPhone}`} <br/>
              </>
            }
            {thirdParty.thirdPartyEmail &&  `${thirdParty.thirdPartyEmail} `}
          </p>
        </>}
      <FormControlLabel
        onChange={e => onTermsCheckboxClick(e.target.checked)}
        sx={{ alignItems: 'flex-start', marginTop: '20px', color: '#2D7695'}}
        control={<Checkbox checked={termsAccepted} sx={{paddingTop: 0}} size="small" />}
        label={t('agree-damage-checkbox')} />
      {error.terms && <p className={'error-message'}>{t(error.terms)}</p>}
    </div>
  );
}

DamageSummaryComponent.propTypes = {
  damageLocation: PropTypes.object,
  vehicleInfo: PropTypes.object,
  thirdParty: PropTypes.object,
  profile: PropTypes.object,
  error: PropTypes.object,
  onTermsCheckboxClick: PropTypes.func,
  termsAccepted: PropTypes.bool,
};
export default DamageSummaryComponent;

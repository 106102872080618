import React from 'react';
import PropTypes from "prop-types";
/** Loading components **/
import { LicensePlate } from "@components/Car/LicensePlate";

/** Loading Date Formatter **/
import { dateFormatted } from "@services/formatters";

/** Loading translation helper **/
import { t } from "@helpers/i18n";


const TripHeaderInfo = (props) => {


  /**
   * extracting properties from trip prop
   */
  const { trip } = props;
  const { tracks } = trip;
  const date = tracks?.[0].start;
  const licensePlate = tracks?.[0]['license-plate'];

  /**
   * calculate distance and time for whole trip
   * @returns {{distance: number, time: string}}
   */
  const fullDistanceTime = () => {
    let distance = 0;
    let time = 0;
    tracks.map((track) => {
      distance = distance + track.distance;
      time = time + Math.ceil(Math.abs(new Date(track.end) - new Date(track.start)) / 60000);
    });
    const hours = Math.floor(time/60);
    const minutes = time % 60;
    if(hours < 1){
      time =`${minutes}`;
    } else {
      time = `${hours}h ${minutes}`;
    }

    return { distance, time };
  };

  return (<div className={'trips-licence flex'} style={{display:"flex", alignItems:'center', justifyContent:"space-between", width:"350px"}}>
    <LicensePlate license={licensePlate} country={'D'} />
    <span>{dateFormatted(date)}</span>
    <span>{Math.round(fullDistanceTime().distance)} {t('lbl_km')}</span>
    <span>{fullDistanceTime().time}{t('lbl_min')}</span>
  </div>);
};

TripHeaderInfo.propTypes = {
  trip: PropTypes.object
};

TripHeaderInfo.defaultProps = {
  trip: {}
};

export default TripHeaderInfo;

import React from 'react';
import {useSelector} from "react-redux";

import EmployeeLicense from "@components/Employee/EmployeeLicense";

const EmployeeRegistration = () => {
  const { employee = {} } = useSelector(state => state.employees);

  const { state, driverLicence, name, surname, licenceIdentifier, birthday, expirationDate, issueDate } = employee || {};

  const licenseProps = { state, driverLicence, name, surname, licenceIdentifier, birthday, issueDate, expirationDate };

  return (
    <EmployeeLicense {...licenseProps} />
  );
};

export default EmployeeRegistration;

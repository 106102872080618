import React, {useMemo} from "react";
import { useDispatch, useSelector } from "react-redux";
import { format } from 'date-fns';
import * as dailyRentsActions from "@store/dailyRent/actions";
import DailyRentWrapper from "@containers/DailyRent/DailyRentWrapper";

const DailyRentListHolder = () => {
  const dispatch = useDispatch();
  const { dailyRents = [], loading = false, lastPage = false } = useSelector(state => state.dailyRent);
  console.log({dailyRents});
  const { auth } = useSelector(state => state.auth);

  /**
     * Fetch Daily Rents
     * @param {number} page - current page
     * @param {string} filter - daily rent filters
     * @param {string} search - value from search field
     * @param {object} dateFrom - starting Date
     * @param {object} dateTo - end Date
     **/
  const filterDailyRents = ({ page, filter, search, dateFrom, dateTo }) => {
    dispatch(dailyRentsActions.dailyRentGet({
      userId: auth.user.id,
      filter: filter,
      search: search ? `&search=${search}` : '',
      page: page,
      dateFrom: dateFrom ? `&filterDateFrom=${format(dateFrom, 'yyyy-MM-dd')}` : '',
      dateTo: dateTo ? `&filterDateTo=${format(dateTo, 'yyyy-MM-dd')}` : ''
    }));
  };

  /**
     * Daily Rent List End reached
     * @param {number} page - current page
     * @param {string} filter - daily rent filters
     * @param {string} search - value from search field
     * @param {object} dateFrom - starting Date
     * @param {object} dateTo - end Date
     **/
  const isEndReached = ({ page, filter, search, dateFrom, dateTo }) => {
    dispatch(dailyRentsActions.dailyRentLoadMore({
      userId: auth.user.id,
      filter: filter,
      search: search ? `&search=${search}` : '',
      page: page,
      dateFrom: dateFrom ? `&filterDateFrom=${format(dateFrom, 'yyyy-MM-dd')}` : '',
      dateTo: dateTo ? `&filterDateTo=${format(dateTo, 'yyyy-MM-dd')}` : ''
    }));
  };

  const memorizedDailyRents = useMemo(() => {
    return (
      <DailyRentWrapper
        dailyRents={dailyRents}
        loading={loading}
        lastPage={lastPage}
        applyDailyRentFilter={filterDailyRents}
        endReached={isEndReached}
      />
    );
  }, [JSON.stringify(dailyRents), loading, lastPage]);

  return (
    <>
      {memorizedDailyRents}
    </>
  );
};

export default React.memo(DailyRentListHolder);

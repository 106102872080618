import * as carCheckConstants from "./constants";


export const carChecksGet = (payload) => ({type: carCheckConstants.CARCHECK_GET_REQUEST, payload});
export const carChecksGetSuccess = (payload) => ({type: carCheckConstants.CARCHECK_GET_SUCCESS, payload});
export const carChecksGetFail = (error) => ({type: carCheckConstants.CARCHECK_GET_FAIL, error});

export const carChecksLoadMore = (payload) => ({type: carCheckConstants.CARCHECK_LOAD_MORE_REQUEST, payload});
export const carChecksMoreSuccess = (payload) => ({type: carCheckConstants.CARCHECK_LOAD_MORE_SUCCESS, payload});
export const carChecksLoadMoreFail = (payload) => ({type: carCheckConstants.CARCHECK_LOAD_MORE_FAIL, payload});

export const carCheckGetById = (payload) => ({type: carCheckConstants.CARCHECK_SINGLE_GET_REQUEST, payload});
export const carCheckGetByIdSuccess = (payload) => ({type: carCheckConstants.CARCHECK_SINGLE_GET_SUCCESS, payload});
export const carCheckGetByIdFail = (error) => ({type: carCheckConstants.CARCHECK_SINGLE_GET_FAIL, error});

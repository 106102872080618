import React from 'react';

import { NavLink } from "react-router-dom";
import { t } from "@helpers/i18n";

const NavigationLink = ({ route }) => {

  return (<NavLink key={route.key} to={route.path} activeClassName="active">
    <li key={route.key} >
      {route.icon}
      <span>{t(route.key)}</span>
    </li>
  </NavLink>);
};
export default NavigationLink;

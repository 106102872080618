import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import LazyLoadingList from "@components/LazyLoadingList";
import EmployeeCard from "@components/Employee/Card";
import MapContainer from "@containers/Map";
import SearchBarFilter from "@components/Common/SearchBar/withFilters";
import VehicleFilter from "@components/Vehicle/Filter";
import * as employeesActions from "@store/employees/actions";

const EmployeeListContainer = () => {
  /**
   * Redux selectors and dispatch
   */
  const { employees, loading, lastPage } = useSelector((state) => state.employees);
  const { auth } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  /**
   * Navigation history hook
   */
  const history = useHistory();

  /**
   * React State
   * filter parameters
   */
  const [filterQuery, setFilterQuery] = useState(``);
  const [employeeSearch, setEmployeeSearch] = useState("");
  const [page, setPage] = useState(0);
  const [settings, setSettings] = useState([
    {id: 0, title: "all", filter: "all", value: true},
    {id: 1, title: "complete", filter: "filterDataCompleted=1", value: true},
    {id: 2, title: "incomplete", filter: "filterDataCompleted=0", value: true},
  ]);
  const [showSettings, setShowSettings] = useState(false);

  /**
   * Fetches first page of employees
   */
  const fetchEmployeeInitial = () => {
    setPage(0);
    dispatch(employeesActions.employeeGet({
      userId:auth.user.id,
      page:0,
      filter:filterQuery,
      search: employeeSearch
    }));
  };

  /**
   * Fetching employee effect
   **/
  useEffect(() => {
    fetchEmployeeInitial();
  }, [auth, filterQuery, employeeSearch]);

  /**
   * On Employee Card Click redirect to Employee personal information
   * @param id
   */
  const onEmployeeCardClick = (id) => {
    history.push(`/employees/${id}/info`);
  };

  /**
   * set employee search local state
   * @param value
   */
  const onEmployeeSearch = (value) => {
    setEmployeeSearch(value);
  };

  /**
   * On Employee Filter value change
   * @param {string} key - filter key
   **/
  const onSettingsChange = (key) => {
    const newFilter = [...settings];
    let i = 0;
    if (Object.is(key, "all") && settings[0].value) {
      newFilter.map(filter => filter.value = false);
    }
    else if (Object.is(key, "all") && !settings[0].value) {
      newFilter.map(filter => filter.value = true);
    }
    else {
      for (i = 1; i < newFilter.length; i++) {
        if (Object.is(newFilter[i].title, key)) {
          newFilter[i].value = !newFilter[i].value;
        }
      }
    }
    newFilter[0].value = Object.is(newFilter[1].value, newFilter[2].value) && newFilter[1].value;
    setSettings(newFilter);
  };

  /**
   * on toggle button click show or disappear Employees filters
   */
  const onSettingsClick = () => {
    setShowSettings(!showSettings);
  };

  /** Preform Employee filtering **/
  const onApplyClick = () => {
    if (settings[1].value && !settings[2].value) {
      setFilterQuery(`&${settings[1].filter}`);
    }
    if (!settings[1].value && settings[2].value) {
      setFilterQuery(`&${settings[2].filter}`);
    }
    if (settings[0].value && settings[1].value && settings[2].value) {
      setFilterQuery('');
    }
  };

  /**
   * Fetch employees on end page reached
   */
  const isEndReached = () => {
    setPage(page + 1);
    dispatch(employeesActions.employeeLoadMore({
      userId: auth.user.id,
      page: page + 1,
      search: employeeSearch,
      filter: filterQuery
    }));
  };

  /**
   * Render Employee Card List
   * @param employee
   * @param employeeIndex
   * @returns {JSX.Element}
   */
  const renderCardListEmployee = (employee, employeeIndex) => {
    return(
      <EmployeeCard
        key={employeeIndex.toString()}
        employee={employee}
        onClick={() => onEmployeeCardClick(employee.userId)}
      />
    );
  };

  /**
   * render Lazy loading for employee list
   * @returns {JSX.Element}
   */
  const renderEmployeeList = () => {
    return(
      <LazyLoadingList
        data={employees ?? []}
        loading={loading}
        isLastPage={lastPage===page}
        onEndReached={isEndReached}
        renderItem={renderCardListEmployee}
      />
    );
  };

  /**
   * employee props for EmployeeFilter Component
   * @type {{filterValues: [
   * {filter: string, id: number, title: string, value: boolean},
   * {filter: string, id: number, title: string, value: boolean},
   * {filter: string, id: number, title: string, value: boolean}],
   * visible: boolean, filterChange: onSettingsChange, applyFilter: onApplyClick}}
   */
  const employeeFilterProps = {
    visible: showSettings,
    filterValues: settings,
    filterChange: onSettingsChange,
    applyFilter: onApplyClick,
    employeeFilterTitle:{complete:'employee_complete', incomplete:'employee_incomplete'}
  };

  return (
    <div className={'list-wrapper'}>
      <MapContainer/>
      <div className={'list-view'}>
        <SearchBarFilter onSearch={(e) => onEmployeeSearch(e.target.value)}
          search={employeeSearch}
          onFilterPress={onSettingsClick}
          onClose={() => onEmployeeSearch('')}
          children={<VehicleFilter {...employeeFilterProps}/>} />
        {renderEmployeeList()}
      </div>
    </div>
  );
};

export default EmployeeListContainer;

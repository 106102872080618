import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { damagesConstants } from "@constants";
import {
  vehiclesGetDamagesById,
  vehicleDamagesLoadMore,
} from "@store/vehicles/actions";

/** load components **/
import Grid from '@mui/material/Grid';
import CardListDamage from "@components/Card/CardListDamage";
import VehicleMap from "@components/Vehicle/VehicleMap/VehicleMap.component";

const VehicleDamagesComponent = () => {
  const {
    lastDamagesPage,
    loadingMoreDamages,
    loadingDamages,
    generalInfo,
    vehicle,
    damages,
  } = useSelector((state) => state.vehicles);

  const { id } = useParams();

  const history = useHistory();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);

  useEffect(() => {
    dispatch(vehiclesGetDamagesById({ id, page: 0 }));
  }, []);


  const loadMoreDamages = () => {
    if (loadingMoreDamages || loadingDamages) {
      return;
    }

    setPage(page + 1);
    dispatch(vehicleDamagesLoadMore({ id, page: page + 1 }));
  };


  const onDamageCardClick = (id) => {
    const damage = damages.find((damage) => damage.id === id);

    dispatch({
      type: damagesConstants.SET_CURRENT_DAMAGE,
      payload: {
        ...damage,
      },
    });

    history.push(`/damages/${id}/partlight`);
  };

  return (
    <>
      <Grid container style={{height: '100%'}}>
        <Grid item xs={7}>
          <div className="tab-list details-content overflow-auto">
            <CardListDamage
              damages={damages}
              loading={loadingDamages}
              page={page}
              lastPage={lastDamagesPage === page}
              isEndReached={loadMoreDamages}
              onDamageCardClick={onDamageCardClick}
            />
          </div>
        </Grid>
        <Grid item xs={5}>
          <VehicleMap generalInfo={generalInfo} vehicle={vehicle}/>
        </Grid>
      </Grid>
    </>
  );
};

VehicleDamagesComponent.propTypes = {};
export default VehicleDamagesComponent;

import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';

/** Load components **/
import DamageImage from './DamageImage';
import Slider from '@components/Common/Slider';

/** Load helpers **/
import { t } from '@helpers/i18n';
import {media} from '@api/media';


const DamageAdditionalInfo = ({ info }) => {

  const { id, appState, images } = info;

  const sliderRef = useRef();

  const [slides, setSlides] = useState(new Array(images.length).fill(null));

  /** Call back method when car check damage image is clicked **/
  const onImageClick = (index) => {
    sliderRef.current.previewImage(index);
  };

  /**
   * API call for loading full resolution image
   * @param {number} index
   **/
  const loadFullImage = async (index) => {
    return await media.getFullImage({id: id, name: images[index].name, type: 'damage' , category:''});
  };


  return (
    <div className={'damage-extra-info'}>
      <p className={'text-blue'}> { t("damage-id") } { id }</p>
      <p className={'text-blue'}>  { t('state') }: { appState ? t(appState) : '-' }</p>
      { /** load images **/ }
      <div className={'flex flex-wrap'}>
        {
          images.map((img, index) =>
            <DamageImage
              imgSrc={img.blob}
              onImageClick={() => onImageClick(index)}
              key={index}
              alt={`damageImage-${index}`}/>
          )
        }
      </div>
      {
        <Slider ref={sliderRef}
          slides={slides}
          setSlides={setSlides}
          imageAPI={loadFullImage}
        />
      }
    </div>
  );
};

DamageAdditionalInfo.propTypes = {
  info: PropTypes.object.isRequired,
};

DamageAdditionalInfo.defaultProps = {
  info: {},
};



export default DamageAdditionalInfo;

import React, {useState} from 'react';
import { useParams } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import CheckIcon from 'mdi-react/CheckIcon';
import {BUTTON_TYPES, damagesConstants} from '@constants';
/** load components **/
import {Field, Form, Formik} from 'formik';
import {Button} from '@components/Common/Button';
import MultipleUploadComponent from '@components/Common/MultipleUpload';
import PageHeader from '@components/Layout/PageHeader';
import {Alert, Snackbar } from '@mui/material';
import AlertCmp from '@components/Common/Alert';
import {CmpInput} from '@components/Common/CmpInput/CmpInput.component';
import AnimatedLoader from '@components/Common/AnimatedLoader';
/** load services **/
import {toBase64} from '@services/utils';
import {t} from '@helpers/i18n';

import './DamageReclamation.styles.scss';


const DamageReclamationContainer = () => {

  const { id } = useParams(); // load damage id for url
  const {
    reclamationLoading,
    reclamationSuccess,
    reclamationFailure,
  } = useSelector(state => state.damages);
  const [removedFiles, setRemovedFiles] = useState([]);
  const [reasonError, setReasonError] = useState(false);
  const [imagesError, setImagesError] = useState(false);
  const dispatch = useDispatch();

  const validate = (values) => {
    setImagesError((Array.from(values.reclamationImages).length - removedFiles.length) < 3);
    setReasonError(values.reason?.length < 10);
    return values.reason?.length > 10 && (values.reclamationImages.length - removedFiles.length) > 2;
  };

  const onSave = async (values) => {
    if (!validate(values)) {
      return;
    }
    const payload = {
      damageId: id,
      reclamationReason: values.reason,
      files: [],
    };
    const filesArray = values.reclamationImages;
    for (let i = 0; i < filesArray.length; i++) {
      if (!removedFiles.includes(i)) {
        // eslint-disable-next-line no-await-in-loop
        const base64Image = await toBase64(filesArray[i]);
        payload.files.push({
          blob: base64Image,
          identifier: '',
          desc: '',
          name: `damage_${payload.damageId}_reclamation_${i + 1}`,
          type: 'reclamation',
        });
      }
    }

    dispatch({
      type: damagesConstants.SEND_RECLAMATION_REQUEST,
      payload,
    });
  };

  /**
   * File upload field handler
   * @param fileList - newly uploaded files
   * @param currentValues - previously uploaded files
   * @param setFieldValue - form field value setter
   */
  const onFileUpload = (fileList, currentValues, setFieldValue) => {
    const fileArray = Array.from(fileList);
    setFieldValue('reclamationImages', [...currentValues, ...fileArray]);
  };

  /**
   * Resets success and failure flags for reclamation
   */
  const closePopup = () => {
    dispatch({
      type: damagesConstants.SEND_RECLAMATION_RESET,
    });
  };

  if (reclamationLoading) {
    return (
      <AnimatedLoader/>
    );
  }

  return (
    <div className={'details-container'}>
      {reclamationLoading && <AnimatedLoader/>
      }
      <PageHeader backLink={`/damages`} text={t("back")}/>
      <div className={'details-content'}>

        <Formik
          initialValues={
            {
              reclamationImages: [],
              reason: '',
            }
          }
          onSubmit={(values) => {
            onSave(values);
          }}
        >
          {({values, submitForm, handleChange, setFieldValue}) => (
            <Form  className="flex h-100 p-b-2 flex-col justify-between">

              <div className={'mt-10'}>
                <p className="label">{t('photos_of_the_damage_length')}</p>
                <MultipleUploadComponent
                  name={'reclamationImages'}
                  value={values.reclamationImages}
                  onChange={(e) => onFileUpload(e.target.files, values.reclamationImages, setFieldValue)}
                  onRemove={(index) => setRemovedFiles([...removedFiles, index])}
                />
                <Field
                  name="reason"
                  label={`${t("reclamation_report")} (${t('min10characters')})`}
                  inputType={'textarea'} type={'textArea'}
                  component={CmpInput}
                />
              </div>

              <Button
                className={'btn-edit-license self-center'}
                icon={<CheckIcon/>}
                isSmall
                isSubmit
                type={BUTTON_TYPES.SUCCESS}
                isLoading={reclamationLoading}
                text={t("send")}
              />
            </Form>
          )}
        </Formik>
      </div>
      {reasonError &&
        <Snackbar
          autoHideDuration={4000}
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
          open={reasonError}
          key={'damage_reason_error'}>
          <Alert severity="error">
            {t('damage_reason_error')}
          </Alert>
        </Snackbar>
      }
      {imagesError &&
        <Snackbar
          autoHideDuration={4000}
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
          open={imagesError}
          key={'damage_images_error'}
        >
          <Alert severity="error">
            {t('damage_images_error')}
          </Alert>
        </Snackbar>
      }
      {reclamationSuccess && <AlertCmp
        visible={reclamationSuccess}
        title={t('reclamation_success_message')}
        onClick={closePopup}
      />}
      {reclamationFailure && <AlertCmp
        visible={reclamationFailure}
        title={t('reclamation_failure_message')}
        onClick={closePopup}
      />}
    </div>
  );
};

DamageReclamationContainer.propTypes = {};

export default DamageReclamationContainer;

import React, { useCallback } from 'react';
import PropTypes from "prop-types";

/** Load components **/
import LazyLoadingList from '@components/LazyLoadingList';
import CarCheckCard from '@components/CarCheck/Card';

const CarCheckList = ({
  loading,
  carChecks,
  isLastPage,
  isEndReached }) => {

  /**
   * Render single Car Check card
   * @param {object} carCheck - carCheck object
   * @param {number} i - index of element
   * @returns {JSX.Element}
   **/
  const renderCarCheckCard = (carCheck, i) => (
    <CarCheckCard
      carCheck={carCheck}
      carCheckId={carCheck.id}
      key={i}
    />);


  /**
   * Render Lazy Loading Card list
   * @returns {JSX.Element}
   **/
  const renderContent = useCallback(() => {

    return (
      <LazyLoadingList
        data={carChecks || []}
        loading={loading}
        isLastPage={isLastPage}
        onEndReached={isEndReached}
        renderItem={renderCarCheckCard}
      />
    );
  }, [loading, carChecks]);

  return (
    <>
      {renderContent()}
    </>
  );
};

export default CarCheckList;

CarCheckList.propTypes = {
  loading: PropTypes.bool, // loading animation toggle
  carChecks: PropTypes.array, // list of CarChecks
  isLastPage: PropTypes.bool, // is the last page reached
  isEndReached: PropTypes.func, // callback when end on list is reached
};

CarCheckList.defaultProps = {
  loading: false,
  carChecks: [],
  isLastPage: false,
  isEndReached: () => { },
};

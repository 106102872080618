import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {useLocation, useParams} from "react-router-dom";
import { t } from "@helpers/i18n";
import {activeTabIndex} from "@services/utils";

/** load components **/
import PageHeader from "@components/Layout/PageHeader";
import Avatar from "@components/User/Avatar";
import { LicensePlate } from "@components/Car/LicensePlate";
import Navigation from "@components/ProfileNavigation";
import InfoBlock from "@components/User/InfoBlock";

/** load Material Design Icons **/
import CarIcon from "mdi-react/CarIcon";
import PhotoCameraIcon from "mdi-react/ImageAreaIcon";
import FormatListBulletedIcon from "mdi-react/FormatListBulletedIcon";

/** load constants and style **/
import { damagesConstants } from "@constants";
import "./index.scss";
import {getDamageStateCardColor, getDamageStateIcon} from '@helpers/common';

const Damages = (props) => {

  /**
   * Redux selectors
   */
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const params = useParams();

  const { currentDamage } = useSelector(state => state.damages);
  const damageId = currentDamage.id || params.id;

  /**
   * Initial Mounted hook
   * fetch damage by id
   **/
  useEffect(() => {
    dispatch({
      type: damagesConstants.DAMAGE_GET_BY_ID_REQUEST,
      payload: {
        userId: damageId,
      }
    });

    return () => {
      dispatch({
        type: damagesConstants.CLEAN_CURRENT_DAMAGE
      });
    };
  }, []);

  /**
   * Render Page Header For Damages Details
   * @returns {JSX.Element|null}
   */
  const renderPageHeader = () => {

    if (!props.tabs) {
      return null;
    }

    const routes = [
      {
        key: "sketch",
        icon: <CarIcon />,
        path: `/damages/${damageId}/partlight`
      },
      {
        key: "pictures",
        icon: <PhotoCameraIcon />,
        path: `/damages/${damageId}/pictures`,
      },
      {
        key: "details",
        icon: <FormatListBulletedIcon />,
        path: `/damages/${damageId}/details`,
      },
    ];


    return (
      <Navigation
        routes={routes}
        activeIndex={activeTabIndex(routes, pathname)}
        horizontal={true}
      />
    );
  };

  const name = currentDamage?.driverName || '';
  const surname = currentDamage?.driverSurname || '';
  const number = currentDamage?.damageNumber || '';
  const license = currentDamage?.vehicleId || '';

  const getIcon = () => {
    return getDamageStateIcon(currentDamage?.appState);
  };

  const getIconColor = () => {
    return getDamageStateCardColor(currentDamage?.appState);
  };

  return (
    <div id="damage-container" className="details-container">
      <PageHeader text={t("back")} backLink={'/damages'}>
        <div className="header-info-row">
          <InfoBlock
            name={`${name} ${surname}`}
            isAdmin={false}
            tags={[number]}
            avatarElement={
              <Avatar
                icon={getIcon()}
                src={currentDamage.userAvatar}
                iconBackgroundColor={getIconColor()}
              />
            }
            bottomElement={
              <LicensePlate
                country="D"
                license={license}
              />
            }
            state={currentDamage?.id ? currentDamage?.appState ? t(currentDamage?.appState) : '-' : null}
          />
          {renderPageHeader()}
        </div>
      </PageHeader>
      <div className={'details-content'}>
        {props.render}
      </div>
    </div>
  );
};

Damages.propTypes = {
  tabs: PropTypes.bool,
  render: PropTypes.element
};
Damages.defaultProps = {
  tabs: false,
};

export { Damages };

import StarIcon from "mdi-react/StarIcon";
import StarHalfFullIcon from "mdi-react/StarHalfFullIcon";
import StarOutlineIcon from "mdi-react/StarOutlineIcon";
import "./ServicePartnerPopup.styles.scss";

const ServicePartnerPopup = ({ name, customerPlace, rating }) => {
  const renderStar = (rate) => {
    if (rate >= 1) {
      return <StarIcon />;
    }

    if (rate >= 0.5) {
      return <StarHalfFullIcon />;
    }

    return <StarOutlineIcon />;
  };

  return (
    <div className="service-popup">
      <div className="info-container">
        <div className="info-header">
          <div className="header-name">{name}</div>
        </div>
        <div className="popup-footer">
          <div className="customer-data">
            <div className="data-line">
              <div className="line-start">{`${customerPlace.street} ${customerPlace.number}`}</div>
            </div>
            <div className="data-line">
              <div className="line-start">{`${customerPlace.zip} ${customerPlace.city}`}</div>
            </div>
          </div>
        </div>
        <div className="rating-set">
          {renderStar(rating)}
          {renderStar(rating - 1)}
          {renderStar(rating - 2)}
          {renderStar(rating - 3)}
          {renderStar(rating - 4)}
        </div>
        <div className="triangle" />
      </div>
    </div>
  );
};

export default ServicePartnerPopup;

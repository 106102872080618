import React from 'react';
import classnames from "classnames";

import "./CmpInput.style.scss";

export const CmpInput = (props) => {
  const {label, error, placeholder, inputType, field, width, prop} = props;

  return (
    <div className={classnames("cmp-input", width)}>
      <p className="cmp-input-label">{label}</p>
      <input className={'cmp-input-field'} placeholder={placeholder}  type={inputType} {...field} {...prop} />
      {error && <p className={'cmp-input-error'}>{error}</p>}
    </div>
  );
};

CmpInput.propTypes = {
  // field: PropTypes.object,
  // form: PropTypes.object,
  // prop: PropTypes.object,
  // width: PropTypes.oneOf(Object.values(INPUT_SIZES)),
  // options: PropTypes.arrayOf(PropTypes.shape({
  //   value: PropTypes.any,
  //   label: PropTypes.string,
  // })),
  // inputType: PropTypes.string,
  // placeholder: PropTypes.string,
};

CmpInput.defaultProps = {
  field: null,
  form: null,
  prop: null,
  // width: INPUT_SIZES.FULL,
  options: [],
  inputType: "text",
  placeholder: "",
};

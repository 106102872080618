import React from "react";
import PropTypes from "prop-types";

import PersonIcon from "mdi-react/AccountIcon";
import { ICON_BACKGROUND_COLORS } from "@helpers/constants";
import classnames from "classnames";

const Icon = ({ iconBackgroundColor, icon, small }) => {
  return (
    <div
      className={classnames(`icon-container ${iconBackgroundColor}`, {
        small: small,
        "has-background-color":
          iconBackgroundColor !== ICON_BACKGROUND_COLORS.TRANSPARENT,
      })}
    >
      {icon}
    </div>
  );
};

Icon.propTypes = {
  icon: PropTypes.node,
  iconBackgroundColor: PropTypes.string,
  small: PropTypes.bool,
};

Icon.defaultProps = {
  icon: null,
  iconBackgroundColor: <PersonIcon />,
  small: false,
};

export default Icon;

import React, { useRef, useState } from 'react';

import { media } from "@api/media";
import { date, t } from "@helpers/i18n";
import { DEFAULT_DATE_ZERO, NO_DATE } from "@constants";
import { LicenseFormatter, LicenseIcon } from "@services/formatters/profile/license";

import DataForm from "@components/Common/Form/DataForm";
import Slider from '@components/Common/Slider';
import { EmployeeLicenseImages } from "@components/Employee";
import PageContent from "@components/Layout/PageContent";

import './EmployeeLicense.styles.scss';

const EmployeeLicense = ({ state, driverLicence, name, surname, issueDate, expirationDate, licenceIdentifier, birthday }) => {
  const sliderRef = useRef();
  const [slides, setSlides] = useState(LicenseFormatter.licenseImagesSlideFormatter(driverLicence));

  /**
   * will load license image in full original resolution
   * @param {number} index - index is used to diff between front & back img
   */
  const loadFullImage = (index) => {
    const imageId = index 
      ? driverLicence.backImage.desc 
      : driverLicence.frontImage.desc;

    return media.getFullImage({ id: imageId, name: '', type: 'driverlicence', category: '' });
  };

  /**
   * method used for slider
   * @param {number} index - index is used to diff between front & back img
   */
  const onPreviewImage = (index) => {
    sliderRef.current.previewImage(index);
  };

  /** Driving license form data **/
  const driversLicenseData = [
    {
      color: state,
      name: t("state"),
      value: state,
      icon: LicenseIcon.getIcon(state)
    },
    {
      name: t("surname"),
      value: surname ? surname : '-',
    },
    {
      name: t("name"),
      value: name ? name : '-',
    },
    {
      name: t("number"),
      value: licenceIdentifier ? licenceIdentifier : '-',
    },
    {
      name: t("date-of-issue"),
      value: issueDate && issueDate !== NO_DATE && issueDate !== DEFAULT_DATE_ZERO ? date(issueDate) : '-'
    },
    {
      name: t("expiry-date"),
      value: expirationDate && expirationDate !== NO_DATE && expirationDate !== DEFAULT_DATE_ZERO ? date(expirationDate) : '-'
    },
    {
      name: t("birthday"),
      value: date(birthday) && date(birthday) !== NO_DATE ? date(birthday) : '-'
    },
  ];

  return (
    <PageContent>
      <div className="license-tab">
        <EmployeeLicenseImages
          driverLicense={driverLicence}
          onImagePreview={onPreviewImage}
        />
        <DataForm
          data={driversLicenseData}
        />
      </div>
      <Slider
        ref={sliderRef}
        slides={slides}
        setSlides={setSlides}
        imageAPI={loadFullImage} />
    </PageContent>
  );
};

export default EmployeeLicense;

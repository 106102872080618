/** Load components **/
import React, {useMemo} from 'react';
import MapContainer from '@containers/Map';
import InvoicesWrapper from '@containers/Invoices/InvoicesWrapper/InvoicesWrapper.container';

/** Load Redux hooks and actions **/
import {useDispatch, useSelector} from 'react-redux';
import * as invoicesActions from '@store/invoices/actions';

/** Load routing hooks **/
import {useLocation} from "react-router-dom";

/** Load services and constants **/
import {format} from 'date-fns';
import DATE_FORMATS from '@helpers/constants/dateFormats';


const InvoicesList = () => {

  const location = useLocation();
  const contractId = location?.state?.contractId;
  const reservationId = location?.state?.reservationId;
  const dispatch = useDispatch();
  const {invoices, loading, lastPage} = useSelector(state => state.invoices);
  const {auth} = useSelector(state => state.auth);

  // Function to determine the filter parameter string
  const getFilterParam = (param, value) => {
    return value === 0 || value === '0' || !value || value === ''
        ? ''
        : `&${param}=${value}`;
  };

  const getQueryParameter = (param, value, formatter) => {
    return value
        ? `&${param}=${formatter ? format(value, formatter) : value}`
        : '';
  };

  /**
   * Fetch Invoices
   * @param {number} page - current page
   * @param {string} filter - invoice filters
   * @param {string} search - value from search field
   * @param {object} dateFrom - starting Date
   * @param {object} dateTo - end Date
   **/
  const filterInvoices = ({ page, filter, search, dateFrom, dateTo }) => {
    dispatch(invoicesActions.invoiceGet({
      userId: auth.user.id,
      filter: filter,
      search: getQueryParameter('search', search),
      page: page,
      dateFrom: getQueryParameter('filterDateFrom', dateFrom, DATE_FORMATS.full_YMD_dash),
      dateTo: getQueryParameter('filterDateTo', dateTo, DATE_FORMATS.full_YMD_dash),
      filterContractId: getFilterParam('filterContractId', contractId),
      filterReservationId: getFilterParam('filterReservationId', reservationId)
    }));
  };


  /**
   * Invoices List End reached
   * @param {number} page - current page
   * @param {string} filter - appointment filters
   * @param {string} search - value from search field
   * @param {object} dateFrom - starting Date
   * @param {object} dateTo - end Date
   **/
  const isEndReached = ({ page, filter, search, dateFrom, dateTo }) => {
    dispatch(invoicesActions.invoiceGetMore({
      userId: auth.user.id,
      filter: filter,
      search: getQueryParameter('search', search),
      page: page,
      dateFrom: getQueryParameter('filterDateFrom', dateFrom, DATE_FORMATS.full_YMD_dash),
      dateTo: getQueryParameter('filterDateTo', dateTo, DATE_FORMATS.full_YMD_dash),
      filterContractId: getFilterParam('filterContractId', contractId),
      filterReservationId: getFilterParam('filterReservationId', reservationId)
    }));
  };

  /** Memoized Invoices List **/
  const memoizedInvoices = useMemo(() => {
    return (
      <>
        <InvoicesWrapper
          invoices={invoices}
          loading={loading}
          lastPage={lastPage}
          endReached={isEndReached}
          applyInvoicesFilter={filterInvoices}
        />
      </>
    );
  }, [JSON.stringify(invoices), loading, lastPage]);



  return (
    <div id={'invoices-list'} className={'list-wrapper'}>
      <MapContainer />
      { memoizedInvoices }
    </div>
  );
};


export default InvoicesList;

import React, {useMemo} from 'react';
import PropTypes from "prop-types";
/** Loading components **/
import Avatar from "@components/User/Avatar";

/** Loading MDI Icons **/
import BriefcaseOutlineIcon from "mdi-react/BriefcaseOutlineIcon";
import AccountStarOutlineIcon from "mdi-react/AccountStarOutlineIcon";
import FlareIcon from "mdi-react/FlareIcon";

/** Loading translation helper **/
import { t } from "@helpers/i18n";

/** Loading styles **/
import './TripDriverInfo.styles.scss';


const TripDriverInfo = (props) => {

  /**
   * Extract data from props
   */
  const { tracks, drivers } = props;

  /**
   * returns Trip type
   * @returns {JSX.Element} The trip type icon
   */
  const loadTripType = useMemo( () => {
    const tripTypes = tracks.map((track) => track['trip-type']);
    const hasBusiness = tripTypes.some((type) => type === 'business');
    const hasPrivate = tripTypes.some((type) => type === 'private');
    const hasNotDefinedType = tripTypes.some((type) => type === 'unset');

    if (hasNotDefinedType) {
      return <div className={'type'}>-</div>;
    }
    if (hasBusiness && hasPrivate && !hasNotDefinedType) {
      return (<div className={'type'}><FlareIcon color={'#00587F'}/> {t('lbl_type_mixed')}</div>);
    }

    const allBusiness = tripTypes.every((type) => type === 'business');
    const allPrivate = tripTypes.every((type) => type === 'private');

    if (allBusiness) {
      return (<div className={'type'}><BriefcaseOutlineIcon color={'#00587F'}/> {t('lbl_type_business')}</div>);
    }
    if (allPrivate) {
      return (<div className={'type'}><AccountStarOutlineIcon color={'#00587F'}/> {t('lbl_type_private')}</div>);
    }
  },[JSON.stringify(tracks)]);


  /**
   * return Driver text depending on number of drivers for given trip
   * @returns {string|*}
   */
  const driverText = () => {
    const tripDrivers = tracks.map((track) => track['driver-id']);

    const hasNotDefinedDriver = tripDrivers.some((driver) => driver === 'unset');

    if(hasNotDefinedDriver){
      return  "-";
    } else if (drivers.length > 1) {
      return  (
        <div className={'multi-driver'}>
          <Avatar src={''} micro/>
          <span className={'driver-name-span'}>{t("multiple_drivers")}</span>
        </div>);
    } else if (drivers.length === 1) {
      const driverInfo = `${drivers[0].name} ${drivers[0].surname}`;
      return (
        <>
          <Avatar src={drivers[0].avatar} micro />
          <span className={'driver-name-span'}>{driverInfo}</span>
        </>);
    }
  };

  return (
    <div className={'trip-static'}>
      <div>{t('lbl_type')}{ loadTripType }</div>
      <div>{t('driver')}<div className={'driver'}>{driverText()}</div></div>
    </div>
  );
};

TripDriverInfo.propTypes = {
  tracks: PropTypes.array,
  drivers: PropTypes.array
};

TripDriverInfo.defaultProps = {
  tracks: [],
  drivers: []
};

export default TripDriverInfo;

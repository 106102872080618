import React, {useEffect, useState, memo} from "react";

/** Loading components **/
import {Input} from "@components/Common/Input";
import Toggler from "@components/Common/Toggler";
import BlockingOverlayComponent from "@containers/Damages/AddDamage/BlockingOverlay/BlockingOverlay.component";

/** Loading MUI components **/
import Grid from "@mui/material/Grid";

/** Loading formik components and helper functions **/
import {Field, Formik} from "formik";
import {t} from "@helpers/i18n";

/** Loading styles **/
import './GarageDetails.styles.scss';

/**
 * Initial Values for Garage Details
 * @type {{zipcode: string, number: string, streetAndNumber: string, mail: string, phone: string, city: string, preferredGarage: string, garageName: string, garageId: string, appointmentConfirmation: boolean}}
 */
const initialState = {
  preferredGarage: '',
  garageId: '',
  number: '',
  mail: '',
  phone: '',
  garageName:'',
  streetAndNumber:'',
  zipcode:'',
  city:'',
  appointmentConfirmation: true
};


const GarageDetails = ({stepData, onChange, isDisabled, error, garages, preselectedGarage}) => {


  /**
   * Local state for all changes in the step.
   *
   * On mount should be set to global data from parent,
   * so the form can be prefilled with data when going in between steps
   */
  const [data, setData] = useState(stepData || initialState);

  /**
   * This effect should update parent state once there is local changes
   */
  useEffect(() => {
    onChange(data);
  }, [data]);

  /**
   * If a user has been redirected from the home screen to create appointment
   * Preselect garage that user chose on a home screen
   */
  useEffect(() => {
    if(preselectedGarage) {
      setData({
        ...data,
        garageId: preselectedGarage.id,
        garageName: preselectedGarage.title,
        streetAndNumber :`${preselectedGarage.address.street} ${preselectedGarage.address.number}`,
        number: preselectedGarage.address.number,
        mail: preselectedGarage.email,
        phone: preselectedGarage.phone,
        zipcode: preselectedGarage.address.zip,
        city: preselectedGarage.address.city,
        preferredGarage: preselectedGarage.title
      });
    }
  }, [preselectedGarage]);

  /**
   * Toogle form visibility
   */
  const onToggleChange = () => {
    setData({... data, ['appointmentConfirmation']: !data.appointmentConfirmation});
  };

  /**
   * Gets garages
   * @returns
   */
  const titles = () => {
    const list = garages.map((g, i) => ({value: g.name, label: g.name, id: i + 1}));
    if(preselectedGarage) {
      list.unshift({value: preselectedGarage.title, label: preselectedGarage.title, id: preselectedGarage.id});
    }
    list.unshift({value: '', label: t("please_select"), id: 0});
    return list;
  };

  /**
   * On Field Change set local state
   * @param {string} propertyName
   * @param value
   */
  const onFieldChange = (propertyName, value) => {
    setData({... data, [propertyName]: value});
  };

  /**
   * It takes the value of the selected garage and sets the state of the data object
   * with the values of the selected garage
   * @param e - the event that is triggered when the user selects a garage from the dropdown
   */
  const preferredGarage = (e) => {
    onFieldChange('preferredGarage', e.target.value);

    const selectedGarage = garages.find(g => g.name === e.target.value);
    if (selectedGarage) {
      setData({
        ...data,
        garageId: selectedGarage.id,
        garageName: selectedGarage.name,
        streetAndNumber :`${selectedGarage.address.street} ${selectedGarage.address.number}`,
        number: selectedGarage.address.number,
        mail: selectedGarage.mail,
        phone: selectedGarage.phone,
        zipcode: selectedGarage.address.zipcode,
        city: selectedGarage.address.city,
        preferredGarage: selectedGarage.name
      });
    }
    if (e.target.value === ''){
      setData({
        ...data, garageName: '',
        garageId:'',
        streetAndNumber: '',
        number:'',
        mail: '',
        phone: '',
        zipcode: '',
        city: '',
        preferredGarage: ''
      });
    }
  };

  return(
    <div className={'main-form-container'}>
      {/* Transparent overlay preventing changes when not on this step */}
      {isDisabled && <BlockingOverlayComponent />}
      <Formik
        enableReinitialize
        onSubmit={undefined}
        initialValues={data}>
        {({values}) => {
          return (
            <div className={'garage-details'}>
              <h3 className={'card-title'}>{t('garage_details')}</h3>

              <Grid container spacing={1} style={{marginTop:"15px"}}>

                <Grid item xs={12}>
                  <p className="input-label">{`${t("preferred_garage")}:`}</p>
                  <Field
                    value={values.preferredGarage}
                    className={'select-box dark-blue-input'}
                    style={{marginBottom: '20px', maxWidth: '100%'}}
                    component={"select"}
                    name="preferredGarage"
                    onChange={(e) => preferredGarage(e)}
                  >
                    {titles().map(order => (
                      <option key={order.id} value={order.value}>{order.label}</option>
                    ))}
                  </Field>
                  {error?.preferredGarage && <p className={'error-message'}>{t(error?.preferredGarage)}</p>}
                </Grid>
                <Grid item xs={12}>
                  <Field
                    label={`${t("garage_name")} *`}
                    name="garageName"
                    component={Input}
                    className={'dark-blue-input'}
                    style={{ marginBottom: '20px' }}
                    value={values.garageName}
                    onChange={(e) => onFieldChange('garageName', e.target.value)}
                  />
                  {error?.garageName && <p className={'error-message'}>{t(error?.garageName)}</p>}
                </Grid>
                <Grid item xs={12}>
                  <Field
                    label={`${t("street_no")} *`}
                    name="streetAndNumber"
                    component={Input}
                    className={'dark-blue-input'}
                    style={{ marginBottom: '20px' }}
                    value={values.streetAndNumber}
                    onChange={(e) => onFieldChange('streetAndNumber', e.target.value)}
                  />
                  {error?.streetAndNumber && <p className={'error-message'}>{t(error?.streetAndNumber)}</p>}
                </Grid>
                <Grid item xs={12}>
                  <Field
                    label={`${t("zipcode")} *`}
                    name="zipcode"
                    component={Input}
                    className={'dark-blue-input'}
                    style={{ marginBottom: '20px' }}
                    value={values.zipcode}
                    onChange={(e) => onFieldChange('zipcode', e.target.value)}
                  />
                  {error?.zipcode && <p className={'error-message'}>{t(error?.zipcode)}</p>}
                </Grid>
                <Grid item xs={12}>
                  <Field
                    label={`${t("city")} *`}
                    name="city"
                    component={Input}
                    className={'dark-blue-input'}
                    style={{ marginBottom: '20px' }}
                    value={values.city}
                    onChange={(e) => onFieldChange('city', e.target.value)}
                  />
                  {error?.city && <p className={'error-message'}>{t(error?.city)}</p>}
                </Grid>
                <Grid item xs={10}>
                  <h4>{t('appointment_confirmation')}</h4>
                </Grid>
                <Grid item xs={1}>
                  <Toggler onChange={onToggleChange} checked={values.appointmentConfirmation} />
                </Grid>
              </Grid>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default memo(GarageDetails);

import PropTypes from "prop-types";
import {t} from "@helpers/i18n";

const BusinessInfo = ({company, street, number, zipcode, city}) => {
  const renderBusinessInfo = () => {
    if (!!company) {
      return <div className="personal-workplace_address">
        <p>{company}</p>
        <p>{`${street} ${number}`}</p>
        <p>{`${zipcode} ${city}`}</p>
      </div>;
    }

    return '-';
  };

  return (
    <div className="personal-workplace details-content">
      <p className="personal-workplace_title">{t("workplace")}</p>
      <div className="personal-last">
        {renderBusinessInfo()}
      </div>
    </div>
  );
};


BusinessInfo.propTypes = {
  company: PropTypes.string,
  street: PropTypes.string,
  number: PropTypes.string,
  zipcode: PropTypes.string,
  city: PropTypes.string
};

BusinessInfo.defaultProps = {
  company: '',
  street: '',
  number: '',
  zipcode: '',
  city: ''
};

export default BusinessInfo;

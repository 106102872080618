import React from "react";
import {useDispatch, useSelector} from "react-redux";
import MapContainer from "@containers/Map";
import AppointmentsWrapper from "@containers/Appointments/AppointmentsWrapper";

import { format } from 'date-fns';

import * as appointmentsActions from "@store/appointments/actions";


const AppointmentListContainer = () => {
  const dispatch = useDispatch();
  const {appointments, loading, lastPage, createLoading} = useSelector(state => state.appointments);

  const {auth} = useSelector(state => state.auth);
  /**
   * Fetch Appointments
   * @param {number} page - current page
   * @param {string} filter - appointment filters
   * @param {string} search - value from search field
   * @param {object} dateFrom - starting Date
   * @param {object} dateTo - end Date
   **/
  const filterAppointments = ({ page, filter, search, dateFrom, dateTo }) => {
    dispatch(appointmentsActions.appointmentsGet({
      userId: auth.user.id,
      filter: filter,
      search: search ? `&search=${search}` : '',
      page: page,
      dateFrom: dateFrom ? `&filterDateFrom=${  format(dateFrom, 'yyyy-MM-dd') }` : '',
      dateTo: dateTo ? `&filterDateTo=${ format(dateTo, 'yyyy-MM-dd') }` : ''
    }));
  };

  /**
   * Appointment List End reached
   * @param {number} page - current page
   * @param {string} filter - appointment filters
   * @param {string} search - value from search field
   * @param {object} dateFrom - starting Date
   * @param {object} dateTo - end Date
   **/
  const isEndReached = ({ page, filter, search, dateFrom, dateTo }) => {
    dispatch(appointmentsActions.appointmentsLoadMore({
      userId: auth.user.id,
      filter: filter,
      search: `&search=${search}`,
      page: page,
      dateFrom: dateFrom ? `&filterDateFrom=${ format(dateFrom, 'yyyy-MM-dd') }` : '',
      dateTo: dateTo ? `&filterDateTo=${ format(dateTo, 'yyyy-MM-dd') }` : ''
    }));
  };

  return (
    <div id={'appointments-list'} className={'list-wrapper'}>
      <MapContainer />
      <AppointmentsWrapper
        appointments={appointments}
        loading={loading}
        lastPage={lastPage}
        applyAppointmentsFilter={filterAppointments}
        endReached={isEndReached}
        createLoading={createLoading}
      />
    </div>
  );
};

export default AppointmentListContainer;

import React from 'react';
import PropTypes from "prop-types";


const ProfileImageUpload = ({ name, allowFileType, onChange }) => {

  return (
    <div className={'fileInput'}>
      <input
        className={'file-upload'}
        name={name}
        type="file"
        accept={allowFileType}
        onChange={onChange}/>
    </div>
  );
};

ProfileImageUpload.propTypes = {
  value: PropTypes.object,
  allowFileType: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func
};

ProfileImageUpload.defaultProps = {
  value: null,
  allowFileType: 'image/png, image/jpeg',
  name: 'file',
  onChange: () => {}
};

export default ProfileImageUpload;

import React from 'react';
import PropTypes from 'prop-types';
import { TailSpin } from "react-loader-spinner";
import { useEffect, useRef } from 'react';


import './ListLoader.styles.scss';

const ListLoader = (props) => {

  const { onIntersection } = props;

  const loaderRef = useRef(); // interaction observer options
  const interactionOptions = { root: null, rootMargin: "0px", threshold: 0.1 };
  const observer = new IntersectionObserver(([entry]) => {
    if (entry.isIntersecting) {
      onIntersection();
    }
  }, interactionOptions);
  useEffect(() => {
    if (loaderRef && loaderRef.current) {
      observer.observe(loaderRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, []);
  return <div className="list-loader" ref={loaderRef}>
    <TailSpin
      color="#FFF"
      height={20}
      width={20}
    />
  </div>;
};

ListLoader.propTypes = {
  onIntersection: PropTypes.func.isRequired
};

ListLoader.defaultProps = {
  onIntersection: () => {}
};

export default ListLoader;

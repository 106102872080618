import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {t} from '@helpers/i18n';
import Close from 'mdi-react/CloseIcon';
import './MultipleUpload.styles.scss';

const MultipleUploadComponent = props => {
  const {value, name, allowFileType, onChange, onRemove, withoutPreview, disabled, multiple} = props;
  const [removedFiles, setRemovedFiles] = useState([]);

  const onImageRemove = (index) => {
    setRemovedFiles([...removedFiles, index]);
    onRemove(index);
  };

  return (
    <div className="Upload">
      <div className={'upload-cmp'}>
        <p className={'title'}>{t('file_upload_title')}</p>
        <input
          className={'file-upload'}
          name={name}
          type="file"
          multiple={multiple}
          accept={allowFileType}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
      {!withoutPreview && value &&
        <div className={'img-preview'}>

          {value.map((file, index) => (
            removedFiles.includes(index) ?
              <></>
              :
              <div className={'img-container'} key={index.toString()}>
                <img src={window.URL.createObjectURL(file)}/>
                <span className={'img-remove'} onClick={() => onImageRemove(index)}><Close/></span>
              </div>
          ))}
        </div>
      }

    </div>
  );
};

MultipleUploadComponent.propTypes = {
  value: PropTypes.array,
  allowFileType: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  withoutPreview: PropTypes.bool,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool
};
MultipleUploadComponent.defaultProps = {
  value: null,
  allowFileType: 'image/png, image/jpeg',
  name: 'file',
  onChange: () => {
  },
  disabled: false,
  multiple: true
};

export default MultipleUploadComponent;

import React from 'react';
import PropTypes from 'prop-types';
/** Load translation helper **/
import { t } from "@helpers/i18n";
/** load components **/
import ApplyButton from "@components/Common/Button/ApplyButton";
import TripFilterForm from "./FilterForm";


const TripFilter = (props) => {

  const { visible,
    filterValues,
    filterChange,
    applyFilter,
    dateFrom,
    dateTo, 
    onDateChange } = props;

  return (
    visible &&
        <div className={'list-filter'}>
          { /** Filter form **/}
          <TripFilterForm
            settings={filterValues}
            onChange={filterChange}
            onDateChange={onDateChange}
            dateTo={dateTo}
            dateFrom={dateFrom}
          />

          <ApplyButton onClick={applyFilter} text={t('apply')}>
          </ApplyButton>
        </div>);
};

export default TripFilter;

TripFilter.propTypes = {
  visible: PropTypes.bool, // filter visibility
  filterValues: PropTypes.array, // filter Values
  applyFilter: PropTypes.func // on apply filter callback
};

TripFilter.defaultProps = {
  visible: false,
  filterValues: [],
  applyFilter: () => {}
};

import React from 'react';
import PropTypes from "prop-types";

import './AppointmentPrimary.styles.scss';

const AppointmentPrimaryContent = (props) => {

  const { appointment, expanded } = props;

  const { serviceTask } = appointment;
  const tasks = serviceTask ? serviceTask.split(',') : [];

  const renderServiceInfo = () => {
    if (!expanded && tasks.length > 3) {
      return (
        <>
          <p className={'service-text'}>{ tasks[0]}</p>
          <p className={'service-text'}>{ `${tasks[0]} ...` }</p>
        </>);
    }
    return (
      tasks.map((task, i) => (
        <p key={`service-${i}`} className={'service-text'}>
          { task }
        </p>)
      ));
  };

  return (
    <div className={'appointment-service-info'}>
      {
        renderServiceInfo()
      }
    </div>);

};


AppointmentPrimaryContent.propTypes = {
  appointment: PropTypes.object
};

AppointmentPrimaryContent.defaultProps = {
  appointment: {}
};

export default AppointmentPrimaryContent;

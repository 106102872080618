import React from 'react';
import PropTypes from 'prop-types';
/** load components **/
import ApplyButton from '@components/Common/Button/ApplyButton';
import InvoiceFilterForm from '@components/Invoice/Filter/FilterForm/InvoiceFilterForm.component';

/** import services **/
import {InvoicesFilterFormatter} from '@services/formatters/invoices/InvoicesFilter.formatter';
import {t} from '@helpers/i18n';

const InvoiceFilter = (props) => {

  const { filterValues, visible, invoiceDates, onDateChange, filterChanged, applyFilter } = props;

  const filterChange = (e) => {
    const updatedFilters = InvoicesFilterFormatter(e, filterValues);
    filterChanged(updatedFilters);
  };

  return (
    visible &&
        <div className={'list-filter'}>
          <InvoiceFilterForm
            invoiceDates={invoiceDates}
            filters={filterValues}
            onChange={filterChange}
            onDateChange={onDateChange}
          />
          <ApplyButton onClick={applyFilter} text={t('apply')} />
        </div>
  );
};

InvoiceFilter.propTypes = {
  filterValues: PropTypes.array,
  visible: PropTypes.bool,
  invoiceDates: PropTypes.object,
  onDateChange: PropTypes.func,
  filterChanged: PropTypes.func,
  applyFilter: PropTypes.func
};

InvoiceFilter.defaultProps = {
  filterValues: [],
  visible: false,
  invoiceDates: {},
  onDateChange: () => {},
  filterChanged: () => {},
  applyFilter: () => {}
};

export default InvoiceFilter;

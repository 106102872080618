import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

/** load components **/
import InvoiceCard from '@components/Invoice/Card';
import LazyLoadingList from '@components/LazyLoadingList';


const InvoiceCardList = (props) => {
  const {
    loading,
    invoices,
    isLastPage,
    isEndReached,
  } = props;

  /**
   * Render single Appointment card
   * @param {object} invoice - invoice object
   * @param {number} i - index of element
   * @returns {JSX.Element}
   **/
  const renderInvoiceCard = (invoice, i) => (
    <InvoiceCard
      invoice={invoice}
      key={i}
    />);

  /**
   * Render Lazy Loading Card list
   * @returns {JSX.Element}
   **/
  const renderContent = useCallback(() => {
    return (
      <LazyLoadingList
        data={invoices || []}
        loading={loading}
        isLastPage={isLastPage}
        onEndReached={isEndReached}
        renderItem={renderInvoiceCard}
      />
    );
  }, [loading, invoices]);


  return (
    <>
      {renderContent()}
    </>
  );
};

InvoiceCard.propTypes = {
  loading: PropTypes.bool,
  invoices: PropTypes.array,
  isLastPage: PropTypes.bool,
  isEndReached: PropTypes.func
};

InvoiceCardList.defaultProps = {
  loading: false,
  invoices: [],
  isLastPage: false,
  isEndReached: () => {}
};

export default InvoiceCardList;

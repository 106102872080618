import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { errorHandler } from "@helpers/saga";
import { Api } from "@api";
import * as vehiclesConstants from "./constants";
import * as vehicleActions from '@store/vehicles/actions';

function* getTrips(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(Api.vehicles.getVehicleTrips, id);
    data.id = id;

    yield put({ type: vehiclesConstants.GET_TRIPS_SUCCESS, payload: data });
  }
  catch (err) {
    yield call(errorHandler, err);
    yield put({ type: vehiclesConstants.GET_TRIPS_FAIL });
  }
}

function* getTripsPositions(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(Api.vehicles.getVehicleTripsPositions, id);
    data.id = id;

    yield put({
      type: vehiclesConstants.GET_TRIPS_POSITIONS_SUCCESS,
      payload: data,
    });
  }
  catch (err) {
    yield call(errorHandler, err);
    yield put({ type: vehiclesConstants.GET_TRIPS_POSITIONS_FAIL });
  }
}

function* getVehicles(action) {
  try {
    const { userId, page, filter, search } = action.payload;
    let query = `${userId}/car/extended?page=${page}${filter}`;
    query = search ? query.concat(`&search=${search}`) : query;

    const { data } = yield call(Api.vehicles.getVehiclesExtended, query);

    yield put({ type: vehiclesConstants.VEHICLE_GET_SUCCESS, payload: data });
  }
  catch (err) {
    yield call(errorHandler, err);
    yield put({ type: vehiclesConstants.VEHICLE_GET_FAIL });
  }
}

function* loadMoreVehicles(action) {
  try {
    const { userId, page, filter, search } = action.payload;
    let query = `${userId}/car/extended?page=${page}${filter}`;
    query = search ? query.concat(`&search=${search}`) : query;

    const { data } = yield call(Api.vehicles.getVehiclesExtended, query);

    yield put({ type: vehiclesConstants.VEHICLE_LOAD_MORE_GET_SUCCESS, payload: data });
  }
  catch (err) {
    yield call(errorHandler, err);
    yield put({ type: vehiclesConstants.VEHICLE_LOAD_MORE_FAIL });
  }
}

function* getVehicleGeneralInformation(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(Api.vehicles.getVehicleGeneralInformation, id);
    
    yield put({
      type: vehiclesConstants.VEHICLE_GENERAL_INFORMATION_SUCCESS,
      payload: data,
    });
  }
  catch (err) {
    yield call(errorHandler, err);
    yield put({ type: vehiclesConstants.VEHICLE_GENERAL_INFORMATION_FAIL });
  }
}

function* getCarChecksOfVehicle(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(Api.vehicles.getCarchecksOfVehicle, {
      id,
      page: 0,
    });

    yield put(vehicleActions.getVehiclesCarChecksSuccess(data));
  }
  catch (err) {
    yield call(errorHandler, err);
    yield put(vehicleActions.getVehiclesCarChecksFail());
  }
}

function* loadMoreCarChecks(action) {
  try {
    const { id, page } = action.payload;
    const { data } = yield call(Api.vehicles.getCarchecksOfVehicle, {
      id,
      page,
    });
    yield put(vehicleActions.loadMoreVehiclesCarChecksSuccess(data));
  }
  catch (err) {
    yield call(errorHandler, err);
    yield put(vehicleActions.loadMoreVehiclesCarChecksFail());
  }
}

function* getVehicleExtendedInformation(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(Api.vehicles.getVehicleExtendedInformation, id);

    yield put({
      type: vehiclesConstants.GET_VEHICLE_EXTENDED_INFORMATION_SUCCESS,
      payload: data,
    });
  }
  catch (err) {
    yield call(errorHandler, err);
    yield put({
      type: vehiclesConstants.GET_VEHICLE_EXTENDED_INFORMATION_FAIL,
    });
  }
}

function* getDamagesOfVehicle(action) {
  try {
    const { id, page } = action.payload;
    const { data } = yield call(Api.vehicles.getDamagesOfVehicle, {
      vehicleId: id,
      page,
    });

    yield put({
      type: vehiclesConstants.VEHICLE_GET_DAMAGES_BY_ID_SUCCESS,
      payload: data,
    });
  }
  catch (err) {
    yield call(errorHandler, err);
    yield put({ type: vehiclesConstants.VEHICLE_GET_DAMAGES_BY_ID_FAIL });
  }
}

function* loadMoreDamages(action) {
  try {
    const { id, page } = action.payload;
    const { data } = yield call(Api.vehicles.getDamagesOfVehicle, {
      vehicleId: id,
      page,
    });

    yield put({
      type: vehiclesConstants.VEHICLE_DAMAGES_LOAD_MORE_SUCCESS,
      payload: data,
    });
  }
  catch (err) {
    yield call(errorHandler, err);
    yield put({ type: vehiclesConstants.VEHICLE_DAMAGES_LOAD_MORE_FAIL });
  }
}

function* getVehicleLocation(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(Api.vehicles.getSingleVehicleLocation, id);

    yield put({
      type: vehiclesConstants.GET_VEHICLE_LOCATION_SUCCESS,
      payload: data,
    });
  }
  catch (err) {
    yield call(errorHandler, err);
    yield put({ type: vehiclesConstants.GET_VEHICLE_LOCATION_FAIL });
  }
}

function* saveVehicleTags(action) {
  try {
    const { vehicleId, tags } = action.payload;
    const { data } = yield call(Api.vehicles.saveTags, vehicleId, tags);
    yield put(vehicleActions.saveVehiclesTagsSuccess());
    yield put({ type: vehiclesConstants.UPDATE_VEHICLE_TAGS, payload: tags });
  }
  catch (e) {
    yield call(errorHandler, e);
    yield put(vehicleActions.saveVehiclesTagsFail());
  }
}

function* assignDriverToVehicle(action) {
  try {
    const { driverId, carId } = action.payload;
    yield call(Api.vehicles.assignDriver, { driverId, carId });
    const {data} = yield call(Api.vehicles.getVehicleGeneralInformation, carId);
    yield put({
      type: vehiclesConstants.VEHICLE_ASSIGN_DRIVER_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield call(errorHandler, err);
  }
}
export function* vehicleSagas() {
  yield takeLatest(vehiclesConstants.GET_TRIPS_REQUEST, getTrips);
  yield takeLatest(vehiclesConstants.GET_TRIPS_POSITIONS_REQUEST, getTripsPositions);
  yield takeLatest(vehiclesConstants.GET_TRIPS_POSITIONS_REQUEST, getTripsPositions);
  yield takeLatest(vehiclesConstants.VEHICLE_GET_REQUEST, getVehicles);
  yield takeLatest(vehiclesConstants.VEHICLE_LOAD_MORE_REQUEST, loadMoreVehicles);
  yield takeLatest(vehiclesConstants.VEHICLE_GENERAL_INFORMATION_REQUEST, getVehicleGeneralInformation);
  yield takeLatest(vehiclesConstants.VEHICLE_GET_CAR_CHECKS_BY_ID_REQUEST, getCarChecksOfVehicle);
  yield takeLatest(vehiclesConstants.VEHICLE_CAR_CHECK_LOAD_MORE, loadMoreCarChecks);
  yield takeLatest(vehiclesConstants.GET_VEHICLE_EXTENDED_INFORMATION_REQUEST, getVehicleExtendedInformation);
  yield takeLatest(vehiclesConstants.VEHICLE_GET_DAMAGES_BY_ID_REQUEST, getDamagesOfVehicle);
  yield takeLatest(vehiclesConstants.VEHICLE_DAMAGES_LOAD_MORE, loadMoreDamages);
  yield takeLatest(vehiclesConstants.GET_VEHICLE_LOCATION, getVehicleLocation);
  yield takeEvery(vehiclesConstants.SAVE_VEHICLE_TAGS_REQUEST, saveVehicleTags);
  yield takeLatest(vehiclesConstants.VEHICLE_ASSIGN_DRIVER, assignDriverToVehicle);
}

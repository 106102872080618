import React from 'react';
import PropTypes from "prop-types";
import {Field, Form, Formik} from "formik";
import { t } from "@helpers/i18n";

/** Load components **/
import Select from "react-select";
import Grid from '@mui/material/Grid';
import { InputList } from "@components/Common/Input/InputList";
import { Input, INPUT_SIZES, INPUT_TYPES } from "@components/Common/Input";


import CheckIcon from "mdi-react/CheckIcon";
import ArrowLeft from "@assets/svg/arrow-left.svg";
import CmpDatePicker from "@components/Common/DatePicker";

const PersonalInfo = ({ registrationInfo, nextStep, stepBack, fieldChanged }) => {

  const style = {
    control: base => ({
      ...base,
      border: 0,
      boxShadow: "none"
    })
  };

  const submitForm = (values) => {
    nextStep('registerUser', values);
  };

  return (<div className={'personal-info-container'}>
    <div className="personal-info">
      <div className="right-title">{t('something_about_you')}</div>
      <Formik
        enableReinitialize
        initialValues={
          {
            stations: registrationInfo.stations,
            surname: registrationInfo.surname,
            name: registrationInfo.name,
            addressCity: registrationInfo.addressCity,
            addressNumber: registrationInfo.addressNumber,
            addressStreet: registrationInfo.addressStreet,
            addressZipcode: registrationInfo.addressZipcode,
            birthday: registrationInfo.birthday
          }
        }
        onSubmit={submitForm}>
        <Form>
          <p className="title">{t('title')}:</p>
          <Select
            styles={style}
            className={'select-box'}
            isLoading={false}
            disabled={true}
            placeholder={'All'}
            name="stations"
            defaultValue={registrationInfo.stations}
            options={
              [
                { value: 'mr', label: 'Mr.' },
                { value: 'ms', label: 'Ms.' }
              ]
            }
            onChange={(e) => fieldChanged('stations', e)}
          />
          <InputList>
            <Field
              label={`${t("name")}:`}
              name="name"
              onChange={(e) => fieldChanged('name', e.target.value)}
              component={Input}
              width={INPUT_SIZES.HALF}
            />
            <Field
              label={`${t("surname")}:`}
              name="surname"
              component={Input}
              onChange={(e) => fieldChanged('surname', e.target.value)}
              marginLeft={INPUT_SIZES.MARGIN_LEFT}
            />
          </InputList>
          <InputList>
            <Field
              label={`${t("street")}:`}
              name="addressStreet"
              onChange={(e) => fieldChanged('addressStreet', e.target.value)}
              width={INPUT_SIZES.HALF}
              component={Input}
            />
            <Field
              label={`${t("house-number")}:`}
              name="addressNumber"
              onChange={(e) => fieldChanged('addressNumber', e.target.value)}
              width={INPUT_SIZES.ONE_FIFTH}
              component={Input}
              marginLeft={INPUT_SIZES.MARGIN_LEFT}
            />
            <Field
              label={`${t("zipcode")}:`}
              name="addressZipcode"
              onChange={(e) => fieldChanged('addressZipcode', e.target.value)}
              component={Input}
              marginLeft={INPUT_SIZES.MARGIN_LEFT}
            />
          </InputList>
          <Field
            label={`${t("city")}:`}
            name="addressCity"
            onChange={(e) => fieldChanged('addressCity', e.target.value)}
            component={Input}
          />
          <CmpDatePicker
            label={`${t("birthday")}:`}
            value={registrationInfo.birthday}
            name="birthday"
            maxDate={new Date()}
            onChange={fieldChanged}
          />
          <div style={{ marginTop: '20px'}}>
            <Grid container spacing={2} rowSpacing={1}>
              <Grid item xs={6}>
                <button type={'button'} onClick={stepBack} className="button button_customer is-link back-btn">
                  <img className="button-arrow rotate-icon" src={ArrowLeft} alt="icon"/>
                </button>
              </Grid>
              <Grid item xs={6}>
                <button type="submit" className="button is-primary next-btn">
                  <CheckIcon />
                  <div className="button-text">{t('get_started')}</div>
                </button>
              </Grid>
            </Grid>
          </div>
        </Form>
      </Formik>
    </div>
  </div> );
};

PersonalInfo.propTypes = {
  registrationInfo: PropTypes.object,
  nextStep: PropTypes.func.isRequired,
  stepBack: PropTypes.func.isRequired,
  fieldChanged: PropTypes.func.isRequired,
};

PersonalInfo.defaultProps = {
  registrationInfo: {},
  nextStep: () => {},
  stepBack: () => {},
  fieldChanged: () => {}
};


export default PersonalInfo;

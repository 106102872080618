import {toBase64} from "@services/utils";

const LicenseImagesFormatter = async (currentLicense, licenseData) => {
  let frontImg = `data:image/jpeg;base64,`;
  let backImg = `data:image/jpeg;base64,`;

  if (licenseData.frontImage) {
    frontImg = await toBase64(licenseData.frontImage);
  } else {
    frontImg = frontImg + currentLicense.frontImage.blob;
  }

  if (licenseData.backImage) {
    backImg = await toBase64(licenseData.backImage);
  } else {
    backImg = backImg + currentLicense.backImage.blob;
  }

  return [frontImg, backImg];
};


const licenseImagesSlideFormatter = (license) => {
  const slides = [];
  if (license?.frontImage) {
    slides.push(null);
  }
  if (license?.backImage) {
    slides.push(null);
  }
  return slides;
};

export {
  LicenseImagesFormatter,
  licenseImagesSlideFormatter
};


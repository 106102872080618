import React from 'react';
import PropTypes from 'prop-types';
/** load components **/
import ApplyButton from '@components/Common/Button/ApplyButton';

/** import services **/
import {DailyRentsFilterFormatter} from '@services/formatters/dailyRent/DailyRentFilter.formatter';
import {t} from '@helpers/i18n';
import DailyRentFilterForm from "@components/DailyRent/Filter/FilterForm/DailyRentFilterForm.component";

const DailyRentFilter = (props) => {
  const { filterValues, visible, rentDates, onDateChange, filterChanged, applyFilter } = props;

  const filterChange = (e) => {
    const updatedFilters = DailyRentsFilterFormatter(e, filterValues);
    filterChanged(updatedFilters);
  };

  return (
    visible &&
        <div className={'list-filter'}>
          <DailyRentFilterForm
            rentDates={rentDates}
            filters={filterValues}
            onChange={filterChange}
            onDateChange={onDateChange}
          />
          <ApplyButton onClick={applyFilter} text={t('apply')} />
        </div>
  );
};

DailyRentFilter.propTypes = {
  filterValues: PropTypes.array,
  visible: PropTypes.bool,
  rentDates: PropTypes.object,
  onDateChange: PropTypes.func,
  filterChanged: PropTypes.func,
  applyFilter: PropTypes.func,
};

DailyRentFilter.defaultProps = {
  filterValues: [],
  visible: false,
  rentDates: {},
  onDateChange: () => {},
  filterChanged: () => {},
  applyFilter: () => {},
};

export default DailyRentFilter;

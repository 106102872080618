import React from 'react';
import PropTypes from "prop-types";
import Toggle from "react-toggle";

import "./Toggler.styles.scss";

const Toggler = (props) => {

  const { id, label, checked, onChange } = props;

  return (
    <label className="toggler">
      <div>
        {label}
      </div>
      <Toggle
        id={id}
        onChange={onChange}
        checked={checked}
        icons={false}
      />
    </label>
  );
};

Toggler.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

Toggler.defaultProps = {
  id: "",
  label: "",
  checked: false,
  onChange: null,
};

export default Toggler;

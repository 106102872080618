import "./index.scss";
import classnames from "classnames";
import PropTypes from "prop-types";

const TaskSelector = (props) => {

  const renderTasks = () => {
    const { data, onClick, active } = props;

    return data.map(el => {
      return (
        <div
          id={el.id}
          key={el.id}
          className={classnames("task-box", { active: active.includes(el.id.toString()) })}
          onClick={onClick}>
          <div className="icon-block"><img src={el.icon} alt="icon"/></div>
          <span>{el.label}</span>
        </div>
      );
    });
  };
  
  return (
    <div className="task-selector">
      {renderTasks()}
    </div>
  );
};

TaskSelector.propTypes = {
  onClick: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.any,
    label: PropTypes.string,
  })),
  active: PropTypes.any,
};

TaskSelector.defaultProps = {
  onClick: null,
  data: [],
  active: null
};

export default TaskSelector;

import React from "react";

import packageJson from '../../../../package.json';
import {Link} from "react-router-dom";
import { t } from "@helpers/i18n";

import "./index.scss";

const InfoBlock = (props) => {
  const {
    vertical,
    customerName,
    customerNumber,
    avatarElement,
    state,
    name,
    isAdmin,
    bottomElement,
    tags
  } = props;

  const getNameSName = () => {
    const userName = isAdmin? name: props.name;
    return userName ? userName: '-';
  };

  const renderTags = () => {
    return (
          tags?.length &&
        <div className="info-tags">
          {tags.join(" | ")}
        </div>
    );
  };

  return (
    <div className="info-row">
      <Link
        key="profile"
        to="/profile"
        className={ classnames("user-info-block", { "vertical": vertical }) }
      >
        {avatarElement}
        <div className="info-container">
          <p className="version">{packageJson.version} </p>
          <div className="info-data">
            <div className="info-name">
              <p className="name">{getNameSName()}</p>
              <p className="company">{ !!customerName && !!customerNumber && `${customerName} ${customerNumber}`}</p>
            </div>
            {renderTags()}
          </div>
          {bottomElement}
        </div>
      </Link>
      {!!state &&
        <p className="state row-state">
          {t('state')}
          <span> {state}</span>
        </p>
      }
    </div>
  );
};

export default InfoBlock;

import React, {useState} from 'react';
import { CARD_TYPES } from "@components/Card";
import PropTypes from "prop-types";

/** loading components **/
import DriverInfo from "@components/Damage/Card/DriverInfo";
import Avatar from "@components/User/Avatar";
import VehicleInfo from "@components/Vehicle/Card/VehicleInfo";
import VehicleExpandableCard from "@components/Vehicle/Card/ExpandableCard/VehicleExpandableCard.component";
import VehicleInspection from "@components/Vehicle/Card/VehicleInspection";

/** loading icons **/
import CarIcon from "mdi-react/CarIcon";
import CheckIcon from "mdi-react/CheckIcon";
import CardAccountDetailsOutlineIcon from "mdi-react/CardAccountDetailsOutlineIcon";

const VehicleCard = ({ vehicle, onClick, additionalClass }) => {

  const { licencePlate,  manufacturer, vehicleModelType, carAvatar, mileage, carInspectionStatus } = vehicle;
  const [expanded, setExpanded] = useState(false);

  /**
   * return proper class for right border of Vehicle card
   **/
  const vehicleCardColor = () => {
    if (vehicle.isCarcheckOkay
        && !vehicle.isVehicleRegistrationMissing ){
      return `damage_border_${CARD_TYPES.GREEN}`;
    }
    else{
      return `damage_border_${CARD_TYPES.RED}`;
    }
  };

  /**
   * return proper class for avatar of Vehicle card
   **/
  const getIconBackgroundColor = () => {
    if (vehicle.isCarReadyToDrive){
      return CARD_TYPES.GREEN;
    } else {
      return CARD_TYPES.RED;
    }
  };

  /**
   * return Icon for Vehicle card
   * @returns {{}}
   */
  const getIcons = () => {
    const icon={};

    if(!vehicle.isCarcheckOkay){
      icon.leftIcon = <CarIcon color={'red'}/>;
    }
    if(vehicle.isVehicleRegistrationMissing){
      icon.rightIcon = <CardAccountDetailsOutlineIcon color={'red'} />;
    }
    if(Object.keys(icon).length===0){
      icon.checkIcon= <CheckIcon color={'#95C11E'} />;
    }
    return icon;
  };

  /**
   * Render Vehicle Card Left Block
   **/
  const renderLeft = () => (
    <Avatar
      iconBackgroundColor={getIconBackgroundColor()}
      src={carAvatar}
      small
      icon={<></>}
    />
  );

  /**
   * Render Vehicle Card Right Block
   **/
  const renderRight = () => (
    <VehicleInfo mileage={mileage}  vehicleId={licencePlate} icon={getIcons()} expanded={expanded}
      onExpandButtonClick={(event) => {
        event.stopPropagation();
        setExpanded(!expanded);
      }}
    />
  );

  /**
   * Render Vehicle Card Center Block
   **/
  const renderPrimaryContent = () => (
    <DriverInfo driverName={manufacturer} driverSurname={vehicleModelType} tags={vehicle.tags}/>
  );

  return (<>
    <VehicleExpandableCard
      onVehicleClick={onClick}
      vehicleCardColor={vehicleCardColor}
      additionalClass={additionalClass}
      rightContent={renderRight}
      leftContent={renderLeft}
      primaryContent={renderPrimaryContent}
      expanded={expanded}
    >
      <VehicleInspection carInspectionStatus={carInspectionStatus}/>
    </VehicleExpandableCard>
  </>
  );
};

export default VehicleCard;

VehicleCard.propTypes = {
  vehicle: PropTypes.object, // single vehicle object
  onClick: PropTypes.func, // on Vehicle card click callback
  additionalClass: PropTypes.string
};

VehicleCard.defaultProps = {
  vehicle: {},
  onClick: () => {},
  additionalClass: ''
};


import {t} from "@helpers/i18n";
import './NoPermission.styles.scss';

const NoPermission = () => {

  return(
    <div className={'no_permission'}>{t("no_permission")}</div>
  );
};

export default NoPermission;

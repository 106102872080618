import {CARD_TYPES} from "@constants";
import CheckIcon from "mdi-react/CheckIcon";
import Timer from "mdi-react/TimerSandIcon";

/* Defining the different states of a ticket. */
export const TICKET_CARD_TYPES = {
  OPEN: 'open',
  NEW: 'new',
  ASSIGNED: 'assigned',
  OBJECTED: 'objected',
  MANUAL: 'manual',
  FINISHED: 'finished',
  COMPLETED: 'completed'
};

const newTicketAvatarLightRed = 'ticket-avatar-background-red';
const newTicketLightRed = 'light-red-card';

/**
 * Damage icon depending on ticket state
 * @param state - ticket state
 * @param role - user role
 * @returns {JSX.Element}
 */
export const getTicketStateIcon = (state, role) => {
  switch (state) {
    case TICKET_CARD_TYPES.FINISHED:
    case TICKET_CARD_TYPES.COMPLETED:
    case TICKET_CARD_TYPES.MANUAL:
    case TICKET_CARD_TYPES.OBJECTED:
      return <CheckIcon/>;

    case TICKET_CARD_TYPES.ASSIGNED:
      return <Timer/>;

    case TICKET_CARD_TYPES.NEW:
    case TICKET_CARD_TYPES.OPEN:
      return role === 'manager' ? null : <Timer/>;
    default:
      return null;
  }
};

/**
 * It returns an icon color based on the state of the ticket and the role of the user
 * @param state - The state of the ticket.
 * @param role - The role of the user.
 */
export const getTicketStateIconColor = (state, role) => {
  switch (state) {
    case TICKET_CARD_TYPES.OBJECTED:
    case TICKET_CARD_TYPES.FINISHED:
    case TICKET_CARD_TYPES.COMPLETED:
    case TICKET_CARD_TYPES.MANUAL:
    case TICKET_CARD_TYPES.ASSIGNED:
      return CARD_TYPES.GREEN;
      
      // case TICKET_CARD_TYPES.ASSIGNED:
      //   return role === 'manager' ? CARD_TYPES.GREEN : CARD_TYPES.RED_ARCHIVE;

    case TICKET_CARD_TYPES.OPEN:
    case TICKET_CARD_TYPES.NEW:
      return role === 'manager' ? null : CARD_TYPES.RED_ARCHIVE;
    default:
      return null;
  }
};

/**
 * Card color depending on ticket state
 * @param state - ticket state@param role - The role of the user.
 * @param role - The role of the user.
 * @returns {string}
 */
export const getTicketStateCardColor = (state, role) => {
  if (state) {
    switch (state) {
      case TICKET_CARD_TYPES.NEW:
      case TICKET_CARD_TYPES.OPEN:
        return `damage_border_${CARD_TYPES.RED}`;

      case TICKET_CARD_TYPES.ASSIGNED:
        return role === 'manager' ? `damage_border_${CARD_TYPES.GREEN_WHITE}` : `damage_border_${CARD_TYPES.RED_ARCHIVE}`;
      default:
        return `damage_border_${CARD_TYPES.GREEN_WHITE}`;
    }
  }
  return CARD_TYPES.WHITE;
};

/**
 * If the state is open or new, return the light red color, otherwise return null.
 * @param state - The state of the ticket.
 * @returns avatar background color
 */
export const getTicketCardAvatarBackgroundColor = (state, role) => {
  switch (state) {
    case TICKET_CARD_TYPES.OPEN:
    case TICKET_CARD_TYPES.NEW:
      return role==='manager' ? newTicketAvatarLightRed : '';
    default:
      return '';
  }
};

/**
 * Given a ticket state, return the background color of the ticket card.
 * @param state - The state of the ticket.
 * @returns A function that takes in a state and returns a color.
 */
export const getTicketCardBackgroundColor = (state) => {
  switch (state) {
    case TICKET_CARD_TYPES.NEW:
      return newTicketLightRed;
    default:
      return '';
  }
};

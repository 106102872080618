import React, {useEffect, useState, memo} from 'react';
import PropTypes from 'prop-types';
import BlockingOverlayComponent from '@containers/Damages/AddDamage/BlockingOverlay/BlockingOverlay.component';
import {Field, Formik} from "formik";
import {t} from "@helpers/i18n";
import {Input} from "@components/Common/Input";
import {Grid} from "@mui/material";
import SelectDriverCmp from "@components/Common/AddDriver";
import './DamageLocation.scss';
import DateTimePickerGrid from "@components/Appointment/DateTimePickerGrid/DateTimePickerGrid.component";

const DamageLocation = ({stepData, onChange, isDisabled, error, profile}) => {

  const initialState = {
    driverName:'',
    driverId:'',
    street:'',
    number:'',
    zipcode:'',
    city:'',
    country:'',
    accidentDateAndTime:''
  };

  /**
     * Local state for all changes in the step.
     *
     * On mount should be set to global data from parent,
     * so the form can be prefilled with data when going in between steps
     */

  const [data, setData] = useState(stepData || initialState);

  /**
     * This effect should update parent state once there is local changes
     */
  useEffect(() => {
    onChange(data);
  }, [data]);

  const getDriver = (driver) => {
    setData({...data, driverName: `${driver.name} ${driver.surname}`,driverId:driver.userId});
  };


  const onDriverSelected = (driver) => {
    setData({...data, driverName: `${driver.name} ${driver.surname}`,driverId:driver.userId});
  };


  return (
    <div className={'main-form-container'}>
      {/* Transparent overlay preventing changes when not on this step */}
      {isDisabled && <BlockingOverlayComponent/>}

      <Formik
        enableReinitialize
        onSubmit={undefined}
        initialValues={
          {
            street: data.street,
            number: data.number,
            zipcode: data.zipcode,
            city: data.city,
            country: data.country,
            accidentDateAndTime: data.accidentDateAndTime,
          }
        }>
        {({values}) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}>
              <div className={'step-one'}>
                <Grid container spacing={1} rowSpacing={3} alignItems="center"
                  justifyContent="center">
                  <h3 className={'card-title'}>{t("crash_location")}</h3>
                  <Grid item xs={8}>
                    <Field
                      label={t("street")}
                      name="street"
                      component={Input}
                      onChange={e => setData({...data,street:e.target.value})}
                      className={'dark-blue-input'}
                    />
                    {error?.street && <p className={'error-message-loc'}>{t(error?.street)}</p>}
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      label={t("house-number")}
                      name="number"
                      component={Input}
                      onChange={e => setData({...data, number: e.target.value})}
                      className={'dark-blue-input'}
                    />
                    {error?.number && <p className={'error-message-loc'}>{t(error?.number)}</p>}
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      label={t("zipcode")}
                      name="zipcode"
                      component={Input}
                      onChange={e => setData({...data, zipcode: e.target.value})}
                      className={'dark-blue-input'}
                    />
                    {error?.zipcode && <p className={'error-message-loc'}>{t(error?.zipcode)}</p>}
                  </Grid>
                  <Grid item xs={8}>
                    <Field
                      label={t("city")}
                      name="city"
                      component={Input}
                      onChange={e => setData({...data, city: e.target.value})}
                      className={"dark-blue-input"}
                    />
                    {error?.city && <p className={'error-message-loc'}>{t(error?.city)}</p>}
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      label={t("country")}
                      name="country"
                      component={Input}
                      onChange={e => setData({...data, country:e.target.value})}
                      className={'dark-blue-input'}
                    />
                    {error?.country && <p className={'error-message-loc'}>{t(error?.country)}</p>}
                  </Grid>
                  <DateTimePickerGrid
                    label={t("date_damage")}
                    value={data.accidentDateAndTime || null}
                    onChange={newValue => setData({...data, accidentDateAndTime: newValue})}
                    error={error?.accidentDateAndTime}
                    language={profile?.language}
                  />
                </Grid>
              </div>
            </form>
          );}}
      </Formik>

      <h3 className={'card-title'} style={{marginTop:'25px'}}>{t('driver_information')}</h3>
      {/*<VehicleDetails hideDetails getDriver={getDriver}/>*/}
      <SelectDriverCmp onDriverSelected={onDriverSelected}/>
      {error?.driverName && <p className={'error-message-loc'}>{t(error?.driverName)}</p>}

    </div>
  );
};

DamageLocation.propTypes = {
  isDisabled: PropTypes.bool, // flag that should be true when current step is not the one for the component
  onChange: PropTypes.func, // handler for each of the children. Parent component should have one handler for each child component
  stepData: PropTypes.object // state from the parent containing all the data for this step
};

export default memo(DamageLocation);

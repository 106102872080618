// square distance between 2 points
function getSqDist(p1, p2) {

  const dx = p1.location.lat - p2.location.lat,
    dy = p1.location.lng - p2.location.lng;

  return dx * dx + dy * dy;
}

// square distance from a point to a segment
function getSqSegDist(p, p1, p2) {

  let {lat} = p1.location,
    {lng} = p1.location,
    dx = p2.location.lat - lat,
    dy = p2.location.lng - lng;

  if (dx !== 0 || dy !== 0) {

    const t = ((p.location.lat - lat) * dx + (p.location.lng - lng) * dy) / (dx * dx + dy * dy);

    if (t > 1) {
      // eslint-disable-next-line prefer-destructuring
      lat = p2.location.lat;
      // eslint-disable-next-line prefer-destructuring
      lng = p2.location.lng;

    } else if (t > 0) {
      lat += dx * t;
      lng += dy * t;
    }
  }

  dx = p.location.lat - lat;
  dy = p.location.lng - lng;

  return dx * dx + dy * dy;
}
// rest of the code doesn't care about point format

// basic distance-based simplification
function simplifyRadialDist(points, sqTolerance) {



  // eslint-disable-next-line prefer-destructuring
  let prevPoint = points[0],
    point;
  const newPoints = [prevPoint];

  for (let i = 1, len = points.length; i < len; i++) {
    point = points[i];

    if (getSqDist(point, prevPoint) > sqTolerance) {
      newPoints.push(point);
      prevPoint = point;
    }
  }

  if (prevPoint !== point) newPoints.push(point);

  return newPoints;
}

function simplifyDPStep(points, first, last, sqTolerance, simplified) {
  let maxSqDist = sqTolerance,
    index;

  for (let i = first + 1; i < last; i++) {
    const sqDist = getSqSegDist(points[i], points[first], points[last]);

    if (sqDist > maxSqDist) {
      index = i;
      maxSqDist = sqDist;
    }
  }

  if (maxSqDist > sqTolerance) {
    if (index - first > 1) simplifyDPStep(points, first, index, sqTolerance, simplified);
    simplified.push(points[index]);
    if (last - index > 1) simplifyDPStep(points, index, last, sqTolerance, simplified);
  }
}

// simplification using Ramer-Douglas-Peucker algorithm
function simplifyDouglasPeucker(points, sqTolerance) {
  const last = points.length - 1;

  const simplified = [points[0]];
  simplifyDPStep(points, 0, last, sqTolerance, simplified);
  simplified.push(points[last]);

  return simplified;
}

// both algorithms combined for awesome performance
export function simplify(points, tolerance, highestQuality) {

  if (points.length <= 2) return points;

  const sqTolerance = tolerance !== undefined ? tolerance * tolerance : 1;

  points = highestQuality ? points : simplifyRadialDist(points, sqTolerance);
  points = simplifyDouglasPeucker(points, sqTolerance);

  return points;
}

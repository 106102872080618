import React from 'react';
import PropTypes from 'prop-types';
import {Tooltip} from '@mui/material';
import './ReportingInfoTooltip.styles.scss';


const ReportingInfoTooltipComponent = props => {
  return (
    <Tooltip
      arrow
      placement="top"
      title={props.tooltipText}>
      <div className={'tooltip-button'}>
        ?
      </div>
    </Tooltip>
  );
};

ReportingInfoTooltipComponent.propTypes = {
  tooltipText: PropTypes.string.isRequired
};

export default ReportingInfoTooltipComponent;

import React from 'react';

/** load components **/
import Toggler from "@components/Common/Toggler";

/** Load translation helper **/
import { t } from "@helpers/i18n";
import FilterDatePicker from "@components/Common/FilterDatePicker";


const TripFilterForm = (props) => {

  const { settings, onChange, dateFrom, dateTo, onDateChange } = props;

  return (<div className="filter-rows">
    <div className="row">
      <div className="toggle">
        <label><p>{t('show_all')}</p>
          <Toggler onChange={() => onChange('all')} checked={settings[0].value} />
        </label>
        <label><p>{t('trips_data_complete')}</p>
          <Toggler onChange={() => onChange('complete')} checked={settings[1].value} />
        </label>
        <label><p>{t('trips_data_incomplete')}</p>
          <Toggler onChange={() => onChange('incomplete')} checked={settings[2].value} />
        </label>
        <label><p>{t('trips_between')}</p>
          <Toggler onChange={() => onChange('trips_between')} checked={settings[3].value} />
        </label>
        {settings[3].value && <div className="date-picker">
          <FilterDatePicker
            dateFrom={dateFrom}
            dateTo={dateTo}
            onDateChange={onDateChange}
          />
        </div>}
      </div>
    </div>
  </div>);
};

export default TripFilterForm;

import React, { useEffect, useState, memo } from 'react';
import {useDispatch, useSelector} from "react-redux";

/** Loading components **/
import ServiceOrder from "@containers/Appointments/AddAppointment/ServiceOrder/ServiceOrder.component";
import DateDetails from "@containers/Appointments/AddAppointment/DateDetails/DateDetails.component";
import GarageDetails from "@containers/Appointments/AddAppointment/GarageDetails/GarageDetails.component";
import AppointmentSummary from "@containers/Appointments/AddAppointment/AppointmentSummary/AppointmentSummary.component";
import AppointmentType from "@containers/Appointments/AddAppointment/AppointmentType/AppointmentType.component";
import TireInformation from "@containers/Appointments/AddAppointment/TireInformation/TireInformation.component";
import {TwoSidedButton} from "@components/Common/TwoSidedButton/TwoSidedButton.component";
import Alert from "@components/Common/Alert/Alert.component";
import {Spinner} from "@components/Spinner";

/** Loading MUI components **/
import ArrowLeftIcon from "mdi-react/ArrowLeftIcon";
import SendIcon from "mdi-react/SendIcon";
import CloseIcon from "mdi-react/CloseIcon";
import ArrowRightIcon from "mdi-react/ArrowRightIcon";

/** Loading constants, helper functions and formatters **/
import * as AddAppointmentValidators from "@containers/Appointments/AddAppointment/Validations/AddAppointment.validator";
import {appointmentTypesMapping, BUTTON_TYPES} from "@constants";
import {t} from "@helpers/i18n";
import {format} from "date-fns";
import {appointmentCreate} from "@store/appointments/actions";
import * as garageActions from "@store/garages/actions";

/** A constant that is used to format the date. **/
const full_YDM_dash = 'yyyy-MM-dd HH:mm:ss';


const AddAppointmentContainer = (props) => {

  /** Extracting props **/
  const { cancelAddAppointment, onAppointmentComplete, isRequest, createLoading, preselectedGarage } = props;

  /** Local states **/
  const [step, setStep] = useState(1);
  const [serviceOrder, setServiceOrder] = useState(null);
  const [dateDetails, setDateDetails] = useState(null);
  const [garageDetails, setGarageDetails] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [alertVisible, setAlertVisible] = useState(false);
  const [type, setType] = useState(true);
  const [serviceType, setServiceType] = useState([]);
  const [tire, setTire] = useState(false);
  const [otherSelected, setOtherSelected] = useState(false);
  const [tireInformation, setTireInformation] = useState(null);
  const [page, setPage] = useState([]);
  const [serviceTaskName, setServiceTaskName] = useState([]);

  /** Redux selectors and dispatch */
  const { profile } = useSelector((state) => state.profile);
  const { garages = [] } = useSelector((state) => state.garages);
  const { auth } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  /** Fetching garage data for appointment garage component **/
  useEffect(() => {
    dispatch(garageActions.garagesGet({id: auth.user.id}));
  }, []);

  /**
   * An object mapping steps to their corresponding validation functions.
   * Each function performs validation based on specific conditions.
   *
   * @type {Object.<(number|string), Function>}
   * @property {Function} 1 - Validation function for service inspection.
   *                       Calls `appointmentServiceInspectionValidation` from `AddAppointmentValidators`.
   * @property {Function} 2 - Validation function for date details.
   *                       Calls `appointmentDateDetailsValidation` from `AddAppointmentValidators`.
   * @property {Function} 3 - Validation function for garage details.
   *                       Calls `appointmentGarageDetailsValidation` from `AddAppointmentValidators`.
   * @property {Function} tire - Validation function for tire information.
   *                             Calls `appointmentTireValidation` from `AddAppointmentValidators`.
   */
  const validationFunctions = {
    1: () => AddAppointmentValidators.appointmentServiceInspectionValidation(serviceOrder ?? {}, otherSelected),
    2: () => AddAppointmentValidators.appointmentDateDetailsValidation(dateDetails ?? {}),
    3: () => AddAppointmentValidators.appointmentGarageDetailsValidation(garageDetails ?? {}),
    'tire': () => AddAppointmentValidators.appointmentTireValidation(tireInformation ?? {})
  };

  /**
   * Main validation function to determine which validation function to call
   * based on the current step.
   *
   * @param {number|string} currentStep - The current step for which to perform validation.
   * @returns {Object} - The result of the validation function for the given step.
   *                     Contains an `isValid` property indicating if the validation passed.
   *                     Returns `{ isValid: false }` if the step does not have a corresponding validation function.
   */
  const validate = (currentStep) => {
    return validationFunctions[currentStep]
        ? validationFunctions[currentStep]()
        : { isValid: false };
  };

  /**
   * It takes a file and returns a promise that resolves to the file's base64 encoding
   * @param file - The file to be converted to base64
   */
  const getBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const onSubmit =  async () => {

    const dashboardImage = await Promise.all(serviceOrder.dashboardImages.map(img => getBase64(img)));
    const vehicleRegistrationImg = await Promise.all(dateDetails.vehicleRegistrationImages.map(img => getBase64(img)));
    let dates;

    if (isRequest){
      dates = {
        date: '',
        wishDate1: dateDetails.wishDate1 && format(new Date(dateDetails.wishDate1), full_YDM_dash),
        wishDate2: dateDetails.wishDate2 && format(new Date(dateDetails.wishDate2), full_YDM_dash),
        wishDate3: dateDetails.wishDate3 && format(new Date(dateDetails.wishDate3), full_YDM_dash),
      };
    } else {
      dates = {
        date: dateDetails.appointmentDate && format(new Date(dateDetails.appointmentDate), full_YDM_dash),
        wishDate1: '',
        wishDate2: '',
        wishDate3: '',
      };
    }

    const request = {
      userId: profile.id,
      mail: profile.username,
      garage: {
        address: {
          city: garageDetails.city,
          number: garageDetails.number,
          street: garageDetails.streetAndNumber,
          zipcode: garageDetails.zipcode
        },
        id: garageDetails.garageId,
        mail: garageDetails.mail,
        name: garageDetails.garageName,
        phone: garageDetails.phone,
      },

      profileDepthFl: tireInformation?.tyre_fl.state ? tireInformation.tyre_fl.val.toString() : null,
      profileDepthFr: tireInformation?.tyre_fr.state ? tireInformation.tyre_fr.val.toString() : null,
      profileDepthRl: tireInformation?.tyre_rl.state ? tireInformation.tyre_rl.val.toString() : null,
      profileDepthRr: tireInformation?.tyre_rr.state ? tireInformation.tyre_rr.val.toString() : null,
      tireComment: tireInformation?.message,

      serviceTask: serviceTaskName,
      message: serviceOrder.message,
      taskDescription: serviceOrder.orderDescription,

      serviceImage:{blob: dashboardImage[0]},
      ...dates,
      vehicleRegistration: {blob: vehicleRegistrationImg.length ? vehicleRegistrationImg[0] : ''},
      sendConfirmation: garageDetails.appointmentConfirmation,
      vehicleId: dateDetails.licensePlate
    };
    dispatch(appointmentCreate({ appointment: request, successCallback: onAppointmentComplete }));
  };

  /**
   * If the current step is 4, then submit the form. Otherwise, validate the current step and if it's valid, increment the step
   */
  const onNextStep = () => {
    if (step === 4) {
      onSubmit();
      return;
    }
    const stepValidation = validate(step);
    if (!stepValidation.isValid) {
      setValidationErrors(stepValidation);
      return;
    }
    setValidationErrors({});
    setStep(step + 1);
    setPage([...page, 'step']);
  };

  /**
   * If there are validation errors, validate the current step and set
   * the validation errors to the result of the validation
   * @param stepNum - The current step number
   */
  const validateSteps = (stepNum) => {
    if (Object.keys(validationErrors).length) {
      const stepValidation = validate(stepNum);
      if (!stepValidation.isValid) {
        setValidationErrors(stepValidation);
      }
      else {
        setValidationErrors({});
      }
    }
  };

  useEffect(() => {
    validateSteps(1);
  }, [serviceOrder]);

  useEffect(() => {
    validateSteps(2);
  }, [dateDetails]);

  useEffect(() => {
    validateSteps(3);
  }, [garageDetails]);

  useEffect(() => {
    validateSteps('tire');
  }, [tireInformation]);

  const onCloseAddAppointment = () => {
    setAlertVisible(true);
  };

  const alertClose = (isVisibleFlag) => {
    setAlertVisible(isVisibleFlag);
    if (isVisibleFlag) {
      cancelAddAppointment();
    }
  };

  const changeType = (type) => {
    setType(false);
    setServiceType(type);
    const serviceTask = [];

    type.forEach(x => {
      serviceTask.push(appointmentTypesMapping[x]);
    });

    setServiceTaskName(serviceTask);
    if(page[0]!=='type'){
      setPage([...page, 'type']);
    }
    if (type.includes('1')) {
      setTire(true);
    }
    if (type.includes('4')){
      setOtherSelected(true);
    }
  };

  const changeTire = () => {

    const stepValidation = validate('tire');
    if (!stepValidation.isValid) {
      setValidationErrors(stepValidation);
      return;
    }
    setValidationErrors({});
    setTire(false);
    setPage([...page, 'tire']);
  };

  const tireLeftClick = () => {
    setType(true);
    setTire(false);
    setTireInformation(null);
    setDateDetails(null);
    setGarageDetails(null);
    setServiceOrder(null);
    setOtherSelected(false);
  };

  const onBackClick = () => {
    if(step===1 && page[1]!=='tire') {
      setTireInformation(null);
      setDateDetails(null);
      setGarageDetails(null);
      setServiceOrder(null);
      setOtherSelected(false);
    }
    if (page[page.length - 1]==='type') {
      setType(true);
      page.pop();
    }else if(page[page.length -1]==='tire'){
      setTire(true);
      page.pop();
    }else{
      setStep(step-1);
      page.pop();
    }
  };

  return(
    <>
      {createLoading &&
        <div className={'loading-overlay'}>
          <Spinner />
        </div>}

      {type &&
      <AppointmentType
        changeType={changeType}
        alertClose={alertClose}
        alertVisible={alertVisible}
        onCloseAppointment={onCloseAddAppointment}
      />}

      {tire &&
            <TireInformation
              stepData={tireInformation}
              changeTire={changeTire}
              onChange={setTireInformation}
              alertClose={alertClose}
              alertVisible={alertVisible}
              onCloseAppointment={onCloseAddAppointment}
              tireLeftClick={tireLeftClick}
              error={validationErrors}
            />}

      {!type && !tire &&
      <div className={`add-damage-container step-${step}`}>
        {step > 0 &&
        <ServiceOrder
          isDisabled={step !== 1}
          stepData={serviceOrder}
          error={validationErrors}
          onChange={setServiceOrder}
          otherSelected={otherSelected}
        />}
        {step > 1 &&
        <DateDetails
          isDisabled={step !== 2}
          stepData={dateDetails}
          profile={profile}
          error={validationErrors}
          onChange={setDateDetails}
          isRequest={isRequest}
        />}
        {step > 2 &&
        <GarageDetails
          preselectedGarage={preselectedGarage}
          isDisabled={step !== 3}
          stepData={garageDetails}
          garages={garages}
          error={validationErrors}
          onChange={setGarageDetails}
        />}
        {step === 4 &&
        <AppointmentSummary
          profile={profile}
          serviceOrder={serviceOrder}
          dateDetails={dateDetails}
          garageDetails={garageDetails}
          error={validationErrors}
          isRequest={isRequest}
          serviceType={serviceType}
          tireInformation={tireInformation}
        />
        }

        <div className={`arrow-buttons step-${step}`}>
          <TwoSidedButton
            iconLeft={<ArrowLeftIcon size={45}/>}
            iconRight={step === 4 ? <SendIcon size={30}/> : <ArrowRightIcon size={45}/>}
            textRight={step === 4 ? t('send') : null}
            typeRight={step === 4 ? 'is-success' : 'is-link'}
            onClickLeft={onBackClick}
            onClickRight={onNextStep}
          />
        </div>
        <div className={`cancel-add-damage-button step-${step}`}>
          <button onClick={onCloseAddAppointment}>
            <CloseIcon/>
          </button>
        </div>
        <Alert visible={alertVisible} title={t('appointment_exit_title')} onClick={alertClose} type={BUTTON_TYPES.SUCCESS}/>
      </div>}
    </>
  );
};

export default memo(AddAppointmentContainer);

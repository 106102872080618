import {call, put, all, takeLatest, takeEvery} from "redux-saga/effects";
import { errorHandler } from "@helpers/saga";
import { toast } from "react-toastify";
import { Api } from "@api";
import * as ticketActions from './actions';
import {
  CREATE_TICKET_REQUEST,

  VISION_TICKET_REQUEST,
  VISION_TICKET_SUCCESS,
  VISION_TICKET_FAIL,
} from "./constants";
import * as ticketsConstants from "@store/tickets/constants";
import {format} from "date-fns";
import * as ticketsActions from "@store/tickets/actions";
import DATE_FORMATS from "@helpers/constants/dateFormats";
import {t} from "@helpers/i18n";

/**
 * It takes an object with search, filters and page properties and returns a query string
 * @returns A string of query parameters
 */
const buildQuery = ({search, filters, page}) => {
  const query = [];
  if (search) {
    query.push(`search=${ search }`);
  }
  if (filters) {
    const {dates, state} = filters;

    if (dates.dateFrom && dates.dateTo) {
      query.push(`dateFrom=${ format(dates.dateFrom, DATE_FORMATS.full_YMD_dash) }`);
      query.push(`dateTo=${ format(dates.dateTo, DATE_FORMATS.full_YMD_dash) }`);
    }
    if (state) {
      // convert tickets state to query string
      const ticketStatesQuery = state
        .filter((ticketState) => ticketState.value && ticketState.title !== 'all' && ticketState.title !== 'tickets_between')
        .map((item) => item.title).join(',');
      if (ticketStatesQuery) {
        query.push(`state=${ ticketStatesQuery }`);
      }
    }
  }
  query.push(`page=${ page }`);
  return query.join('&');
};

function* getTickets(action) {

  try {
    const {customerNumber} = action.payload.user.info;
    const currentUser = action.payload.user.user;
    const {id: userId, accesslevel: userRole} = currentUser;
    const currentUserAvatar = action.payload.user.user.avatar;
    if (!customerNumber) {
      // throw Error('NoCustomerNumberFromGetTickets');
      yield put(ticketsActions.ticketsGetFail('NoCustomerNumberFromGetTickets'));
      return;
    }

    const query = buildQuery(action.payload);
    const {data} = yield call(Api.tickets.getTicketsForCustomer, userId, query);

    let tickets;
    let drivers = null;
    if (userRole === 'manager') {
      tickets = data.offenses;
      // eslint-disable-next-line prefer-destructuring
      drivers = data.drivers;
      if (drivers.length ?? null) {
        tickets = tickets.map((item) => {
          const tmpT = {...item};
          if (item.assignee) {
            tmpT.user = drivers.find(d => +d.id === +item.assignee);
          }
          return tmpT;
        });
      }
    } else {
      tickets = data.offenses.filter(item => ((+item.assignee === +userId)
          || (+item['author-id'] === +userId)));
      tickets = tickets.map((item) => {
        const tkt = item;
        if (tkt.assignee) {
          tkt.user = {
            ...currentUser,
            avatar: currentUserAvatar
          };
        }
        return (tkt);
      });
    }
    const response = {...data, offenses: tickets};
    yield put(ticketsActions.ticketsGetSuccess(response));


  } catch (err) {
    yield call(errorHandler, err);
    yield put(ticketsActions.ticketsGetFail(err));
  }
}

function* loadMoreTickets(action) {

  try {
    const {customerNumber} = action.payload.user.info;
    const currentUser = action.payload.user.user;
    const {id: userId, accesslevel: userRole} = currentUser;
    const currentUserAvatar = action.payload.user.user.avatar;
    if (!customerNumber) {
      // throw Error('NoCustomerNumberFromGetTickets');
      yield put(ticketsActions.ticketsLoadMoreFail('NoCustomerNumberFromGetTickets'));

      return;
    }
    const query = buildQuery(action.payload);
    const {data} = yield call(Api.tickets.getTicketsForCustomer, userId, query);

    let tickets;
    let drivers = null;
    if (userRole === 'manager') {
      tickets = data.offenses;
      // eslint-disable-next-line prefer-destructuring
      drivers = data.drivers;
      if (drivers.length ?? null) {
        tickets = tickets.map((item) => {
          const tmpT = {...item};
          if (item.assignee) {
            tmpT.user = drivers.find(driver => +driver.id === +item.assignee);
          }
          return tmpT;
        });
      }
    } else {
      tickets = data.offenses.filter(item => ((+item.assignee === +userId)
          || (+item['author-id'] === +userId)));
      tickets = tickets.map((item) => {
        const tkt = item;
        if (tkt.assignee) {
          tkt.user = {
            ...currentUser,
            avatar: currentUserAvatar
          };
        }
        return (tkt);
      });
    }

    const response = {...data, tickets};
    yield put(ticketsActions.ticketsLoadMoreSuccess(response));

  } catch (err) {
    yield call(errorHandler, err);
    yield put(ticketsActions.ticketsLoadMoreFail({}));
  }
}

function* getSingleTicket(action) {
  try {
    const { id } = action.payload;
    let { data: ticket } = yield call(Api.tickets.getSingleTicket, {id});

    /* If ticket is new, mark it as read on the backend */
    if (ticket.state === 'new') {
      const readResponse = yield call(Api.tickets.read, {id: ticket.id});
      ticket = readResponse.data;
    }

    yield put(ticketActions.ticketSingleGetSuccess(ticket));
  } catch (err) {
    yield call(errorHandler, err);
    yield put(ticketActions.ticketSingleGetFail(err));
  }
}

function* getPayLink(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(Api.tickets.pay, {id});

    yield put({ type: ticketsConstants.GET_PAYLINK_SUCCESS, payload: data });

  } catch (err) {
    yield call(errorHandler, err);
    yield put({ type: ticketsConstants.GET_PAYLINK_FAIL });
  }
}


function* deleteTicket(action) {
  try {
    const { id, history } = action.payload;
    const { data } = yield call(Api.tickets.delete, { id });

    yield call(toast.success, t("ticket_successfully_deleted"));

    yield put(ticketActions.ticketDeleteSuccess(data));
    history.push('/tickets');
  } catch (err) {
    yield call(errorHandler, err);
    yield put(ticketActions.ticketDeleteFail(err));
  }
}

function* resetTicket(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(Api.tickets.reset, {id});

    yield put(ticketActions.ticketResetSuccess(data));

    yield call(toast.success, t("ticket_successfully_reset"));
  } catch (err) {
    yield call(errorHandler, err);
    yield put(ticketActions.ticketResetFail(err));
  }
}

function* completeTicket(action) {
  try {
    const { id, reason } = action.payload;
    const { data } = yield call(Api.tickets.complete, {id, reason});

    yield call(toast.success, t("ticket_successfully_completed"));
    yield put(ticketActions.ticketResetSuccess(data));

  } catch (err) {
    yield call(errorHandler, err);
    yield call(toast.error, t("ticket_not_completed"));
    yield put(ticketActions.ticketCompleteFail(err));
  }
}

function* createTicket(action) {
  try {
    const {ticketInfo, media, history} = action.payload;
    const {data} = yield call(Api.tickets.create, ticketInfo);

    yield all(media.content.map((file, index) => {
      const ticketMedia = {
        blob: file,
        desc :`Ticket_${data.id}_file_${index}`,
        name :`Ticket_${data.id}_file_${index}`
      };
      return call(Api.tickets.attach, {id: data.id, media: ticketMedia});
    }));

    yield put(ticketActions.ticketCreateSuccess(data));
    yield call(toast.success, t("offense-created"));

    history.push('/tickets');
  } catch (e) {
    console.log(e);
    yield put(ticketActions.ticketCreateFail(e));
  }
}

function* objectTicket(action) {
  try {
    const { id, emailData } = action.payload;
    const { data } = yield call(Api.tickets.object, id, {
      from: emailData.emailFrom,
      target: emailData.emailTo,
      subject: emailData.subject,
      content: emailData.content,
    });

    yield put(ticketActions.ticketObjectedSuccess(data));
    yield call(toast.success, t("ticket_successfully_objected"));

  } catch (err) {
    yield call(errorHandler, err);
    yield put(ticketActions.ticketObjectedFail(err));
  }
}

function* assignTicket(action) {
  try {
    const { id, userId, emailData } = action.payload;

    const { data } = yield call(Api.tickets.assign, {
      id,
      driverId: userId,
      mail: {
        from: emailData.emailFrom,
        target: emailData.emailTo,
        subject: emailData.subject,
        content: emailData.content,
      }
    });


    yield put(ticketActions.ticketAssignSuccess(data));

    yield call(toast.success, t("ticket_successfully_assigned"));

    yield put(ticketsActions.ticketSingleGet(action.payload));

  } catch (err) {
    yield call(errorHandler, err);
    yield put(ticketActions.ticketAssignFail(err));
  }
}

function* visionTicket(action) {
  try {
    const { file } = action.payload;
    const data = yield call(Api.tickets.vision, file);
    yield put({ type: VISION_TICKET_SUCCESS, payload: data });


  } catch (err) {
    yield call(errorHandler, err);

    yield put({ type: VISION_TICKET_FAIL });
  }
}

function* visionTicketSaga() {
  yield takeEvery(VISION_TICKET_REQUEST, visionTicket);
}

function* getTicketsSaga() {
  yield takeLatest(ticketsConstants.TICKETS_GET_REQUEST, getTickets);
}

function* loadMoreTicketsSaga() {
  yield takeLatest(ticketsConstants.TICKETS_LOAD_MORE, loadMoreTickets);
}

function* createTicketSaga() {
  yield takeLatest(CREATE_TICKET_REQUEST, createTicket);
}


export function* ticketSagas() {
  yield all([
    call(getTicketsSaga),
    call(loadMoreTicketsSaga),
    yield takeLatest(ticketsConstants.TICKET_SINGLE_GET_REQUEST, getSingleTicket),
    yield takeLatest(ticketsConstants.TICKET_DELETE_REQUEST, deleteTicket),
    yield takeLatest(ticketsConstants.GET_PAYLINK_REQUEST, getPayLink),
    yield takeLatest(ticketsConstants.TICKET_RESET_REQUEST, resetTicket),
    yield takeLatest(ticketsConstants.TICKET_COMPLETE_REQUEST, completeTicket),
    yield takeLatest(ticketsConstants.TICKET_OBJECT_REQUEST, objectTicket),
    yield takeLatest(ticketsConstants.TICKET_ASSIGN_REQUEST, assignTicket),
    call(createTicketSaga),
    call(visionTicketSaga),
  ]);
}

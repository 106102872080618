import React, {useRef, useState} from 'react';
import Grid from '@mui/material/Grid';
import Slider from '@components/Common/Slider';
import ImageCmp from "@components/Common/Image";
import { format } from 'date-fns';
import { t } from "@helpers/i18n";


import './AdditionalDamageListItem.styles.scss';
import {media} from "@api/media";

const AdditionalDamageListItem = ({ item }) => {

  const sliderRef = useRef();
  const { images } = item;
  const [slides, setSlides] = useState(new Array(images.length).fill(null));
  const { created, drivername, description, archived } = item || { created: '', user: '', archived: ''};

  const date = format(new Date(created), "dd.MM.yyyy"); // get created date
  const time = format(new Date(created), "HH:mm"); // get created time

  const onImagePreview = (index) => {
    sliderRef.current.previewImage(index);
  };

  const getImageProps = (image, index) => ({
    src: image.blob ? image.blob : null,
    isBlob: !!image?.blob,
    className: `additional-info-img`,
    onClick: () => image?.blob ? onImagePreview(index) : ""
  });

  const isInfoArchived = ()  => {
    const archivedTKey = parseInt(archived) ? 'damage_info_archived' : 'damage_info_unarchived';
    return t(archivedTKey);
  };

  /**
   * API call for loading full resolution image
   * @param {number} index
   **/
  const loadFullImage = (index) => {
    const {  desc } = images[index];
    const { id } = item;
    const name = desc.substring(6);
    return media.getFullImage({id: id, name: name, type: 'damage_information' , category:''});
  };


  return (<div className={'additional-list-item'}>
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <p>{ date } |  { time } | { drivername } | { isInfoArchived() }</p>
        <p>{ description }</p>
      </Grid>
      <Grid item xs={7}>
        <div className={'damage-images'}>
          {
            images.map((image, index) => {
              return (
                <ImageCmp key={index} {...getImageProps(image, index)} />
              );
            })
          }
        </div>
      </Grid>
    </Grid>
    {
      <Slider
        ref={sliderRef}
        slides={slides}
        setSlides={setSlides}
        imageAPI={loadFullImage}
      />
    }
  </div>);
};


export default AdditionalDamageListItem;

import React from 'react';
import PropTypes from "prop-types";
/** load components **/
import { Field, Form, Formik } from "formik";
import {Grid} from "@mui/material";
import {CmpInput} from "@components/Common/CmpInput/CmpInput.component";
import CmpDatePicker from "@components/Common/DatePicker";
import CmpUpload from "@components/Common/Upload";
import { Button } from "@components/Common/Button";
import {BUTTON_TYPES, DRIVERS_LICENSE_NUMBER_REGEX} from "@constants";
/** load icons **/
import CheckIcon from "mdi-react/CheckIcon";
/** load tt-tool **/
import { t } from "@helpers/i18n";

import "./index.scss";


const EditDriverLicense = (props) => {

  const { info, license, loading, onSave, onPreviewImage } = props;

  const {
    id,
    name,
    surname,
    licenceIdentifier,
    issueDate,
    expirationDate,
    birthday,
  } = info;

  const testLicenseNumber = (number) => DRIVERS_LICENSE_NUMBER_REGEX.test(number);

  return (
    <div className={'edit-license-container'}>
      <Formik
        initialValues={
          {
            id,
            name,
            surname,
            licenceIdentifier,
            issueDate,
            expirationDate,
            birthday,
            frontImage: null,
            backImage: null
          }
        }
        onSubmit={(values) => {
          onSave(values);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form className="details-content flex flex-col justify-between p-b-2">
            <div className={'driver-license-form'}>
              <Grid container spacing={1} className={'left-column'}>
                <Grid item xs={12}>
                  <Field
                    name="name"
                    label={t("name")}
                    component={CmpInput}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="surname"
                    label={t("surname")}
                    component={CmpInput}
                  />
                </Grid>
                <Grid item xs={12} className={!testLicenseNumber(values.licenceIdentifier)? 'error_field' : ''}>
                  <Field
                    name="licenceIdentifier"
                    label={t("number")}
                    component={(props) =>
                      <CmpInput
                        {...props}
                        error={
                        !testLicenseNumber(values.licenceIdentifier) ?
                          t('field_required') : ''
                        } />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CmpDatePicker
                    name="issueDate"
                    label={t("date-of-issue")}
                    value={values.issueDate}
                    onChange={setFieldValue}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CmpDatePicker
                    name="expirationDate"
                    label={t("expiry-date")}
                    value={values.expirationDate}
                    onChange={setFieldValue}
                  />
                </Grid>
                <Grid item xs={12}>
                  <p className="label">{t('license_front_upload')}</p>
                  <CmpUpload
                    name={'frontImage'}
                    value={values.frontImage}
                    onChange={(e) => setFieldValue('frontImage', e.target.files[0])}
                    onRemove={() => setFieldValue('frontImage', null)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <p className="label">{t('license_back_upload')}</p>
                  <CmpUpload
                    label={t('license_back_upload')}
                    name={'backImage'}
                    value={values.backImage}
                    onChange={(e) => setFieldValue('backImage', e.target.files[0])}
                    onRemove={() => setFieldValue('backImage', null)} />
                </Grid>
              </Grid>
              <Grid item xs={6} className={'column-pictures'}>
                {!!license.frontImage.blob &&
                <div className={'license-container'}>
                  <img className="license-img"
                    onClick={() => onPreviewImage(0)}
                    src={`data:image/png;base64, ${license.frontImage.blob}`}
                    alt="license-front" />
                </div>}
                {!!license.backImage.blob &&
                <div className={'license-container'}>
                  <img className="license-img"
                    onClick={() => onPreviewImage(1)}
                    src={`data:image/png;base64, ${license.backImage.blob}`}
                    alt="license-back" />
                </div>}
              </Grid>
            </div>
            <Button
              className={'self-center save-button-text'}
              icon={<CheckIcon />}
              isSubmit
              isDisabled={!testLicenseNumber(values.licenceIdentifier)}
              type={BUTTON_TYPES.SUCCESS}
              isLoading={loading}
              text={t("save")}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

EditDriverLicense.propTypes = {
  info: PropTypes.object,
  license: PropTypes.object,
  loading: PropTypes.bool,
  onSave: PropTypes.func.isRequired
};

EditDriverLicense.defaultProps = {
  info: {},
  license: {},
  loading: false,
  onSave: () => { }
};

export default EditDriverLicense;

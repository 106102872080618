export const INVOICE_GET_REQUEST = 'INVOICE_GET_REQUEST';
export const INVOICE_GET_SUCCESS = 'INVOICE_GET_SUCCESS';
export const INVOICE_GET_FAIL = 'INVOICE_GET_FAIL';

export const INVOICE_GET_MORE_REQUEST = 'INVOICE_GET_MORE_REQUEST';
export const INVOICE_GET_MORE_SUCCESS = 'INVOICE_GET_MORE_SUCCESS';
export const INVOICE_GET_MORE_FAIL = 'INVOICE_GET_MORE_FAIL';

export const INVOICE_DETAIL_GET_REQUEST = 'INVOICE_DETAIL_GET_REQUEST';
export const INVOICE_DETAIL_GET_SUCCESS = 'INVOICE_DETAIL_GET_SUCCESS';
export const INVOICE_DETAIL_GET_FAIL = 'INVOICE_DETAIL_GET_FAIL';

export const INVOICE_PDF_GET_REQUEST = 'INVOICE_PDF_GET_REQUEST';
export const INVOICE_PDF_GET_SUCCESS = 'INVOICE_PDF_GET_SUCCESS';
export const INVOICE_PDF_GET_FAIL = 'INVOICE_PDF_GET_FAIL';

export const INVOICE_MARK_READ = 'INVOICE_MARK_READ_REQUEST';
export const INVOICE_MARK_READ_SUCCESS = 'INVOICE_MARK_READ_SUCCESS';
export const INVOICE_MARK_READ_FAIL = 'INVOICE_MARK_READ_FAIL';

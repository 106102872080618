import {
  GET_REPORTING_DATA_REQUEST,
  GET_REPORTING_DATA_SUCCESS,
  GET_REPORTING_DATA_FAILURE,
  UPDATE_REPORTING_CONSTANTS_REQUEST,
  UPDATE_REPORTING_CONSTANTS_SUCCESS,
  UPDATE_REPORTING_CONSTANTS_FAILURE
} from './constants';

const initialState = {
  isLoading: false,
  updatingConstants: false,
  reportData: {},
};

export default function reporting(state = initialState, action) {
  switch (action.type) {
    case GET_REPORTING_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_REPORTING_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        reportData: action.payload,
      };
    case GET_REPORTING_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_REPORTING_CONSTANTS_REQUEST:
      return {
        ...state,
        updatingConstants: true
      };
    case UPDATE_REPORTING_CONSTANTS_SUCCESS:
      return {
        ...state,
        updatingConstants: false,
        reportData: action.payload,
      };
    case UPDATE_REPORTING_CONSTANTS_FAILURE:
      return {
        ...state,
        updatingConstants: false
      };
    default:
      return state;
  }
}

import { ReactComponent as ServicePartnerMarker } from "@assets/svg/map-marker-service-partner.svg";
import { ReactComponent as CarMarker } from "@assets/svg/map-marker-car.svg";
import { ReactComponent as VanMarker } from "@assets/svg/map-marker-van.svg";
import Marker, { MARKER_TYPES } from "./Marker";
import GoogleMapReact from "google-map-react";
import React, { useState, useEffect, useRef } from "react";
import { TailSpin } from "react-loader-spinner";
import {simplify} from "@services/utils/SimplifyRoute";


const Map = (props) => {

  const {
    vehicles,
    stations,
    servicePartners,
    defaultZoom,
    defaultCenter,
    onMapBoundsChange,
    onMarkerClick,
    settings,
    trip,
    loadingTrip,
    activeVehicle
  } = props;

  const [currentZoom, setCurrentZoom] = useState(defaultZoom);
  const [mapState, setMapState] = useState();
  const ref = useRef([]);
  const startMarkerRef = useRef(null);
  const prevActiveVehicleRef = useRef(null);
  const renderMarkerIcon = (type) => {
    switch (type) {
      case MARKER_TYPES.SERVICE:
        return <ServicePartnerMarker className="marker-icon" />;
      case MARKER_TYPES.CAR:
        return <CarMarker className="marker-icon" />;
      case MARKER_TYPES.VAN:
      default:
        return <VanMarker className="marker-icon" />;
    }
  };

  const priceLevel = (index, length) => {
    if (index <= length / 3) {
      return "low";
    }

    if (index <= length / 1.5) {
      return "middle";
    }

    return "high";
  };

  const renderVehicles = () =>
    vehicles &&
    vehicles.map((marker, index) => (
      <Marker
        marker={marker}
        brand={marker.brand}
        price={marker.price}
        type={marker.vehicleClass}
        hoverMarker={settings.hoverMarker}
        carsCount={marker.carsCount}
        priceLevel={marker.priceLevel}
        speed={marker.speed}
        lat={marker.latitude}
        lng={marker?.longitude}
        started={marker?.started}
        key={`${marker.vehicleType}${index}`}
        customerCity={marker?.city}
        customerPlace={marker?.place}
        name={`${marker.driver?.name || "-"} ${marker.driver?.surname || "-"}`}
        fuelLevel={marker["fuel-level"]}
        licensePlate={marker["license-plate"]}
        userCar={`${marker.manufacturer} ${marker.vehicleModelType}`}
        markerIcon={renderMarkerIcon(marker.vehicleClass)}
        onMarkerClick={() => onMarkerClick("vehicles", marker.id, marker)}
      />
    ));

  const renderStations = () => {
    return (
      stations &&
      stations.map((marker, index) => (
        <Marker
          marker={marker}
          type={marker.type}
          hoverMarkerStation={settings.hoverMarkerStation}
          key={`${marker.type}${index}`}
          brand={marker.brand}
          lat={marker.latitude}
          lng={marker.longitude}
          price={marker[`price-${settings?.typeFuel || "diesel"}`]}
          onMarkerClick={() => onMarkerClick("station", marker.id, marker)}
          priceLevel={priceLevel(index, stations?.length)}
        />
      ))
    );
  };

  const renderServicePartners = () => {
    if (servicePartners) {
      return servicePartners?.map((marker, index) => (
        <Marker
          marker={marker}
          type={marker.type}
          hoverMarkerService={settings.hoverMarkerService}
          name={marker.title}
          key={`${marker.type}${index}`}
          rating={+marker.rating}
          lat={marker.address.lat}
          lng={marker.address.lng}
          customerPlace={marker.address}
          markerIcon={renderMarkerIcon(marker.type)}
          onMarkerClick={() => onMarkerClick("service", marker.id, marker)}
        />
      ));
    }
  };

  const defineCenter = () => {
    if (settings.coordinates?.lat && settings.coordinates?.lng) {
      return settings.coordinates;
    }
  };

  const defineZoom = () => {
    const {
      marker,
      isStation,
      hoverMarker,
      selectAppointment,
      hoverMarkerStation,
      hoverMarkerService,
    } = settings;

    if (isStation) {
      return currentZoom;
    }

    if (marker) {
      return 14;
    } else if (
      /**
       If I clicked on Vehicles or Station place it in the center set scale - 14
       */
      hoverMarker ||
      hoverMarkerStation ||
      hoverMarkerService ||
      selectAppointment
    ) {
      return 14;
    } else {
      return currentZoom;
    }
  };

  const onMapPositionChange = (properties) => {
    setCurrentZoom(properties.zoom);
    onMapBoundsChange(properties);
  };

  const tripWaypoints = (trip) => {
    const waypoints = [];
        trip?.tracks?.forEach((track, index, array) => {
          waypoints.push({location: {lat: track?.['starting-position'].latitude, lng: track?.['starting-position'].longitude}});
          track?.waypoints?.forEach(waypoint => {
            waypoints.push({location: {lat: waypoint?.latitude, lng: waypoint?.longitude}});
          });
          waypoints.push({location: {lat: track?.['stop-position'].latitude, lng: track?.['stop-position'].longitude}});
        });

        const simplifyPoints = simplify(waypoints, 0.01, true);

        if(simplifyPoints.length > 27) {
          const waypointsMatrix = [];
          let tempArray = [];
          simplifyPoints.forEach((waypoint, index, array) => {
            if( index !== 0 && index % 27 === 0 ) {
              waypointsMatrix.push(tempArray);
              tempArray = [];
            }
            tempArray.push(waypoint);
            if (index === array.length - 1) {
              waypointsMatrix.push(tempArray);
            }
          });

          return waypointsMatrix;
        } else {
          return [simplifyPoints];
        }
  };

  const lineSymbol = {
    path: 'M 3 4 L 3 0 L 4 2 M 3 0 L 2 2',
    fillColor: 'blue',
    strokeColor: 'blue',
    strokeWeight: 2,
    strokeOpacity: 1
  };

  const routeOriginIcon = 'http://maps.google.com/mapfiles/kml/paddle/grn-circle.png';

  const drawSingleRoute = (waypoints) => {
    const origin = waypoints.shift();
    const destination = waypoints.pop();
    if (origin && destination && mapState?.map) {
      const directionsService = new window.google.maps.DirectionsService();
      const directionsRenderer = new window.google.maps.DirectionsRenderer();
      directionsRenderer.setOptions({
        suppressMarkers: true,
        polylineOptions: {
          strokeColor: 'rgba(0,153,255,0.66)',
          strokeWeight: 4,
          icons: [{
            icon: lineSymbol,
            offset: '150px',
            repeat: '400px'
          }]
        }});

      directionsRenderer.setMap(mapState?.map);
      ref.current.push(directionsRenderer);

      directionsService.route(
        {
          origin: origin,
          destination: destination,
          travelMode: google.maps.TravelMode.DRIVING,
          waypoints: waypoints
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            directionsRenderer.setDirections(result);

            startMarkerRef.current = new google.maps.Marker({
              position: origin.location,
              map: mapState?.map,
              icon: {
                url: routeOriginIcon,
                scaledSize: new google.maps.Size(30, 30)
              }});

          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );
    }
  };

  /**
   * If the trip has tracks and the map is loaded, draw the trip on the map
   */
  const drawTrip = () => {
    if (trip?.tracks) {
      const waypointsMatrix = tripWaypoints(trip);
      if (activeVehicle) {
        prevActiveVehicleRef.current = activeVehicle;
        waypointsMatrix?.forEach((waypoints, index, array) => {
          drawSingleRoute(waypoints);
        });
      }
    }
  };

  const removeTrip = () => {
    startMarkerRef.current?.setMap(null);
    if (ref.current){
      ref.current.forEach(el => el.setMap(null));
      ref.current = [];
    }
  };

  useEffect(() => {
    if (activeVehicle === trip?.vehicleId){
      removeTrip();
      drawTrip();
    } else {
      removeTrip();
    }
  }, [trip, mapState, activeVehicle]);

  /**
   * returns map Loader
   * @returns {JSX.Element}
   */
  const mapLoader = () => {
    return (<div className={"map-loader"}>
      <div className="list-loader">
        <TailSpin
          color="#FFF"
          height={50}
          width={50}
        />
      </div>
    </div>);
  };

  return (
    <>
      {loadingTrip && mapLoader()}
      <GoogleMapReact
        zoom={defineZoom()}
        center={defineCenter()}
        defaultZoom={defaultZoom}
        options={(maps) => ({
          mapTypeId: settings.satellite
            ? maps?.MapTypeId?.SATELLITE
            : maps?.MapTypeId?.ROADMAP,
          mapTypeControlOptions: {
            mapTypeIds: [
              maps?.MapTypeId?.ROADMAP,
              maps?.MapTypeId?.SATELLITE,
              maps?.MapTypeId?.HYBRID,
            ],
          },
        })}
        defaultCenter={defaultCenter}
        onChange={onMapPositionChange}
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => setMapState({map:map, maps:maps})}
      >
        {renderVehicles()}
        {renderStations()}
        {renderServicePartners()}
      </GoogleMapReact>
    </>
  );
};

export default Map;

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import { Provider } from "react-redux";
import { reducers } from "@store/reducers";
import { sagas } from "@store/sagas";
import AppContainer from './containers/App';

import "styles/index.scss";

const saga = createSagaMiddleware();

export const store = createStore(reducers, composeWithDevTools(
  applyMiddleware(...[ saga])
));

saga.run(sagas);

ReactDOM.render(
  // Strict mode disabled due to issue in google-map-react library
  // https://github.com/google-map-react/google-map-react/issues/677
  // <React.StrictMode>
  <Provider store={store}>
    <Router>
      <AppContainer />
    </Router>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

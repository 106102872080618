import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageContent from "@components/Layout/PageContent";
import TimeTrackingCardList from "@components/TimeTracking/TimeTrackingCardList/TimeTrackingCardList.component";
import EmployeeTimeTrackingFilter from '@components/Employee/EmployeeTimeTrackingFilter';
import EmployeeTimeTrackingDetails from "@components/Employee/EmployeeTimeTrackingDetails";
import * as timeTrackingActions from "@store/tracking/actions";
import {format} from "date-fns";
import {Grid} from "@mui/material";
import DATE_FORMATS from "../../../../helpers/constants/dateFormats";
import './EmployeeTimeTracking.styles.scss';

const EmployeeTimeTracking = () => {
  const [page, setPage] = useState(0);
  const {trackingUser = [], loading = false, lastPage = false, timeTrackingDetails = [], loadingTimeTrackingDetails = false, totalTimeInSeconds} = useSelector(state => state.tracking);
  const {employee} = useSelector(state => state.employees);
  const [trackingDates, setTrackingDates] = useState({
    dateFrom: null, dateTo: null
  });
  const [trackngDetailsVisible, setTrackingDetailsVisible] = useState(false);
  const dispatch = useDispatch();
  const currentCard = useRef();
  const setSelectedCardRef = useRef(()=>{});

  /**
   * "If the employee exists, then dispatch the timeTrackingUserGet action with the employee's userId,
   * the current page, and the dateFrom and dateTo values from the trackingDates object."
   */
  const fetchTrackingForUser = () => {
    if(employee) {
      dispatch(timeTrackingActions.timeTrackingUserGet({
        userId: employee?.userId,
        page: page,
        dateFrom: trackingDates.dateFrom ? `&filterDateFrom=${ format(trackingDates.dateFrom, DATE_FORMATS.full_YMD_dash) }` : '',
        dateTo: trackingDates.dateTo ? `&filterDateTo=${ format(trackingDates.dateTo, DATE_FORMATS.full_YMD_dash) }` : ''
      }));
    }
  };

  /* A React hook that is used for performing side effects in function components. */
  useEffect(() => {
    fetchTrackingForUser();
  }, [employee, trackingDates]);

  /**
   * It loads more time tracking data for the current employee
   */
  const loadMoreTimeTracking = () => {
    if (!lastPage) {
      const nextPage = page + 1;

      dispatch(timeTrackingActions.timeTrackingUserLoadMore({
        userId: employee?.userId,
        page: nextPage,
        dateFrom: trackingDates.dateFrom ? `&filterDateFrom=${ format(trackingDates.dateFrom, DATE_FORMATS.full_YMD_dash) }` : '',
        dateTo: trackingDates.dateTo ? `&filterDateTo=${ format(trackingDates.dateTo, DATE_FORMATS.full_YMD_dash) }` : ''
      }));
      setPage(nextPage);
    }
  };

  /**
   * It takes in a type and a value, and then sets the trackingDates state to the current trackingDates state, but with the type key set to the value
   * @param type - The type of date that is being changed. This can be either 'startDate' or 'endDate'.
   * @param value - The value of the date picker.
   */
  const onDateChange = (type, value) => {
    setPage(0);
    setTrackingDates({
      ...trackingDates,
      [type]: value
    });
    setTrackingDetailsVisible(false);
    setSelectedCardRef.current = () => {};
  };

  /**
   * If the employee is not null, then if the current card is the same as the date, then set the tracking details to false, set the selected card to false, and set the current card to null. Otherwise, set the tracking details to true, set the selected card to true, and set the current card to the date
   * @param date - the date of the card that was clicked
   * @param setSelectedCard - This is a function that is passed to the child component. It is used to set the selectedCard state in the child component.
   */
  const onTrackingClick = (date, setSelectedCard) => {
    if(employee) {
      if (currentCard.current === date){
        setTrackingDetailsVisible(false);
        setSelectedCard(false);
        currentCard.current = null;
      } else {
        dispatch(timeTrackingActions.timeTrackingDetailsGet({userId: employee.userId, date }));
        setTrackingDetailsVisible(true);
        setSelectedCardRef.current(false);
        setSelectedCard(true);
        currentCard.current = date;
      }
      setSelectedCardRef.current = setSelectedCard;
    }
  };

  /* Creating an object that is being passed to the TimeTrackingCardList component. */
  const cardListTimeTrackingProps = {
    trackings: trackingUser,
    loading,
    page,
    lastPage,
    isEndReached: loadMoreTimeTracking,
    onTrackingClick
  };

  return (
    <Grid container spacing={2} style={{height: '100%'}}>
      <Grid item xs={8}>
        <div className={'employee-damages-list-container'}>
          <PageContent className={'employee-damages-list employee-time-tracking-list'}>
            <TimeTrackingCardList {...cardListTimeTrackingProps} />
          </PageContent>
          {trackngDetailsVisible && !loadingTimeTrackingDetails &&
          <EmployeeTimeTrackingDetails trackingDetails={timeTrackingDetails}/>}
        </div>
      </Grid>
      <Grid item xs={4}>
        <EmployeeTimeTrackingFilter
          trackingDates={trackingDates}
          totalTime={totalTimeInSeconds ? parseInt(totalTimeInSeconds) : 0}
          onDateChange={onDateChange} />
      </Grid>
    </Grid>
  );
};
export default EmployeeTimeTracking;

import React, { useEffect, useState } from 'react';
import VehicleInformationComponent from '@containers/Damages/AddDamage/VehicleInformation/VehicleInformation.component';
import VehicleThirdParty from "@containers/Damages/AddDamage/VehicleThirdParty/VehicleThirdParty.component";
import { useDispatch, useSelector } from 'react-redux';
import * as AddDamageValidators from './Validations/AddDamage.validator';
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import ArrowRightIcon from 'mdi-react/ArrowRightIcon';
import SendIcon from 'mdi-react/SendIcon';
import { TwoSidedButton } from '@components/Common/TwoSidedButton/TwoSidedButton.component';
import DamageLocation from "@containers/Damages/AddDamage/DamageLocation/DamageLocation.component";
import DamageSummaryComponent from '@containers/Damages/AddDamage/DamageSummary/DamageSummary.component';
import { Button } from '@components/Common/Button';
import { BUTTON_TYPES, damagesConstants } from '@constants';
import { Spinner } from '@components/Spinner';
import Alert from '@components/Common/Alert';
import PropTypes from 'prop-types';
import { t } from "@helpers/i18n";
import dayjs from 'dayjs';
import './AddDamage.style.scss';


const AddDamageContainer = (props) => {

  const { cancelAddDamage, onDamageComplete } = props;
  const { profile } = useSelector((state) => state.profile);
  const { uploadingDamages } = useSelector((state) => state.damages);

  const [step, setStep] = useState(1);

  const [damageLocation, setDamageLocation] = useState(null);
  const [vehicleInfo, setVehicleInfo] = useState(null);
  const [thirdPartyInfo, setThirdPartyInfo] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [hasThirdParty, setHasThirdParty] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const dispatch = useDispatch();

  const validate = (currentStep) => {
    switch (currentStep) {
      case 1: return AddDamageValidators.damageLocationValidation(damageLocation);
      case 2: return AddDamageValidators.vehicleInformationValidation(vehicleInfo);
      case 3: return AddDamageValidators.thirdPartyValidation(hasThirdParty, thirdPartyInfo);
      default: return { isValid: false };
    }
  };

  const completeDamage = () => {
    onDamageComplete();
  };

  const onSubmit = async () => {
    if(!termsAccepted) {
      setValidationErrors({terms: 'field_required'});
      return;
    }
    setValidationErrors({});
    const getBase64 = (file) => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

    const allImagesAsBlob = await Promise.all(vehicleInfo.damageImages.map(img => getBase64(img)));

    const allImages = vehicleInfo?.damageImages?.map((image, index) => {
      return {
        'blob': allImagesAsBlob[index],
        'identifier': 'damage_xx_'.concat((index+1).toString()),
        'desc': image.type,
        'name': `damage`,
        'category': 'damage',
        'uri': image.uri
      };
    });

    if (vehicleInfo?.policeImages?.length) {
      const allImagesAsBlob = await Promise.all(vehicleInfo?.policeImages?.map(img => getBase64(img)));
      vehicleInfo?.policeImages?.forEach((image, index) => {
        allImages.push({
          'blob': allImagesAsBlob[index],
          'identifier': 'damageMisc_xx_'.concat((index+1).toString()),
          'desc': image.type,
          'name': `damage`,
          'category': 'damage',
          'uri': image.uri
        });
      });
    }

    if (thirdPartyInfo?.thirdPartyDamageImages?.length) {
      const allImagesAsBlob = await Promise.all(thirdPartyInfo?.thirdPartyDamageImages?.map(img => getBase64(img)));
      thirdPartyInfo?.thirdPartyDamageImages?.forEach((image, index) => {
        allImages.push({
          'blob': allImagesAsBlob[index],
          'identifier': 'damageOpponent_xx_'.concat((index+1).toString()),
          'desc': image.type,
          'name': `damage`,
          'category': 'damage',
          'uri': image.uri
        });
      });
    }

    const formattedAccidentDate = dayjs(new Date(damageLocation?.accidentDateAndTime)).format('YYYY-MM-DD HH:mm:ss');

    const request = {
      carcheckId: null,
      category: `${vehicleInfo.damageType}`,
      date: formattedAccidentDate,
      desc: vehicleInfo.description,
      driverId: damageLocation.driverId,
      hash: null,
      images: allImages,
      location: {
        zipcode: damageLocation.zipcode,
        country: damageLocation.country,
        city: damageLocation.city,
        number: damageLocation.number,
        street: damageLocation.street,
      },
      hasPoliceInformation: vehicleInfo.policeInvolved === 'yes' ? 1 : 0,
      policeInformationDescription: vehicleInfo.policeInformation,
      vehicleId: vehicleInfo.licensePlate,
      mileage: vehicleInfo.mileage ? vehicleInfo.mileage.replace('.', '') : '', // remove commas from mileage
      opponentCity: thirdPartyInfo.thirdPartyCity,
      opponentEmail: thirdPartyInfo.thirdPartyEmail,
      opponentName: thirdPartyInfo.thirdPartyName,
      opponentNumber: thirdPartyInfo.thirdPartyStreetNum,
      opponentPhone: thirdPartyInfo.thirdPartyPhone,
      opponentStreet: thirdPartyInfo.thirdPartyStreet,
      opponentSurname: thirdPartyInfo.thirdPartySurname,
      opponentVehicleId: thirdPartyInfo.thirdPartyLicensePlate,
      opponentZipcode: thirdPartyInfo.thirdPartyZipCode
    };
    dispatch({ type: damagesConstants.REPORT_DAMAGE_REQUEST, payload: { requestData: request, successCallback: completeDamage } });
  };
  const onNextStep = () => {
    if (step === 4) {
      onSubmit();
      return;
    }
    const stepValidation = validate(step);
    if (!stepValidation.isValid) {
      setValidationErrors(stepValidation);
      return;
    }
    setValidationErrors({});
    setStep(step + 1);
  };

  useEffect(() => {
    if (Object.keys(validationErrors).length) {
      const stepValidation = validate(1);
      if (!stepValidation.isValid) {
        setValidationErrors(stepValidation);
      }
      else {
        setValidationErrors({});
      }
    }
  }, [damageLocation]);

  useEffect(() => {
    if (Object.keys(validationErrors).length) {
      const stepValidation = validate(2);
      if (!stepValidation.isValid) {
        setValidationErrors(stepValidation);
      }
      else {
        setValidationErrors({});
      }
    }
  }, [vehicleInfo]);

  useEffect(() => {
    if (hasThirdParty) {
      if (Object.keys(validationErrors).length) {
        const stepValidation = validate(3);
        if (!stepValidation.isValid) {
          setValidationErrors(stepValidation);
        }
        else {
          setValidationErrors({});
        }
      }
    }
  }, [thirdPartyInfo]);

  useEffect(() => {
    if(termsAccepted) {
      setValidationErrors({});
    }
  }, [termsAccepted]);

  const alertClose = (isVisibleFlag) => {
    setAlertVisible(isVisibleFlag);
    if (isVisibleFlag) {
      cancelAddDamage();
    }
  };

  const onCloseAddDamage = () => {
    setAlertVisible(true);
  };

  const onTermsCheckboxClick = (checked) => {
    setTermsAccepted(checked);
  };

  return (
    <div className={`add-damage-container step-${step}`}>
      {uploadingDamages && <div className={'loading-overlay'}>
        <Spinner />
      </div>}
      {step > 0 &&
        <DamageLocation
          isDisabled={step !== 1}
          stepData={damageLocation}
          profile={profile}
          error={validationErrors}
          onChange={setDamageLocation}
        />}
      {step > 1 &&
        <VehicleInformationComponent
          isDisabled={step !== 2}
          stepData={vehicleInfo}
          onChange={setVehicleInfo}
          profile={profile}
          error={validationErrors} />
      }
      {step > 2 &&
        <VehicleThirdParty
          isDisabled={step !== 3}
          stepData={thirdPartyInfo}
          hasThirdParty={hasThirdParty}
          setHasThirdParty={setHasThirdParty}
          onChange={setThirdPartyInfo}
          error={validationErrors} />
      }
      {step === 4 &&
        <DamageSummaryComponent
          onTermsCheckboxClick={onTermsCheckboxClick}
          termsAccepted={termsAccepted}
          damageLocation={damageLocation}
          thirdParty={{ ...thirdPartyInfo, isThirdPartyInvolved: hasThirdParty }}
          vehicleInfo={vehicleInfo}
          error={validationErrors}
          profile={profile} />
      }
      <div className={`arrow-buttons step-${step}`}>
        {step === 1 ?
              <Button iconOnly icon={<ArrowRightIcon size={45} />} onClick={onNextStep} type={'is-link'} className={'icon-only'}/>
              :
              <TwoSidedButton
                iconLeft={<ArrowLeftIcon size={45} />}
                iconRight={step === 4 ? <SendIcon size={30} /> : <ArrowRightIcon size={45} />}
                textRight={step === 4 ? 'Send' : null}
                typeRight={step === 4 ? 'is-success' : 'is-link'}
                onClickLeft={() => setStep(step - 1)}
                onClickRight={onNextStep}
              />
        }
      </div>
      <div className={`cancel-add-damage-button step-${step}`} >
        <button onClick={onCloseAddDamage}>
          <CloseIcon />
        </button>
      </div>
      <Alert visible={alertVisible} title={t('cancel-add-damage-message')} onClick={alertClose} type={BUTTON_TYPES.SUCCESS} />
    </div>
  );
};
AddDamageContainer.propTypes = {
  cancelAddDamage: PropTypes.func,
  onDamageComplete: PropTypes.func,
};

export default AddDamageContainer;

import React from 'react';
import PropTypes from 'prop-types';

import {DebounceInput} from "react-debounce-input";

/** Load icons **/
import Magnify from "mdi-react/MagnifyIcon";
import Close from "mdi-react/CloseIcon";

/** Load component styles **/
import './SearchBar.styles.scss';
import {t} from "@helpers/i18n";


const SearchBar = ({ search, onSearch, onClose, children }) => {

  const DEBOUNCE_TIMEOUT = 1000;

  return (<div className={'search-bar'}>
    <div className={'search-input'}>
      { search.length ? <Close onClick={onClose} style={{cursor: "pointer"}}/> : <Magnify/>}
      <DebounceInput
        debounceTimeout={DEBOUNCE_TIMEOUT}
        value={search}
        placeholder={`${t('search')}...`}
        onChange={onSearch}
      />
      { children }
    </div>
  </div> );
};

SearchBar.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};

SearchBar.defaultProps = {
  onClose: () => {},
  onSearch: () => {}
};

export default React.memo(SearchBar);

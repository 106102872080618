import React, {Component} from 'react';
import './BlockingOverlay.style.js.scss';

class BlockingOverlayComponent extends Component {
  render() {
    return (
      <div className={'blocker-overlay'} />
    );
  }
}

export default BlockingOverlayComponent;

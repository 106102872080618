import React from 'react';
import classNames from "classnames";
import Icon from './Icon.component';
import PropTypes from "prop-types";


import PersonIcon from "mdi-react/AccountIcon";

import './Avatar.styles.scss';


const Avatar = (props) => {

  const { micro, small, src, className, hasImage = true,  missingIcon, icon, iconBackgroundColor, children } = props;

  const renderImg = () => {
    if (!!src) {
      if (!!src.startsWith("http")) {
        return <img src={src} />;
      }

      return <img src={`data:image/png;base64, ${src}`} />;
    }
    return missingIcon;

  };

  return (<div className={classNames("user-avatar", { [className]: !!className })}>
    <div className={classNames("img-container", { "small": small }, {"micro":micro})}>
      { hasImage && renderImg() }
    </div>
    {
      icon && <Icon iconBackgroundColor={iconBackgroundColor} small={small} icon={icon} />
    }
    { children }
  </div>);
};

Avatar.propTypes = {
  src: PropTypes.string,
  small: PropTypes.bool,
  icon: PropTypes.node,
  iconBackgroundColor: PropTypes.string,
  missingIcon: PropTypes.node
};

Avatar.defaultProps = {
  src: '',
  small: false,
  icon: null,
  iconBackgroundColor: null,
  missingIcon: <PersonIcon />
};

export default Avatar;

import React, {useState} from "react";

import ButtonGroup from "@components/Common/ButtonGroup";
import Grid from "@mui/material/Grid";
import {Spinner} from "@components/Spinner";
import AlertCmp from "@components/Common/Alert";

import WalletIcon from "mdi-react/WalletIcon";
import FileDocumentIcon from "mdi-react/FileDocumentIcon";
import CloseIcon from "mdi-react/AlertCircleOutlineIcon";

import {t} from "@helpers/i18n";
import {isValidHttpUrl} from "@services/utils";
import {Api} from "@api";
import {formatDateFromISO} from "@services/formatters/dailyRent/DailyRentFilter.formatter";
import {formatMileage} from "@services/formatters";

import './DailyRentContract.style.scss';

const DailyRentContract = (props) => {
    const { dailyRentDetail } = props;
    const [loader, setLoader] = useState(false);
    const [pdfNotAvailablePopup, setPdfNotAvailablePopup] = useState(false);
    const [paymentLinkNotAvailablePopup, setPaymentLinkNotAvailablePopup] = useState(false);

    const fetchPdfData = async (id, type, name) => {
        const response = await Api.media.getContractPdf({ id, type, name });
        if (!response?.data?.blob || !response?.data?.name) {
            return null;
        }
        return response.data;
    };

    const handleFailedDownload = () => {
        setLoader(false);
        setPdfNotAvailablePopup(true);
    };

    // Function to handle contract PDF download
    const downloadContractPdf = async () => {
        try {
            setLoader(true);
            if (!dailyRentDetail?.contractId || !dailyRentDetail.contractPDFs.length) {
                handleFailedDownload();
                return;
            }
            const data = await fetchPdfData(
                dailyRentDetail?.contractId,
                'contract_pdf',
                dailyRentDetail.contractPDFs[0].name
            );

            if (data?.blob) {
                const linkSource = `data:application/pdf;base64,${data.blob}`;
                const downloadLink = document.createElement("a");
                const fileName = data.name;
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
                setLoader(false);
            }
            else {
                setPdfNotAvailablePopup(true);
                setLoader(false);
            }
        } catch (e) {
            console.log(e);
        }
    };

    // Function to handle payment link opening
    const payNow = (URL) => {
        if(isValidHttpUrl(URL)){
            window.open(URL, "_blank");
        } else {
            setPaymentLinkNotAvailablePopup(true);
        }
    };

    // Configure buttons with respective actions
    const buttonGroup = [
        {
            label: t('registration-download'),
            icon: <FileDocumentIcon />,
            action: () => downloadContractPdf()
        },
        {
            label: t('dailyRentDetails.onlinePay'),
            icon: <WalletIcon />,
            action: () => payNow(dailyRentDetail?.paymentLink),
        },
    ];

    // Functions to close popups
    const closePdfPopup = () => { setPdfNotAvailablePopup(false); };
    const closePaymentPopup = () => { setPaymentLinkNotAvailablePopup(false); };
    const getSafeValue = (value, fallback = '') => (value !== undefined && value !== null) ? value : fallback;

    const isValidNumber = value => {
        if (value === null || value === undefined || typeof value === 'boolean' || value === '') {
            return false;
        }
        const num = Number(value);
        return !isNaN(num) && typeof num === 'number';
    };

    const currencyFormatter = (currency) => {
        if (currency !== null) {
            const sum = Number(currency).toFixed(2);
            const replacedDecimals = sum.replace('.', ',');
            const formattedCurrency = replacedDecimals.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            return `${ formattedCurrency }`;
        }
        return '-';
    };

    if (!dailyRentDetail || Object.keys(dailyRentDetail).length === 0) {
        return (
            <div>No Contract</div>
        );
    }

    const formattedAddOptions = [
        `• ${t('dailyRentDetails.moreKilometers')} ${
            isValidNumber(dailyRentDetail.excessMileageCost) ? currencyFormatter(dailyRentDetail.excessMileageCost) : '-'
        } ${t('dailyRentDetails.eurKM')}`,
        ...(dailyRentDetail.addOptions?.map(
            (option) =>
                `• ${option.description} \n${isValidNumber(option.amount) ? currencyFormatter(option.amount) : '-'} ${
                    t('dailyRentDetails.eurPerDay')
                }`
        ) || [])
    ].join('\n');

    const mainOption = `• ${t('dailyRentDetails.moreKilometers')} ${
        isValidNumber(dailyRentDetail.excessMileageCost)
            ? currencyFormatter(dailyRentDetail.excessMileageCost)
            : '-'
    } ${t('dailyRentDetails.eurKM')}`;

    const additionalOptions = dailyRentDetail.addOptions
        ? dailyRentDetail.addOptions.map(({ description, amount }, index) => (
            <div className={'single-billing-row'} key={index}>
                <span>{`• ${description} ${isValidNumber(amount) ? currencyFormatter(amount) : '-'} ${t('dailyRentDetails.eurPerDay')}`}</span>
                <span>MISSING VALUE</span>
            </div>
        ))
        : [];

    const leftRowInformation = [
        ['dailyRentDetails.contractNumber', getSafeValue(dailyRentDetail.contractNumber, '-')],
        ['dailyRentDetails.handover', getSafeValue(formatDateFromISO(dailyRentDetail.begin), '-')],
        ['dailyRentDetails.return', getSafeValue(formatDateFromISO(dailyRentDetail.end), '-')],
        ['dailyRentDetails.rentalDays', `${getSafeValue(dailyRentDetail.days, '0')} ${t('days')}`],
        [   'mileage',
            `${dailyRentDetail.excessMileageCost == 0
                ? '∞'
                : getSafeValue(formatMileage(dailyRentDetail.allowedMileageTotal), '0')} ${t('lbl_km')}`
        ],
        [
            'dailyRentDetails.mileagePerDay',
            `${dailyRentDetail.excessMileageCost == 0
                ? '∞'
                : getSafeValue(formatMileage(dailyRentDetail.allowedMileagePerDay), '0')} ${t('lbl_km')}`
        ],
        [
            'dailyRentDetails.handoverLocation',
            `${getSafeValue(dailyRentDetail.handoverLocation?.address_street, '')} ${getSafeValue(
                dailyRentDetail.handoverLocation?.address_number,
                ''
            )} ${getSafeValue(dailyRentDetail.handoverLocation?.address_zipcode, '')} ${getSafeValue(
                dailyRentDetail.handoverLocation?.address_city,
                ''
            )} ${getSafeValue(dailyRentDetail.handoverLocation?.address_country, '').toUpperCase()}`
        ],
        [
            'dailyRentDetails.returnLocation',
            `${getSafeValue(dailyRentDetail.returnLocation?.address_street, '')} ${getSafeValue(
                dailyRentDetail.returnLocation?.address_number,
                ''
            )} ${getSafeValue(dailyRentDetail.returnLocation?.address_zipcode, '')} ${getSafeValue(
                dailyRentDetail.returnLocation?.address_city,
                ''
            )} ${getSafeValue(dailyRentDetail.returnLocation?.address_country, '').toUpperCase()}`
        ]];

    const renderReservationDetails = () => (
        <Grid container>
            <Grid item xs={4}>
                <div className={'reservation-detailes-rows'}>
                    {leftRowInformation.map(([headLine, info], index) => (
                        <div className={'single-information-row'} key={index}>
                            <span>{t(headLine)}:</span>
                            <span>{info}</span>
                        </div>
                    ))}
                </div>
            </Grid>
            <Grid item xs={4}>
                <div className={'reservation-billing-rows'}>
                    <div className='single-billing-row'>
                        <span>Vereinbarte Mietrate {currencyFormatter(dailyRentDetail.dailyRate)} {t('dailyRentDetails.eurPerDay')}</span>
                        <span>MISSING VALUE</span>
                    </div>
                    <div className={'single-billing-row'}>
                        <span>{mainOption}</span>
                        <span>MISSING VALUE</span>
                    </div>
                    <>{additionalOptions}</>
                    <div className={'single-billing-row row-with-border'}>
                        <span>{t('dailyRentDetails.totalRentNet')}:</span>
                        <span>{currencyFormatter(dailyRentDetail.sumWithoutTax)} &euro;</span>
                    </div>
                    <div className={'single-billing-row'}>
                        <span>{t('dailyRentDetails.taxes')}:</span>
                        <span>{currencyFormatter(dailyRentDetail.taxAmount)} &euro;</span>
                    </div>
                    <div className={'single-billing-row row-with-border'}>
                        <span>{t('dailyRentDetails.totalRentGross')}:</span>
                        <span>{currencyFormatter(dailyRentDetail.sumWithTax)} &euro;</span>
                    </div>
                    <div className={'single-billing-row balance'}>
                        <span>{t('dailyRentDetails.outstandingBalance')}:</span>
                        <span>{currencyFormatter(dailyRentDetail.unpaidAmount)} &euro;</span>
                    </div>
                </div>
            </Grid>
            <Grid item xs={4} style={{display: 'flex', justifyContent: 'center'}}>
                <div className="reservation-info">
                    <p><CloseIcon/></p>
                    <div style={{flexDirection:'column'}}>
                        <div style={{fontWeight: 500}}>{t('dailyRentDetails.contractInfoTitle')}</div>
                        <div>{t('dailyRentDetails.contractInfoMessage')}</div>
                    </div>
                </div>
            </Grid>
        </Grid>
    );


    return (
        <div className='reservation-details-container'>
            {loader && <Spinner />}
            <div>{renderReservationDetails()}</div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <ButtonGroup className={'reservation-btn-group'} buttonGroup={buttonGroup} />
            </div>
            <AlertCmp visible={pdfNotAvailablePopup} title={t('report_not_available')} onClick={closePdfPopup} />
            <AlertCmp visible={paymentLinkNotAvailablePopup} title={t('dailyRentDetails.noPaymentLinkTitle')} onClick={closePaymentPopup} />
        </div>
    );
};

export default DailyRentContract;

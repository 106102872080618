import React, { useState } from 'react';
import Alert from '@components/Common/Alert';
import CloseIcon from "mdi-react/CloseIcon";
import { t } from "@helpers/i18n";
import {BUTTON_TYPES} from "@constants";

const TagItem = ({ tag, onRemove }) => {


  const [alertVisible, setAlertVisible] = useState(false);


  const onTagRemoval = () => {
    setAlertVisible(true);
  };

  const onOptionSelected = (selectedOption) => {
    if (selectedOption) {
      onRemove(tag);
    }
    setAlertVisible(false);
  };

  return (<>
    <div className={'tag-item'}>
      <div className="remove-tag" onClick={onTagRemoval}>
        <CloseIcon />
      </div>
      <p>{tag.name} </p>
    </div>
    <Alert visible={alertVisible} title={t('cancel-remove-tag-message')} onClick={onOptionSelected} type={BUTTON_TYPES.SUCCESS} />
  </>
  );
};

export default TagItem;

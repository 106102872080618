import * as damagesConstants from './constants';

const initialState = {
  damages: [],
  loading: false,
  loadingMore: false,
  damageLoading: true,
  lastPage: false,
  reclamationLoading: false,
  reclamationSuccess: false,
  reclamationFailure: false,
  damagesDetailed: [],
  currentDamage:"",
  img: [],
  imgDamage: [],
  imgArray: [],
  incompleteDamages: [],
  damagesForUpload: [],
  uploadingDamages: false,
  imageIsUploading: false
};

const damages = (state = initialState, action) => {
  switch (action.type) {
    case damagesConstants.DAMAGE_GET_REQUEST:
    case damagesConstants.DAMAGE_GET_DEFAULT_PARTLIGHT_PICTURES_REQUEST:
    case damagesConstants.GET_IMG_REQUEST:
    case damagesConstants.GET_IMG_REQUEST_ARRAY:
      return {
        ...state,
        loading: true
      };

    case damagesConstants.REPORT_DAMAGE_REQUEST : return {
      ...state,
      uploadingDamages: true,
    };
    case damagesConstants.SET_CURRENT_DAMAGE:
      return {
        ...state,
        currentDamage: action.payload
      };

    case damagesConstants.CLEAN_CURRENT_DAMAGE:
      return {
        ...state,
        currentDamage: ''
      };

    case damagesConstants.DAMAGE_GET_BY_ID_REQUEST:
      return {
        ...state,
        damageLoading: true
      };

    case damagesConstants.DAMAGE_GET_INCOMPLETE_REQUEST: {
      return {
        ...state,
        incompleteDamages: [],
        damagesForUpload: [],
        uploadingDamages: false
      };
    }
    case damagesConstants.DAMAGE_GET_INCOMPLETE_SUCCESS: {
      return {
        ...state,
        incompleteDamages: action.payload.damageIds,
        damagesForUpload: action.payload.damages,
        uploadingDamages: action.payload.damageIds.length > 0
      };
    }
    case damagesConstants.DAMAGE_GET_INCOMPLETE_FAIL: {
      return {
        ...state,
        incompleteDamages: [],
        damagesForUpload: [],
        uploadingDamages: false
      };
    }

    case damagesConstants.DAMAGE_UPLOAD_IMAGE_INCOMPLETE_REQUEST: {
      return {
        ...state,
        imageIsUploading: true
      };
    }
    case damagesConstants.DAMAGE_UPLOAD_IMAGE_INCOMPLETE_SUCCESS: {
      return {
        ...state,
        damagesForUpload: state.damagesForUpload.map(damage =>
            damage.id === action.payload.damage.id ? {...action.payload.damage, forUpload: true} : damage),
        imageIsUploading: false
      };
    }
    case damagesConstants.DAMAGE_UPLOAD_IMAGE_INCOMPLETE_FAIL: {
      return {
        ...state,
        incompleteDamages: [],
        damagesForUpload: [],
        uploadingDamages: false,
        imageIsUploading: false
      };
    }

    case damagesConstants.DAMAGE_COMPLETE_REQUEST: {
      return {
        ...state
      };
    }
    case damagesConstants.DAMAGE_COMPLETE_SUCCESS: {
      const incompleteIds = state.incompleteDamages.filter(id => id !== action.payload.damage.id);
      return {
        ...state,
        damages: [action.payload.responseDamage, ...state.damages],
        incompleteDamages: incompleteIds,
        damagesForUpload: state.damagesForUpload.filter(damage => damage.id !== action.payload.damage.id),
        uploadingDamages: incompleteIds.length > 0
      };
    }
    case damagesConstants.DAMAGE_COMPLETE_FAIL: {
      return {
        ...state,
        uploadingDamages: false,
      };
    }

    case damagesConstants.DAMAGE_GET_MORE_REQUEST:
      return {
        ...state,
        loadingMore: true
      };
    case damagesConstants.DAMAGE_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        damages: action.payload,
        lastPage: action.payload?.length === 0 || action.payload?.pageTotal - 1 === action.payload?.pageNumber
      };

    case damagesConstants.REPORT_DAMAGE_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case damagesConstants.DAMAGE_GET_MORE_SUCCESS:
      return {
        ...state,
        loadingMore: false,
        damages: [...state.damages, ...action.payload],
        lastPage: action.payload?.length === 0 || action.payload?.pageTotal - 1 === action.payload?.pageNumber
      };

    case damagesConstants.GET_IMG_REQUEST_SUCCESS:
      return {
        ...state,
        img: action.payload
      };

    case damagesConstants.DAMAGE_GET_BY_ID_IMG_DETAILED_SUCCESS:
      return {
        ...state,
        imgDamage: action.payload
      };

    case damagesConstants.DAMAGE_GET_BY_ID_IMG_DETAILED_CLEAR:
      return {
        ...state,
        imgDamage: []
      };

    case damagesConstants.GET_IMG_REQUEST_EMPTY:
      return {
        ...state,
        img: []
      };

    case damagesConstants.GET_IMG_REQUEST_ARRAY_EMPTY:
      return {
        ...state,
        imgArray: []
      };

    case damagesConstants.REMOVE_DAMAGE_SUCCESS:
      return {
        ...state,
        damage: {}
        // damageLoading: true
      };


    case damagesConstants.DAMAGE_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        damage: action.payload,
        currentDamage: action.payload,
        damageLoading: false
      };

    case damagesConstants.GET_IMG_REQUEST_ARRAY_SUCCESS:
      return {
        ...state,
        imgArray: [
          ...state.imgArray.slice(0, action.payload.key),
          action.payload.data.blob,
          ...state.imgArray.slice(action.payload.key),
        ]
      };

    case damagesConstants.DAMAGE_GET_BY_ID_DETAILED_SUCCESS:
      return {
        ...state,
        loading: false,
        damagesDetailed: action.payload
      };

    case damagesConstants.DAMAGE_GET_DEFAULT_PARTLIGHT_PICTURES_SUCCESS:
      return {
        ...state,
        loading: false,
        partlight: action.payload
      };

    case damagesConstants.DAMAGE_GET_FAIL:
    case damagesConstants.GET_IMG_REQUEST_FAIL:
    case damagesConstants.DAMAGE_GET_BY_ID_FAIL:
    case damagesConstants.DAMAGE_GET_BY_ID_DETAILED_FAIL:
    case damagesConstants.DAMAGE_GET_DEFAULT_PARTLIGHT_PICTURES_FAIL:
      return {
        ...state,
        loading: false,
        damageLoading: false
      };

    case damagesConstants.DAMAGE_GET_MORE_FAIL:
      return {
        ...state,
        loadingMore: false
      };
    case damagesConstants.CLEAR_IMG_REQUEST:
      return {
        ...state,
        img: []
      };


    case damagesConstants.SEND_RECLAMATION_RESET:
      return {
        ...state,
        reclamationLoading: false,
        reclamationSuccess: false,
        reclamationFailure: false,
      };
    case damagesConstants.SEND_RECLAMATION_REQUEST:
      return {
        ...state,
        reclamationLoading: true,
        reclamationSuccess: false,
        reclamationFailure: false,
      };
    case damagesConstants.SEND_RECLAMATION_SUCCESS:
      return {
        ...state,
        reclamationLoading: false,
        reclamationSuccess: true,
        reclamationFailure: false,
      };
    case damagesConstants.SEND_RECLAMATION_FAIL:
      return {
        ...state,
        reclamationLoading: false,
        reclamationSuccess: false,
        reclamationFailure: true,
      };


    default:
      return state;
  }
};

export default damages;

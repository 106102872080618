import React from "react";
import EditUserInfo from '@components/User/EditUserInfo/index2';

const EditPersonalInformation = ({profile, loading, onSaveClick, onDeleteClick, isAssign, onToggleChange}) => {
  return (
    <EditUserInfo
      profile={profile}
      loading={loading}
      onSave={(userData) => onSaveClick(userData)}
      onDelete={(userId) => onDeleteClick(userId)}
      isAssign={isAssign}
      onToggleChange={onToggleChange}
    />
  );
};

export default EditPersonalInformation;

import {t} from "@helpers/i18n";
import React from "react";
import './PrivacyPolicy.styles.scss';

const PrivacyPolicy = () => {

  return (<div className="privacy-policy-public">
    <div className="right-title">{t('privacy-header2')}</div>
    <div className="privacy-text">
      <h2>{t("mobile-privacy-header")}</h2>
      <p>{t("privacy-last-change")}</p>
      <h2>{t("privacy-header1")}</h2>
      <p>{t("privacy-text1")}</p>
      <h2>{t("privacy-header2")}</h2>
      <p>{t("privacy-text2")}</p>
      <h2>{t("privacy-header3")}</h2>
      <ul>
        <li>{t("privacy-list1")}</li>
        <li>{t("privacy-list2")}</li>
        <li>{t("privacy-list3")}</li>
      </ul>
      <h2>{t("privacy-header4")}</h2>
      <h2 className="blue">{t("privacy-subheader1")}</h2>
      <p>{t("privacy-text3")}</p>
      <ul>
        <li>{t("privacy-list4")}</li>
        <li>{t("privacy-list5")}</li>
      </ul>
      <h2 className="blue">{t("privacy-subheader2")}</h2>
      <p>{t("privacy-text4")}</p>
      <h2>{t("privacy-header5")}</h2>
      <p>{t("privacy-text5")}</p>
      <h2 className="blue">{t("privacy-subheader3")}</h2>
      <p>{t("privacy-text6")}</p>
      <h2 className="blue">{t("privacy-subheader4")}</h2>
      <p>{t("privacy-text7")}</p>
      <h2 className="blue">{t("privacy-subheader5")}</h2>
      <p>{t("privacy-text8")}</p>
      <h2 className="blue">{t("privacy-subheader6")}</h2>
      <p>{t("privacy-privacy-text9")}</p>
      <h2 className="blue">{t("privacy-subheader7")}</h2>
      <p>{t("privacy-text10")}</p>
      <h2 className="blue">{t("privacy-subheader8")}</h2>
      <p>{t("privacy-text11")}</p>
      <h2>{t("privacy-header6")}</h2>
      <p>{t("privacy-text12")}</p>
      <h2 className="blue">{t("privacy-subheader9")}</h2>
      <p>{t("privacy-text13")}</p>
      <h2>{t("privacy-header7")}</h2>
      <p>{t("privacy-text14")}</p>
      <h2 className="blue">{t("privacy-subheader10")}</h2>
      <p>{t("privacy-text15")}</p>
      <h2 className="blue">{t("privacy-subheader11")}</h2>
      <p>{t("privacy-text16")}</p>
      <h2>{t("privacy-header8")}</h2>
      <h2 className="blue">{t("privacy-subheader12")}</h2>
      <p>{t("privacy-text17")}</p>
      <h2 className="blue">{t("privacy-subheader13")}</h2>
      <p>{t("privacy-text18")}</p>
      <h2 className="blue">{t("privacy-subheader14")}</h2>
      <p>{t("privacy-text19")}</p>
      <h2>{t("privacy-header9")}</h2>
      <p>
        {t("privacy-text20.1")}
        <a target="_blank" href="https://www.datenschutz.rlp.de">{t('privacy-text20.3')}</a>
        {t("privacy-text20.2")}
      </p>
      <h2>{t("privacy-header10")}</h2>
      <p>
        {t("privacy-text21")}
        <a target="_blank" href="mailto:info@fleetlink.de">{t("privacy-text21.1")}</a>
        {t("privacy-text21.2")}
      </p>

      <h2>{t("privacy-header11")}</h2>
      <p>{t("privacy-text22")}</p>
      <p className={'footer'}>
        FLEET-LINK GmbH | In der Illbach 2-4, 56412 Heiligenroth | Amtsgericht Montabaur
        HRB 28147 | Geschäftsführung: Daniel Heinz, Bernhard Reusch | Ust-IdNr.: DE303445240
        Postfach: 220 116, 56544 Neuwied |+49 260 299 999 51 | service@fleetlink.de
      </p>
    </div>
  </div>);
};

export default PrivacyPolicy;

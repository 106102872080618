import {
  CARCHECK_GET_REQUEST,
  CARCHECK_GET_SUCCESS,
  CARCHECK_GET_FAIL,

  CARCHECK_SINGLE_GET_REQUEST,
  CARCHECK_SINGLE_GET_SUCCESS,
  CARCHECK_SINGLE_GET_FAIL,

  CARCHECK_SINGLE_GET_REQUEST_CLEAR,

  CARCHECK_LOAD_MORE_REQUEST,
  CARCHECK_LOAD_MORE_SUCCESS,
  CARCHECK_LOAD_MORE_FAIL
} from "./constants";

const initialState = {
  carChecks: [],
  carCheck: null,
  loadingMore: false,
  singleLoading: false,
  detailedCarChecks: [],
  lastPage: 0,
  loading: false,
  single: [],
  loadingCarCheck: false
};

export default function carchecks(state = initialState, action) {

  switch (action.type) {

    case CARCHECK_GET_REQUEST:
      return {
        ...state,
        loading: true,
        loadingCarCheck: true
      };

    case CARCHECK_LOAD_MORE_REQUEST:
      return {
        ...state,
        loadingMore: true
      };

    case CARCHECK_LOAD_MORE_SUCCESS:
      return {
        ...state,
        loadingMore: false,
        carChecks: [...state.carChecks, ...action.payload],
        lastPage: action.payload.length > 0 ? action.payload[0].pageTotal : 1
      };

    case CARCHECK_LOAD_MORE_FAIL:
      return {
        ...state,
        loadingMore: false
      };

    case CARCHECK_SINGLE_GET_REQUEST:
      return {
        ...state,
        singleLoading: true
      };

    case CARCHECK_SINGLE_GET_SUCCESS:
      return {
        ...state,
        singleLoading: false,
        detailedCarChecks: [...state.detailedCarChecks, action.payload],
        carCheck: action.payload
      };

    case CARCHECK_SINGLE_GET_FAIL:
      return {
        ...state,
        loading: false
      };

    case CARCHECK_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingCarCheck: false,
        carChecks: action.payload,
        lastPage: action.payload.length > 0 ? action.payload[0].pageTotal : 1
      };

    case CARCHECK_SINGLE_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        carChecks: action.payload,
        single: action.payload
      };

    case CARCHECK_SINGLE_GET_REQUEST_CLEAR:
      return {
        ...state,
        loading: false,
        carChecks: []
      };


    case CARCHECK_GET_FAIL:
      return {
        ...state,
        loading: false,
        loadingCarCheck: false
      };

    default:
      return state;
  }
}

import React, {useCallback} from 'react';
/** load components **/
import {useSelector } from "react-redux";

/** load components **/
import AnimatedLoader from "@components/Common/AnimatedLoader";
import VehicleDetails from "@containers/Vehicles/Tabs/VehicleGeneral/VehicleDetails/VehicleDetails.container";

const VehicleGeneral = () => {

  /** Vehicle tabs **/
  const { singleLoading, generalInfo, vehicle } = useSelector((state) => state.vehicles);

  const renderAnimatedLoader = () => {
    return (<AnimatedLoader />);
  };

  const renderDetails = useCallback(() => {
    if(vehicle) return (<VehicleDetails details={vehicle} locationInfo={generalInfo}/>);
  }, [vehicle, generalInfo]);

  return (
    <>
      {singleLoading && renderAnimatedLoader() }
      {(!singleLoading && generalInfo && vehicle ) && renderDetails() }
    </>
  );
};

export default VehicleGeneral;

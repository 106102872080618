import React, {useState, memo} from "react";

/** Loading components **/
import TaskSelector from "@components/TaskSelector";
import Alert from "@components/Common/Alert/Alert.component";

/** Loading SVGs and images **/
import Brakes from "@assets/img/breaks.png";
import Tire from "@assets/img/tire.png";
import Service from "@assets/img/service.png";
import Other from "@assets/img/other.png";
import ArrowLeft from "@assets/svg/arrow-left.svg";

/** Loading constants and helper functions **/
import {BUTTON_TYPES} from "@constants";
import {t} from "@helpers/i18n";

/** Loading MUI components **/
import CloseIcon from "mdi-react/CloseIcon";

/** Loading styles **/
import './AppointmentType.styles.scss';


const AppointmentType = (props) => {

  /**
   * Props extraction and local state
   */
  const { changeType, alertClose, alertVisible, onCloseAppointment } = props;
  const [task, setTask] = useState([]);

  /**
   * If the task array includes the id of the element that was clicked, remove it from the array. Otherwise, add it to the array
   * @param e - the event object
   */
  const selectTask = (e) => {
    if(task.includes(e.currentTarget.id)){
      setTask([...task.filter(x => x !== e.currentTarget.id)]);
    } else{
      setTask([...task, e.currentTarget.id] );
    }
  };

  /** An array of objects that are used to create the buttons in the TaskSelector component. **/
  const appointmentsTypes = [
    { id: 1, label: t("tire-change"), icon: Tire },
    { id: 2, label: t("service_inspection"), icon: Service },
    { id: 3, label: t("brakes"), icon: Brakes },
    { id: 4, label: t("other"),icon: Other },
  ];

  return(
    <div className="create-appointment">
      <div className="right-section">
        <div className="need-done">
          <div  className="need-done_head">
            <p className="need-done__title">{t("appointments")}</p>
            <div className="field-label need-done__subtitle">{t("what-needs-to-be-done")}</div>
            <TaskSelector
              data={appointmentsTypes}
              active={task}
              onClick={selectTask}
            />
          </div>
          <div className={`cancel-add-damage-button step-1`}>
            <button onClick={onCloseAppointment}>
              <CloseIcon/>
            </button>
          </div>
          <Alert visible={alertVisible} title={t('appointment_exit_title')} onClick={alertClose} type={BUTTON_TYPES.SUCCESS}/>
          <div className={'arrow_button'}>
            <button type="submit" className="button button_customer is-link appointment-what-needs" onClick={() => changeType(task)} disabled={!task.length}>
              <img className="button-arrow" src={ArrowLeft} alt="icon"/>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(AppointmentType);

import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';

/** Load components **/
import SelectSearch from '../SearchSelect/SearchSelect.component';


const SelectSearchWrapper = forwardRef((props, ref) => {

  const { apiCall, search, setSearch, inputProps, renderListItem, itemClicked, setSearchTerm, children } = props;

  /**
   * Local state
   */
  const [page, setPage] = useState(0);
  const [options, setOptions] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchOptions = async () => {
    const data = await apiCall({ search: search, page: page });
    const updated = options.concat(data);
    setOptions(updated);
  };

  /**
   * Trigger useEffect every time page changes to load more Drivers
   */
  useEffect(() => {
    if (page !== 0) {
      fetchOptions();
    }
  }, [page]);

  /**
   * Search Driver
   * @param term
   * @returns {Promise<void>}
   */
  const onSearch = async (term) => {
    setSearch(term);
    setDropdownVisible(true);
    setLoading(true);
    const data = await apiCall({ search: term, page: 0 });
    setOptions(data);
    setLoading(false);
    setPage(0);
  };

  /**
   * Dropdown toggle
   */
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  /**
   * Search on focus
   * @returns {Promise<void>}
   */
  const onFocus = async () => {
    if (!options.length) {
      setDropdownVisible(true);
      setLoading(true);
      const data = await apiCall({ search: '', page: 0 });
      setOptions(data);
      setLoading(false);
    } else {
      setDropdownVisible(true);
    }
  };

  /**
   * On Driver clicked
   * @param item
   * @param index
   */
  const itemClick = (item, index) => {
    const { userId, name } = item;
    const searchQuery = setSearchTerm(item);
    setSearch(searchQuery);
    setDropdownVisible(false);
    itemClicked(item, index);
  };

  /**
   * On end reach for Search dropdown list
   */
  const onEndReached = () => {
    setPage((page) => page + 1);
  };

  /**
   * Render list of Drivers
   * @param item
   * @param index
   * @returns {JSX.Element}
   */
  const itemWrapperRender = (item, index) => {
    return (<div key={index} className="select-search-item" onClick={() => itemClick(item, index)}>
      {renderListItem(item, index)}
    </div>);
  };

  /**
   * useImperativeHandle hook for searh Driver
   */
  useImperativeHandle(ref, () => ({
    setSearchParam: (param) => {
      setSearch(param);
    },
    setDropDown: (param) => {
      setDropdownVisible(param);
    }
  }));

  return (
    <div className={'select-wrapper'} ref={ref}>
      <SelectSearch
        search={search}
        onFocus={onFocus}
        onSearch={onSearch}
        options={options}
        dropdownVisible={dropdownVisible}
        toggleDropdown={toggleDropdown}
        renderListItem={itemWrapperRender}
        onEndReached={onEndReached}
        inputProps={inputProps}
        isLoading={loading}
      >{children}
      </SelectSearch>
    </div>
  );
});

export default SelectSearchWrapper;

import { call, put, takeLatest } from "redux-saga/effects";
import { errorHandler } from "@helpers/saga";
import { Api } from "@api";
import * as appointmentConstants from "./constants";
import * as appointmentActions from '@store/appointments/actions';

function* getAppointments(action) {
  try {
    const { userId, page, filter, search, dateFrom, dateTo } = action.payload;

    const { data } = yield call(Api.appointments.getAll,
      { userId, search, page, filter, dateFrom, dateTo });

    yield put(appointmentActions.appointmentsGetSuccess(data));

  } catch (err) {
    yield call(errorHandler, err);
    yield put(appointmentActions.appointmentsGetFail(err));
  }
}

function* loadMoreAppointments(action) {
  try {
    const { userId, search, page, filter, dateFrom, dateTo } = action.payload;

    const { data } = yield call(Api.appointments.getAll,
      { userId, search, page, filter, dateFrom, dateTo });

    yield put(appointmentActions.appointmentsMoreSuccess(data));

  } catch (err) {
    yield call(errorHandler, err);
    yield put(appointmentActions.appointmentsLoadMoreFail(err));
  }
}

function* createAppointment(action) {
  try {
    const {appointment, successCallback} = action.payload;
    const {data} = yield call(Api.appointments.create, appointment);
    yield put(appointmentActions.appointmentCreateSuccess(data));
    yield call(successCallback);

  } catch (err) {
    yield call(errorHandler, err);
    yield put(appointmentActions.appointmentCreateFail());
  }
}

function* cancelAppointment(action) {
  try {
    const {appointmentId, userId, reasonId, onSuccess} = action.payload;
    const {data} = yield call(Api.appointments.cancel, {appointmentId, userId, reasonId});
    yield put(appointmentActions.appointmentCancelSuccess(data));
    yield call(onSuccess);
  } catch (err) {
    yield call(errorHandler, err);
    yield put(appointmentActions.appointmentCancelFail(err));
  }
}

function* loadCalendarAppointments(action) {
  try {
    const { userId, page, filter, search, dateFrom, dateTo } = action.payload;
    const { data } = yield call(Api.appointments.getAll,
      { userId, search, page, filter, dateFrom, dateTo });
    yield put(appointmentActions.calendarAppointmentsSuccess(data));
  } catch (err) {
    yield call(errorHandler, err);
    yield put(appointmentActions.calendarAppointmentsFail(err));
  }
}

export function* appointmentSagas() {
  yield takeLatest(appointmentConstants.APPOINTMENT_GET_ALL_REQUEST, getAppointments);
  yield takeLatest(appointmentConstants.APPOINTMENTS_LOAD_MORE_REQUEST, loadMoreAppointments);
  yield takeLatest(appointmentConstants.APPOINTMENT_CREATE_REQUEST, createAppointment);
  yield takeLatest(appointmentConstants.APPOINTMENT_CANCEL_REQUEST, cancelAppointment);
  yield takeLatest(appointmentConstants.APPOINTMENT_CALENDAR_REQUEST, loadCalendarAppointments);
}

import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {BUTTON_TYPES, damagesConstants} from "@constants";
import { format } from "date-fns";

/** load components **/
import MapContainer from "@containers/Map";
import CardListDamage from "@components/Card/CardListDamage";
import SearchBarFilter from "@components/Common/SearchBar/withFilters";
import DamageFilter from "@components/Damage/Filter";
import AddDamageContainer from "@containers/Damages/AddDamage/AddDamage.container";
import {Button} from "@components/Common/Button";
import MdiPlus from 'mdi-react/PlusIcon';
import {t} from "@helpers/i18n";

import "./DamageList.styles.scss";


const DamageListContainer = () => {
  /**
   * Redux selectors
   */
  const dispatch = useDispatch();
  const { damages, loading, lastPage } = useSelector((state) => state.damages);
  const { auth } = useSelector((state) => state.auth);
  const history = useHistory();
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();

  /**
   * React State
   * filter parameters
   */
  const filters = [
    { id: 0, title: "all", value: true },
    { id: 1, title: "pending", value: true },
    { id: 2, title: "in_process", value: true },
    { id: 3, title: "in_workshop", value: true },
    { id: 4, title: "regulated", value: true },
    { id: 5, title: "completed", value: true },
    { id: 6, title: "missing_data", value: true },
    { id: 7, title: "reclamation", value: true },
    { id: 8, title: "damages_between", value: false },
  ];

  const [filterQuery, setFilterQuery] = useState(``);
  const [damageSearch, setDamageSearch] = useState("");
  const [settings, setSettings] = useState(filters);
  const [page, setPage] = useState(0);
  const [showSettings, setShowSettings] = useState(false);
  const [showAddDamage, setShowAddDamage] = useState(false);

  const initialDamagesFetch = () => {
    setPage(0);
    dispatch({
      type: damagesConstants.DAMAGE_GET_REQUEST,
      payload: {
        userId: auth.user.id,
        page: 0,
        filter: filterQuery,
        search: damageSearch,
      },
    });
  };

  /** Initial Mounted hook
   * fetch car Checks
   **/
  useEffect(() => {
    initialDamagesFetch();
  }, [auth, filterQuery, damageSearch]);

  /**
   * Open Damage on click
   * @param {string} id - id of damage
   **/
  const onDamageCardClick = (id) => {
    const damage = damages.find((damage) => damage.id === id);
    dispatch({
      type: damagesConstants.SET_CURRENT_DAMAGE,
      payload: {
        ...damage,
      },
    });
    history.push(`/damages/${id}/partlight`);
  };
  /**
   * Search Damages
   * @param {string} value - search query
   **/
  const onDamageSearch = (value) => {
    setDamageSearch(value);
  };

  /**
   * On Damage Filter value change
   * @param {string} key - filter key
   **/
  const onSettingsChange = (key) => {
    const newFilter = [...settings];
    let i = 0;
    if (key === "all" && settings[0].value === true) {
      for (let i = 0; i < newFilter.length; i++) {
        newFilter[i].value = false;
      }
    } else if (key === "all" && settings[0].value === false) {
      for (i = 0; i < newFilter.length; i++) {
        newFilter[i].value = true;
      }
    } else {
      for (i = 1; i < newFilter.length; i++) {
        if (newFilter[i].title === key) {
          newFilter[i].value = !newFilter[i].value;
        }
      }
    }
    setSettings(newFilter);
  };

  /**
   * toggle Filter component visibility
   **/
  const onSettingsClick = () => {
    setShowSettings(!showSettings);
  };

  /**
   * It sets the dateFrom or dateTo state variable depending on the name parameter
   * @param name - The name of the date picker.
   * @param date - The date that was selected
   */
  const onDateChange = (name, date) => {
    Object.is(name, 'dateFrom') && setDateFrom(date);
    Object.is(name, 'dateTo') && setDateTo(date);
  };

  /** Preform Damage filtering **/
  const onApplyClick = () => {
    const filtered = [];

    for (let i = 0; i < settings.length - 1; i++) {
      settings[i].value &&
        settings[i].title !== "all" &&
        filtered.push(settings[i].title.toLowerCase());
    }

    if (
      settings[8].value &&
      dateFrom !== undefined &&
      dateFrom !== null &&
      dateTo !== undefined &&
      dateTo !== null
    ) {
      filtered.length &&
        setFilterQuery(
          `&filterState[]=${filtered.join(
            "&filterState[]="
          )}&filterDamageDateFrom=${format(
            dateFrom,
            "yyyy-MM-dd"
          )}&filterDamageDateTo=${format(dateTo, "yyyy-MM-dd")}`
        );
    } else {
      filtered.length &&
        setFilterQuery(`&filterState[]=${filtered.join("&filterState[]=")}`);
    }
  };
  /** Load more car Checks when user scrolls to end of the page **/
  const isEndReached = () => {
    setPage(page + 1);
    dispatch({
      type: damagesConstants.DAMAGE_GET_MORE_REQUEST,
      payload: {
        userId: auth.user.id,
        page: page + 1,
        search: damageSearch,
        filter: filterQuery,
      },
    });
  };
  /** Go to Add Damage Page **/
  const onAddDamage = () => {
    setShowAddDamage(!showAddDamage);
  };

  /** Damage Card List Props **/
  const cardListDamageProps = {
    loading,
    damages,
    damageSearch,
    settings,
    dateFrom,
    dateTo,
    showSettings,
    lastPage,
    page,
    isEndReached,
    onDamageCardClick,
    onDamageSearch,
    onSettingsChange,
    onSettingsClick,
    onApplyClick,
  };

  /** Damage Filter component Props **/
  const damageFilterProps = {
    visible: showSettings,
    filterValues: settings,
    filterChange: onSettingsChange,
    dateFrom: dateFrom,
    dateTo: dateTo,
    onDateChange: onDateChange,
    applyFilter: onApplyClick,
  };

  /** Render Damage List
   *  @return  {<jsx>}
   **/
  const renderDamageList = () => {
    return (
      <div className="list-view">
        <SearchBarFilter
          onSearch={(e) => onDamageSearch(e.target.value)}
          search={damageSearch}
          onFilterPress={onSettingsClick}
          onClose={() => onDamageSearch("")}
          children={<DamageFilter {...damageFilterProps} />}
        />
        <div>
          <CardListDamage {...cardListDamageProps} />
          <Button
            className={'list-add-button'}
            type={BUTTON_TYPES.WARNING}
            icon={<MdiPlus/>}
            text={t("bnt-add-damage")}
            isSmall
            onClick={onAddDamage}
          />
        </div>
      </div>);
  };

  const onCancelAddDamage = () => {
    setShowAddDamage(false);
  };

  const onDamageComplete = () => {
    setShowAddDamage(false);
    initialDamagesFetch();
  };

  /** Add Damage Stepper
   *  @return  {<jsx>}
   **/
  const renderAddDamage = () => {
    return (<AddDamageContainer cancelAddDamage={onCancelAddDamage} onDamageComplete={onDamageComplete}/>);
  };


  return (
    <div className="list-wrapper">
      <MapContainer />
      { !showAddDamage && renderDamageList() }
      { showAddDamage && renderAddDamage() }
    </div>
  );
};

export default DamageListContainer;

import "./index.scss";
import PropTypes from "prop-types";

export const InputList = (props) => {

  const classes = `input-list ${props.class}`;

  return (
    <div className={classes}>
      {props.children}
    </div>
  );
};

InputList.propTypes = {
  class: PropTypes.string,
  // children: PropTypes.object,
};

InputList.defaultProps = {
  class: '',
  children:{}
};



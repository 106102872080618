import {
  LANGUAGE_DEFAULT,
} from "@constants";

import * as authConstants from "@store/auth/constants";

const initialState = {
  language: LANGUAGE_DEFAULT,
};

export default function app(state = initialState, action) {
  switch (action.type) {

    case authConstants.AUTH_LOGOUT_SUCCESS:
      return {
        ...initialState
      };

    default:
      return state;
  }
}

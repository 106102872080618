import { call, put,  all, takeLatest } from "redux-saga/effects";
import { errorHandler } from "@helpers/saga";
import { Api } from "@api";

import * as tripConstants from "./constants";
import * as tripActions from '@store/trips/actions';
import {toast} from "react-toastify";

function* getTrips(action) {
  try {
    const { userId, page, filter, search } = action.payload;

    let query = `${userId}/telemetry?${filter}&page=${page}`;

    query = search ? query.concat(`&search=${search}`) : query;

    const { data } = yield call(Api.trips.getTrips, query);

    yield put(tripActions.tripsGetSuccess(data));
  } catch (err) {
    yield call(errorHandler, err);
    yield put(tripActions.tripsGetFail(err));
  }
}

function* loadMoreTrips(action) {
  try {
    const { userId, page, filter, search } = action.payload;
    let query = `${userId}/telemetry?${filter}&page=${page}`;
    query = search ? query.concat(`&search=${search}`) : query;

    const { data } = yield call(Api.trips.getTrips, query);
    yield put(tripActions.tripsLoadMoreSuccess(data));

  } catch (err) {
    yield call(errorHandler, err);
    yield put(tripActions.tripsLoadMoreFail(err));
  }
}

function* getTripById(action) {
  try {
    const {carId, tripDate} = action.payload;
    const {data} = yield call(Api.trips.getSingleTrip, carId, tripDate || '');
    yield put(tripActions.tripGetByIdSuccess(data));
  } catch (err) {
    yield call(errorHandler, err);
    yield put(tripActions.tripsLoadMoreFail(err));
  }
}

function* updateTrip(action) {
  try {
    const {carId, driver, tripType, trips, tripDate } = action.payload;
    if (trips.length) {
      const reqBody = {};
      if (tripType !== null) {
        reqBody['trip_type'] = tripType || 'unset';
      }
      if (driver !== null) {
        reqBody['trip_driver_id'] = driver.userId || 0;
      }
      yield all(trips.map(tripId => {
        return call(Api.trips.updateSingleTrip, { carId, tripId, data: reqBody });
      }));
    } else {
      const reqBody = {
        date: tripDate,
      };
      if (tripType !== null) {
        reqBody['trip_type'] = tripType || 'unset';
      }
      if (driver !== null) {
        reqBody['trip_driver_id'] = driver.userId || 0;
      }
      const {data} = yield call(Api.trips.updateWholeTrip, { carId, data: reqBody } );
      if (!data.success) {
        toast.error(data.error, { position: "bottom-center" });
      }
      yield put(tripActions.tripUpdateSuccess(data));
    }
    yield put(tripActions.tripGetById({carId, tripDate}));
  } catch (err) {
    yield call(errorHandler, err);
    yield put(tripActions.tripUpdateFail(err));
  }
}


export function* tripSagas() {
  yield takeLatest(tripConstants.TRIPS_GET_REQUEST, getTrips);
  yield takeLatest(tripConstants.TRIPS_LOAD_MORE_REQUEST, loadMoreTrips);
  yield takeLatest(tripConstants.TRIP_GET_BY_ID_REQUEST, getTripById);
  yield takeLatest(tripConstants.TRIP_UPDATE_REQUEST, updateTrip);
}

export const TRIPS_GET_REQUEST = "TRIPS_GET_REQUEST";
export const TRIPS_GET_SUCCESS = "TRIPS_GET_SUCCESS";
export const TRIPS_GET_FAIL = "TRIPS_GET_FAIL";

export const TRIPS_LOAD_MORE_REQUEST = "TRIPS_LOAD_MORE_REQUEST";
export const TRIPS_LOAD_MORE_SUCCESS = "TRIPS_LOAD_MORE_SUCCESS";
export const TRIPS_LOAD_MORE_FAIL = "TRIPS_LOAD_MORE_FAIL";

export const TRIP_GET_BY_ID_REQUEST = "TRIP_GET_BY_ID_REQUEST";
export const TRIP_GET_BY_ID_SUCCESS = "TRIP_GET_BY_ID_SUCCESS";
export const TRIP_GET_BY_ID_FAIL = "TRIP_GET_BY_ID_FAIL";

export const TRIP_UPDATE_REQUEST = "TRIP_UPDATE_REQUEST";
export const TRIP_UPDATE_SUCCESS = "TRIP_UPDATE_SUCCESS";
export const TRIP_UPDATE_FAIL = "TRIP_UPDATE_FAIL";


export const RESET_TRIPS_LIST = 'RESET_TRIPS_LIST';

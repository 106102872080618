import React from "react";
import PropTypes from "prop-types";
/** load components **/
import AppointmentFilterForm from "@components/Appointment/Filter/FilterForm/AppointmentFilterForm.component";
import ApplyButton from "@components/Common/Button/ApplyButton";
/** load services **/
import {AppointmentsFilterFormatter} from "@services/formatters/appointments/AppointmentFilter.formatter";
import {t} from "@helpers/i18n";

const AppointmentFilter = (props) => {

  const { filterValues, visible, filterChanged, applyFilter } = props;

  const filterChange = (e) => {
    const updatedFilters = AppointmentsFilterFormatter(e, filterValues);
    filterChanged(updatedFilters);
  };

  return (
    visible &&
        <div className={'list-filter'}>
          <AppointmentFilterForm
            filters={filterValues}
            onChange={filterChange}
          />
          <ApplyButton onClick={applyFilter} text={t('apply')}>
          </ApplyButton>
        </div>);
};

AppointmentFilter.propTypes = {
  filterValues: PropTypes.array,
  visible: PropTypes.bool,
  filterChanged: PropTypes.func,
  applyFilter: PropTypes.func
};

AppointmentFilter.defaultProps = {
  filterValues: [],
  visible: false,
  filterChanged: () => {},
  applyFilter: () => {}
};

export default AppointmentFilter;

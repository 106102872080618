import * as authConstants from "@store/auth/constants";

export const authLogin = (payload) => ({type: authConstants.AUTH_LOGIN_REQUEST, payload});
export const authLoginSuccess = (payload) => ({type: authConstants.AUTH_LOGIN_SUCCESS, payload});
export const authLoginFail = (payload) => ({type: authConstants.AUTH_LOGIN_FAIL, payload});

export const authLogout = (payload) => ({type: authConstants.AUTH_LOGOUT_REQUEST, payload});
export const authLogoutSuccess = (payload) => ({type: authConstants.AUTH_LOGOUT_SUCCESS, payload});
export const authLogoutFail = (payload) => ({type: authConstants.AUTH_LOGOUT_FAIL, payload});

export const authResetPassword = (payload) => ({type: authConstants.AUTH_RESET_PASSWORD_REQUEST, payload});
export const authResetPasswordSuccess = (payload) => ({type: authConstants.AUTH_RESET_PASSWORD_SUCCESS, payload});
export const authResetPasswordFail = (payload) => ({type: authConstants.AUTH_RESET_PASSWORD_FAIL, payload});

export const authPasswordClear = () => ({ type: authConstants.AUTH_RESET_PASSWORD_CLEAR });

export const authChallengeResetPassword = (payload) => ({type: authConstants.AUTH_CHALLENGE_RESET_PASSWORD_REQUEST, payload});
export const authChallengeResetPasswordSuccess = (payload) => ({type: authConstants.AUTH_CHALLENGE_RESET_PASSWORD_SUCCESS, payload});
export const authChallengeResetPasswordFail = (payload) => ({type: authConstants.AUTH_CHALLENGE_RESET_PASSWORD_FAIL, payload});

import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Grid from '@mui/material/Grid';

import { EmployeeVehicle, EmployeeMap } from '@components/Employee';
import { mileageFormatter } from "@services/formatters";
import * as vehicleActions from '@store/vehicles/actions';

const EmployeeVehicleContainer = () => {
  const { employee = {} } = useSelector(state => state.employees);
  const { generalInfo, vehicle } = useSelector(state => state.vehicles);
  const dispatch = useDispatch();

  const fetchEmployeeVehicle = (carId) => {
    if (carId) {
      dispatch(vehicleActions.loadVehicleLocation({ id: carId }));
      dispatch(vehicleActions.vehicleGeneralInfo({ id: carId }));
    } else {
      dispatch(vehicleActions.loadVehicleLocationFail());
    }
  };

  useEffect(() => {
    if(!vehicle) {
      fetchEmployeeVehicle(employee?.car?.id);
    }
  }, [employee]);

  const employeeVehicleProps = {
    manufacturer: employee?.car.manufacturer,
    location: generalInfo?.place,
    kilometrage: generalInfo?.kilometrage ? `${mileageFormatter(Math.round(generalInfo?.kilometrage))} km` : '',
    fuelLevel: generalInfo?.['fuel-level'] ? `${generalInfo?.['fuel-level']} %` : '',
    velocity: generalInfo?.speed ? `${generalInfo?.speed} km/h` : '',
    file: employee?.car?.id === vehicle?.id ? vehicle?.vehicleRegistrationImages?.[0] : null
  };

  const employeeMapProps = {
    vehicle: employee?.car,
    generalInfo
  };

  return (
    <Grid container spacing={2} style={{ height: '100%' }}>
      <Grid item xs={7}>
        <EmployeeVehicle {...employeeVehicleProps} />
      </Grid>
      <Grid item xs={5}>
        <EmployeeMap {...employeeMapProps} />
      </Grid>
    </Grid>
  );
};

export default EmployeeVehicleContainer;

import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
/** load components **/
import Grid from '@mui/material/Grid';
import VehicleForm from "@components/Vehicle/General/VehicleForm/VehicleForm.component";
import VehicleMap from "@components/Vehicle/VehicleMap/VehicleMap.component";
import SplitButton from "@components/Common/SplitButton";
import SelectDriverCmp from "@components/Common/AddDriver";

import VehicleInfoDTO from "@components/Vehicle/General/dtos/VehicleFormDTO";

import * as vehiclesActions from "@store/vehicles/actions";

import './VehicleDetails.styles.scss';


const VehicleDetails = ({details, locationInfo}) => {
  const [data, setData] = useState(new VehicleInfoDTO(details, locationInfo));
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [buttonEnable, setButtonEnable] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    setData(new VehicleInfoDTO(details, locationInfo));
  }, [details, locationInfo]);


  const onDriverSelected = (userId) => {
    setSelectedDriver(userId);
    setButtonEnable(!!userId);
  };


  const assignDriver = () => {
    if (selectedDriver) {
      const {id} = details; // get carId
      dispatch(vehiclesActions.vehicleAssignDriver({
        driverId: selectedDriver.userId,
        carId: id,
      }));
    }
  };

  const editTags = () => {
    const {id} = details; // get carId
    history.push(`/vehicle/${id}/info/tags`);
  };


  return (
    <Grid container style={{height: '100%'}}>
      <Grid item xs={7}>
        <div className={'details-content h-100 flex flex-col justify-between p-b-2'}  style={{ position: 'relative' }}>
          <div className={'vehicle-form'}>
            <VehicleForm details={data}/>
            <SelectDriverCmp onDriverSelected={onDriverSelected} />
          </div>
          <SplitButton assignDriver={assignDriver} editTags={editTags} disableButton={!buttonEnable}/>
        </div>
      </Grid>
      <Grid item xs={5}>
        <VehicleMap vehicle={details} generalInfo={locationInfo} />
      </Grid>
    </Grid>
  );
};

export default VehicleDetails;

VehicleDetails.propTypes = {
  getDriver: PropTypes.func
};
VehicleDetails.defaultProps ={
  getDriver: () => {}
};

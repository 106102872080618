import React from 'react';
import PropTypes from "prop-types";
/** load icons **/
import BriefcaseBusiness from '@assets/svg/trips/briefcase-with-text.svg';
import AccountStar from '@assets/svg/trips/account-star.svg';

import './TripType.styles.scss';

const tripTypes = {
  business: BriefcaseBusiness,
  private: AccountStar
};


const TripType = (props) => {

  const { currentType , type, onClick } = props;

  return (<button
    className={`trip-type ${currentType === type ? 'active' : ''}`}
    onClick={() => onClick(type)}>
    <img src={tripTypes[type]} alt="briefcase-business" />
  </button>);
};


TripType.propTypes = {
  currentType: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func
};

TripType.defaultProps = {
  currentType: '',
  type: '',
  onClick: () => {}
};

export default TripType;

import React, { useCallback } from "react";
import DamageCard from "../../Damage/Card";
import LazyLoadingList from "@components/LazyLoadingList";
import "./index.scss";
import PropTypes from "prop-types";

const CardListDamage = ({
  loading,
  damages,
  lastPage,
  page,
  isEndReached,
  onDamageCardClick,
}) => {
  /**
   * Render single Car Check card
   * @param {object} damage - carCheck object
   * @param {number} i - index of element
   * @returns {JSX.Element}
   **/
  const renderCardListDamage = (damage, i) => {
    const {
      id,
      appState,
      damageNumber,
      vehicleId,
      userAvatar,
      date,
      driverName,
      driverSurname,
    } = damage;

    return (
      <DamageCard
        key={i}
        state={appState}
        damageNumber={damageNumber}
        vehicleId={vehicleId}
        userAvatar={userAvatar}
        date={date}
        driverName={driverName}
        driverSurname={driverSurname}
        onClick={() => onDamageCardClick(id)}
      />
    );
  };

  /**
   * Render Lazy Loading Card list
   * @returns {JSX.Element}
   **/
  const renderContent = useCallback(() => {
    return (
      <LazyLoadingList
        data={damages || []}
        loading={loading}
        isLastPage={lastPage}
        onEndReached={isEndReached}
        renderItem={renderCardListDamage}
      />
    );
  }, [loading, damages]);

  return <>{renderContent()}</>;
};

export default CardListDamage;

CardListDamage.propTypes = {
  loading: PropTypes.bool, // loading animation toggle
  damages: PropTypes.array, // list of Damages
  page: PropTypes.number, // current page
  lastPage: PropTypes.bool, // damage list last page flag
  isEndReached: PropTypes.func, // callback when end on list is reached
  onDamageCardClick: PropTypes.func, // callback func when damage card is clicked
};

CardListDamage.defaultProps = {
  loading: false,
  carChecks: [],
  page: 0,
  lastPage: false,
  isEndReached: () => {},
};

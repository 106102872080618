import React from 'react';
import PropTypes from "prop-types";

const CarCheckPrimaryContent = ({ name, surname }) => {

  return (
    <div className="primary-text">
      <p>{ name } </p>
      <p>{ surname } </p>
    </div>
  );
};

export default CarCheckPrimaryContent;

CarCheckPrimaryContent.propTypes = {
  name: PropTypes.string,
  surname: PropTypes.string,
};

CarCheckPrimaryContent.defaultProps = {
  name: '',
  surname: '',
};

import { call, put } from "redux-saga/effects";
import { toast } from "react-toastify";
import { push } from 'react-router-redux';
import { t } from "@helpers/i18n";
import store from "store2";

import * as authConstants from "@store/auth/constants";

/** Global error handler when user logs out **/
export function* errorHandler(err) {
  yield call(console.log, err.message);
  // 401 user session expires so he will be logged out
  if (err.message.includes("401")) {
    yield call(toast.error, t('error_session_expired'), { position: "bottom-center" });
    yield put({ type: authConstants.AUTH_LOGOUT_SUCCESS });
    store.set("auth", null);
    store.set('profile', null);
    yield put(push('/'));
  }
}

import Archive from "mdi-react/ArchiveIcon";
import CheckIcon from "mdi-react/CheckIcon";
import Alert from "mdi-react/AlertOutlineIcon";
import React from "react";
import TimerSandIcon from "mdi-react/TimerSandIcon";
import Close from "mdi-react/CloseIcon";

export const getIcon = (param) => {
  switch (param) {
    case 'grey':
      return <Archive/>;

    case 'green':
      return <CheckIcon/>;

    case 'green_tick':
      return <CheckIcon/>;

    case 'red':
      return <Alert/>;

    case 'red_archive':
      return <Alert/>;

    case 'yellow':
      return <Alert/>;

    case 'yellow_warn':
      return <Alert/>;

    default:
      return <Archive/>;
  }
};

export const getColor = (param) => {
  switch (param) {
    case 'grey':
      return 'grey';

    case 'green':
      return 'green';

    case 'green_tick':
      return 'green';

    case 'red':
      return 'red';

    case 'red_archive':
      return 'red';

    case 'yellow':
      return 'yellow';

    case 'yellow_warn':
      return 'yellow';

    case 'green_white':
      return 'green-white';

    default:
      return 'grey';
  }
};

export const getColorCarCheck = (param) => {
  switch (param) {
    case 'green_tick':
      return 'green';

    case 'green':
      return 'green';

    case 'grey':
      return 'grey';

    case 'red':
      return 'red';

    case 'red_cross':
      return 'red';

    case 'yellow_warn':
      return 'yellow';

    case 'red_archive':
      return 'grey';

    default:
      return 'grey';
  }
};

export const getIconCarCheck = (param) => {
  switch (param) {
    case 'grey':
      return <TimerSandIcon/>;

    case 'green':
      return <TimerSandIcon/>;

    case 'green_tick':
      return <CheckIcon/>;

    case 'red':
      return <Close/>;

    case 'red_cross':
      return <Alert/>;

    case 'yellow_warn':
      return <Alert/>;

    case 'red_archive':
      return <Archive/>;

    default:
      return <Archive/>;
  }
};


export default {getColor, getIcon, getIconCarCheck, getColorCarCheck };

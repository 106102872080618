import GasStationIcon from "mdi-react/GasStationIcon";
import Settings from "mdi-react/TuneIcon";
import Close from "mdi-react/CloseIcon";
import WrenchIcon from "mdi-react/BuildIcon";
import PersonIcon from "mdi-react/AccountIcon";
import Magnify from "mdi-react/MagnifyIcon";
import CarIcon from "mdi-react/CarIcon";
import { DebounceInput } from "react-debounce-input";
import StationInfo from "@components/StationInfo";
import ServiceInfo from "@components/ServiceInfo";
import IconSet from "@components/IconSet";
import Station from "./Station/index";
import { t } from "@helpers/i18n";
import Service from "./Service";
import Setting from "./Setting";
import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import Vehicle from "./Vehicle";
import "./MapSidebar.styles.scss";
import {
  MAP_COORDINATES_SET,
  HOVER_MAKER_STATION,
  HOVER_MAKER_SERVICE,
  CLEAR_SEARCH,
  HOVER_MARKER,
  GET_TRIPS_REQUEST,
  SET_CURRENT_TAB,
} from "@constants";
import { useDispatch, useSelector } from "react-redux";
import EmployeeCard from "@components/Employee/Card";
import {DATE_FORMATS} from "@helpers/constants";
import { format } from "date-fns";
import {GET_ACTIVE_VEHICLE_TRIP_REQUSET} from "@store/map/constants";

const MapSidebarContainer = forwardRef((props, ref) => {

  const {
    selectedMarker,
    settings,
    vehicles,
    servicePartners,
    stations,
    employees,
    activeVehicleTrip,
    selectedService,
    selectedStation,
    selectedCar,
    setSelectedStation,
    setSelectedService,
    setSelectedCar
  } = props;

  const dispatch = useDispatch();
  const { trips } = useSelector((state) => state.vehicles);

  const [searchInput, setSearchInput] = useState("");
  const [previousTab, setPreviousTab] = useState(1);
  const [filteredItems, setFilteredItems] = useState({});

  const {
    filteredStations,
    filteredServices,
    filteredVehicles,
    filteredEmployees,
  } = filteredItems;

  const onBackClick = () => {
    setSelectedService(null);
    setSelectedStation(null);
    dispatch({
      type: HOVER_MAKER_SERVICE,
      payload: null,
    });
    dispatch({
      type: HOVER_MAKER_STATION,
      payload: null,
    });
  };

  const onCloseClick = () => {
    setSearchInput("");
    setFilteredItems({});
    dispatch({
      type: CLEAR_SEARCH,
    });
  };

  const onSearchInputChange = (e) => {
    const inputValue = e.target.value.toLowerCase();

    setSearchInput(e.target.value);

    const filteredStations = stations?.filter(
      (station) =>
        station.name.toLowerCase().includes(inputValue) ||
        station.address.toLowerCase().includes(inputValue)
    );

    const filteredServices = servicePartners?.filter(
      (service) =>
        service.title.toLowerCase().includes(inputValue) ||
        service.address.street.toLowerCase().includes(inputValue)
    );

    const filteredVehicles = vehicles?.filter(
      (vehicle) =>
        vehicle?.driver?.name?.toLowerCase().includes(inputValue) ||
        vehicle?.driver?.surname?.toLowerCase().includes(inputValue) ||
        vehicle?.["license-plate"].toLowerCase().includes(inputValue) ||
        vehicle?.manufacturer.toLowerCase().includes(inputValue)
    );

    const filteredEmployees = employees?.filter(
      (employee) =>
        employee.name.toLowerCase().includes(inputValue) ||
          employee.surname.toLowerCase().includes(inputValue) ||
          employee?.car?.licencePlate.toLowerCase().includes(inputValue)
    );

    setFilteredItems({
      filteredServices,
      filteredStations,
      filteredVehicles,
      filteredEmployees,
    });
  };

  const formattedTodayDate = format(new Date(), DATE_FORMATS.full_YMD_dash);

  useImperativeHandle(ref, () => ({
    getRefreshVehicleTrip () {
      if (selectedCar){
        dispatch({type: GET_ACTIVE_VEHICLE_TRIP_REQUSET, payload: { carId: selectedCar, tripDate:formattedTodayDate}});
      }
    }
  }));

  const onCarClick = (vehicle) => {
    dispatch({
      type: MAP_COORDINATES_SET,
      payload: {
        lat: +vehicle?.latitude,
        lng: +vehicle?.longitude,
      },
    });

    dispatch({
      type: GET_TRIPS_REQUEST,
      payload: { id: vehicle.vehicleId },
    });

    if (selectedCar === vehicle.vehicleId) {
      dispatch({
        type: HOVER_MARKER,
        payload: null,
      });
      setSelectedCar(null);
    } else {
      dispatch({
        type: HOVER_MARKER,
        payload: vehicle,
      });

      if (activeVehicleTrip?.vehicleId !== vehicle?.vehicleId) {
        dispatch({
          type: GET_ACTIVE_VEHICLE_TRIP_REQUSET,
          payload: { carId: vehicle?.vehicleId, tripDate:formattedTodayDate}
        });
      }

      setSelectedCar(vehicle.vehicleId);
    }
  };

  const renderCarList = () => {
    if (!vehicles?.length || (filteredVehicles && !filteredVehicles?.length)) {
      return (
        <p className="vehicles-info_zoom">{t("sidebar_list_no_results")}</p>
      );
    }
    if (searchInput.length && filteredVehicles?.length) {
      return (
        <>
          {filteredVehicles.map((vehicle, key) => {
            return (
              <Vehicle
                trips={trips}
                vehicle={vehicle}
                key={key}
                open={selectedCar === vehicle.vehicleId}
                onVehicleClick={(vehicle) => onCarClick(vehicle)}
              />
            );
          })}
        </>
      );
    }
    return (
      <>
        {vehicles?.map((vehicle, key) => {
          return (
            <Vehicle
              trips={trips}
              vehicle={vehicle}
              key={key}
              open={selectedCar === vehicle.vehicleId}
              onVehicleClick={(vehicle) => onCarClick(vehicle)}
            />
          );
        })}
      </>
    );
  };

  const renderEmployeeList = () => {
    if (
      !employees?.length ||
      (filteredEmployees && !filteredEmployees?.length)
    ) {
      return (
        <p className="vehicles-info_zoom">{t("sidebar_list_no_results")}</p>
      );
    }
    if (searchInput.length && filteredEmployees?.length) {
      return (
        <>
          {filteredEmployees.map((employee, key) => {
            return <EmployeeCard employee={employee} key={key} />;
          })}
        </>
      );
    }

    return (
      <>
        {employees?.map((employee, key) => {
          return <EmployeeCard employee={employee} key={key} />;
        })}
      </>
    );
  };

  const onServiceClick = (service) => {
    const { lat, lng } = service.address;

    dispatch({
      type: MAP_COORDINATES_SET,
      payload: { lat: +lat, lng: +lng },
    });

    dispatch({
      type: HOVER_MAKER_SERVICE,
      payload: service,
    });

    setSelectedService(service);
  };

  const onStationClick = (station) => {
    const { latitude, longitude } = station;

    dispatch({
      type: MAP_COORDINATES_SET,
      payload: { lat: +latitude, lng: +longitude },
    });

    dispatch({
      type: HOVER_MAKER_STATION,
      payload: station,
    });
    setSelectedStation(station);
  };

  useEffect(() => {
    if (selectedMarker.type === "service") {
      onServiceClick(selectedMarker.object);
    }

    if (selectedMarker.type === "station") {
      onStationClick(selectedMarker.object);
    }

    if (selectedMarker.type === "vehicles") {
      onCarClick(selectedMarker.object);
    }
  }, [selectedMarker]);

  const renderServiceList = () => {
    if (!!selectedService) {
      return <ServiceInfo service={selectedService} />;
    }

    if (
      !servicePartners?.length ||
      (filteredServices && !filteredServices?.length)
    ) {
      return (
        <p className="vehicles-info_zoom">{t("sidebar_list_no_results")}</p>
      );
    }

    if (searchInput.length && filteredServices?.length) {
      // this should be changed asap
      return (
        <>
          {filteredServices.map((service, key) => (
            <Service
              service={service}
              key={key}
              onServiceClick={onServiceClick}
            />
          ))}
        </>
      );
    }

    return (
      <>
        {servicePartners?.map((service, key) => (
          <Service
            service={service}
            key={key}
            onServiceClick={onServiceClick}
          />
        ))}
      </>
    );
  };

  const renderStationList = () => {
    if (!!selectedStation) {
      return <StationInfo station={selectedStation} />;
    }

    if (!stations?.length || (filteredStations && !filteredStations?.length)) {
      return (
        <p className="vehicles-info_zoom">{t("sidebar_list_no_results")}</p>
      );
    }

    if (searchInput.length && filteredStations?.length) {
      return (
        <>
          {filteredStations.map((station, key) => (
            <Station
              typeFuel={settings.typeFuel}
              station={station}
              key={key}
              onStationClick={onStationClick}
            />
          ))}
        </>
      );
    }

    return (
      <>
        {stations?.map((station, key) => {
          return (
            <Station
              typeFuel={settings.typeFuel}
              station={station}
              key={key}
              onStationClick={onStationClick}
            />
          );
        })}
      </>
    );
  };

  const renderContentTab = () => {
    switch (settings?.currentTab) {
      case 1:
        return renderEmployeeList();
      case 2:
        return renderCarList();
      case 3:
        return renderServiceList();
      case 4:
        return renderStationList();
      case 5:
        return <Setting settings={settings}/>;
      default:
        return <></>;
    }
  };

  const onSettingsClick = () => {
    if (settings.currentTab !== 5) {
      setPreviousTab(settings.currentTab);
      dispatch({
        type: SET_CURRENT_TAB,
        payload: 5,
      });
    } else {
      dispatch({
        type: SET_CURRENT_TAB,
        payload: previousTab,
      });
    }
  };

  const onTabIconClick = (tabNumber) => {
    dispatch({
      type: SET_CURRENT_TAB,
      payload: tabNumber,
    });

    //setSearchInput("");
    setSelectedService(null);
    setSelectedStation(null);

    dispatch({
      type: CLEAR_SEARCH,
    });
  };

  const renderCount = (items) => {
    const itemLength = items?.length;

    return (
      <div className="row">
        <span className="count">{itemLength > 99 ? 99 : itemLength}</span>
        {itemLength > 99 && <span className="arrow">&#8593;</span>}
      </div>
    );
  };

  return (
    <div className="vehicles-info">
      <div className={`tab-${settings?.currentTab}`}>
        <div
          className={
            !selectedStation && !selectedService
              ? "input-field"
              : "input-field none-field"
          }
        >
          {!selectedStation && !selectedService && (
            <>
              <div className="input full">
                <DebounceInput
                  minLength={0}
                  debounceTimeout={500}
                  placeholder={t("placeholder_search")}
                  value={searchInput}
                  onChange={(e) => {
                    onSearchInputChange(e);
                  }}
                />
              </div>
              <Settings
                className={settings?.currentTab === 5 ? "color-settings" : ""}
                onClick={onSettingsClick}
              />
              {!!searchInput?.length ? (
                <Close onClick={onCloseClick} />
              ) : (
                <Magnify />
              )}
            </>
          )}
        </div>
        {settings?.currentTab !== 5 && (
          <IconSet>
            <div className="tab-block">
              <PersonIcon onClick={() => onTabIconClick(1)} />

              {searchInput && renderCount(filteredEmployees)}
            </div>

            <div className="tab-block">
              <CarIcon
                onClick={() => {
                  onTabIconClick(2);
                }}
              />

              {searchInput && renderCount(filteredVehicles)}
            </div>

            <div className="tab-block">
              <WrenchIcon
                className="service-icon"
                onClick={() => {
                  onTabIconClick(3);
                }}
              />

              {searchInput && renderCount(filteredServices)}
            </div>

            <div className="tab-block">
              <GasStationIcon onClick={() => onTabIconClick(4)} />

              {searchInput && renderCount(filteredStations)}
            </div>

            <p
              className={
                !!selectedStation || !!selectedService ? "back" : "back none"
              }
              onClick={onBackClick}
            >
              <span>&larr;</span>Back
            </p>
          </IconSet>
        )}
        {renderContentTab()}
      </div>
    </div>
  );
});

export default MapSidebarContainer;

import React, { useCallback, useState } from 'react';
import GoogleMapReact from 'google-map-react';

import CarMarker from "@assets/svg/map-marker-car.svg";
import MapOverlay from "@components/Common/MapOverlay";
import { DriverPopup } from "@components/Map/DriverPopup";

import './EmployeeMap.styles.scss';

const EmployeeMap = ({
  generalInfo, vehicle
}) => {
  const [hovered, setHovered] = useState(false);

  const vehicleHasLocation = !!(generalInfo && generalInfo.latitude && generalInfo.longitude);

  const getDriverPopupProps = () => {
    return {
      speed: generalInfo.speed,
      username: (vehicle?.driver && vehicle.driver.name && vehicle.driver.surname) ?
        `${vehicle.driver.name} ${vehicle.driver.surname}` : '',
      userCar: `${vehicle?.manufacturer} ${vehicle?.vehicleType}`,
      fuelLevel: generalInfo['fuel-level'],
      customerCity: generalInfo.city,
      customerPlace: generalInfo.place,
      tags: vehicle?.tags
    };
  };

  const renderVehicleMarker = useCallback(() => {
    if (!!generalInfo) {
      return (
        <>
          {hovered &&
            <DriverPopup {...getDriverPopupProps()} />
          }
          <div
            className="icon-background car-icon"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            <img src={CarMarker} alt="marker" />
            <div className="license-plate-icon">{generalInfo['license-plate']}</div>
          </div>
        </>
      );
    }
    return (
      <div className="no-data">
        <div className="no-data-row">
          <img src={Logo} alt="logo" />
        </div>
      </div>
    );
  }, [generalInfo, hovered]);

  const googleMapReactProps = {
    draggable: false,
    defaultZoom: 11,
    center: {
      lat: generalInfo?.latitude || '',
      lng: generalInfo?.longitude || ''
    },
    bootstrapURLKeys: {
      key: process.env.REACT_APP_GOOGLE_MAPS_KEY
    }
  };

  return (
    <div className="map">
      <div className={generalInfo ? "map-row" : "map-row map-row-none"}>
        {(vehicleHasLocation)
          ? <GoogleMapReact {...googleMapReactProps}>
            {renderVehicleMarker()}
          </GoogleMapReact>
          : <MapOverlay />}
      </div>
    </div>
  );
};

export default EmployeeMap;

import React from 'react';
import PropTypes from "prop-types";
/** Load components **/
import Toggler from "@components/Common/Toggler";

/** Load translation helper **/
import { t } from "@helpers/i18n";

const AppointmentFilterForm = (props) => {

  const { filters, onChange } = props;

  return (<div className={'filter-rows'}>
    <div className="toggle">
      {filters.map(filter =>
        <label key={filter.id}>
          <p>{t(filter.filter)}</p>
          <Toggler
            id={filter.id.toString()}
            checked={filter.value}
            onChange={onChange} />
        </label>)}
    </div>
  </div>);
};

AppointmentFilterForm.propTypes = {
  filterValues: PropTypes.array,
  onChange: PropTypes.func
};

AppointmentFilterForm.defaultProps = {
  filterValues: [],
  onChange: () => {}
};

export default AppointmentFilterForm;

import "./index.scss";
import classnames from "classnames";
import PropTypes from "prop-types";
const DEFAULT_LICENSE_PLATE = `-`;

export const LicensePlate = ({ country, license, onClick }) => {

  return (
    <div
      className={classnames(
        "license-plate",
        { "clickable": onClick }
      )}
      onClick={onClick}
    >
      <div className="country-container">
        {country}
      </div>
      <div
        className={classnames("license-container", {"default-license": !license})}>
        { license || DEFAULT_LICENSE_PLATE }
      </div>
    </div>
  );
};

LicensePlate.prototype = {
  country: PropTypes.string,
  license: PropTypes.string,
  onClick: PropTypes.func,
};

LicensePlate.defaultProps = {
  country: "",
  license: "",
  onClick: null,
};

import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DayPicker } from 'react-day-picker';
import PropTypes from "prop-types";

import { de, enGB, bg, pl, tr, ro } from 'date-fns/locale';
import { LANGUAGE_BG, LANGUAGE_DE, LANGUAGE_EN, LANGUAGE_PL, LANGUAGE_RO, LANGUAGE_TR } from '@constants';
import 'react-day-picker/dist/style.css';

const Calendar = (props) => {
  const { modifiers, modifierStyles, modifiersClassNames, mode, range, setRange, onMonthChange, defaultMonth } = props;
  const { profile } = useSelector(state => state.profile);

  const localizationLanguage = useMemo(() => {
    switch (profile.language) {
      case LANGUAGE_EN:
        return enGB;
      case LANGUAGE_DE:
        return de;
      case LANGUAGE_BG:
        return bg;
      case LANGUAGE_PL:
        return pl;
      case LANGUAGE_RO:
        return ro;
      case LANGUAGE_TR:
        return tr;
      default:
        return de;
    }
  }, [profile]);

  return (
    <DayPicker
      mode={mode}
      onMonthChange={onMonthChange}
      defaultMonth={defaultMonth}
      modifiers={modifiers}
      modifiersClassNames={modifiersClassNames}
      modifiersStyles={modifierStyles}
      selected={range}
      onSelect={setRange}
      locale={localizationLanguage}
    />
  );
};

Calendar.propTypes = {
  mode: PropTypes.string, // range of dates
  modifierStyles: PropTypes.object,
  modifiersClassNames: PropTypes.object,
  modifiers: PropTypes.object,
  range: PropTypes.object,
  setRange: PropTypes.func,
  onMonthChange: PropTypes.func,
  defaultMonth: PropTypes.object
};

Calendar.defaultProps = {
  mode: '',
  modifierStyles: {},
  modifiersClassNames: {},
  modifiers: {},
  range: {},
  setRange: () => {},
  onMonthChange: () => {},
  defaultMonth: null
};


export default Calendar;

import React, { useMemo } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as timeTrackingActions from "@store/tracking/actions";
import {format} from "date-fns";
import DATE_FORMATS from "@helpers/constants/dateFormats";
import TimeTrackingWrapper from '../TimeTrackingWrapper';

const TimeTrackingListHolder = () => {
  const dispatch = useDispatch();
  const {trackings = [], loading = false, lastPage = false} = useSelector(state => state.tracking);
  const {auth} = useSelector(state => state.auth);

  /**
   * It takes an object with four properties, and returns a function that takes a dispatch function as an argument
   */
  const filterTracking = ({page, search, dateFrom, dateTo}) => {
    dispatch(timeTrackingActions.timeTrackingGet({
      userId: auth.user.id,
      search: search ? `&search=${search}` : '',
      page: page,
      dateFrom: dateFrom ? `&filterDateFrom=${  format(dateFrom, DATE_FORMATS.full_YMD_dash) }` : '',
      dateTo: dateTo ? `&filterDateTo=${ format(dateTo,  DATE_FORMATS.full_YMD_dash) }` : ''
    }));
  };

  /**
   * It's a function that takes in an object with four properties, and returns a function that dispatches an action with the same four properties
   */
  const isEndReached = ({ page, search, dateFrom, dateTo }) => {
    dispatch(timeTrackingActions.timeTrackingLoadMore({
      userId: auth.user.id,
      search: search ? `&search=${search}` : '',
      page: page,
      dateFrom: dateFrom ? `&filterDateFrom=${ format(dateFrom, DATE_FORMATS.full_YMD_dash) }` : '',
      dateTo: dateTo ? `&filterDateTo=${ format(dateTo, DATE_FORMATS.full_YMD_dash) }` : ''
    }));
  };

  /* It's a function that takes in an object with four properties, and returns a function that dispatches an action with the same four properties */
  const memorizedTimeTracking = useMemo(() => {
    return (
      <TimeTrackingWrapper
        trackings={trackings}
        loading={loading}
        lastPage={lastPage}
        applyTrackingFilter={filterTracking}
        endReached={isEndReached}
      />
    );
  }, [JSON.stringify(trackings), loading, lastPage]);

  return (
    <>
      {memorizedTimeTracking}
    </>
  );
};

export default React.memo(TimeTrackingListHolder);

import React from "react";
import SimpleCard from "@components/Card/SimpleCard";
import Avatar from "@components/User/Avatar";
import classnames from "classnames";
import {formatDateFromISO} from "@services/formatters/dailyRent/DailyRentFilter.formatter";
import {t} from "@helpers/i18n";
import './DailyRentCard.styles.scss';

const DailyRentCard = props => {
  const { rent, onRentClick } = props;
  const {vehicle, begin, end, sumWithTax, state, reservationId, contractId, hasReservation} = rent;
  const {carAvatar = '', vehicleClass, vehicleType} = vehicle;

  function trimString(str, maxLength = 50) {
    if (str.length <= maxLength) {
      return str;
    }
    return `${str.substring(0, maxLength - 3)  }...`;
  }

  const renderLeft = () =>
    <Avatar
      src={carAvatar}
      iconBackgroundColor={'transparent'}
      small
    />;

  const renderPrimaryContent = () =>
    <div>
      <p>{vehicleClass}</p>
      <p>{trimString(vehicleType, 15)}</p>
    </div>;

  const renderRight = () =>
    <>
      <p>{formatDateFromISO(begin)}</p>
      <p>{formatDateFromISO(end)}</p>
      <p className={'rentalSum'}>{`${ sumWithTax ?? '-' } \u20AC` ?? ''}</p>
      <p className={'rentalTax'}>({t('dailyRentDetails.includeVat')})</p>
    </>;

  return (
    <article
      className={classnames('invoice-card',
        { "rental_border_red": state === 'cancelled',
          "rental_border_green": state === 'signed',
          "rental_border_yellow": state === 'reserved',
          "rental_border_grey": state === 'archived'
        }
      )}
    >
      <SimpleCard
        primaryContent={renderPrimaryContent}
        renderLeft={renderLeft}
        renderRight={renderRight}
        onClick={() => onRentClick(reservationId || contractId, hasReservation ? 'reservation': 'contract')}
      />
    </article>
  );
};

export default React.memo(DailyRentCard);

import React from "react";
import {DATE_FORMAT_DMY_DOT, dateFormatter} from "@services/formatters/util/DateTime.formatters";
import {extractTime, toHoursMinSec} from "@helpers/common/tracking";
import PropTypes from "prop-types";
import './TimeTrackingHeader.styles.scss';

const TimeTrackingHeader = (props) => {

  const {date, begin, end, seconds, inProgress} = props;
  const timeForTimeTrackingInProgress = extractTime(begin);
  const timeForTimeTrackingFinished = `${extractTime(begin)} - ${extractTime(end)}`;
  return(
    <div className={'tracking-details-header'}>
      <p>{dateFormatter(date, DATE_FORMAT_DMY_DOT)}</p>
      <p>
        {inProgress ? timeForTimeTrackingInProgress : timeForTimeTrackingFinished}
        <span className={'minutes'}>{toHoursMinSec(seconds)}</span>
      </p>
    </div>
  );
};

TimeTrackingHeader.propTypes = {
  date: PropTypes.string,
  begin: PropTypes.string,
  end: PropTypes.string,
  minutes: PropTypes.number
};

TimeTrackingHeader.defaultProps = {
  date: '',
  begin: '',
  end: '',
  minutes: 0
};

export default TimeTrackingHeader;

import {format, isValid, parseISO} from "date-fns";

/** Daily Rent filter states  **/
export const FILTER_STATES = ['','reserved', 'signed', 'archived', 'cancelled', 'between'];

const DailyRentsFilterFormatter = (e, filters) => {
  const { id, checked } = e.currentTarget;
  let updateFilters = [...filters];
  const filterId = parseInt(id, 10);
  const filterIndex = filters.findIndex((filter) => filter.id === filterId);

  if (filterIndex === 0) {
    updateFilters = updateFilters.map((filter) => ({ ...filter, value: checked }));
  } else {
    updateFilters[filterIndex].value = checked;

    updateFilters[0].value = updateFilters.slice(1, 4).every((filter) => filter.value);
  }

  return updateFilters;
};

/** Helper for Daily Rents List query
 * formats filters, search into query
 * @param {array} filters filter options array
 * **/
const DailyRentsQueryFormatter = (filters) => {
  if (filters[0].value) return '';

  return filters
    .slice(1, 4)
    .reduce(
      (query, filter, index) =>
              filter.value ? `${query}&filterState[]=${FILTER_STATES[index + 1]}` : query, ''
    );
};

function formatDateFromISO(isoDateString) {
  // Ensure the input is a string to prevent errors
  if (typeof isoDateString !== 'string') {
    return '-';
  }

  // Parse the ISO-formatted date string
  const parsedDate = parseISO(isoDateString);

  // Check if the parsed date is valid
  if (!isValid(parsedDate)) {
    return '-';
  }

  // Format the parsed date into the desired format
  return format(parsedDate, 'dd.MM.yyyy | HH:mm');
}

export {
  DailyRentsFilterFormatter,
  DailyRentsQueryFormatter,
  formatDateFromISO
};

import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';


import * as profileActions from '@store/profile/actions';

/** load components **/
import AlertCmp from '@components/Common/Alert';
import AnimatedLoader from '@components/Common/AnimatedLoader';
import Slider from '@components/Common/Slider';
import DriversLicense from './DriversLicense';
import EditDriversLicense from './EditDriversLicense';

/** load services **/
import {
  LicenseFormatter
} from '@services/formatters/profile/license';
import { t } from '@helpers/i18n';
import {media} from '@api/media';

import "./DriversLicenseTab.styles.scss";

const DriversLicenseTab = () => {

  const dispatch = useDispatch();

  const { profile, license, loading } = useSelector(state => state.profile);

  const { auth } = useSelector(state => state.auth);

  const sliderRef = useRef();

  const [slides, setSlides] = useState(LicenseFormatter.licenseImagesSlideFormatter(license));

  const [editMode, setEditMode] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);


  /** update license details **/
  const updateLicense = async (licenseData) => {
    const [frontImg, backImg] = await LicenseFormatter.LicenseImagesFormatter(license, licenseData);
    dispatch(
      profileActions.profileUpdateLicense({
        userId: auth.user.id,
        payload: {
          state: license.state,
          licenceIdentifier: license.licenceIdentifier,
          issueDate: licenseData.issueDate,
          expirationDate: licenseData.expirationDate,
          nextUpload: license.nextUpload,
          birthday: licenseData.birthday,
          name: licenseData.name,
          surname: licenseData.surname,
          id: licenseData.id,
          frontImage: {
            blob: frontImg,
            desc: license.frontImage.desc
          },
          backImage: {
            blob: backImg,
            desc: license.backImage.desc
          },
        }
      })
    );
    dispatch(
      profileActions.profileGet({
        userId: auth.info.id
      })
    );
    setEditMode(false);
  };

  const onSaveClick = (userData) => {
    updateLicense(userData);
    setConfirmPopup(true);
    setSlides(LicenseFormatter.licenseImagesSlideFormatter(license));
  };


  /** preview license front or back image **/
  const onPreviewImage = (index) => {
    sliderRef.current.previewImage(index);
  };

  const loadFullImage = (index) => {
    const imageId = index ? license.backImage.desc :  license.frontImage.desc;
    return media.getFullImage({id: imageId, name: '', type: 'driverlicence' , category:''});
  };


  const onEditClick = () => {
    setEditMode(true);
  };

  const renderLoading = () => (
    <AnimatedLoader />
  );

  const closePopup = () => {
    setConfirmPopup(false);
  };

  const driversLicenseProps = { profile, auth, license, loading, onEditClick, onPreviewImage };
  const editDriversLicenseProps = { profile, license, loading, onSaveClick, onPreviewImage };

  return (
    <>
      {loading && renderLoading()}
      {!loading && !editMode && profile && <DriversLicense {...driversLicenseProps} />}
      {!loading && editMode && <EditDriversLicense {...editDriversLicenseProps} />}
      <AlertCmp
        visible={confirmPopup}
        title={t('profile_updated')}
        onClick={closePopup}
      />
      <Slider
        ref={sliderRef}
        slides={slides}
        setSlides={setSlides}
        imageAPI={loadFullImage}/>
    </>
  );
};

export default DriversLicenseTab;

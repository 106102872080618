import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ReportingCardComponent from '@components/Reporting/ReportingCard/ReportingCard.component';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import {FormControlLabel, Radio, RadioGroup} from '@mui/material';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import {styled} from '@mui/material/styles';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import './OverviewTable.styles.scss';
import ReportingInfoTooltipComponent from '@components/Reporting/ReportingInfoTooltip';
import {currencyFormatter, mileageFormatter} from '@services/formatters';
import {DebounceInput} from 'react-debounce-input';

const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    textAlign: 'right',
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 12,
  }, [`&.${tableCellClasses.body}`]: {
    textAlign: 'right',
    fontSize: 12,
    overflowY: 'auto',
  },
}));
const StyledTableSumCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    textAlign: 'right',
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 14,
    fontWeight: 'bold',
  }, [`&.${tableCellClasses.body}`]: {
    textAlign: 'right',
    fontSize: 14,
    fontWeight: 'bold',
    overflowY: 'auto',
  },
}));
const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  }, // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const OverviewTableComponent = (props) => {

  const {
    titleComponent,
    selectedVehicles,
    onReportTableDataChange,
    chartColors,
    tableData,
    reportConstants,
    getMonthLabel,
    month,
  } = props;

  // first table column headers depending on filter type
  const tableColumns = {
    total: [
      'Fahrzeug', // tablice
      'Einsatztage \n Tage', // broj radnih dana (workingDays)
      'Stundenlohn \n €/h', // satnica (driverHourlyWage)
      'Laufleistung \n km', // kilometraza (drivenKilometers)
      'Verbrauch \n l/100km', // potrosnja (fuelConsumption)
      'Einsatzzeit \n h:m', // radno vreme (workingTime)
      'Kraftstoff \n €', // gorivo (fuelCost)
      'Fahrer \n €', // vozac (driverCosts)
      'Fahrzeug \n €', // vozilo (carCosts)
      'Admin \n €', // adnim (adminCosts)
      'Schäden & Sonstiges \n €', // ostecenja i drugo (damageCost)
      'KM-Kosten \n €', //km troskovi (kilometerCost)
      'Gesamt \n €', // ukupno
    ], perDay: [
      'Fahrzeug',
      'Einsatztage \n Tage',
      'Stundenlohn \n €/h',
      'Laufleistung \n km/Tag',
      'Verbrauch \n l//Tag',
      'Einsatzzeit \n h/Tag',
      'Kraftstoff \n €/Tag',
      'Fahrer \n €/Tag',
      'Fahrzeug \n €/Tag',
      'Admin \n €/Tag',
      'Schäden & Sonstiges \n €/Tag',
      'KM-Kosten \n €/Tag',
      'Gesamt \n €/Tag',
    ], perKm: [
      'Fahrzeug',
      'Einsatztage \n Tage',
      'Stundenlohn \n €/h',
      'Laufleistung \n km',
      'Verbrauch \n l/km',
      'Einsatzzeit \n min/km',
      'Kraftstoff \n €/km',
      'Fahrer \n €/km',
      'Fahrzeug \n €/km',
      'Admin \n €/km',
      'Schäden & Sonstiges \n €/km',
      'KM-Kosten \n €/km',
      'Gesamt \n €/km',
    ],
  };
  // indexes of heading cells that should have tooltip button in them
  const headerCellsWithTooltip = [1, 2, 9];
  const priceTooltipText = "Der Krafstoffpreis wird aus dem durchschnittl. Kraftstoffpreis in Ihrer Fahrzeug-Region berechnet. Die Preisangaben sind Netto pro Liter.";
  const radioTooltipText = "Bitte geben Sie hier die Berechnungsmethode an um die Fahrerkosten zu ermitteln.";
  const tableHeaderTooltipsText = {
    1: 'Für eine Neuberechnung der Werte, löschen Sie bitte die Werte aus dem/n Feld/ern.',
    2: 'Der Stundenlohn basiert auf dem Durchschnitts-Gehalt (z.B. 8,50 €) in Ihrer Region, inklusive aller Sozialabgeben und einem kalkulatoririschen Wert für Urlaub, Krankheit und Ausfall. Für eine individuelle Analyse tragen Sie bitte einen Wert ein.',
    9: 'Die Kosten, die für die Administration des Fahrzeuges pro Monat anfallen',
  };

  const [fuelPrice, setFuelPrice] = useState(reportConstants?.fuelPrice);
  const [editableTableData, setEditableTableData] = useState(tableData);
  const [cost, setCost] = useState('overall');
  const [summedTableData, setSummedTableData] = useState({});
  // filters for first data table
  const [tableFilter, setTableFilter] = useState('total');

  /**
   * Gets fuel price from constants
   */
  useEffect(() => {
    setFuelPrice(reportConstants?.fuelPrice);
  }, [reportConstants]);


  useEffect(() => {
    const totalData = {
      workingDays: 0,
      driverHourlyWage: 0,
      drivenKilometer: 0,
      fuelConsumption: 0,
      workingTime: 0,
      fuelCost: 0,
      driverOverallCost: 0,
      carCost: 0,
      adminCosts: 0,
      damageCost: 0,
      kilometerCost: 0,
      driverOverallCostCalculated: 0,
    };
    selectedVehicles.forEach((vehicleLicencePlate) => {
      Object.keys(tableData[vehicleLicencePlate]).map(key => {
        totalData[key] += +tableData[vehicleLicencePlate][key];
      });
    });
    setSummedTableData(totalData);
  }, [selectedVehicles, tableData]);

  /**
   * Update editable data on table data change
   */
  useEffect(() => {
    setEditableTableData(tableData);
  }, [tableData]);


  const formatMileageData = (data) => {
    return mileageFormatter(Math.round(data));
  };

  /**
   * Parses data according to the filters
   * @param value
   * @param vehicleData
   * @param {'mileage' | 'currency' | null} formatterType
   * @returns {string|*}
   */
  const calculatedValue = (value, vehicleData, formatterType) => {
    let calculatedData;
    const numericalValue = +value || 0;

    // calculate data in relation to filter
    switch (tableFilter) {
      case 'total':
        calculatedData = numericalValue?.toFixed(2);
        break;
      case 'perDay':
        calculatedData = (numericalValue / (vehicleData?.workingDays ? vehicleData.workingDays : 1)).toFixed(2);
        break;
      case 'perKm':
        calculatedData = (numericalValue / (vehicleData?.drivenKilometer ? vehicleData.drivenKilometer : 1)).toFixed(2);
        break;
      default:
        calculatedData = numericalValue?.toFixed(2);
    }

    if (!formatterType) {
      return calculatedData;
    }

    // format data before return
    if (formatterType === 'mileage') {
      return formatMileageData(calculatedData);
    }
    else {
      return currencyFormatter(+calculatedData);
    }
  };

  const calculateTotalSum = (columnData) => {
    const {carCost,
      adminCosts,
      damageCost,
      kilometerCost,
      driverOverallCostCalculated} = columnData;

    return (+carCost) + (+adminCosts) + (+damageCost) + (+kilometerCost) + (+driverOverallCostCalculated);
  };

  /**
   * Formats numbers below 10 to two digits
   * @param number
   * @returns {number|string}
   */
  const forceTwoDigitNumber = (number) => {
    return number > 10 ? Math.floor(number) : (`0${Math.floor(number)}`).slice(-2);
  };

  /**
   * Formats time given in second to hh:mm format
   * @param timeInSeconds
   * @returns {`${string}:${string}`}
   */
  const getTimeInHours = (timeInSeconds) => {
    const hours = timeInSeconds / 3600;
    const minutes = (timeInSeconds % 3600) / 60;

    return `${forceTwoDigitNumber(hours)}:${forceTwoDigitNumber(minutes)}`;
  };

  /**
   * Formats time given in second to mm:ss format
   * @param timeInSeconds
   * @returns {`${string}:${string}`}
   */
  const getTimeInMinutes = (timeInSeconds) => {
    const minutes = timeInSeconds / 60;
    const seconds = timeInSeconds % 60;

    return `${forceTwoDigitNumber(minutes)}:${forceTwoDigitNumber(seconds)}`;
  };

  /**
   * Renders table headers with tooltip button in it
   * @param columnHeaderLabel
   * @param columnIndex
   * @returns {JSX.Element}
   */
  const renderTableHeaderWithTooltip = (columnHeaderLabel, columnIndex) => {
    const columnHeaderTitleArray = columnHeaderLabel.split('\n');
    return (
      <div className={'tooltip-header'}>
        <p style={{textAlign: 'right'}}>{columnHeaderTitleArray[0]}</p>
        <ReportingInfoTooltipComponent tooltipText={tableHeaderTooltipsText[columnIndex]}/>
        <p style={{textAlign: 'right'}}>{columnHeaderTitleArray[1]}</p>
      </div>
    );
  };


  /**
   * Sends parent component data for update on fuel price and cost base change
   */
  const onGeneralConstantsChange = (event) => {
    let updateData;
    if (event.target.name === 'fuelPrice') {
      setFuelPrice(event.target.value);
      updateData = {
        fuelPrice: event.target.value,
        driverCostCalculationType: cost,
      };
    }
    else {
      setCost(event.target.value);
      updateData = {
        fuelPrice,
        driverCostCalculationType: event.target.value,
      };
    }
    onReportTableDataChange(null, updateData);
  };


  /**
   * Global handler for input fields inside the table
   * @param event
   * @param licencePlate
   * @param vehicleDataRowId
   */
  const handleInputChange = (event, licencePlate, vehicleDataRowId) => {
    setEditableTableData({
      ...editableTableData,
      [licencePlate]: {
        ...editableTableData[licencePlate],
        [event.target.name]: event.target.value,
      },
    });

    const updateData = {
      fuelPrice: fuelPrice,
      driverCostCalculationType: cost,
      id: vehicleDataRowId,
      [event.target.name]: event.target.value,
    };

    onReportTableDataChange(vehicleDataRowId, updateData);
  };

  return (
    <Grid item xs={12}>
      <ReportingCardComponent
        additionalClass={'chart-container'}
        headerContentLeft={titleComponent('Übersicht')}
        headerContentRight={titleComponent(getMonthLabel(month))}>
        <Grid container spacing={3} padding={3}>
          <Grid item xs={8}>
            <div style={{'display': 'flex', 'alignItems': 'center'}}>
              <p><strong>Kraftstoffpreis: </strong></p>
              <FormControl sx={{m: 1, width: '150px'}} variant="outlined">
                <div className={'debounce-input-container'}>
                  <DebounceInput
                    debounceTimeout={500}
                    value={fuelPrice}
                    name={'fuelPrice'}
                    onChange={onGeneralConstantsChange}
                  />
                  <span>€/l</span>
                </div>

              </FormControl>
              <ReportingInfoTooltipComponent tooltipText={priceTooltipText}/>
            </div>
            <div style={{'display': 'flex', 'alignItems': 'center'}}>
              <div style={{'display': 'flex', 'alignItems': 'center'}}>
                <strong>Berechnung der Fahrerkosten: </strong>
                <ReportingInfoTooltipComponent tooltipText={radioTooltipText}/>
              </div>
              <RadioGroup
                row aria-label="cost"
                name="cost" style={{paddingLeft: 10}}
                value={cost} onChange={onGeneralConstantsChange}>
                <FormControlLabel value="overall" control={<Radio/>} label="Gesamtkosten"/>
                <FormControlLabel value="hours" control={<Radio/>} label="Stundenlohn-Basis"/>
              </RadioGroup>
            </div>
          </Grid>
          <Grid
            item xs={4}
            display={'flex'}
            paddingTop={4}
            paddingBottom={1}
            alignItems={'flex-end'}
            justifyContent={'flex-end'}>
            <ToggleButtonGroup
              color="primary"
              value={tableFilter}
              exclusive
              onChange={(e, value) => setTableFilter(value || tableFilter)}
            >
              <ToggleButton value="total">Gesamt</ToggleButton>
              <ToggleButton value="perDay">Ø pro Einsatztag</ToggleButton>
              <ToggleButton value="perKm">Ø pro gefahrene KM</ToggleButton>
            </ToggleButtonGroup>

          </Grid>
        </Grid>

        {tableData && editableTableData &&
          <TableContainer style={{maxHeight: '70%'}}>
            <Table stickyHeader sx={{minWidth: 700}} aria-label="customized table">
              <TableHead>
                <TableRow>
                  {tableColumns[tableFilter].map((column, index) => {
                    if (index === 0) {
                      return (<TableCell
                        key={index.toString()}
                        style={{backgroundColor: 'black', color: 'white'}}>
                        {column}
                      </TableCell>);
                    }
                    if (headerCellsWithTooltip.includes(index)) {
                      return (<TableCell
                        key={index.toString()}
                        style={{backgroundColor: 'black', color: 'white'}}>
                        {renderTableHeaderWithTooltip(column, index)}
                      </TableCell>);
                    }
                    return (<StyledTableCell key={index.toString()}>{column}</StyledTableCell>);
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedVehicles.map((vehicleLicencePlate, index) => (
                  <StyledTableRow key={vehicleLicencePlate}>
                    <TableCell>
                      <div
                        className={'licence-plate-table-cell'}
                        style={{borderBottom: `4px solid ${chartColors[index % chartColors.length]}`}}>
                        <strong>{vehicleLicencePlate}</strong>
                      </div>
                    </TableCell>
                    <StyledTableCell align="right">
                      {tableFilter === 'perDay' ?
                        editableTableData[vehicleLicencePlate]?.workingDays
                        :
                        <DebounceInput
                          debounceTimeout={500}
                          type={'number'} onChange={e => handleInputChange(e, vehicleLicencePlate, tableData[vehicleLicencePlate]?.id)} name={'workingDays'}
                          className={'table-input'} value={editableTableData[vehicleLicencePlate]?.workingDays}/>}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {cost === 'overall' ?
                        calculatedValue(editableTableData[vehicleLicencePlate]?.driverHourlyWage, editableTableData[vehicleLicencePlate], 'currency')
                        :
                        <DebounceInput
                          debounceTimeout={500}
                          type={'number'} onChange={e => handleInputChange(e, vehicleLicencePlate, tableData[vehicleLicencePlate]?.id)} name={'driverHourlyWage'}
                          className={'table-input'} value={calculatedValue(editableTableData[vehicleLicencePlate]?.driverHourlyWage, editableTableData[vehicleLicencePlate], null)}/>}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {calculatedValue(tableData[vehicleLicencePlate]?.drivenKilometer, tableData[vehicleLicencePlate], 'mileage')}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {calculatedValue(tableData[vehicleLicencePlate]?.fuelConsumption, tableData[vehicleLicencePlate], 'currency')}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {tableFilter === 'perKm' ?
                        getTimeInMinutes(calculatedValue(tableData[vehicleLicencePlate]?.workingTime, tableData[vehicleLicencePlate], null))
                        :
                        getTimeInHours(calculatedValue(tableData[vehicleLicencePlate]?.workingTime, tableData[vehicleLicencePlate], null))
                      }
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {calculatedValue(tableData[vehicleLicencePlate]?.fuelCost, tableData[vehicleLicencePlate], 'currency')}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {tableFilter === 'total' && cost === 'overall' ?
                        <DebounceInput
                          debounceTimeout={500}
                          type={'number'} onChange={e => handleInputChange(e, vehicleLicencePlate, tableData[vehicleLicencePlate]?.id)} name={'driverOverallCost'}
                          className={'table-input'} value={editableTableData[vehicleLicencePlate]?.driverOverallCost}/>
                        :
                        editableTableData[vehicleLicencePlate]?.driverOverallCost}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {calculatedValue(tableData[vehicleLicencePlate]?.carCost, tableData[vehicleLicencePlate], 'currency')}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {tableFilter === 'total' ?
                        <DebounceInput
                          debounceTimeout={500}
                          type={'number'} onChange={e => handleInputChange(e, vehicleLicencePlate, tableData[vehicleLicencePlate]?.id)} name={'adminCosts'}
                          className={'table-input'} value={editableTableData[vehicleLicencePlate]?.adminCosts}/>
                        :
                        editableTableData[vehicleLicencePlate]?.adminCosts}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {calculatedValue(tableData[vehicleLicencePlate]?.damageCost, tableData[vehicleLicencePlate], 'currency')}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {calculatedValue(tableData[vehicleLicencePlate]?.kilometerCost, tableData[vehicleLicencePlate], 'currency')}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {calculatedValue(calculateTotalSum(tableData[vehicleLicencePlate]), tableData[vehicleLicencePlate], 'currency')}
                    </StyledTableCell>
                  </StyledTableRow>))}
                <StyledTableRow>
                  <TableCell>
                    <div
                      className={'licence-plate-table-cell'}>
                      <strong>Gesamt</strong>
                      <p>{selectedVehicles.length} Fahrzeuge</p>
                    </div>
                  </TableCell>
                  <StyledTableSumCell align="right">
                    {summedTableData.workingDays}
                  </StyledTableSumCell>
                  <StyledTableSumCell align="right">

                  </StyledTableSumCell>
                  <StyledTableSumCell align="right">
                    {calculatedValue(summedTableData?.drivenKilometer, summedTableData, 'mileage')}
                  </StyledTableSumCell>
                  <StyledTableSumCell align="right">
                    {calculatedValue(summedTableData?.fuelConsumption, summedTableData, 'currency')}
                  </StyledTableSumCell>
                  <StyledTableSumCell align="right">
                    {tableFilter === 'perKm' ?
                      getTimeInMinutes(calculatedValue(summedTableData?.workingTime, summedTableData, null))
                      :
                      getTimeInHours(calculatedValue(summedTableData?.workingTime, summedTableData, null))
                    }
                  </StyledTableSumCell>
                  <StyledTableSumCell align="right">
                    {calculatedValue(summedTableData?.fuelCost, summedTableData, 'currency')}
                  </StyledTableSumCell>
                  <StyledTableSumCell align="right">
                    {summedTableData?.driverOverallCost}
                  </StyledTableSumCell>
                  <StyledTableSumCell align="right">
                    {calculatedValue(summedTableData?.carCost, summedTableData, 'currency')}
                  </StyledTableSumCell>
                  <StyledTableSumCell align="right">
                    {currencyFormatter(+summedTableData?.adminCosts)}
                  </StyledTableSumCell>
                  <StyledTableSumCell align="right">
                    {calculatedValue(summedTableData?.damageCost, summedTableData, 'currency')}
                  </StyledTableSumCell>
                  <StyledTableSumCell align="right">
                    {calculatedValue(summedTableData?.kilometerCost, summedTableData, 'currency')}
                  </StyledTableSumCell>
                  <StyledTableSumCell align="right">
                    {calculatedValue(calculateTotalSum(summedTableData), summedTableData, 'currency')}
                  </StyledTableSumCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>}
      </ReportingCardComponent>
    </Grid>
  );
};

OverviewTableComponent.propTypes = {
  chartColors: PropTypes.array.isRequired,
  titleComponent: PropTypes.func.isRequired,
  onReportTableDataChange: PropTypes.func.isRequired,
  selectedVehicles: PropTypes.array.isRequired,
  tableData: PropTypes.object.isRequired,
  reportConstants: PropTypes.object.isRequired,
  getMonthLabel: PropTypes.func.isRequired,
  month: PropTypes.object.isRequired,
};

export default OverviewTableComponent;

import "./index.scss";
import classnames from "classnames";
import PropTypes from "prop-types";

export const ButtonList = (props) => {

  const { column } = props;

  return (
    <div className={classnames("button-list", {
      buttonColumn: column
    })}>
      {props.children}
    </div>
  );
};

ButtonList.propTypes = {
  column: PropTypes.bool,
};

ButtonList.defaultProps = {
  column: false,
};

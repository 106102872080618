import React from 'react';
import PropTypes from 'prop-types';

/** Load translation helper **/
import { t } from "@helpers/i18n";

/** load components **/
import ApplyButton from "@components/Common/Button/ApplyButton";
import TicketFilterForm from "./FilterForm";


const TicketFilter = (props) => {

  const { visible,
    filterValues,
    filterChange,
    applyFilter,
    dateFrom,
    dateTo,
    onDateChange } = props;

  return (
    visible &&
        <div className={'list-filter'}>
          { /** Filter form **/}
          <TicketFilterForm
            filters={filterValues}
            onChange={(filterTitle)=> filterChange(filterTitle)}
            onDateChange={onDateChange}
            dateTo={dateTo}
            dateFrom={dateFrom}
          />

          <ApplyButton onClick={applyFilter} text={t('apply')}>
          </ApplyButton>
        </div>);
};

export default TicketFilter;

TicketFilter.propTypes = {
  visible: PropTypes.bool,
  filterValues: PropTypes.array,
  applyFilter: PropTypes.func,
  onDateChange: PropTypes.func
};

TicketFilter.defaultProps = {
  visible: false,
  filterValues: [],
  applyFilter: () => {},
  onDateChange: () => {}
};

import { call, put, takeLatest } from "redux-saga/effects";
import { errorHandler } from "@helpers/saga";
import {invoiceDetailGetFail, invoiceDetailGetSuccess} from "@store/invoices/actions";
import * as invoicesConstants from "./constants";
import * as invoiceActions from '@store/invoices/actions';
import { Api } from "@api";

function* getInvoices(action) {
  try {
    const { userId, page, filter, search, dateFrom, dateTo, filterContractId = '', filterReservationId = '' } = action.payload;
    const { data } = yield call(Api.invoices.getInvoices,
      { userId, search, page, filter, dateFrom, dateTo, filterContractId, filterReservationId });

    yield put(invoiceActions.invoiceGetSuccess(data));

  } catch (err) {
    yield call(errorHandler, err);
    yield put(invoiceActions.invoiceGetFail(err));
  }
}

function* loadMoreInvoices(action) {
  try {
    const { userId, page, filter, search, dateFrom, dateTo, filterContractId = '', filterReservationId = '' } = action.payload;
    const { data } = yield call(Api.invoices.getInvoices,
      { userId, search, page, filter, dateFrom, dateTo, filterContractId, filterReservationId });

    yield put(invoiceActions.invoiceGetMoreSuccess(data));

  } catch (err) {
    yield call(errorHandler, err);
    yield put(invoiceActions.invoiceGetMore(err));
  }
}

export function* getInvoiceDetails(action) {
  try {
    const {invoiceId} = action.payload;
    const {data} = yield call(Api.invoices.getInvoiceDetails, invoiceId);
    yield put(invoiceDetailGetSuccess(data));

  } catch (err) {
    yield call(errorHandler, err);
    yield put(invoiceDetailGetFail());
  }
}

export function* invoiceSagas() {
  yield takeLatest(invoicesConstants.INVOICE_GET_REQUEST, getInvoices);
  yield takeLatest(invoicesConstants.INVOICE_GET_MORE_REQUEST, loadMoreInvoices);
  yield takeLatest(invoicesConstants.INVOICE_DETAIL_GET_REQUEST, getInvoiceDetails);
}

/** util func: converts upload file to base64 string **/
export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

/** Active Tab for navigation **/
export const activeTabIndex = (routes, pathname) => {
  return routes.findIndex(route => {
    return pathname === route.path;
  });
};


/**
 * File upload field handler
 * @param fileList - newly uploaded files
 * @param currentValues - previously uploaded files
 * @param setFieldValue - form field value setter
 * @param data - images data
 * @param setSlides - setSlides state setter
 * @param fieldName - field name in form
 */
export const onImageUpload = (fileList, currentValues, setFieldValue, data, setSlides, fieldName) => {

  const fileArray = Array.from(fileList);
  // will compare with current files & return non duplicated images
  const withoutDuplicates = fileArray.filter((file, index) => {
    if (data) {
      const dmgImgs = data.find((dmgImg) => {
        return (dmgImg.name === file.name) && (dmgImg.size === file.size) && (dmgImg.type === file.type);

      });
      return !dmgImgs;
    } else {
      return true;
    }
  });
  setFieldValue(fieldName, [...currentValues, ...withoutDuplicates]);
  const slides = Array([...currentValues, ...withoutDuplicates].length).fill(null);
  setSlides(slides);
};

export const onImageRemove = (index, setFieldValue, images, fieldName, setSlides) => {
  const updatedImages = images.filter((file, fileIndex) => fileIndex !== index);
  setFieldValue(fieldName, updatedImages);
  const slides = Array(updatedImages.length).fill(null);
  setSlides(slides);
};

/**
 * It returns true if the string is a valid HTTP or HTTPS URL, and false otherwise
 * @param string - The string to check.
 * @returns A boolean value.
 */
export const isValidHttpUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};

import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import React, {useCallback, useEffect, useState} from "react";
import * as vehiclesActions from "@store/vehicles/actions";
import * as profileActions from "@store/profile/actions";
import {BUTTON_TYPES} from "@constants";
import {t} from "@helpers/i18n";
import TagOutlineIcon from "mdi-react/TagOutlineIcon";
import MapContainer from "@containers/Map";
import SearchBarFilter from "@components/Common/SearchBar/withFilters";
import VehicleFilter from "@components/Vehicle/Filter";
import LazyLoadingList from '@components/LazyLoadingList';
import VehicleCard from '@components/Vehicle/Card';
import VehicleListEditTagModalComponent from '@components/Vehicle/VehicleListEditTagModal';
import {Button} from "@components/Common/Button";
import {toast} from "react-toastify";
import {Api} from "@api";

import './index.scss';

const VehicleListContainer = () => {
  /**
   * Redux selectors and dispatch
   */
  const {vehicles, loading, lastPage, savingTags = 0} = useSelector((state) => state.vehicles);
  const {auth} = useSelector((state) => state.auth);
  const {tags = []} = useSelector((state) => state.profile);
  const [newTags, setNewTags] = useState([]);
  const dispatch = useDispatch();

  /**
   * Navigation history hook
   */
  const history = useHistory();


  /**
   * React State
   * filter parameters
   */
  const [filterQuery, setFilterQuery] = useState(``);
  const [vehicleSearch, setVehicleSearch] = useState("");
  const [settings, setSettings] = useState([
    {id: 0, title: "all", filter: "all", value: true},
    {id: 1, title: "complete", filter: "filterDataCompleted=1", value: true},
    {id: 2, title: "incomplete", filter: "filterDataCompleted=0", value: true},
  ]);
  const [page, setPage] = useState(0);
  const [showSettings, setShowSettings] = useState(false);
  const [selectedTag, setSelectedTag] = useState('');
  const [showEditTag, setShowEditTag] = useState(false);
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [selectedVehiclesTags, setSelectedVehiclesTags] = useState({});
  const [tagsSubmitted, setTagsSubmitted] = useState(false);

  /**
   * Fetches first page of vehicles
   */
  const fetchVehicleInitial = () => {
    setPage(0);
    dispatch(vehiclesActions.vehicleGet(
      {
        userId: auth.user.id,
        page: 0,
        filter: filterQuery,
        search: vehicleSearch,
      }),
    );
  };


  /**
   * Toggles between edit tag states
   */
  const toggleEditTag = () => {
    setSelectedVehiclesTags([]);
    setSelectedVehicles([]);
    setShowEditTag(!showEditTag);
  };


  /**
   * Fetching vehicles effect
   **/
  useEffect(() => {
    fetchVehicleInitial();
  }, [auth, filterQuery, vehicleSearch]);

  /**
   *
   */
  useEffect(() => {
    dispatch(profileActions.getTagsRequest({userId: auth.user.id}));
  }, []);

  useEffect(() => {
    if (showEditTag && tagsSubmitted && !savingTags) {
      toast.success(t('tags-saved-msg'), {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      setTagsSubmitted(false);
      toggleEditTag();
      fetchVehicleInitial();
    }
  }, [showEditTag, savingTags, tagsSubmitted]);


  /**
   * Search Vehicles
   * @param {string} value - search query
   **/
  const onVehicleSearch = (value) => {
    setVehicleSearch(value);
  };

  /**
   * On Vehicle Filter value change
   * @param {string} key - filter key
   **/
  const onSettingsChange = (key) => {
    const newFilter = [...settings];
    let i = 0;
    if (Object.is(key, "all") && settings[0].value) {
      newFilter.map(filter => filter.value = false);
    }
    else if (Object.is(key, "all") && !settings[0].value) {
      newFilter.map(filter => filter.value = true);
    }
    else {
      for (i = 1; i < newFilter.length; i++) {
        if (Object.is(newFilter[i].title, key)) {
          newFilter[i].value = !newFilter[i].value;
        }
      }
    }
    newFilter[0].value = Object.is(newFilter[1].value, newFilter[2].value) && newFilter[1].value;
    setSettings(newFilter);
  };

  /**
   * toggle Filter component visibility
   **/
  const onSettingsClick = () => {
    setShowSettings(!showSettings);
  };

  /** Preform Vehicles filtering **/
  const onApplyClick = () => {
    if (settings[1].value && !settings[2].value) {
      setFilterQuery(`&${settings[1].filter}`);
    }
    if (!settings[1].value && settings[2].value) {
      setFilterQuery(`&${settings[2].filter}`);
    }
    if (settings[0].value && settings[1].value && settings[2].value) {
      setFilterQuery('');
    }
  };

  /** Load more Vehicles when user scrolls to end of the page **/
  const isEndReached = () => {
    setPage(page + 1);
    dispatch(vehiclesActions.vehicleLoadMore({
      userId: auth.user.id,
      page: page + 1,
      search: vehicleSearch,
      filter: filterQuery,
    }));
  };


  /**
   * Adds tags of newly selected vehicle
   * @param vehicle
   */
  const addVehicleTags = (vehicle) => {
    let selectedTags = {...selectedVehiclesTags};
    vehicle.tags.map(tag => {
      if (Object.keys(selectedTags).includes(tag.name)) {
        // increase number of vehicles tag should be added
        selectedTags[tag.name] = selectedTags[tag.name] + 1;
      }
      else {
        // if tag was not in any of selected vehicles
        selectedTags = {...selectedTags, [tag.name]: 1};
      }
    });
    setSelectedVehiclesTags(selectedTags);
  };

  /**
   * Removes tags of deselected vehicle
   * @param vehicle
   */
  const removeVehicleTags = (vehicle) => {
    const selectedTags = {...selectedVehiclesTags};
    vehicle.tags.map(tag => {
      selectedTags[tag.name] = selectedTags[tag.name] - 1;
    });
    // for tags that should be added to all the selected vehicles, update the value to new count of selected vehicles
    Object.keys(selectedVehiclesTags).map(tag => {
      if (selectedVehiclesTags[tag.name] === selectedVehicles.length) {
        selectedTags[tag.name] = selectedTags[tag.name] - 1;
      }
    });
    setSelectedVehiclesTags(selectedTags);
  };


  /**
   * Handler for vehicle card click in the tag edit mode
   * @param vehicleId
   */
  const selectVehicleForTags = (vehicleId) => {
    if (selectedVehicles.includes(vehicleId)) {
      setSelectedVehicles(selectedVehicles.filter(v_id => v_id !== vehicleId));
      removeVehicleTags(vehicles.find(v => v.id === vehicleId));
      return;
    }
    addVehicleTags(vehicles.find(v => v.id === vehicleId));
    setSelectedVehicles([...selectedVehicles, vehicleId]);
  };


  /**
   * Open Vehicle on click
   * @param {string} id - id of vehicle
   **/
  const onVehicleCardClick = (id) => {
    if (showEditTag) {
      selectVehicleForTags(id);
      return;
    }
    history.push(`/vehicle/${id}/info`);
  };


  /**
   * Checks if vehicle card is selected for tag edit
   * @param vehicleId
   * @returns {boolean}
   */
  const isVehicleSelectedForTags = (vehicleId) => {
    return showEditTag && selectedVehicles.includes(vehicleId);
  };

  /**
   * Renders a vehicle cards for the list
   * @param vehicle
   * @param vehicleIndex
   * @returns {JSX.Element}
   */
  const renderCardListVehicle = (vehicle, vehicleIndex) => {
    return (
      <VehicleCard
        additionalClass={isVehicleSelectedForTags(vehicle.id) ? 'green-card' : ''}
        key={vehicleIndex.toString()}
        vehicle={vehicle}
        onClick={() => onVehicleCardClick(vehicle.id)}/>
    );
  };

  /**
   * Handler for choosing tag from the select
   */
  const onTagSelected = async (tag) => {
    if (tag.id) {
      if (!Object.keys(selectedVehiclesTags).includes(tag.name)) {
        // when adding tag from the select, it should be added to all currently selected vehicles
        // thus it's value in selected vehicle tags object should be number of selected vehicles
        setSelectedVehiclesTags({...selectedVehiclesTags, [tag.name]: selectedVehicles.length});
      }
      setSelectedTag('');
    }
    else {
      const {data} = await Api.vehicles.createUserTag(auth.user.id, [{name: tag}]);
      const [createdTag] = data;
      setSelectedVehiclesTags({...selectedVehiclesTags, [createdTag.name]: selectedVehicles.length});
      // add newly created tag to the list
      setNewTags([createdTag, ...newTags]);
    }
  };

  /**
   * Handler for clicking on the checkbox in the tags checklist
   * @param tag
   */
  const onTagCheckBoxClick = (tag) => {
    const newTagCount = selectedVehiclesTags[tag] < selectedVehicles.length ? selectedVehicles.length : 0;
    setSelectedVehiclesTags({...selectedVehiclesTags, [tag]: newTagCount});
  };

  /**
   * Vehicle Filter component Props
   */
  const vehicleFilterProps = {
    visible: showSettings,
    filterValues: settings,
    filterChange: onSettingsChange,
    applyFilter: onApplyClick,
  };


  /**
   * Saves edited tags
   */
  const saveTagsEdit = () => {
    // merges newly created tags with existing ones
    const allTags = [...newTags, ...tags];

    // gets whole tag object based on a tag name
    const selectedTagsObjects = allTags.filter(tag => Object.keys(selectedVehiclesTags).includes(tag.name));

    // get tags that should be added to all selected vehicles
    const tagsForAllVehicles = selectedTagsObjects.filter(tag => selectedVehiclesTags[tag.name] === selectedVehicles.length);

    // gets the whole vehicle object based on a selected vehicle's id array
    const selectedVehiclesObject = vehicles.filter(v => selectedVehicles.includes(v.id));

    selectedVehiclesObject.map(vehicle => {
      // get tags already in vehicle, that are not unchecked from the tag's list
      const perVehicleTags = vehicle.tags.filter(tag => !!selectedVehiclesTags[tag.name] && selectedVehiclesTags[tag.name] < selectedVehicles.length);
      dispatch(vehiclesActions.saveVehiclesTagsRequest(
        {
          vehicleId: vehicle.id,
          // old tags that are not removed and tags that should be added to the all vehicles
          tags: [...perVehicleTags, ...tagsForAllVehicles].map(tag => ({id: tag.id})),
        }));
    });
    setTagsSubmitted(true);
  };

  /**
   * Props list for the VehicleListEditTagModalComponent
   */
  const editTagModalProps = {
    toggleEditTag,
    setSelectedTag,
    selectedTag,
    tags,
    onTagSelected,
    selectedVehiclesTags,
    onTagCheckBoxClick,
    selectedVehicles,
    saveTagsEdit,
    savingTags,
  };

  const renderVehicleList = () => {
    return (
      <div>
        <LazyLoadingList
          data={vehicles || []}
          loading={loading}
          isLastPage={lastPage === page}
          onEndReached={isEndReached}
          renderItem={renderCardListVehicle}
        />
        <Button
          className={'list-add-button'}
          type={BUTTON_TYPES.WARNING}
          icon={<TagOutlineIcon/>}
          text={t("edit_tags")}
          isSmall
          onClick={toggleEditTag}
        />
      </div>
    );
  };

  const renderEditTagModal = useCallback(() => {
    return <VehicleListEditTagModalComponent {...editTagModalProps} />;
  }, [
    selectedVehiclesTags,
    selectedVehicles,
    setSelectedTag,
    showEditTag,
    selectedTag,
    savingTags,
    newTags,
    tags,
  ]);

  return (
    <div className="list-wrapper">
      <MapContainer/>
      <div className="list-view">
        <SearchBarFilter
          onSearch={(e) => onVehicleSearch(e.target.value)}
          search={vehicleSearch}
          onFilterPress={onSettingsClick}
          onClose={() => onVehicleSearch("")}
          children={<VehicleFilter {...vehicleFilterProps} />}
        />

        {renderVehicleList()}

        {showEditTag && renderEditTagModal()}
      </div>
    </div>
  );
};
VehicleListContainer.propTypes = {};
export default VehicleListContainer;

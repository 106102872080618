import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {t} from "@helpers/i18n";
import {Button} from '@components/Common/Button';
import {BUTTON_TYPES} from '@constants';
import PaperAirplaneIcon from 'mdi-react/PaperAirplaneIcon';
import './TIcketManagementEmail.style.scss';
import {IconButton} from '@mui/material';
import WindowCloseIcon from 'mdi-react/WindowCloseIcon';

const TicketManagementEmailComponent = (props) => {
  const {
    assignee = '',
    currentUser,
    targetEmail = '',
    licencePlate = '',
    driver = {},
    ticketNumber,
    onSubmit,
    onClose
  } = props;


  const preselectedTextAssign = useMemo(() => (
    'Sehr geehrte Damen und Herren,\ndas Fahrzeug mit dem amtl. Kennzeichen: \n' +
    `${ licencePlate } \n` +
    `war zum fraglichen Zeitpunkt dem Fahrer:\n${ driver.name } ${ driver.surname }\n` +
    `${ driver.address_street || '' } ${ driver.address_number || '' }\n` +
    `${ driver.address_zipcode || '' } ${ driver.address_city || '' }\n` +
        `${driver.email}\n`+
    'zur Nutzung überlassen.' +
    `Mit freundlichen Grüßen\n${ currentUser.name } ${ currentUser.surname }`
  ), [licencePlate, driver]);

  const preselectedTextObjection = useMemo(() => (
    `Sehr geehrte Damen und Herren,\n\nhiermit informiere ich Sie, dass ich zum fraglichen Zeitpunkt nicht der Fahrer des Fahrzeugs mit dem amtl.\n` +
    `Kennzeichen:\n${ licencePlate }\nwar.\n\nMit freundlichen Grüßen\n${ currentUser.name } ${ currentUser.surname }`
  ), [licencePlate, currentUser]);

  const [emailFrom, setEmailFrom] = useState(currentUser.email || '');
  const [emailTo, setEmailTo] = useState(targetEmail || '');
  const [subject, setSubject] = useState(assignee ? `Fahrerermittlung ${ticketNumber}` : `Widerspruch ${ticketNumber}`);
  const [text, setText] = useState(assignee ? preselectedTextAssign : preselectedTextObjection);

  const _handleSendOnPress = () => {
    const mailData = {
      emailFrom,
      emailTo,
      subject,
      content: text
    };
    if (assignee) {
      onSubmit({...mailData, assignee});
    } else {
      onSubmit(mailData);
    }
  };

  return (
    <div className={'ticket-email-container'}>
      <div className={'form-close-button'}>
        <IconButton aria-label="upload picture" component="span"
          onClick={onClose}>
          <WindowCloseIcon/>
        </IconButton>
      </div>
      <div className="email-content">
        <div className="email-header-input-container">
          <p>{`${t('from')}:`}</p>
          <input type="email" value={emailFrom} onChange={event => setEmailFrom(event.target.value)} disabled/>
        </div>
        <div className="email-header-input-container">
          <p>{`${t('to')}:`}</p>
          <input type="email" value={emailTo} onChange={event => setEmailTo(event.target.value)} disabled/>
        </div>
        <div className="email-header-input-container">
          <p>{`${t('subject')}:`}</p>
          <input type="text" value={subject} onChange={event => setSubject(event.target.value)}/>
        </div>
        <textarea
          className={'email-body'}
          name="body" id="body"
          cols="70" rows="20"
          value={text}
          onChange={event => setText(event.target.value)}>
        </textarea>
      </div>
      <Button
        className={'send-button'}
        text={t('send')}
        onClick={_handleSendOnPress}
        type={BUTTON_TYPES.SUCCESS}
        icon={<PaperAirplaneIcon color={'black'}/>} />
    </div>
  );
};

TicketManagementEmailComponent.propTypes = {
  assignee: PropTypes.string,
  currentUser: PropTypes.object.isRequired,
  targetEmail: PropTypes.string.isRequired,
  licencePlate: PropTypes.string.isRequired,
  driver: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default TicketManagementEmailComponent;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  PROFILE_GET_REQUEST,
  PROFILE_UPDATE_REQUEST,
  PROFILE_DELETE_REQUEST,
} from "@constants";

/** load components **/
import PersonalInformation from "./PersonalInformation";
import AnimatedLoader from '@components/Common/AnimatedLoader';
import AlertCmp from "@components/Common/Alert";

import { Api } from "@api";
/** load tt tool **/
import { t } from "@helpers/i18n";
import EditProfile from "@components/User/EditUserInfo/EditProfile";

const PersonalInformationTab = () => {
  const dispatch = useDispatch();
  const { profile, loading } = useSelector(state => state.profile);
  const { auth } = useSelector(state => state.auth);

  const [confirmPopup, setConfirmPopup] = useState(false);

  const [editMode, setEditMode] = useState(false);

  const onEditClick = () => {
    if (profile.isUserManagingDirector) {
      setConfirmPopup(true);
      setEditMode(false);
    } else {
      setEditMode(true);
    }
  };
  /**
   * switch to View mode & show confirmation popup
   */
  const showPopup = () => {
    setEditMode(false);
    setConfirmPopup(true);
  };
  /**
   * api call for changing user Avatar
   * @param {number} userId - id of logged user
   * @param {string} avatar - avatar img in base64 format
   */
  const changeAvatar = async (userId , avatar) => {
    await Api.users.changeAvatar({ id: userId, avatar: avatar });
  };
  /**
   * save user Profile
   * @param {object} userData - contains all user data
   */
  const onSaveClick = async (userData) => {
    dispatch({
      type: PROFILE_UPDATE_REQUEST,
      payload: {
        userData: userData,
        callback: showPopup
      }
    });
    await changeAvatar(auth.user.id, userData.avatar);
  };

  /**
   * save user Profile
   * @param {number} userId - userId of logged user
   */
  const onDeleteClick = (userId) => {
    setEditMode(false);
    dispatch({
      type: PROFILE_DELETE_REQUEST,
      payload: {
        userId: userId
      }
    });
    dispatch({
      type: PROFILE_GET_REQUEST,
      payload: {
        userId: userId
      }
    });
    setConfirmPopup(true);
  };

  const onUpdateLogoClick = () => {
    setEditMode(false);
  };

  const renderAnimatedLoader = () => {
    return (<AnimatedLoader />);
  };

  const closePopup = () => {
    setConfirmPopup(false);
  };

  const personalInformationProps = { profile, auth, onEditClick };

  return (
    <>
      {loading && renderAnimatedLoader()}
      {!loading && profile && !editMode && <PersonalInformation {...personalInformationProps} />}
      {!loading && editMode && <EditProfile profile={profile} saveProfile={onSaveClick} />}
      <AlertCmp
        visible={confirmPopup}
        title={profile.isUserManagingDirector ? t('unable_to_update_profile') : t('profile_updated')}
        onClick={closePopup}
      />
    </>
  );
};

export default PersonalInformationTab;

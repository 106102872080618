import SpeedometerIcon from "mdi-react/SpeedometerIcon";
import GasStationIcon from "mdi-react/GasStationIcon";
import Avatar from "@components/User/Avatar";
import {withRouter} from "react-router-dom";
import React from "react";
import "./index.scss";


@withRouter
@connect(state => ({
  state: state,
}))
class DriverPopup extends React.Component {

  static propTypes = {
    username: PropTypes.string,
    userCar: PropTypes.string,
    customerCity: PropTypes.string,
    customerPlace: PropTypes.string,
    speed: PropTypes.number,
    id: PropTypes.number,
    fuelLevel: PropTypes.number,
    tags: PropTypes.array
  };

  static defaultProps = {
    username: "",
    userCar: "",
    customerCity: "",
    customerPlace: "",
    speed: 0,
    fuelLevel: 0,
    tags: []
  };

  get address() {
    return this.props.customerPlace.split(",");
  }

  render() {
    const {
      username,
      userCar,
      customerCity,
      speed,
      fuelLevel,
    } = this.props;


    const getAvatarEmployee = () => {
      let userAvatar = '';
      const { employees } = this.props.state.employees;

      employees.map((el => {
        const userInfo = `${el.name} ${el.surname}`;
        if(userInfo === username){
          userAvatar = el.avatar;
        }
      }));
      return userAvatar;
    };

    const renderTags = () => {
      if (this.props.tags && this.props.tags?.length) {
        return (
          <div className="info-tags">
            {this.props.tags.map((tag) => tag.name).join(" | ")}
          </div>
        );
      }

    };

    return (
      <div className="driver-popup">
        <div className="info-container">
          <div className="info-header">
            <p className="header-name">{username}</p>
            <p className="header-car">{userCar}</p>
            <div className="header-car" style={{ paddingTop: '5px', paddingBottom: '5px'  }}>{renderTags()}</div>
          </div>
          <div className="popup-footer">
            <div className="customer-city">
              {customerCity}
            </div>
            <div className="customer-data">
              <div className="data-line">
                <div className="line-start">{this.address[0]}</div>
                <SpeedometerIcon />
                <div className="line-end">{speed} km/h</div>
              </div>
              <div className="data-line">
                <div className="line-start">{this.address[1]}</div>
                <GasStationIcon />
                <div className="line-end">{fuelLevel}%</div>
              </div>
            </div>
          </div>
          <Avatar
            small
            src={getAvatarEmployee()}
          />
          <div className="triangle" />
        </div>
      </div>
    );
  }
}

export { DriverPopup };


import React from 'react';
/** Load colors **/
import {
  LIGHT_COLOR_RED,
} from "@constants";


/** load icons **/
import FlashAlertIcon from "mdi-react/FlashIcon";
import TireIcon from '../../../../../assets/svg/tire_icon';
/** load mileage formatter **/
import {mileageFormatter} from "@services/formatters";

import { t } from "@helpers/i18n";
import {cleanlinessStates} from "@components/Card/CarCheckCard/Cleanliness/constants";

const CarCheckBaseDetails = ({
  damageCount,
  status,
  name,
  surname,
  comment,
  commentFleetlink,
  isTyreOk,
  mileage}) => {

  const renderCleanlinessIcon = (iconState) => {
    const foundIcon = cleanlinessStates.find((icon) => icon.id === iconState);
    return foundIcon.smallIcon;
  };


  const carCheckCleanliness = status.find(stat => stat.type === 'cleanliness_state');

  return (
    <div className={'row p-10'}>
      { /** left info column **/}
      <div className={'column'}>
        {/** todo if one or more damages were reported
         no damage icon if there was no damage report **/ }
        <div className={'row align-items-center'}>
          <FlashAlertIcon size={16} color={LIGHT_COLOR_RED} />
          <p className={'pl-5'}>{ damageCount } { t('damages') } </p>
        </div>
        <div className={'user'}>
          <p className={'bold'}>{ t('user') }</p>
          <p>{ name } { surname }</p>
        </div>
        {
            carCheckCleanliness ?
                <div className={'row'}>
                  <p className={'bold'}> {t('car_check_cleanliness')} </p>
                  <p className={'pl-5'}>{renderCleanlinessIcon(+carCheckCleanliness.state)}</p>
                </div> : <div>
                  <p className={'bold'}>{t('car_check_cleanliness')} </p>
                  <p> - </p>
                </div>
        }
        <p className={'bold'}>{ t('comment_user') }</p>
        <p> { comment ?  comment : '-' } </p>
      </div>
      { /** right info column **/}
      <div className={'column'}>
        <div className={'row align-items-center'}>
          <TireIcon fill={isTyreOk ? '#95C11E' : 'red'}/>
          <p className={'pl-5'}>
            {`${ status.reduce((acc, val) => acc +
                      (+val.state > 0 && val.type.match('tyre') ? 1 : 0), 0) } / 4  Okay`}
          </p>
        </div>
        <div className={'comment-section'}>
          <p className={'bold'}>{ t('mileage') }</p>
          <p>{ mileageFormatter(mileage) } km</p>
          <p className={'bold'}>{ t('comment-fleet')}</p>
          <p>{ commentFleetlink ? commentFleetlink : '-' }</p>
        </div>
      </div>
    </div>);

};


export default CarCheckBaseDetails;


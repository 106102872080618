import "./index.scss";
import PropTypes from "prop-types";
import classnames from "classnames";
import {BUTTON_TYPES} from "@constants";

export const Button = (props) => {

  const renderIcon = () => {
    const { icon, isLoading } = props;

    if (isLoading) {
      return null;
    }

    return (
      <>
        {icon}
      </>
    );
  };

  const { text, type, icon, isSubmit, className, isDisabled, isFlat, isSmall, isLoading, onClick } = props;

  return (
    <button
      className={classnames("button", type, className,  {
        "is-small": isSmall,
        "is-flat": isFlat,
        "is-loading": isLoading,
        "disabled": isDisabled
      })}
      type={isSubmit && "submit"}
      disabled={isDisabled}
      onClick={onClick}
    >
      {icon && renderIcon()}
      {text && <div className="button-text">{text}</div>}
    </button>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.node,
  type: PropTypes.oneOf(Object.values(BUTTON_TYPES)),
  isSubmit: PropTypes.bool,
  isLoading: PropTypes.bool,
  isFlat: PropTypes.bool,
  isSmall: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  text: "",
  icon: null,
  type: BUTTON_TYPES.DANGER,
  iSubmit: false,
  isLoading: false,
  isSmall: false,
  isFlat: false,
  isDisabled: false,
  onClick: null,
};

import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {useSelector, useDispatch} from "react-redux";
/** load components **/
import AppointmentCancel from "@components/Appointment/AppointmentCancel/AppointmentCancel.component";
import ScreenLoader from "@components/Common/ScreenLoader";
/** load services **/
import {t} from "@helpers/i18n";
import {parseISO} from "date-fns";
/** load icons **/
import Email from "mdi-react/EmailIcon";
import Marker from "mdi-react/MapMarkerIcon";
import Alert from "mdi-react/AlertIcon";
import Close from 'mdi-react/CloseIcon';

import {
  HOVER_MAKER_SERVICE,
  MAP_COORDINATES_SET,
} from "@constants";

import * as appointmentsActions from "@store/appointments/actions";

import './AppointmentDetailed.styles.scss';



const AppointmentDetailedView = (props) => {

  const { appointment, resetAppointmentsList } = props;
  const {driverName, driverSurname, garage, appState } = appointment;

  const {
    servicePartners,
  } = useSelector((state) => state.map);

  const {mapAppointmentLoader} = useSelector(state => state.appointments);

  const [showCancelModal, setShowCancelModal] = useState(false);


  const {auth} = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const garageDetails = garage?.address;

  const wishLocation = appointment?.wishLocation.split(",");


  /**
   * Render Info About Appointment Driver
   * @returns {JSX.Element}
   **/
  const renderDriverInfo = () => (
    <div className="appointment_driver">
      <p> {t('vehicle-driver')}:</p>
      <p>{ driverName ?? '-' }</p>
      <p>{ driverSurname  ?? '-' }</p>
    </div>);

  /**
   * Render Info About Appointment Garage
   * @returns {JSX.Element}
   **/
  const renderGarageDetails = () => {
    return (
      <div className={'appointment-garage-details'}>
        <p>{ garage?.name || '-' }</p>
        { garageDetails &&
          <>
            <p>{ garageDetails.street } { garageDetails.number }</p>
            <p>{ garageDetails.city } { garageDetails.zipcode }</p>
          </>
        }
      </div>);
  };

  /**
   * Render Info About Appointment Garage when there is no garage and user
   * has manually made garage wish input
   * @returns {JSX.Element}
   **/
  const renderGarageDetailsWishLocation = () => {
    return (
      <div className={'appointment-garage-details'}>
        {wishLocation.length &&
          <>
            <p>{ wishLocation[0] || '-' }</p>
            <p>{ wishLocation[1] }</p>
            <p>{ wishLocation[2]}</p>
          </>
        }
      </div>);
  };

  /**
   * Loads Appointment Garage on Map
   **/
  const loadGarageOnMap = () => {
    const { id } = garage;
    // check if garage is all ready in store
    const foundGarage = servicePartners.find((partner) => partner.id == id);
    if (foundGarage) {
      // if garage is found in store
      // show garage on map
      dispatch({
        type: HOVER_MAKER_SERVICE,
        payload: foundGarage,
      });
      // update garage in store used for loading appointment in sidebar
      dispatch(appointmentsActions.setMapAppointment(foundGarage));
      dispatch(appointmentsActions.updateMapAppointment());
    }
  };

  useEffect(() => {
    // when map coordinates change
    // we will load more, service partners for that region
    // wait & then try to show appointment in sidebar
    loadGarageOnMap();
  },[ servicePartners]);


  /**
   * Loads Appointment into Map & opens it in Sidebar
   **/
  const onMapClick = () => {
    const { address } = garage;
    // move map to coordinates of appointment
    dispatch({
      type: MAP_COORDINATES_SET,
      payload: { lat: +address.latitude, lng: +address.longitude },
    });
    // set appointment in store, will be used later to open appointment in sidebar
    dispatch(appointmentsActions.setMapAppointment(garage));
    loadGarageOnMap();
  };

  /**
   * Opens email client to send new mail
   * populate data for sender & receiver
   **/
  const onSendEmail = () => {
    const { mail } = garage;
    const { info } = auth;
    const loggedUserEmail = info.email;
    window.location = `mailto:${mail}?cc=${loggedUserEmail}`;
  };

  /**
   * Render Appointment Due Dte icon & Info
   * @returns {JSX.Element}
   **/
  const appointmentDueDate = () => {
    if (appointment.date && appointment.state === 'processed') {
      const difference = parseISO(appointment.date) - new Date();
      const days = Math.round(difference / (1000 * 3600 * 24));

      return (
        <div className={'appointment-due-date'}>
          <div className={'flex justify-between items-center'}>
            <span className={'warning-icon'}>
              <Alert color={'#edc542'}/>
            </span>
            { `${t('appointment_due_in')} ${days} ${t('appointment_days')}` }
          </div>
        </div>);
    }
  };

  /**
   * Shows Appointments Cancellation modal
   **/
  const cancelAppointment = () => {
    setShowCancelModal(true);
  };
  /**
   * Close Cancel modal
   * @param {boolean} shouldReset - detect if we should reset appointments list
   **/
  const onCloseAppointmentModal = (shouldReset) => {
    setShowCancelModal(false);
    if (shouldReset) {
      resetAppointmentsList();
    }
  };

  return (<div className={'detailed-view'}>
    <div className="flex justify-between garage-details">
      { garage.id ? renderGarageDetails() : renderGarageDetailsWishLocation() }
      { renderDriverInfo() }
    </div>
    <div className={'flex justify-between'}>
      <div className={'flex'}>
        <button className={'appointment-action'} onClick={onSendEmail}>
          <Email color={'#000000'}/>
        </button>
        <button className={'appointment-action'} onClick={onMapClick}>
          <Marker color={'#000000'}/>
        </button>
      </div>
      { appointmentDueDate() }
    </div>
    {appState !== 'canceled' &&
      <button
        className={'cancel-appointment-btn'}
        onClick={cancelAppointment}>
        <span className={'btn-icon'}>
          <Close/>
        </span>
        {t('cancel_appointment')}
      </button>}
    <AppointmentCancel
      visible={showCancelModal}
      onClose={onCloseAppointmentModal}
      appointment={appointment} />
    <ScreenLoader
      className={'appointment-screen-loader'}
      isVisible={mapAppointmentLoader}
    />
  </div>);

};

AppointmentDetailedView.propTypes = {
  appointment: PropTypes.object
};

AppointmentDetailedView.defaultProps = {
  appointment: {}
};

export default AppointmentDetailedView;

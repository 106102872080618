import React, { useState } from 'react';
import PropTypes from 'prop-types';

import SimpleCard from "../SimpleCard";

/** Load icon **/
import ArrowLeft from "mdi-react/ArrowLeftIcon";

/** Load translation helper **/
import { t } from "@helpers/i18n";

/** load styles **/
import './style.scss';

import {getColorCarCheck} from "@components/Card/include";

const ExpandableCard = ({ id, state, primaryContent, renderLeft, renderRight, children, onClick, initiallyExpended, props }) => {

  const [showMoreInfo, setShowMoreInfo] = useState(initiallyExpended);

  const onCardPress = () => {
    if(!initiallyExpended){
      setShowMoreInfo(!showMoreInfo);
    }
    onClick();
  };

  const loadBorderColor = () => {
    const statusColor = getColorCarCheck(state);
    if (showMoreInfo) {
      return `expandableCard`;
    }
    return `expandableCard border_${statusColor}`;
  };

  const onBackPress = () => {
    setShowMoreInfo(!showMoreInfo);
  };

  return (<div className={loadBorderColor(status)}>
    { /** todo pass as prop **/ }
    { showMoreInfo && !initiallyExpended && <div className={'back-row'} onClick={onBackPress}>
      <div className={'arrow-back'} >
        <ArrowLeft color={'#fff'}/>
        <span className={'back-text'}>{ t('back')}</span>
      </div>
    </div> }
    <SimpleCard
      id={id}
      onClick={onCardPress}
      primaryContent={primaryContent}
      renderLeft={renderLeft}
      renderRight={renderRight}>
      { showMoreInfo &&  children }
    </SimpleCard>
  </div>);

};

ExpandableCard.propTypes = {
  onClick: PropTypes.func.isRequired,
  initiallyExpended: PropTypes.bool
};

ExpandableCard.defaultProps = {
  onClick: () => {},
  initiallyExpended: false
};

export default ExpandableCard;

import React, { useState } from 'react';
/** Store */
import { useSelector } from "react-redux";

import VehicleInfoDTO from "@components/Vehicle/General/dtos/VehicleFormDTO";

/** Load components */
import Grid from '@mui/material/Grid';
import VehicleMap from "@components/Vehicle/VehicleMap/VehicleMap.component";
import TagsFormComponent from '@components/Vehicle/General/Tags';

import './VehicleEditTags.styles.scss';

const EditTags = () => {
  const { generalInfo, vehicle } = useSelector((state) => state.vehicles);

  const [data, setData] = useState(new VehicleInfoDTO(vehicle, generalInfo));

  return (<>
    <Grid container style={{ 'height': '100%' }}>
      <Grid item xs={7}>
        <TagsFormComponent vehicleId={vehicle.id} tags={vehicle.tags} />
      </Grid>
      <Grid item xs={5}>
        <VehicleMap generalInfo={generalInfo} vehicle={vehicle}/>
      </Grid>
    </Grid>
  </>);
};


export default EditTags;

import React from "react";
import PropTypes from "prop-types";
import { compose, withProps, lifecycle  } from "recompose";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
} from "react-google-maps";

const defaultCenter = {
  lat: 52.520008,
  lng: 13.404954
};

const defaultZoom = 11;



const MapComponent = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&libraries=geometry,drawing,places`,
    loadingElement: <div className={'h-100'} />,
    containerElement: <div className={'h-100 relative'} />,
    mapElement: <div className={'h-100 relative'} />
  }),
  withScriptjs,
  withGoogleMap,
  lifecycle({
  })
)(props => (
  <GoogleMap
    defaultZoom={defaultZoom}
    defaultCenter={defaultCenter}
    onTilesLoaded={props.onTilesLoaded}
    defaultOptions={{disableDefaultUI: true}}
  >
    { props.children }
  </GoogleMap>
));


MapComponent.propTypes = {
  onTilesLoaded: PropTypes.func,
};

MapComponent.defaultProps = {
  onTilesLoaded: () => {}
};

export default MapComponent;

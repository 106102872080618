import React from 'react';

/** Importing the Avatar component from the User folder and the getColor function from the Card folder. **/
import Avatar from "@components/User/Avatar";
import {getColor} from "@components/Card/include";

/** Loading helper functions prop-types and icons **/
import PropTypes from "prop-types";
import {getTicketCardAvatarBackgroundColor, getTicketStateIcon, getTicketStateIconColor} from "@helpers/common/tickets";
import PersonIcon from "mdi-react/AccountIcon";

/** Loading styles **/
import './TicketAvatar.styles.scss';


const TicketAvatar = ({ userAvatar, state, role, profileColor }) => {

  /**
   * If the state is true, then get the state and convert it to lowercase. Then, return the ticket state icon
   * @returns The function getTicketStateIcon is being returned.
   */
  const getIcon = () => {
    if(state) {
      const param = state.toLowerCase();
      return getTicketStateIcon(param);
    }
  };

  /**
   * It returns the color of the ticket state icon.
   * @returns The color of the ticket state icon.
   */
  const getIconBgColor = () => {
    const param = state.toLowerCase();
    const colorCard = getTicketStateIconColor(param);
    return getColor(colorCard);
  };

  return (
    <Avatar
      iconBackgroundColor={getIconBgColor()}
      icon={getIcon()}
      src={userAvatar}
      small
      missingIcon={<PersonIcon className={`${getTicketCardAvatarBackgroundColor(state, role)} ${profileColor}`}/>}
    />
  );
};

export default TicketAvatar;

TicketAvatar.propTypes = {
  userAvatar: PropTypes.string,
  state: PropTypes.string,
};

TicketAvatar.defaultProps = {
  userAvatar: '',
  state: '',
};


import React from "react";

import "./index.scss";
import PropTypes from "prop-types";


const DataForm = ({ data }) => {
  return (
    <div className="data-form">
      {
        data.map((el, i) => {
          return (
            <div key={i} className={`form-line form-line_${el.color}`}>
              <div className="form-name">{el.name}:</div>
              <div className="form-value icon-user-state">{el.value ? el.value : '-'} {el.icon}</div>
            </div>
          );
        })
      }
    </div>
  );
};

DataForm.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.any,
    value: PropTypes.any,
  }))
};

DataForm.defaultProps = {
  data: []
};

export default DataForm;

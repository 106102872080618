import React from 'react';
import Loader from "../../../../../assets/svg/loader.svg";

import './LicensePopup.style.scss';

const LicensePopup = ({ visible, licenseImg, closePopup }) => {


  return (visible && <div className={'license-popup'}>
    <div className={'license-img'}>
      {!licenseImg ? <img className="loader-image" src={Loader} alt={'loader'} /> : <img src={`data:image/jpg;base64, ${licenseImg}`} />}
    </div>
    <span className="close" onClick={closePopup}></span>
  </div>
  );

};

export default LicensePopup;

import React, { useState } from 'react';
import ArrowRightIcon from "mdi-react/ChevronDownIcon";

import './Dropdown.styles.scss';


const Dropdown = (props) => {

  const { value, keyValue, options, onChange, className } = props;

  const [dropdownVisibility, setDropdownVisibility] = useState(false);

  /**
   * Toggle dropdown options visibility
   **/
  const toggleDropdown = () => {
    setDropdownVisibility(!dropdownVisibility);
  };

  /**
   * Option selected from dropdown list
   * @param {object} e
   **/
  const onOptionSelect = (e) => {
    setDropdownVisibility(false); // hide dropdown options
    onChange(e);
  };

  return (
    <div className={`dropdown-selector ${className} ${dropdownVisibility ? 'select-active' : 'select-deactive'}`}>
      <div className={'fl-selected'} onClick={toggleDropdown}>
        {value[keyValue]}
      </div>
      <div
        className={`arrow ${dropdownVisibility ? "rotate" : ""}`}
        onClick={toggleDropdown}>
        <ArrowRightIcon />
      </div>
      <div className={'select__options'}>
        {options.map((option, index) => {
          return (
            <div className="select__option" key={index} onClick={(child) => onOptionSelect(option)}>
              { option[keyValue] }
            </div>);
        })}
      </div>
    </div>
  );
};


export default Dropdown;

import * as ticketsConstants from './constants';

const initialState = {
  tickets: [],
  singleTicket: {},
  isSingleTicketLoading: true,
  loading: true,
  loadMore: false,
  lastPage: false
};

const ticketsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ticketsConstants.TICKETS_GET_REQUEST:
      return {
        ...state,
        loading: true,
        lastPage: false
      };

    case ticketsConstants.TICKETS_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        tickets: action.payload.offenses,
        lastPage:  (action.payload.meta.pagination.total - action.payload.meta.pagination.limit) < action.payload.meta.pagination.offset,
      };

    case ticketsConstants.TICKETS_GET_FAIL:
      return {
        ...state,
        tickets: [],
        singleTicket: {},
        isSingleTicketLoading: false,
        loading: false
      };

    case ticketsConstants.TICKETS_LOAD_MORE:
      return {
        ...state,
        loadMore: true
      };

    case ticketsConstants.TICKETS_LOAD_MORE_SUCCESS:
      return {
        ...state,
        loadMore: false,
        lastPage:  (action.payload.meta.pagination.total - action.payload.meta.pagination.limit) < action.payload.meta.pagination.offset,
        tickets: [...state.tickets, ...action.payload.offenses]
      };

    case ticketsConstants.TICKETS_LOAD_MORE_FAIL:
      return {
        ...state,
        loadMore: false
      };

    case ticketsConstants.TICKET_SINGLE_GET_REQUEST:
      return {
        ...state,
        singleTicket: {},
        isSingleTicketLoading: true
      };
    case ticketsConstants.TICKET_SINGLE_GET_SUCCESS:
      return {
        ...state,
        isSingleTicketLoading: false,
        singleTicket: action.payload
      };
    case ticketsConstants.TICKET_SINGLE_GET_FAIL:
      return {
        ...state,
        singleTicket: {},
        isSingleTicketLoading: false,
      };

    case ticketsConstants.TICKET_ASSIGN_REQUEST:
      return {
        ...state,
        isSingleTicketLoading: true
      };
    case ticketsConstants.TICKET_ASSIGN_SUCCESS:
      return {
        ...state,
        singleTicket: action.payload,
        isSingleTicketLoading:false
      };
    case ticketsConstants.TICKET_ASSIGN_FAIL: {
      return {
        ...state,
        isSingleTicketLoading: false
      };
    }
    case ticketsConstants.TICKET_OBJECT_REQUEST:
      return {
        ...state,
        isSingleTicketLoading: true
      };
    case ticketsConstants.TICKET_OBJECT_SUCCESS:
      return {
        ...state,
        singleTicket: action.payload,
        isSingleTicketLoading:false
      };
    case ticketsConstants.TICKET_OBJECT_FAIL: {
      return {
        ...state,
        isSingleTicketLoading: false
      };
    }

    case ticketsConstants.TICKET_DELETE_REQUEST:
      return {
        ...state,
        isSingleTicketLoading: true
      };
    case ticketsConstants.TICKET_DELETE_SUCCESS:
      return {
        ...state,
        isSingleTicketLoading:false
      };
    case ticketsConstants.TICKET_DELETE_FAIL: {
      return {
        ...state,
        isSingleTicketLoading: false
      };
    }

    case ticketsConstants.TICKET_RESET_REQUEST:
      return {
        ...state,
        isSingleTicketLoading: true
      };
    case ticketsConstants.TICKET_RESET_SUCCESS:
      return {
        ...state,
        singleTicket: action.payload,
        isSingleTicketLoading: false
      };
    case ticketsConstants.TICKET_RESET_FAIL:
      return {
        ...state,
        singleTicket: {},
        isSingleTicketLoading: false,
      };

    case ticketsConstants.TICKET_COMPLETE_REQUEST:
      return {
        ...state,
        isSingleTicketLoading: true
      };
    case ticketsConstants.TICKET_COMPLETE_SUCCESS:
      return {
        ...state,
        singleTicket: action.payload,
        isSingleTicketLoading: false
      };
    case ticketsConstants.TICKET_COMPLETE_FAIL:
      return {
        ...state,
        isSingleTicketLoading: false
      };

    case ticketsConstants.CREATE_TICKET_REQUEST:
      return {
        ...state,
        creatingTicket: true
      };
    case ticketsConstants.CREATE_TICKET_SUCCESS:
      return {
        ...state,
        creatingTicket: false
      };
    case ticketsConstants.CREATE_TICKET_FAIL:
      return {
        ...state,
        creatingTicket: false
      };

    default:
      return state;
  }
};

export default ticketsReducer;

import React from 'react';

/** Load components **/
import Avatar from "@components/User/Avatar";

/** Load icons **/
import CarIcon from "mdi-react/CarIcon";

/** Load helpers **/
import {getColorCarCheck, getIconCarCheck} from "@components/Card/include";

import styles from './style.scss';

const CarCheckLeftInfoBlock = ({/**/ state, carAvatar }) => {
  return (
    <Avatar
      src={carAvatar || ''}
      small
      missingIcon={<CarIcon/>}
      icon={getIconCarCheck(state)}
      iconBackgroundColor={getColorCarCheck(state)}
    />
  );
};


export default CarCheckLeftInfoBlock;

import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import PropTypes from 'prop-types';
/** Load components **/
import { TailSpin } from "react-loader-spinner";
import TripMapCmp from "@components/Trip/SingleTrip/TripMap/Map.component";
import {
  DirectionsRenderer
} from "react-google-maps";


const MAX_WAYPOINTS_PER_REQUEST = 20; // we define here how many points to send per request


const TripMap = forwardRef((props, ref) => {

  /**
   * Extract props
   */
  const { drawTrip } = props;

  const [directions, setDirections] = useState(null);
  const [mapLoaderVisible, setMapLoaderVisible] = useState(false);
  const [mapLoaded, setMapLoaded] = useState(0);

  useEffect(() => {
    if (mapLoaded === 1) {
      // use prop to draw whole trip on map
      // wait till tiles are loaded, to use direction service
      // then increment mapLoaded when page load,
      // and use useEffect to draw route of whole trip
      drawTrip();
    }
  }, [mapLoaded]);

  /**
   * Cut array of waypoint into smaller pices of 20 so we could send them to google
   * @param {array} waypoints - array of all formatter waypoints
   * @param {number} startingIndex - index from where to start cutting array
   */
  const getNextDirections = (waypoints, startingIndex) => {

    const returnPoints = []; // array of points to return

    if (startingIndex > waypoints.length - 1) {
      return [returnPoints, null];
    } // no more waypoints to process

    let endIndex = startingIndex + MAX_WAYPOINTS_PER_REQUEST;

    // adjust waypoints, because Google allows us to include the start and destination latlongs for free!
    endIndex += 2;

    if (endIndex > waypoints.length - 1) {
      endIndex = waypoints.length;
    }

    for (let i = startingIndex; i < endIndex; i++) {
      returnPoints.push(waypoints[i]);
    }

    if (endIndex != waypoints.length) {
      return [returnPoints, (endIndex -= 1)];
    } else {
      return [returnPoints, null];
    }
  };

  /**
   * Load direction route & make call to google api
   * @param {function} service - array of all formatter waypoints
   * @param {number} waypoints - index from where to start cutting array
   * @param {function} callbackFunc - index from where to start cutting array
   * @param {number} waypointIndex - index from where to start cutting array
   * @param {array} path - index from where to start cutting array
   */
  const loadDirections = (service, waypoints, callbackFunc, waypointIndex = 0, path = []) => {
    // show map Loader
    setMapLoaderVisible(true);
    // set defaults
    waypointIndex = typeof waypointIndex !== "undefined" ? waypointIndex : 0;
    path = typeof path !== "undefined" ? path : [];

    // get next set of waypoints
    const s = getNextDirections(waypoints, waypointIndex);

    if (!s.length) {
      callbackFunc([]);
      return;
    }
    // build request object for google api
    const startl = s[0].shift()["location"];
    const endl = s[0].pop()["location"];
    const request = {
      origin: startl,
      destination: endl,
      waypoints: s[0],
      travelMode: google.maps.TravelMode.DRIVING,
      optimizeWaypoints: false,
      provideRouteAlternatives: false,
    };
    service.route(request, (response, status) => {
      if (status == google.maps.DirectionsStatus.OK) {
        path = path.concat(response);

        if (s[1] != null) {
          // add timeout bcs sending multiple request to direction service will get you blocked by google
          setTimeout(() => {
            loadDirections(service, waypoints, callbackFunc, s[1], path);
          }, 700);
        } else {
          callbackFunc(path);
        }
      } else {
        if (status == google.maps.GeocoderStatus.OVER_QUERY_LIMIT) {
          setTimeout(() => {
            loadDirections(service, waypoints, callbackFunc, s[1], path);
          }, 800);
        }
      }
    });
  };

  /**
   * Draw route directions on Map
   * @param {array} waypoints - array of all formatted waypoints
   */
  const drawDirections = (waypoints) => {

    if (waypoints) {
      // map all points to appropriate array of objects
      const mappedDirections = waypoints.map(p => ({
        location: { lat: p.latitude, lng: p.longitude },
        stopover: true
      }));
      /**
       * Google directions service used to draw route on map
       * @type {google.maps.DirectionsService}
       */
      const DirectionsService = new google.maps.DirectionsService();

      loadDirections(DirectionsService, mappedDirections, (path) => {
        setDirections(path);
        setMapLoaderVisible(false);
      });
    } else {
      setDirections(null);
    }
  };

  useImperativeHandle(ref, () => ({
    // called from parent, used to draw directions for whole trip as well as single track
    drawDirections: (value) => drawDirections(value),
  }));

  /**
   * returns map Loader
   * @returns {JSX.Element}
   */
  const mapLoader = () => {
    return (<div className={"map-loader"}>
      <div className="list-loader">
        <TailSpin
          color="#FFF"
          height={50}
          width={50}
        />
      </div>
    </div>);
  };



  return (
    <>
      <TripMapCmp
        onTilesLoaded={() => {
          if (!mapLoaded) {
            // used these hack bcs we have to wait for google map to load fully
            // before using google direction service
            setMapLoaded((count) => count + 1);
          }
        }}>
        { mapLoaderVisible && mapLoader() }
        {directions &&
        directions.map((direction, index) =>  (
          <DirectionsRenderer
            key={index}
            defaultOptions={{
              suppressMarkers: true
            }} directions={direction} />))
        }
      </TripMapCmp>
    </>
  );
});

TripMap.propTypes = {
  props: PropTypes.object,
  ref: PropTypes.element
};

TripMap.defaultProps = {
  props: {},
  ref: null,
};

export default TripMap;

import React from "react";
import Calendar from "mdi-react/CalendarCheckIcon";
import Timer from "mdi-react/TimerSandIcon";
import CloseBoldIcon from "mdi-react/CloseBoldIcon";
import Archive from "mdi-react/ArchiveIcon";

const appointmentStateColors = {
  'confirm': 'green',
  'pending': 'yellow',
  'canceled': 'red',
  'default': 'gray'
};

const appointmentStateAvatars = {
  'confirm': <Calendar/>,
  'pending': <Timer/>,
  'canceled': <CloseBoldIcon/>,
  'default': <Archive/>
};


const AppointmentStatusIcon = (state) => {
  return (appointmentStateAvatars[state] || appointmentStateAvatars['default']);
};

const AppointmentSideColor = (state) => {
  return (appointmentStateColors[state] || appointmentStateColors['default']);
};


export {
  AppointmentStatusIcon,
  AppointmentSideColor
};

import React from 'react';
import PropTypes from 'prop-types';
/** load components **/
import ApplyButton from '@components/Common/Button/ApplyButton';

/** import services **/
import {t} from '@helpers/i18n';
import TrackingFilterForm from "@components/TimeTracking/Filter/FilterForm";

const TrackingFilter = (props) => {

  const { filterValues, visible, trackingDates, onDateChange, filterChange, applyFilter } = props;
  return (
    visible &&
        <div className={'list-filter'}>
          <TrackingFilterForm
            trackingDates={trackingDates}
            filter={filterValues}
            onChange={(filterTitle) => filterChange(filterTitle)}
            onDateChange={onDateChange}
          />
          <ApplyButton onClick={applyFilter} text={t('apply')} />
        </div>
  );
};

TrackingFilter.propTypes = {
  filterValues: PropTypes.object,
  visible: PropTypes.bool,
  invoiceDates: PropTypes.object,
  onDateChange: PropTypes.func,
  filterChange: PropTypes.func,
  applyFilter: PropTypes.func
};

TrackingFilter.defaultProps = {
  filterValues: {},
  visible: false,
  invoiceDates: {},
  onDateChange: () => {},
  filterChange: () => {},
  applyFilter: () => {}
};

export default React.memo(TrackingFilter);

import React, { useState, createRef } from 'react';
import PropTypes from "prop-types";
import {Field, Form, Formik} from "formik";
import { t } from "@helpers/i18n";

/** Load components **/
import Grid from '@mui/material/Grid';
import NoticeBlock from "@components/Common/NoticeBlock";
/** Load icons **/
import ArrowLeft from "@assets/svg/arrow-left.svg";

/** Load styles for component **/
import './CustomerNumber.styles.scss';

const CustomerNumber = ({ registrationInfo, nextStep, stepBack, fieldChanged }) => {
  /** component state **/
  const [errorNumb, setErrorNumb] = useState(null); // show error message

  const inputRef1 = createRef();
  const inputRef2 = createRef();
  const inputRef3 = createRef();
  const inputRef4 = createRef();
  const inputRef5 = createRef();
  const inputRef6 = createRef();

  // initial values for customer number
  const digits = {
    digit1: registrationInfo.digit1,
    digit2: registrationInfo.digit2,
    digit3: registrationInfo.digit3,
    digit4: registrationInfo.digit4,
    digit5: registrationInfo.digit5,
    digit6: registrationInfo.digit6
  };

  const digitValues = Object.values(digits);
  /** disable net btn if all customer number is partly added **/
  const isNextBtnDisabled = !(digitValues.every(digit => !!digit) || digitValues.every(digit => !digit));

  // keycodes for numbers
  const keyCodes = [8, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105];

  /** Detect if inputed value is valid number **/
  const isNumber = (e) => {
    if (!keyCodes.includes(e.keyCode)) {
      e.preventDefault();
      return;
    }
  };

  const checkBtn = (e, refNext, refPref, fieldName) => {
    if (!keyCodes.includes(e.keyCode)) {
      e.preventDefault();
      return;
    }
    // reset input to '', handle backSpace case
    if ((e.keyCode === 8 || e.keyCode === 37) && (refPref !== null)) {
      if (refPref.current) {
        refPref.current.focus();
        refPref.current.select();
        fieldChanged(fieldName, '');
      }
    }
    // inputs number into field
    if ((e.keyCode !== 8 && e.keyCode !== 37) && (refNext !== null)) {
      if (refNext.current) {
        refNext.current.focus();
        refNext.current.select();
      }
      fieldChanged(fieldName, e.key);
    }
    // first row backspace
    if ((e.keyCode === 8 || e.keyCode === 37) && (refPref === null)) {
      fieldChanged(fieldName, '');
    }
    // last row input value case
    if ((e.keyCode !== 8 && e.keyCode !== 37) && (refNext === null)) {
      fieldChanged(fieldName, e.key);
    }
  };

  const handleSelect = (e) => {
    e.target.select();
  };

  const goToNextStep = () => {
    const digitValues = Object.values(digits);
    const allDigitsFull = digitValues.every(digit => !!digit);
    const noDigits = digitValues.every(digit => !digit);
    if (allDigitsFull || noDigits) {
      nextStep('personalInfo');
    } else {
      setErrorNumb('invalid');
    }
  };

  return (<div className={'customer-number-container'}>
    <div className="customer-number">
      <div className="right-title">{t('already_customer')}</div>
      <div className="number-label">
        {t('enter_your_customer_number')}
      </div>
      <div className="number-input">
        <Formik
          initialValues={digits}
          onSubmit={goToNextStep}
          enableReinitialize
        >
          <Form>
            <div className="center-v-h">
              <Field name="digit1"
                max="9" min="0"
                onClick={handleSelect}
                onKeyDown={(e)=> {isNumber(e);}}
                onKeyUp={(e) => {checkBtn(e, inputRef2, null, 'digit1');}}
                innerRef={inputRef1}
                value={digits.digit1}
                maxLength={1} />
              <Field name="digit2"
                max="9" min="0"
                onClick={handleSelect}
                onKeyDown={(e)=> {isNumber(e);}}
                onKeyUp={(e) => {checkBtn(e, inputRef3, inputRef1, 'digit2');}}
                innerRef={inputRef2}
                value={digits.digit2}
                maxLength={1} />
              <Field name="digit3"
                max="9" min="0"
                onClick={handleSelect}
                onKeyDown={(e)=> {isNumber(e);}}
                onKeyUp={(e) => {checkBtn(e, inputRef4, inputRef2, 'digit3');}}
                innerRef={inputRef3}
                value={digits.digit3}
                maxLength={1}/>
              <Field name="digit4"
                max="9" min="0"
                onClick={handleSelect}
                onKeyDown={(e)=> {isNumber(e);}}
                onKeyUp={(e) => {checkBtn(e, inputRef5, inputRef3, 'digit4');}}
                innerRef={inputRef4}
                value={digits.digit4}
                maxLength={1}/>
              <Field name="digit5"
                max="9" min="0"
                onClick={handleSelect}
                onKeyDown={(e)=> {isNumber(e);}}
                onKeyUp={(e) => {checkBtn(e, inputRef6, inputRef4, 'digit5');}}
                innerRef={inputRef5}
                value={digits.digit5}
                maxLength={1}/>
              <Field
                name="digit6"
                max="9" min="0"
                onClick={handleSelect}
                onKeyDown={(e)=> {isNumber(e);}}
                onKeyUp={(e) => {checkBtn(e, null, inputRef5, 'digit6');}}
                innerRef={inputRef6}
                value={digits.digit6}
                maxLength={1}/>
            </div>
            {errorNumb && <p className="customer-number_invalid">{t('error_customer_number')}</p>}
            <NoticeBlock>
              <div className="notice-container">
                <div className="notice-text">
                  {t('notice_text')}
                </div>
                <div className="notice-text">
                  {t('become_customer')}
                </div>
                <div className="notice-text">
                  <a target="_blank" href="https://www.fleetlink.de/angebot/">{t('click_here')}</a>
                </div>
              </div>
            </NoticeBlock>
            <Grid container spacing={2} rowSpacing={1}>
              <Grid item xs={6}>
                <button type={'button'} className="button button_customer is-link back-btn" onClick={stepBack}>
                  <img className="button-arrow rotate-icon" src={ArrowLeft} alt="icon"/>
                </button>
              </Grid>
              <Grid item xs={6}>
                <button type="submit" disabled={isNextBtnDisabled} className="button button_customer is-link next-btn">
                  <img className="button-arrow" src={ArrowLeft} alt="icon"/>
                </button>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </div>
    </div>
  </div> );
};

CustomerNumber.propTypes = {
  registrationInfo: PropTypes.object,
  nextStep: PropTypes.func.isRequired,
  stepBack: PropTypes.func.isRequired,
  fieldChanged: PropTypes.func.isRequired
};

CustomerNumber.defaultProps = {
  registrationInfo: {},
  nextStep: () => {},
  stepBack: () => {},
  fieldChanged: () => {}
};


export default CustomerNumber;

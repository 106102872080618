import React, {useState, forwardRef, useImperativeHandle} from 'react';
import PropTypes from "prop-types";

/** load components **/
import Modal from '@components/Common/Modal';
import Carousel from "@components/Common/Carousel";

import {errorHandler} from "@helpers/saga";

const Slider = forwardRef((props, ref) => {

  const { imageAPI, slides, setSlides, imageSrcLink = false } = props;

  const [imagePreviewModal, setImagePreviewModal] = useState(false);
  /** Track current slide index **/
  const [slideIndex, setSlideIndex] = useState(0);

  /** Loads full resolution image & handles Slide index **/
  const loadFullResolutionImage = async (index) => {
    try {
      const { data } = await imageAPI(index);
      const copy = [...slides];
      copy[index] = data.blob;
      setSlides(
        copy
      );
    } catch (e) {
      errorHandler(e);
    }
  };

  // The component instance will be extended
  // with whatever you return from the callback passed
  // as the second argument
  useImperativeHandle(ref, () => ({
    /** handle Image click
     *
     * **/
    async previewImage(index) {
      setImagePreviewModal(true);
      setSlideIndex(index);
      if (!slides[index]) {
        await loadFullResolutionImage(index);
      }
    }

  }));

  /** on Slide navigation, handle left right arrow functionality **/
  const onSlideChange =  async (item, nextIndex) => {
    setSlideIndex(slideIndex);
    if (slides[nextIndex] === null) {
      // fetch full res image
      await loadFullResolutionImage(nextIndex);
    }
  };

  /** Close Modal **/
  const onCloseModal = () => {
    setImagePreviewModal(false);
  };


  return (
    <Modal isVisible={imagePreviewModal} onClose={onCloseModal}>
      <Carousel
        imageSrcLink={imageSrcLink}
        slides={slides}
        activeIndex={slideIndex}
        onSlideChange={onSlideChange}
      />
    </Modal>
  );
});

Slider.propTypes = {
  props: PropTypes.object,
};

Slider.defaultProps = {
  props: {},
};

export default Slider;

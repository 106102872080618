import React from "react";
import {DEFAULT_DATE_ZERO, NO_DATE} from "@constants";
import CardAccountDetailsOutlineIcon from "mdi-react/CardAccountDetailsOutlineIcon";
import DataForm from "@components/Common/Form/DataForm";
import { Button } from "@components/Common/Button";
import { EmployeeLicenseImages } from "@components/Employee";
import {BUTTON_TYPES} from "@constants";
import { t, date } from "@helpers/i18n";
import {LicenseIcon} from "@services/formatters/profile/license";

const DriversLicense = ({profile, license, auth, onEditClick, onPreviewImage}) => {

  /** Renders Edit License button **/
  const renderEditButton = () => {
    return profile.id === auth.info.id &&
      <Button
        type={BUTTON_TYPES.WARNING}
        icon={<CardAccountDetailsOutlineIcon />}
        text={t("update-driver-license")}
        className={'self-center'}
        onClick={onEditClick}
      />;
  };

  /** Driving license form data **/
  const driversLicenseData = [
    {
      color: profile.state,
      name: t("state"),
      value: t(profile.state),
      icon: LicenseIcon.getIcon(profile.state)
    },
    {
      name: t("surname"),
      value: profile.surname? profile.surname: '-',
    },
    {
      name: t("name"),
      value: profile.name ? profile.name: '-',
    },
    {
      name: t("number"),
      value: profile.licenceIdentifier ? profile.licenceIdentifier: '-' ,
    },
    {
      name: t("date-of-issue"),
      value: license.issueDate && license.issueDate !== NO_DATE && license.issueDate !== DEFAULT_DATE_ZERO  ? date(license.issueDate) : '-'
    },
    {
      name: t("expiry-date"),
      value: license.expirationDate && license.expirationDate !== NO_DATE && license.expirationDate !== DEFAULT_DATE_ZERO  ? date(license.expirationDate) : '-'
    },
    {
      name: t("birthday"),
      value: date(profile.birthday) && date(profile.birthday) !== NO_DATE  ? date(profile.birthday): '-'
    },
  ];

  return (
    <div className={'flex-1'}>
      <div className={'license-details details-content flex flex-col justify-between p-b-2'}>
        <EmployeeLicenseImages
          driverLicense={license}
          onImagePreview={onPreviewImage}
        />
        <DataForm
          data={driversLicenseData}
        />
        {renderEditButton()}
      </div>
    </div>
  );
};

DriversLicense.propTypes = {
  profile: PropTypes.object,
  license: PropTypes.object,
  auth:  PropTypes.object,
  onEditClick: PropTypes.func,
  onPreviewImage: PropTypes.func
};

DriversLicense.defaultProps = {
  profile: {},
  license: {},
  auth: {},
  onEditClick: () => {},
  onPreviewImage:  () => {}
};

export default DriversLicense;

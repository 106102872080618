import React, { useState } from 'react';
import PropTypes from "prop-types";

/** load icons **/
import LockIcon from "mdi-react/LockIcon";
import CloseIcon from "mdi-react/CloseIcon";

/** load Profile components **/
import ProfilePassword from "./ProfilePassword";
import RemoveProfile from "./RemoveProfile";
import { Button } from "@components/Common/Button";
import Settings from "@containers/Profile/SettingsTab/Settings";
import {BUTTON_TYPES} from "@constants";
import { t } from "@helpers/i18n";
import Grid from "@mui/material/Grid";
import "./index.scss";


const EditUserInfo = ({ profile, loading, onSave, onDelete, isAssign, onToggleChange}) => {

  const [showDeleteAccount,setShowDeleteAccount] = useState(false);

  const [showChangePassword, setShowChangePassword] = useState(false);

  /** toggle edit password view  **/
  const updatePassword = () => {
    setShowDeleteAccount(false);
    setShowChangePassword(!showChangePassword);
  };

  /** toggle delete account view  **/
  const deleteAccount = () => {
    setShowChangePassword(false);
    setShowDeleteAccount(!showDeleteAccount);
  };

  /** save user profile **/
  const saveProfile = (profileData) => {
    onSave({...profile, ...profileData});
  };

  /** save user new user password **/
  const onChangeProfilePassword = (updatedPasswords) => {
    onSave({ ...profile,...updatedPasswords });
  };

  /** delete user account **/
  const onSuccessDelete = () => {
    onDelete(profile.id);
  };

  return (
    <Grid container style={{height: '100%'}}>
      <Settings
        saveProfile={saveProfile}
        isAssign={isAssign}
        onToggleChange={onToggleChange}
        profile={profile}
      >
        <Button
          className="update"
          icon={<LockIcon />}
          type={BUTTON_TYPES.WARNING}
          text={t("update_password")}
          isLoading={loading}
          onClick={updatePassword}
        />
        <Button
          className="close"
          icon={<CloseIcon />}
          type={BUTTON_TYPES.DANGER}
          text={t("delete-account")}
          isLoading={loading}
          onClick={deleteAccount}
        />
      </Settings>
      <Grid item xs={5}>
        { showChangePassword &&
          <ProfilePassword profile={profile} loading={loading} changeProfilePassword={onChangeProfilePassword}/>
        }
        {
          showDeleteAccount &&
            <RemoveProfile avatar={profile.avatar} onSuccessDelete={onSuccessDelete} />
        }
      </Grid>
    </Grid>
  );
};

EditUserInfo.propTypes = {
  loading: PropTypes.bool,
  profile: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};


EditUserInfo.defaultProps = {
  loading: false,
  profile: {},
  onSave: () => {},
  onDelete: () => {},
};

export default EditUserInfo;

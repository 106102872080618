import React, { useState } from 'react';
/** load component **/
import UserProfileBlock from "@containers/Sidebar/UserBlock";
import LogoutButton from './Logout';
import SideMenu from './SideMenu';

import {useSelector} from "react-redux";

import classNames from "classnames";

/** Load styles **/
import './Sidebar.styles.scss';

const SideBar = () => {

  const [expandNav, setExpandNav] = useState(false);

  const { profile } = useSelector(state => state.profile);

  const name = profile?.name || '';
  const surname = profile?.surname || '';
  const customerNumber = profile?.customerNumber || '';
  const avatar = profile?.avatar || '';

  /**
    * Render single Car Check card
    * @param {boolean} expandFlag - carCheck object
  **/
  const expandNavBar = (expandFlag) => {
    setExpandNav(expandFlag);
  };

  return (
    <div
      className={classNames('sidebar', { 'expand': expandNav })}
      onMouseEnter={() => expandNavBar(true)}
      onMouseLeave={() => expandNavBar(false)}>
      {/** User Profile Block **/}
      <UserProfileBlock
        customerNumber={customerNumber}
        avatar={avatar}
        name={name}
        surname={surname}
      />
      {/** SideBar menu links **/}
      <SideMenu />
      {/** Log Out Button **/}
      <LogoutButton />
    </div>
  );
};


export default SideBar;

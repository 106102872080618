import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, Switch, Route } from "react-router-dom";

import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import CardAccountDetailsOutlineIcon from "mdi-react/CardAccountDetailsOutlineIcon";
import CarIcon from "mdi-react/CarIcon";
import FlashAlertIcon from "mdi-react/FlashAlertIcon";

import AnimatedLoader from '@components/Common/AnimatedLoader';
import PageHeader from "@components/Layout/PageHeader";
import ProfileInfoBlock from "@components/ProfileInfoBlock";
import ProfileNavigation from "@components/ProfileNavigation/ProfileNavigation.component";
import EmployeeDetails from "@containers/Employees/Employee/EmployeeDetails";
import EmployeeRegistration from "@containers/Employees/Employee/EmployeeRegistration";
import EmployeeVehicle from "@containers/Employees/Employee/EmployeeVehicle";
import EmployeeCarChecks from "@containers/Employees/Employee/EmployeeCarChecks";
import EmployeeDamages from "@containers/Employees/Employee/EmployeeDamages";

import { t } from "@helpers/i18n";
import {activeTabIndex} from "@services/utils";
import * as employeeActions from "@store/employees/actions";
import EmployeeTimeTracking from "@containers/Employees/Employee/EmployeeTimeTracking";
import {TimerOutlined} from "@mui/icons-material";

const Employee = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { pathname } = useLocation();

  const { auth } = useSelector(state => state.auth);
  const { singleLoading, employee } = useSelector(state => state.employees);

  useEffect(() => {
    dispatch(employeeActions.employeeGetById({ userId: auth.user.id, employeeId: id }));
  }, [auth.user.id, id]);

  /** Employee tabs **/
  const routes = [
    {
      key: "personal-info-tab",
      icon: <AccountOutlineIcon />,
      path: `/employees/${id}/info`,
    },
    {
      key: "driver-license-tab",
      icon: <CardAccountDetailsOutlineIcon />,
      path: `/employees/${id}/registration`,
    },
    {
      key: "vehicle",
      icon: <CarIcon />,
      path: `/employees/${id}/vehicle`,
    },
    {
      key: "car-checks",
      icon: <CarIcon />,
      path: `/employees/${id}/car-checks`,
    },
    {
      key: "damages",
      icon: <FlashAlertIcon />,
      path: `/employees/${id}/damages`,
    },
    {
      key: "working-hours",
      icon: <TimerOutlined />,
      path: `/employees/${id}/tracking`,
    }
  ];

  const renderHeader = () => (
    <div className="header-info-row">
      <ProfileInfoBlock
        name={employee?.name}
        surname={employee?.surname}
        customerNumber={employee?.customerNumber}
        company={employee?.companyName}
        avatar={employee?.avatar}
        licensePlate={employee?.car?.licencePlate}
      />
      <ProfileNavigation
        routes={routes}
        activeIndex={activeTabIndex(routes, pathname)}
        horizontal={true}
      />
    </div>
  );

  const renderEmployeeContainer = () => (
    <div id="employee-container" className="details-container">
      <PageHeader text={t("back")} profile={true} backLink={'/employees'}>
        {renderHeader()}
      </PageHeader>
      <div className={'flex-1'}>
        <Switch>
          <Route
            path={`/employees/:id/info`}
            exact
            component={EmployeeDetails} />
          <Route
            path={`/employees/:id/registration`}
            exact
            component={EmployeeRegistration}
          />
          <Route
            path={`/employees/:id/vehicle`}
            exact
            component={EmployeeVehicle}
          />
          <Route
            path={`/employees/:id/car-checks`}
            exact
            component={EmployeeCarChecks}
          />
          <Route
            path={`/employees/:id/damages`}
            exact
            component={EmployeeDamages}
          />
          <Route
            path={`/employees/:id/tracking`}
            exact
            component={EmployeeTimeTracking}
          />
        </Switch>
      </div>
    </div>
  );

  return (
    <>
      {singleLoading ? <AnimatedLoader /> : renderEmployeeContainer()}
    </>
  );
};

export default Employee;

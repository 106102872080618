import * as tripConstants from "./constants";

export const tripsGet = (payload) => ({type: tripConstants.TRIPS_GET_REQUEST, payload});
export const tripsGetSuccess = (payload) => ({type: tripConstants.TRIPS_GET_SUCCESS, payload});
export const tripsGetFail = (error) => ({type: tripConstants.TRIPS_GET_FAIL, error});

export const tripsLoadMore = (payload) => ({ type: tripConstants.TRIPS_LOAD_MORE_REQUEST, payload });
export const tripsLoadMoreSuccess = (payload) => ({ type: tripConstants.TRIPS_LOAD_MORE_SUCCESS, payload });
export const tripsLoadMoreFail = (error) => ({ type: tripConstants.TRIPS_LOAD_MORE_FAIL, error });

export const tripGetById = (payload) => ({type: tripConstants.TRIP_GET_BY_ID_REQUEST, payload});
export const tripGetByIdSuccess = (payload) => ({type: tripConstants.TRIP_GET_BY_ID_SUCCESS, payload});
export const tripGetByIdFail = (error) => ({type: tripConstants.TRIP_GET_BY_ID_FAIL, error});

export const tripUpdate = (payload) => ({type: tripConstants.TRIP_UPDATE_REQUEST, payload});
export const tripUpdateSuccess = (payload) => ({type:tripConstants.TRIP_UPDATE_SUCCESS, payload});
export const tripUpdateFail = (error) => ({type: tripConstants.TRIP_UPDATE_FAIL, error});

export const resetTripsList = () => ({type: tripConstants.RESET_TRIPS_LIST});

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Grid from '@mui/material/Grid';

import { CarCheckList } from "@components/CarCheck";
import PageContent from "@components/Layout/PageContent";
import * as employeesActions from "@store/employees/actions";

import "./EmployeeCarChecks.styles.scss";

const EmployeeCarChecksContainer = () => {
  const [page, setPage] = useState(0);

  const {
    employee,
    employeeCarChecks,
    carChecksLastPage,
    loading,
    loadingMoreCarChecks
  } = useSelector(state => state.employees);

  const dispatch = useDispatch();

  const loadMoreCarChecks = () => {
    if ((carChecksLastPage - 1) > page && !loadingMoreCarChecks) {
      const nextPage = page + 1;

      dispatch(employeesActions.employeeLoadMoreCarChecks({
        employeeId: employee.userId,
        page: nextPage,
      }));
      setPage(nextPage);
    }
  };

  useEffect(() => {
    if(employee) {
      dispatch(employeesActions.employeeGetCarChecks({
        employeeId: employee?.userId,
      }));
    }
  }, [employee]);

  const carCheckListProps = {
    carChecks: employeeCarChecks,
    loading,
    isLastPage: page === carChecksLastPage,
    isEndReached: loadMoreCarChecks
  };

  return (
    <Grid container spacing={2} className={'h-100'}>
      <Grid item xs={7}>
        <div className="employee-cc-list-container">
          <PageContent className={"employee-cc-list"}>
            <CarCheckList {...carCheckListProps} />
          </PageContent>
        </div>
      </Grid>
    </Grid>
  );
};

export default EmployeeCarChecksContainer;

import CardAccountDetailsStarIcon from "mdi-react/CardAccountDetailsStarIcon";
import Avatar  from "@components/User/Avatar";
import { LicensePlate } from "components/Car/LicensePlate";
import GasStationIcon from "mdi-react/GasStationIcon";
import { Card, CARD_TYPES } from "components/Card";
import CircleIcon from "mdi-react/CircleIcon";
import { withRouter } from "react-router-dom";
import IconSet from "components/IconSet";
import CarIcon from "mdi-react/CarIcon";
import Check from "mdi-react/CheckIcon";
import "react-toggle/style.css";
import { store } from "index";
import React from "react";
import {
  CLEAR_MARKER,
  LIGHT_COLOR_GREEN,
  LIGHT_COLOR_RED,
  SELECT_ID,
  GET_TRIPS_REQUEST,
  VEHICLE_GET_REQUEST,
  HOVER_MARKER,
} from "constants";
import { MAP_COORDINATES_SET } from "@constants";

@withRouter
@connect((state) => ({
  state: state,
  auth: state.auth,
  tickets: state.tickets,
}))
class Car extends React.Component {
  state = {
    statusOpen: false,
    markerId: false,
    currentMarkerId: "",
  };

  componentDidMount() {
    const {
      auth: { auth },
    } = this.props;
    this.props.dispatch({
      type: VEHICLE_GET_REQUEST,
      payload: { id: auth.user.id },
    });
  }

  render() {
    const {
      id,
      name,
      tags,
      surname,
      carType,
      attributes,
      telemetryId,
      manufacturer,
      vehicleModelType,

    } = this.props.event;


    const { onCarClick, openState } = this.props;

    const { licencePlate } = this.props.event;
    const { isCarcheckMissing, isVehicleRegistrationMissing } =
      this.props.properties;
    const { statusOpen, currentMarkerId } = this.state;
    const {
      state: { settings },
      event,
    } = this.props;
    const { started, kilometrage } = this.props.event;
    const kilometrageToday =
      kilometrage - this.props.event["kilometrage-start-of-day"];

    const getFuelLevel = () => {
      if (this.props.event["fuel-level"]) {
        return Math.round(this.props.event["fuel-level"]);
      }
      return "- ";
    };

    const onClickToggle = (marker) => {
      this.props.dispatch({
        type: CLEAR_MARKER,
      });

      if (currentMarkerId === marker.id) {
        this.setState({ currentMarkerId: "" });
        this.props.dispatch({
          type: HOVER_MARKER,
          payload: false,
        });
      } else {
        this.props.dispatch({
          type: MAP_COORDINATES_SET,
          payload: {
            lat: +marker.attributes?.latitude,
            lng: +marker.attributes?.longitude,
          },
        });

        this.setState({ currentMarkerId: marker.id });
        this.props.dispatch({
          type: SELECT_ID,
          payload: marker.id,
        });

        this.props.dispatch({
          type: HOVER_MARKER,
          payload: marker,
        });

        this.props.dispatch({
          type: GET_TRIPS_REQUEST,
          payload: { id: marker.id },
        });

        this.setState((state) => {
          return {
            statusOpen: !state.statusOpen,
          };
        });
      }
    };

    const getCarAvatar = () => {
      let carLogo = "";
      const {
        vehicles: { vehicles },
      } = this.props.state;
      vehicles.map((el) => {
        if (licencePlate === el.licencePlate) {
          carLogo = el.carAvatar;
        }
      });
      return carLogo;
    };

    const divideNumber = (number) => {
      const param = Math.round(Number(number));
      const { auth } = this.props.state;
      const language = auth?.language ? auth.language : "en-GB";
      if (language === "de-DE") {
        return param.toLocaleString("de-DE");
      }
      return param.toLocaleString(language);
    };

    const { markerId } = this.state;
    const {
      vehicles: { trips },
    } = this.props.state;

    const openCard = settings.marker;
    const open =
      (currentMarkerId && (settings.selectId === id || openCard[0] === id)) ||
      openCard[0] === id;

    const cutTypCar = () => {
      let car = `${manufacturer} ${vehicleModelType}`;
      if (!open) {
        if (car.length >= 12) {
          const text = car.substring(0, 12);
          car = `${text} ...`;
        }
        return car;
      }
      return car;
    };

    const cutEmployee = () => {
      if (!name && !surname) {
        return "-";
      } else {
        let user = `${name} ${surname}`.trim();
        if (!open) {
          if (user.length >= 15) {
            const text = user.substring(0, 15);
            user = `${text} ...`;
          }
          return user;
        } else {
          return user;
        }
      }
    };

    return (
      <div className={open ? "car-section car-open" : "car-section"}>
        <Card
          id={id}
          tags={tags}
          event={event}
          trips={trips}
          markerId={markerId}
          status={statusOpen}
          typeComponent={true}
          secondaryText={null}
          onClick={onClickToggle}
          marker={settings.marker}
          telemetryId={telemetryId}
          currentMarkerId={currentMarkerId}
          kilometrageToday={kilometrageToday}
          primaryText={cutTypCar()}
          subText={cutEmployee()}
          type={
            isCarcheckMissing || isVehicleRegistrationMissing
              ? CARD_TYPES.RED
              : CARD_TYPES.GREEN
          }
          renderLeft={
            <Avatar
              small={true}
              src={getCarAvatar()}
              missingIcon={<CarIcon />}
              iconBackgroundColor={'transparent'}
              icon={
                <CircleIcon
                  color={started ? LIGHT_COLOR_GREEN : LIGHT_COLOR_RED}
                />
              }
            />
          }
          renderRight={
            <React.Fragment>
              <h4>
                {kilometrage
                  ? `${divideNumber(kilometrage)} km`
                  : "- km"}
              </h4>
              <IconSet>
                <div className={"icon-left"}>
                  <GasStationIcon />
                  {`${getFuelLevel()}%`}
                </div>
                <div className={"icon-right"}>
                  {isVehicleRegistrationMissing && <CarIcon color={"red"} />}
                  {isCarcheckMissing && (
                    <CardAccountDetailsStarIcon color={"red"} />
                  )}
                  {!isCarcheckMissing && !isVehicleRegistrationMissing && (
                    <Check className="check" color={"#95C11E"} />
                  )}
                </div>
              </IconSet>
              <LicensePlate country="D" license={licencePlate} />
            </React.Fragment>
          }
        />
      </div>
    );
  }
}

export { default } from "./Vehicle";

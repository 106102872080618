import React from 'react';

import DataForm from "@components/Common/Form/DataForm";
import PageContent from "@components/Layout/PageContent";
import { t } from "@helpers/i18n";

const EmployeeVehicle = ({
  manufacturer,
  location,
  kilometrage,
  velocity,
  fuelLevel,
  file
}) => {
  const createDownloadLink = () => {
    const fileTypes = {
      'J': 'pdf',
      '/': 'jpg',
      'i': 'png'
    };
    
    const fileType = fileTypes[file?.blob.charAt(0)];
    let href = `data:image/png;base64, ${file?.blob}`;

    if (fileType === 'pdf') {
      href = `data:application/pdf;base64, ${file?.blob}`;
    }

    return (
      <a href={href} download={`Vehicle-registration.${fileType}`}>
        Vehicle-registration.{fileType}
      </a>
    );
  };

  const vehicleInfo = [
    {
      name: t("vehicle-type"),
      value: manufacturer || '-'
    },
    {
      name: t("vehicle-location"),
      value: location || '-'
    },
    {
      name: t("vehicle-mileage"),
      value: kilometrage || '-'
    },
    {
      name: t("vehicle-velocity"),
      value: velocity || '-'
    },
    {
      name: t("vehicle-fuel"),
      value: fuelLevel || '-'
    },
    {
      name: t("vehicle-registration"),
      value: file ? createDownloadLink() : '-'
    }
  ];

  

  return (
    <PageContent>
      <DataForm data={vehicleInfo} />
    </PageContent>
  );
};

export default EmployeeVehicle;
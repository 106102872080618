import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { t } from "@helpers/i18n";

/** load components **/
import PageHeader from "@components/Layout/PageHeader";
import ProfileNavigation from "@components/ProfileNavigation";
import ProfileInfoBlock from "@components/ProfileInfoBlock";

/** load icons **/
import CardAccountDetailsOutlineIcon from "mdi-react/CardAccountDetailsOutlineIcon";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import SettingsIcon from "mdi-react/CogOutlineIcon";
import CaseIcon from "mdi-react/BriefcaseOutlineIcon";

import {activeTabIndex} from "@services/utils";

import * as profileActions from "@store/profile/actions";

import "./Profile.styles.scss";

const Profile = (props) => {
  const dispatch = useDispatch();
  const { auth } = useSelector(state => state.auth);
  const { profile } = useSelector(state => state.profile);


  // format user profile data
  const name = profile?.name || '';
  const surname = profile?.surname || '';
  const customerNumber = profile?.customerNumber || '';
  const company = profile?.company || '';
  const avatar = profile?.avatar || '';
  const licensePlate = profile?.car?.licencePlate || '';
  const userRole = auth?.user?.accesslevel || '';


  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(profileActions.profileGet({
      userId: auth.info.id
    }));
    dispatch(profileActions.profileGetLicense({
      userId: auth.info.id
    }));
  }, []);


  /** Profile tabs **/
  const routes = [
    {
      key: "personal-info",
      icon: <AccountOutlineIcon />,
      path: "/profile/personal-info"
    },
    {
      key: "drivers-license",
      icon: <CardAccountDetailsOutlineIcon />,
      path: "/profile/drivers-license",
    },
    {
      key: "business-info",
      icon: <CaseIcon />,
      path: "/profile/business-info",
    }
  ];

  if (userRole === 'manager') {
    routes.push({
      key: "settings",
      icon: <SettingsIcon />,
      path: "/profile/settings",
    });
  }

  return (
    <div className="details-container">
      <PageHeader text={t("back")} profile={true}>
        <div className="header-info-row">
          <ProfileInfoBlock
            name={name}
            surname={surname}
            customerNumber={customerNumber}
            company={company}
            avatar={avatar}
            licensePlate={licensePlate}
          />
          <ProfileNavigation
            routes={routes}
            activeIndex={activeTabIndex(routes, pathname)}
            horizontal={true}
          />
        </div>
      </PageHeader>

      {props.render}
    </div>
  );
};
export default Profile;

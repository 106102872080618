import React from 'react';
import PropTypes from 'prop-types';
/** load formatters **/
import {dateFormatter} from '@services/formatters/util/DateTime.formatters';
import DATE_FORMATS from '@helpers/constants/dateFormats';
import {InvoiceSumFormatter} from '@services/formatters/invoices/UnvoiceCard.formatter';


const InvoiceRightBlock = (props) => {

  const { invoice } = props;

  const { invoiceDate } = invoice;
  const invoiceFormattedDate = dateFormatter(invoiceDate, DATE_FORMATS.full_DMY_dot);
  const invoiceSum = InvoiceSumFormatter(invoice.sum_total_with_vat);

  return (
    <div className={'flex flex-col h-100 justify-evenly'}>
      <p>{ invoiceFormattedDate }</p>
      <p> { invoiceSum }</p>
    </div>
  );
};

InvoiceRightBlock.propTypes = {
  invoice: PropTypes.object
};

InvoiceRightBlock.defaultProps = {
  invoice: {}
};

export default InvoiceRightBlock;

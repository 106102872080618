import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Grid from '@mui/material/Grid';
import { CarCheckList }from '@components/CarCheck';
import {getVehiclesCarChecksRequest, loadMoreVehiclesCarChecksRequest} from '@store/vehicles/actions';
import {useParams} from 'react-router-dom';
import VehicleMap from "@components/Vehicle/VehicleMap/VehicleMap.component";

const VehiclesCarCheckComponent = () => {


  const {
    loadingCarChecks,
    carchecks,
    lastCarCheckPage,
    loadingMoreCarChecks,
    generalInfo,
    vehicle,
  } = useSelector(state => state.vehicles);

  const {id} = useParams();

  const dispatch = useDispatch();
  const [page, setPage] = useState(0);

  useEffect(() => {
    dispatch(getVehiclesCarChecksRequest({id, page: 0}));
  }, []);


  const loadMoreCarChecks = () => {
    if (loadingMoreCarChecks || loadingCarChecks) {
      return;
    }
    setPage(page + 1);
    dispatch(loadMoreVehiclesCarChecksRequest({id, page: page + 1}));
  };



  return (
    <>
      <Grid container style={{height: '100%'}}>
        <Grid item xs={7}>
          <div className="details-content">
            <CarCheckList
              carChecks={carchecks}
              loading={loadingCarChecks}
              page={page}
              lastPage={lastCarCheckPage === page }
              isEndReached={loadMoreCarChecks}
            />
          </div>
        </Grid>
        <Grid item xs={5}>
          <VehicleMap generalInfo={generalInfo} vehicle={vehicle} />
        </Grid>
      </Grid>
    </>
  );
};

VehiclesCarCheckComponent.propTypes = {};
export default VehiclesCarCheckComponent;

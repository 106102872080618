import React, { useMemo } from 'react';
import LazyLoadingList from "@components/LazyLoadingList";
import TimeTrackingCard from "@components/TimeTracking/Card/TimeTrackingCard.component";
import PropTypes from 'prop-types';


const TimeTrackingCardList = (props) => {
  const {
    loading,
    trackings,
    isLastPage,
    isEndReached,
    onTrackingClick
  } = props;

  const renderTimeTrackingCard = (tracking, i) => (
    <TimeTrackingCard
      tracking={tracking}
      key={i}
      onTrackingClick={onTrackingClick}
    />
  );

  const renderContent = useMemo( () =>
    <LazyLoadingList
      data={trackings || []}
      loading={loading}
      isLastPage={isLastPage}
      onEndReached={isEndReached}
      renderItem={renderTimeTrackingCard}
    />
  , [loading, trackings]);

  return (
    <>
      {renderContent}
    </>
  );
};

TimeTrackingCardList.propTypes = {
  loading: PropTypes.bool,
  trackings: PropTypes.array,
  isLastPage: PropTypes.bool,
  isEndReached: PropTypes.func,
  onTrackingClick: PropTypes.func
};

TimeTrackingCardList.defaultProps = {
  loading: false,
  trackings: [],
  isLastPage: false,
  isEndReached: () => {},
  onTrackingClick: () => {}
};

export default React.memo(TimeTrackingCardList);

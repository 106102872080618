import React from 'react';

import SpeedometerIcon from "mdi-react/SpeedometerIcon";
import GasStationIcon from "mdi-react/GasStationIcon";
import Avatar from "@components/User/Avatar";

const DriverPopupCmp = (props) => {

  const {
    username,
    userCar,
    customerCity,
    speed,
    fuelLevel,
    tags,
    place,
    driverAvatar
  } = props;

  return (
    <div className="driver-popup">
      <div className="info-container">
        <div className="info-header">
          <p className="header-name">{username}</p>
          <p className="header-car">{userCar}</p>
        </div>
        <div className="popup-footer">
          <div className="customer-city">
            {customerCity}
          </div>
          <div className="customer-data">
            <div className="data-line">
              <div className="line-start">{place}</div>
              <SpeedometerIcon />
              <div className="line-end">{speed} km/h</div>
            </div>
            <div className="data-line">
              <div className="line-start">{place}</div>
              <GasStationIcon />
              <div className="line-end">{fuelLevel}%</div>
            </div>
          </div>
        </div>
        <Avatar
          small
          src={driverAvatar}
        />
        <div className="triangle" />
      </div>
    </div>
  );

};

export default DriverPopupCmp;

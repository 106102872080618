import StarHalfFullIcon from "mdi-react/StarHalfFullIcon";
import StarOutlineIcon from "mdi-react/StarOutlineIcon";
import Services from "@assets/svg/service.svg";
import BuildIcon from "mdi-react/BuildIcon";
import Brakes from "@assets/svg/brakes.svg";
import StarIcon from "mdi-react/StarIcon";
import Tires from "@assets/svg/tires.svg";
import "react-toggle/style.css";
import React from "react";
import "./Service.styles.scss";

import { BRANDS_TYPE } from "@constants";

const Service = ({ service, onServiceClick }) => {
  const { address, rating, title, services, brands } = service;

  const renderBrands = () => {
    if (!!brands.length) {
      return brands
        .filter((brand) => BRANDS_TYPE.includes(brand))
        .slice(0, 3)
        .map((brand, key) => (
          <span key={key}>
            <img
              src={require(`../../../assets/img/${brand}.png`)}
              alt={brand}
            />
          </span>
        ));
    } else {
      return (
        <div className="brand-default">
          <BuildIcon />
        </div>
      );
    }
  };

  const trimText = (text, textLength = 24) => {
    return text.length >= textLength ? `${text.substring(0, 24)} ...` : text;
  };

  const renderStars = (rating) => {
    return [0, 1, 2, 3, 4].map((value, key) => {
      const rate = rating - value;

      if (rate >= 1) {
        return <StarIcon key={key} />;
      }

      if (rate >= 0.5) {
        return <StarHalfFullIcon key={key} />;
      }

      return <StarOutlineIcon key={key} />;
    });
  };

  return (
    <div className="service" onClick={() => onServiceClick(service)}>
      <div className="name">
        <div className="brands">{renderBrands()}</div>
        <div className="info">
          <p className="title">{trimText(title)}</p>
          <p className="street">
            {trimText(`${address.street} ${address.number}`)}
          </p>
          <p className="city">{trimText(`${address.zip} ${address.city}`)}</p>
        </div>
      </div>
      <div className="data">
        <div className="stars">{renderStars(rating)}</div>
        <div className="icons">
          {services ? (
            <div className="icons-img">
              {services.includes("Panne") && <img src={Tires} alt="icon" />}
              {services.includes("Bremsen") && <img src={Brakes} alt="icon" />}
              {services.includes("Reifenwechsel") && (
                <img src={Services} alt="icon" />
              )}
              {!services.includes("Panne") &&
                !services.includes("Bremsen") &&
                !services.includes("Reifenwechsel") &&
                "-"}
            </div>
          ) : (
            "-"
          )}
        </div>
      </div>
    </div>
  );
};

export default Service;

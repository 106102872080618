import { requestMs } from "@helpers/api";

export const tickets = {
  getTicketsForCustomer: (userId, queryParams) =>
    requestMs.get(`/api3/user/${userId}/offense?${queryParams}`, {}),
  getSingleTicket: (params) => requestMs.get(`/api3/offense/${params.id}`, {}),
  read: (params) => requestMs.post(`/api3/offense/${params.id}/read`, params),
  delete: (params) => requestMs.delete(`/api3/offense/${params.id}`, {}),
  reset: (params) => requestMs.post(`/api3/offense/${params.id}/reset`, params),
  complete: (params) =>
    requestMs.post(`/api3/offense/${params.id}/manual`, params), // used for completing ticket
  object: (id, mail) =>
    requestMs.post(`/api3/offense/${id}/object`, mail),
  pay: (params) => requestMs.post(`/api3/offense/${params.id}/pay`, params),
  assign: (params) =>
    requestMs.post(`/api3/offense/${params.id}/assign`, params),
  create: (params) => requestMs.post("/api3/offense", params),
  attach: (params) =>
    requestMs.post(`/api3/offense/${params.id}/media`, params.media),
};

import React from "react";
import {useSelector} from "react-redux";

/** load components **/
import Grid from '@mui/material/Grid';
import BusinessInfo from "./BusinessInfo";
import MapCmp from "@components/Common/Map/MapCmp";

import "./BusinessInfoTab.styles.scss";

const BusinessInfoTabContainer = () => {
  const {profile = {}} = useSelector(state => state.profile);
  const {
    company = '',
    businessAddress: {
      city = '',
      number = '',
      street = '',
      zipcode = ''
    } = {}
  } = profile;

  const businessInfoProps = {company, street, number, zipcode, city};

  return (
    <Grid container spacing={2} style={{ height: '100%'}}>
      <Grid item container xs={7}>
        <BusinessInfo {...businessInfoProps} />
      </Grid>
      <Grid item xs={5}>
        <MapCmp hasLocation />
      </Grid>
    </Grid>
  );
};

export default BusinessInfoTabContainer;

import React from "react";

import {NO_DATE, BUTTON_TYPES} from "@constants";
import AccountOutlineIcon from "mdi-react/AccountEditOutlineIcon";
import {t,date} from "@helpers/i18n";

import Grid from '@mui/material/Grid';
import { Button } from "@components/Common/Button";
import DataForm from "@components/Common/Form/DataForm";
import MapCmp from "@components/Common/Map/MapCmp";

const PersonalInformation = ({profile, auth, onEditClick}) => {
  const getAddress = ({street, number, city, zipcode}) => {
    if(street || number || city || zipcode){
      return (
        <div className="personal-workplace_address">
          <p className="address_place">{`${street} ${number}`}</p>
          <p className="address_place">{`${zipcode} ${city}`}</p>
        </div>
      );
    } else {
      return '-';
    }
  };

  const personalInfoData = [
    {
      name: t("phone"),
      value: profile.phone ? profile.phone : '-'
    },
    {
      name: t("e-mail"),
      value: profile.email ? profile.email : '-'
    },
    {
      name: t("address"),
      value: getAddress(profile.address)
    },
    {
      name: t("birthday"),
      value: date(profile.birthday) && date(profile.birthday) !== NO_DATE ? date(profile.birthday): '-'
    },
  ];

  const renderEditButton = () => {
    return profile.id === auth.info.id &&
          <Button
            type={profile.isUserManagingDirector ? BUTTON_TYPES.DISABLED : BUTTON_TYPES.WARNING }
            icon={<AccountOutlineIcon/>}
            text={t("edit-information")}
            className={'self-center'}
            onClick={onEditClick}
            isWarning
          />;
  };

  return (<div className={'flex-1'}>
    <Grid container style={{ height: '100%'}}>
      <Grid item xs={7}>
        <div className={'details-content flex flex-col justify-between p-b-2'}>
          <DataForm data={personalInfoData} />
          { renderEditButton() }
        </div>
      </Grid>
      <Grid item xs={5}>
        <MapCmp hasLocation />
      </Grid>
    </Grid>
  </div> );
};

PersonalInformation.propTypes = {
  profile: PropTypes.object,
  auth: PropTypes.object,
  onEditClick:  PropTypes.func,
};

PersonalInformation.defaultProps = {
  profile: {},
  auth: {},
  onEditClick: () => {},
};

export default PersonalInformation;

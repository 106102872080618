import React, {useRef, useState} from 'react';
import PropTypes from "prop-types";

/** Load components **/
import CarCheckImage from "./CarCheckImage";
import Slider from '@components/Common/Slider';

/** Load component style **/
import style from './style.scss';

/** api call **/
import { media } from '../../../../../../api/media';



const CarCheckImageList = ({ carCheckId, carChecks }) => {

  const sliderRef = useRef();

  const [slides, setSlides] = useState(new Array(carChecks.length).fill(null));

  /** Call back method when car check image is clicked **/
  const onImageClick = (index) => {
    sliderRef.current.previewImage(index);
  };

  /**
   * API call for loading full resolution image
   * @param {number} index
   **/
  const loadFullImage = (index) => {
    return media.getFullImage({id: carCheckId, name: carChecks[index]?.name, type: 'carcheck' , category:''});
  };


  return (<div className={'car-check-image-list'}>
    {
      carChecks.map((carCheck, index) => {
        return (
          <CarCheckImage
            key={index}
            imgSrc={carCheck.blob}
            onImageClick={() => onImageClick(index)}
            alt={`carCheck-img-${index}`}/>
        );
      })
    }
    {
      <Slider
        ref={sliderRef}
        slides={slides}
        setSlides={setSlides}
        imageAPI={loadFullImage}
      />
    }
  </div> );
};

CarCheckImageList.propTypes = {
  carChecks: PropTypes.array,
  carCheckId: PropTypes.string.isRequired
};

CarCheckImageList.defaultProps = {
  carChecks: [],
  carCheckId: '1',
};

export default CarCheckImageList;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Grid from '@mui/material/Grid';

import CardListDamage from "@components/Card/CardListDamage";

import PageContent from "@components/Layout/PageContent";
import * as employeesActions from "@store/employees/actions";
import * as damagesConstants from "@store/damages/constants";

import "./EmployeeDamages.styles.scss";

const EmployeeDamagesContainer = () => {
  const [page, setPage] = useState(0);
  const history = useHistory();

  const {
    employee,
    employeeDamages,
    damagesLastPage,
    loading,
    loadingMoreDamages
  } = useSelector(state => state.employees);

  const dispatch = useDispatch();

  const loadMoreDamages = () => {
    if ((damagesLastPage - 1) > page && !loadingMoreDamages) {
      const nextPage = page + 1;

      dispatch(employeesActions.employeeLoadMoreDamages({
        employeeId: employee.userId,
        page: nextPage,
      }));
      setPage(nextPage);
    }
  };

  // TODO: This should be refactored
  const onDamageCardClick = (id) => {
    const damage = employeeDamages.find((damage) => damage.id === id);

    dispatch({
      type: damagesConstants.SET_CURRENT_DAMAGE,
      payload: {
        ...damage,
      },
    });
    history.push(`/damages/${id}/partlight`);
  };

  useEffect(() => {
    if(employee) {
      dispatch(employeesActions.employeeGetDamages({
        employeeId: employee.userId,
      }));
    }
  }, [employee]);

  const cardListDamageProps = {
    damages: employeeDamages,
    loading,
    page,
    lastPage: damagesLastPage === page,
    isEndReached: loadMoreDamages,
    onDamageCardClick
  };

  return (
    <Grid container spacing={2} style={{ height: '100%' }}>
      <Grid item xs={7}>
        <div className="employee-damages-list-container">
          <PageContent className={'employee-damages-list'}>
            <CardListDamage {...cardListDamageProps} />
          </PageContent>
        </div>
      </Grid>
    </Grid>
  );
};

export default EmployeeDamagesContainer;

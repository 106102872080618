import React from 'react';
import PropTypes from "prop-types";

/** Load components **/
import Toggler from "@components/Common/Toggler";
import FilterDatePicker from "@components/Common/FilterDatePicker";

/** Load helper functions **/
import { t } from "@helpers/i18n";

const TrackingFilterForm = (props) => {

  const { filter, onChange, trackingDates, onDateChange } = props;

  return (
    <div className={'filter-rows'}>
      <div className="toggle">
        <label key={filter.id}>
          <p>{t(filter.title)}</p>
          {/*<Toggler*/}
          {/*  id={filter.id.toString()}*/}
          {/*  checked={filter.value}*/}
          {/*  onChange={() => onChange(filter.title)} />*/}
        </label>
        {filter.value &&
      <div className="date-picker">
        <FilterDatePicker
          dateFrom={trackingDates.dateFrom}
          dateTo={trackingDates.dateTo}
          onDateChange={onDateChange}
        />
      </div>
        }
      </div>
    </div>);
};

TrackingFilterForm.propTypes = {
  filterValues: PropTypes.array,
  onChange: PropTypes.func,
  onDateChange: PropTypes.func
};

TrackingFilterForm.defaultProps = {
  filterValues: [],
  onChange: () => {},
  onDateChange: () => {}
};

export default React.memo(TrackingFilterForm);

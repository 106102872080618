import React, {useMemo, useCallback} from "react";

/* Importing the components from the respective files. */
import SimpleCard from "@components/Card/SimpleCard";
import Avatar from "@components/User/Avatar";
import { LicensePlate } from "@components/Car/LicensePlate";
import VehicleInspection from "@components/Vehicle/Card/VehicleInspection";

/* Importing icons from Material Design Icons. */
import CarIcon from "mdi-react/CarIcon";
import GasStationIcon from "mdi-react/GasStationIcon";
import CardAccountDetailsStarIcon from "mdi-react/CardAccountDetailsStarIcon";
import IconSet from "@components/IconSet";
import Check from "mdi-react/CheckIcon";
import Flag from "mdi-react/FlagVariantIcon";
import Info from "mdi-react/InformationIcon";

/* Importing the functions from the respective files. */
import { format } from "date-fns";
import { t } from "@helpers/i18n";
import {mileageFormatter} from "@services/formatters";
import {CARD_TYPES} from "@components/Card";

/* Importing the styles for the component. */
import "./Vehicle.styles.scss";
import {DATE_FORMAT_DMY_DOT, DATE_FORMAT_YMD_DASH, dateFormatter} from "@services/formatters/util/DateTime.formatters";


const Vehicle = (props) => {

  const { vehicle, trips, open, onVehicleClick } = props;

  const {
    id,
    driver,
    carAvatar,
    manufacturer,
    vehicleModelType,
    tags,
    isCarcheckMissing,
    isVehicleRegistrationMissing,
    kilometrage,
  } = vehicle;

  const licencePlate = vehicle["license-plate"];
  const fuelLevel = vehicle["fuel-level"];
  const todayDate = format(new Date(), DATE_FORMAT_DMY_DOT);

  const getDriver = () => {
    if (!driver.name && !driver.surname) {
      return "-";
    }
    const user = `${driver.name} ${driver.surname}`.trim();
    return !open && user.length >= 15 ? `${user.substring(0, 15)} ...` : user;
  };

  const getCarType = () => {
    const car = `${manufacturer} ${vehicleModelType}`;
    return !open && car.length >= 12 ? `${car.substring(0, 12)} ...` : car;
  };

  const getFuelLevel = () => {
    return fuelLevel ? Math.round(fuelLevel) : "- ";
  };

  const getTags = () => {
    let tagsText = "-";

    if (tags.length) {
      tagsText = tags.map((tag) => tag.name).join(", ");
      if (!open && tagsText.length >= 12) {
        tagsText = `${tagsText.substring(0, 12)} ...`;
      }
    }

    return tagsText;
  };

  /**
   * return proper class for avatar of Vehicle card
   **/
  const getIconBackgroundColor = useMemo(() => {
    if (vehicle?.isCarReadyToDrive){
      return CARD_TYPES.GREEN;
    } else {
      return CARD_TYPES.RED;
    }
  }, [vehicle]);

  /**
   * return proper class for right border of Vehicle card
   **/
  const vehicleCardColor = useMemo(() => {
    if (vehicle.isCarcheckOkay
        && !vehicle.isCarcheckMissing
        && !vehicle.isVehicleRegistrationMissing ){
      return `damage_border_${CARD_TYPES.GREEN}`;
    } else {
      return `damage_border_${CARD_TYPES.RED}`;
    }
  },[vehicle]);

  const getLast3DaysRange = useCallback(() => {
    const today = new Date();
    const dayBeforeYesterday = new Date(today);
    dayBeforeYesterday.setDate(today.getDate() - 2);

    return `${format(dayBeforeYesterday, "dd.MM")}-${format(today, DATE_FORMAT_DMY_DOT)}`;
  }, []);

  const getPreviousDay = (date = new Date(), daysBefore = 1) => {
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() - daysBefore);

    return previous;
  };

  const getTripKilometers = useCallback(() => {
    let todayKilometers = '0';
    let yesterdayKilometers = '0';
    let totalKilometers = '0';
    trips?.per_date?.forEach(trip => {
      if (trip.date === dateFormatter(new Date().toISOString(), DATE_FORMAT_YMD_DASH)) {
        todayKilometers = trip.total;
      }
      if (trip.date === dateFormatter(getPreviousDay().toISOString(), DATE_FORMAT_YMD_DASH)) {
        yesterdayKilometers = trip.total;
      }
    });
    if (trips?.total || trips?.total === 0) {
      totalKilometers = trips.total;
    }
    return {todayKilometers, yesterdayKilometers, totalKilometers};
  },[trips]);

  const primaryContent = () => {
    return (
      <>
        <p className="primary-text">{getCarType()}</p>
        <p className="tags">{getTags()}</p>
        <p className="sub-text">{getDriver()}</p>
      </>
    );
  };

  const renderLeft = () => (
    <Avatar
      iconBackgroundColor={getIconBackgroundColor}
      src={carAvatar}
      small
      icon={<></>}
    />
  );

  const renderRight = () => (
    <>
      <p>{kilometrage ? `${mileageFormatter(Math.round(kilometrage))} km` : "- km"}</p>
      <IconSet>
        <div className={"icon-left"}>
          <GasStationIcon />
          {`${getFuelLevel()}%`}
        </div>
        <div className={"icon-right"}>
          {isVehicleRegistrationMissing && <CarIcon color={"red"} />}
          {isCarcheckMissing && <CardAccountDetailsStarIcon color={"red"} />}
          {!isCarcheckMissing && !isVehicleRegistrationMissing && (
            <Check className="check" color={"#95C11E"} size={18}/>
          )}
        </div>
      </IconSet>
      <LicensePlate country="D" license={licencePlate} />
    </>
  );

  const renderBottom = () => {
    return (
      <div className={'vehicle-inspection-and-history'}>
        <VehicleInspection carInspectionStatus={vehicle?.carInspectionStatus} />
        <p className="trip">{t("trip_history")}</p>
        <div className="description">
          <div className="info-left">
            <Flag />
            <span className="today">
              {t("today")} {todayDate}
            </span>
          </div>
          <div className="info-right">
            <span className="km">{getTripKilometers().todayKilometers} km</span>
            <Info />
          </div>
        </div>
        <div className="description">
          <div className="info-left">
            <Flag />
            <span className="today">
              {t("yesterday")} {dateFormatter(getPreviousDay().toISOString(), DATE_FORMAT_DMY_DOT)}
            </span>
          </div>
          <div className="info-right">
            <span className="km">{getTripKilometers().yesterdayKilometers} km</span>
            <Info />
          </div>
        </div>
        <div className="description">
          <div className="info-left">
            <Flag />
            <span className="today">
              {t("last3days")} {getLast3DaysRange()}
            </span>
          </div>
          <div className="info-right">
            <span className="km">{getTripKilometers().totalKilometers} km</span>
            <Info />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={open ? "car-section car-open" : "car-section"}>
      <SimpleCard
        id={id}
        primaryContent={primaryContent}
        onClick={() => onVehicleClick(vehicle)}
        renderLeft={renderLeft}
        renderRight={renderRight}
        className={`damage-card ${vehicleCardColor}`}
      >
        {open && renderBottom()}
      </SimpleCard>
    </div>
  );
};

export default Vehicle;

import React from 'react';
import { Button } from "@components/Common/Button";
import {t} from "@helpers/i18n";
import Close from "mdi-react/CloseIcon";
import {BUTTON_TYPES} from "@constants";
import  './Alert.styles.scss';
import PropTypes from "prop-types";

// todo refactor into more reusable component
// pass text as prop

const AlertCmp = ({ visible, onClick, title, children, type }) => {

  const defaultContent = () => {
    return (<>
      <p className={'alert-title'}>{title}</p>
      <div className="confirm-box">
        <Button
          type={type ?? BUTTON_TYPES.PRIMARY}
          text={t("ok")}
          onClick={() => onClick(true)}
          isSmall
          isFlat
        />
      </div>
    </>);
  };

  return (
    visible && <div className="cmp-alert">
      <div className="alert-content">
        {children || defaultContent()}
        <div className={'close-btn'} onClick={() => onClick(false)}>
          <Close />
        </div>
      </div>
    </div>);
};

export default AlertCmp;

AlertCmp.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.func
};

AlertCmp.defaultProps = {
  visible: false,
  onClick:  () => {},
  type: null
};

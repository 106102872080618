import React from "react";

/* Importing the components from the components folder. */
import SimpleCard from "@components/Card/SimpleCard";
import DamageInfo from "@components/Damage/Card/DamageInfo";
import DriverInfo from "@components/Damage/Card/DriverInfo";
import TicketAvatar from "@components/Ticket/Card/TicketAvatar";

/* Importing the functions from the tickets.js file in the helpers folder. */
import {getTicketCardBackgroundColor, getTicketStateCardColor} from "@helpers/common/tickets";
import PropTypes from "prop-types";
import {t} from "@helpers/i18n";
import './TicketCard.styles.scss';

const TicketCard = (props) => {
  const {
    state,
    fileNumber,
    licencePlate,
    userAvatar,
    userRole,
    date,
    driverName,
    driverSurname,
    onClick,
  } = props;

  /* Render Ticket Card Left Block */
  const renderLeft = () => (
    <TicketAvatar state={state} userAvatar={userAvatar} role={userRole} profileColor={getTicketCardBackgroundColor(state)} />
  );

  /* Render Ticket Card Right Block */
  const renderRight = () => (
    <DamageInfo date={date} damageNumber={fileNumber} vehicleId={licencePlate} />
  );

  /* Render Ticket Card Center Block */
  const renderPrimaryContent = () => (
    <DriverInfo driverName={driverName} driverSurname={driverSurname}/>
  );

  return (
    <div className={'ticket-card'}>
      <SimpleCard
        onClick={onClick}
        className={`damage-card ${getTicketStateCardColor(state, userRole)} ${getTicketCardBackgroundColor(state)}`}
        renderRight={renderRight}
        renderLeft={renderLeft}
        primaryContent={renderPrimaryContent}
      />
      {state === 'objected' && <div className={'objected-text'}>{t('objected')}</div>}
    </div>
  );
};

export default TicketCard;

TicketCard.propTypes = {
  onClick: PropTypes.func,
};

TicketCard.defaultProps = {
  onClick: () => {},
};

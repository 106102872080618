import React from 'react';
import PropTypes from 'prop-types';
import ReportingCardComponent from '@components/Reporting/ReportingCard/ReportingCard.component';
import Grid from '@mui/material/Grid';
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import './MileagePerDay.styles.scss';
import {mileageFormatter} from '@services/formatters';


const MileagePerDayComponent = (props) => {
  const {
    chartColors,
    reportData,
    titleComponent,
    selectedVehicles,
    monthlyMileage,
    getMonthLabel,
    month
  } = props;

  const tooltipText = "Die detaillierte Aufstellung der Laufleistung Ihrer Fahrzeuge auf Tagesbasis.";


  const formatMileageData = (data) => {
    if(!data) {
      return '-';
    }
    return mileageFormatter(Math.round(data));
  };
  
  /**
   * Custom legend for daily mileage graph
   * @param licencePLate
   * @param index
   * @returns {JSX.Element}
   */
  const mileageGraphLegend = (licencePLate, index) => {
    const vehicle = reportData.find(veh => veh.licencePlate === licencePLate);
    return (
      <div
        key={index.toString()}
        className="mileage-legend-item"
        style={{'borderLeftColor': chartColors[index % chartColors.length]}}>
        <div className={'vehicle-info'}>
          <p>Ford</p>
          <h2><strong>{licencePLate}</strong></h2>
          <p>Fleetlink</p>
          <p>{`${formatMileageData(vehicle?.mileage)} km`}</p>
        </div>
        <div className="legend-img-container">
          <img src={require('../../../assets/img/report_car_icon.png')} alt=""/>
        </div>
      </div>
    );
  };

  return (
    <Grid item xs={12}>
      <ReportingCardComponent
        headerContentLeft={titleComponent('Kilometer pro Tag je Fahrzeug', tooltipText)}
        headerContentRight={titleComponent(getMonthLabel(month))}>
        <Grid container>
          <Grid item xs={9} style={{minHeight: 'calc(30vw - 50px)'}}>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                data={Object.values(monthlyMileage)}
                margin={{
                  top: 15,
                  bottom: 15,
                  right: 20,
                }}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis
                  dataKey="name"
                  type={'number'}
                  tickCount={Math.min(7, Object.values(monthlyMileage).length)}
                  domain={[1, Object.values(monthlyMileage).length]}
                  allowDecimals={false}
                  interval={'preserveStartEnd'}/>
                <YAxis/>
                <Tooltip/>
                {selectedVehicles.map((veh, index) =>
                  <Line dataKey={veh} key={index.toString()} stroke={chartColors[index % chartColors.length]} strokeWidth={2}/>,
                )}
              </LineChart>
            </ResponsiveContainer>
          </Grid>
          <Grid item xs={3} className={'legend-container'}>
            {selectedVehicles.map((lp, index) => mileageGraphLegend(lp, index))}
          </Grid>
        </Grid>
      </ReportingCardComponent>
    </Grid>
  );
};

MileagePerDayComponent.propTypes = {
  reportData: PropTypes.array.isRequired,
  titleComponent: PropTypes.func.isRequired,
  selectedVehicles: PropTypes.array.isRequired,
  monthlyMileage: PropTypes.object.isRequired,
  getMonthLabel: PropTypes.func.isRequired,
  month: PropTypes.object.isRequired,
};
export default MileagePerDayComponent;

import React from 'react';
import PropTypes from 'prop-types';


import style from './style.scss';

const DamageImage = ({ imgSrc, alt, onImageClick }) => {

  return (<div className={'dmg-img'} onClick={onImageClick}>
    <img src={`data:image/png;base64, ${imgSrc}`} alt={alt} />
  </div> );
};


DamageImage.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  onImageClick: PropTypes.func,
  alt: PropTypes.string,
};

DamageImage.defaultProps = {
  imgSrc: '',
  onImageClick: () => {},
  alt: '',
};


export default DamageImage;

import * as vehiclesConstants from "./constants";

const initialState = {
  vehicles: [],
  vehicle: null,
  carchecks: [],
  detailedCarChecks: [],
  loadingCarChecks: false,
  loadingMoreCarChecks: false,
  lastCarCheckPage: 1,
  lastDamagesPage: 1,
  generalInfo: null,
  damages: [],
  loadingDamages: false,
  loadingMoreDamages: false,
  loading: true,
  loadMore: false,
  lastPage: 0,
  singleLoading: true,
  trips: [],
  tripsHistory: [],
  vehiclesExtended: [],
  vehicleDetails: {},
  savingTags: 0
};

export default function vehicles(state = initialState, action) {
  switch (action.type) {
    case vehiclesConstants.VEHICLE_GET_REQUEST:
    case vehiclesConstants.VEHICLE_GENERAL_INFORMATION_REQUEST:
    case vehiclesConstants.VEHICLE_GET_DAMAGES_BY_ID_REQUEST:
    case vehiclesConstants.GET_VEHICLE_EXTENDED_INFORMATION_REQUEST:
      return {
        ...state,
        loadingDamages: true,
        loading: true,
      };

    case vehiclesConstants.VEHICLE_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        vehicles: action.payload,
        lastPage: action.payload.length > 0 ? action.payload[0].pageTotal : 1,
      };

    case vehiclesConstants.VEHICLE_LOAD_MORE_GET_SUCCESS: {
      return {
        ...state,
        vehicles: [...state.vehicles, ...action.payload],
        lastPage: action.payload.length > 0 ? action.payload[0].pageTotal : state.lastPage,
      };
    }

    case vehiclesConstants.GET_VEHICLE_EXTENDED_INFORMATION_SUCCESS:
      return {
        ...state,
        loading: false,
        vehiclesExtended: action.payload,
      };

    case vehiclesConstants.VEHICLE_GENERAL_INFORMATION_SUCCESS:
      return {
        ...state,
        singleLoading: false,
        vehicle: action.payload,
      };

    case vehiclesConstants.VEHICLE_GET_CAR_CHECKS_BY_ID_REQUEST: {
      return {
        ...state,
        loadingCarChecks: true,
      };
    }
    case vehiclesConstants.VEHICLE_GET_CAR_CHECKS_BY_ID_SUCCESS:
      return {
        ...state,
        loadingCarChecks: false,
        carchecks: action.payload,
        lastCarCheckPage:
          action.payload.length > 0 ? action.payload[0].pageTotal : 1,
      };
    case vehiclesConstants.VEHICLE_GET_CAR_CHECKS_BY_ID_FAIL: {
      return {
        ...state,
        loadingCarChecks: false,
      };
    }

    case vehiclesConstants.VEHICLE_CAR_CHECK_LOAD_MORE: {
      return {
        ...state,
        loadingMoreCarChecks: true,
      };
    }
    case vehiclesConstants.VEHICLE_CAR_CHECK_LOAD_MORE_SUCCESS: {
      return {
        ...state,
        loadingMoreCarChecks: false,
        carchecks: [...state.carchecks, ...action.payload],
        lastCarCheckPage:
          action.payload.length > 0
            ? action.payload[0].pageTotal
            : state.lastCarCheckPage,
      };
    }
    case vehiclesConstants.VEHICLE_CAR_CHECK_LOAD_MORE_FAIL: {
      return {
        ...state,
        loadingMoreCarChecks: false,
      };
    }

    case vehiclesConstants.VEHICLE_DAMAGES_LOAD_MORE: {
      return {
        ...state,
        loadingMoreDamages: true,
      };
    }
    case vehiclesConstants.VEHICLE_DAMAGES_LOAD_MORE_SUCCESS: {
      const loadedDamages = action.payload || [];
      const mappedDamages = loadedDamages.map((damage) => {
        return {
          id: damage.id,
          state: damage.state,
          damageNumber: damage.damageNumber,
          vehicleId: damage.licencePlate,
          userAvatar: damage.driver.avatar,
          date: damage.date,
          driverName: damage.driver.name,
          driverSurname: damage.driver.surname,
        };
      });

      return {
        ...state,
        loadingMoreDamages: false,
        damages: [...state.damages, ...mappedDamages],
        lastDamagesPage:
          action.payload.length > 0
            ? action.payload[0].pageTotal
            : state.lastDamagesPage,
      };
    }
    case vehiclesConstants.VEHICLE_DAMAGES_LOAD_MORE_FAIL: {
      return {
        ...state,
        loadingMoreDamages: false,
      };
    }

    case vehiclesConstants.LOAD_SINGLE_CAR_CHECK:
      return {
        ...state,
        singleLoading: true,
      };
    case vehiclesConstants.LOAD_SINGLE_CAR_CHECK_SUCCESS:
      return {
        ...state,
        detailedCarChecks: [...state.detailedCarChecks, action.payload],
        singleLoading: false,
        carCheck: action.payload,
      };
    case vehiclesConstants.LOAD_SINGLE_CAR_CHECK_FAIL: {
      return {
        ...state,
        singleLoading: false,
      };
    }

    case vehiclesConstants.VEHICLE_GET_DAMAGES_BY_ID_SUCCESS:
      const loadedDamages = action.payload || [];
      const mappedDamages = loadedDamages.map((damage) => {
        return {
          id: damage.id,
          appState: damage.appState,
          state: damage.state,
          damageNumber: damage.damageNumber,
          vehicleId: damage.licencePlate,
          userAvatar: damage.driver.avatar,
          date: damage.date,
          driverName: damage.driver.name,
          driverSurname: damage.driver.surname,
        };
      });

      return {
        ...state,
        loading: false,
        loadingDamages: false,
        damages: mappedDamages,
        lastDamagesPage:
          action.payload.length > 0 ? action.payload[0].pageTotal : 1,
      };

    case vehiclesConstants.VEHICLE_GET_FAIL:
    case vehiclesConstants.VEHICLE_GENERAL_INFORMATION_FAIL:
    case vehiclesConstants.VEHICLE_GET_DAMAGES_BY_ID_FAIL:
      return {
        ...state,
        vehicle: null,
        loading: false,
        loadingDamages: false,
        damages: [],
      };

    case vehiclesConstants.SAVE_VEHICLE_TAGS_REQUEST : {
      return {
        ...state,
        savingTags: state.savingTags + 1,
      };
    }
    case vehiclesConstants.SAVE_VEHICLE_TAGS_SUCCESS : {
      return {
        ...state,
        savingTags: state.savingTags - 1,
      };
    }
    case vehiclesConstants.SAVE_VEHICLE_TAGS_FAIL : {
      return {
        ...state,
        savingTags: state.savingTags - 1,
      };
    }

    case vehiclesConstants.UPDATE_VEHICLE_TAGS : {
      return {
        ...state,
        vehicle: {...state.vehicle, tags: action.payload }
      };
    }

    case vehiclesConstants.GET_TRIPS_REQUEST:
      return {
        ...state,
        trips: action.payload,
      };

    case vehiclesConstants.GET_TRIPS_POSITIONS_REQUEST:
      return {
        ...state,
        tripsHistory: action.payload,
      };

    case vehiclesConstants.GET_TRIPS_SUCCESS:
      return {
        ...state,
        trips: action.payload,
      };

    case vehiclesConstants.GET_TRIPS_POSITIONS_SUCCESS:
      return {
        ...state,
        tripsHistory: action.payload,
      };

    case vehiclesConstants.GET_TRIPS_POSITIONS_FAIL:
      return {
        ...state,
        tripsHistory: action.payload,
      };

    case vehiclesConstants.GET_TRIPS_FAIL:
      return {
        ...state,
        trips: action.payload,
      };

    case vehiclesConstants.GET_VEHICLE_LOCATION:
      return {
        ...state,
        singleLoading: true,
      };
    case vehiclesConstants.GET_VEHICLE_LOCATION_SUCCESS:
      return {
        ...state,
        singleLoading: false,
        generalInfo: action.payload,
      };
    case vehiclesConstants.GET_VEHICLE_LOCATION_FAIL:
      return {
        ...state,
        singleLoading: false,
        generalInfo: null,
      };

    case vehiclesConstants.VEHICLE_ASSIGN_DRIVER:
      return {
        ...state,
        singleLoading: true,
      };
    case vehiclesConstants.VEHICLE_ASSIGN_DRIVER_SUCCESS:
      return {
        ...state,
        singleLoading: false,
        vehicle: action.payload,
      };
    default:
      return state;
  }
}

import React from 'react';
import PropTypes from 'prop-types';

/** load components **/
import DamageItem from './DamageItem';

const DamageListContainer = ({ damages }) => {

  return damages.map((damage, index) => {
    return <DamageItem damage={damage} index={index} key={index}/>;
  });
};

DamageListContainer.propTypes = {
  damages: PropTypes.array
};

DamageListContainer.defaultProps = {
  damages: []
};

export default DamageListContainer;

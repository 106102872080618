import React from 'react';
import PropTypes from 'prop-types';
/** Loading SVG **/
import DotsVertical from '@assets/svg/trips/verticalDots.svg';


const MainTrip = (props) => {

  const { tracks, isMainSelected, onMainTripClick } = props;

  /**
   * Extracting tracks details from props
   */
  const [firstTrack] = tracks;
  const lastTrack = tracks.at(-1);
  const startingPosition = firstTrack['starting-position'];
  const endingPosition = lastTrack['stop-position'];

  /**
   * On whole Trip click event
   */
  const onEditWholeDayTrip = () => {
    // toggle flag for whole trip selection
    onMainTripClick(!isMainSelected);
  };

  return (<div className={`single-trip-container first ${isMainSelected ? 'trip-expanded' : ''}`} >
    <div className={'row-data flex'} onClick={onEditWholeDayTrip}>
      <div className={'left-text'}>
        <p></p>
        <p className={'time-margin'}><span></span></p>
        <p className={'time-margin'}><span></span></p>
        <p className={'time-margin'}>
        </p>

      </div>
      <img src={DotsVertical} alt={'long=dots'} />
      <div className={'right-text'}>
        <p>{startingPosition.street}, {startingPosition.zip} {startingPosition.city}</p>
        <p className={'time-margin'}></p>
        <p className={'time-margin'}> </p>
        <p>{endingPosition.street}, {endingPosition.zip} {endingPosition.city}</p>
      </div>
    </div>
  </div>);
};

MainTrip.propTypes = {
  tracks: PropTypes.array,
  isMainSelected: PropTypes.bool,
  onMainTripClick: PropTypes.func
};

MainTrip.defaultProps = {
  tracks: [],
  isMainSelected: false,
  onMainTripClick: () => {}
};

export default MainTrip;

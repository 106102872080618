import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import { errorHandler } from "@helpers/saga";
import md5 from "js-md5";
import { Api } from "@api";

import * as profileConstants from "./constants";
import * as authConstants from "@store/auth/constants";
import * as employeeActions from "@store/employees/actions";
import * as profileActions from './actions';

import store from "store2";
import { translationKeys } from "@constants";



function* getProfile(action) {
  try {
    const { userId } = action.payload;
    const { data: profileData } = yield call(Api.users.getUserData, userId);
    const { data: userAvatar } = yield call(Api.users.getAvatar, userId);
    profileData.language = translationKeys[profileData.language.substring(0, 2)];
    yield put({ type: profileConstants.PROFILE_GET_SUCCESS, payload: { ...profileData, avatar: userAvatar.avatar }});
    // todo remove later,
    // temp fix when user changes avatar and reloads page
    store.set('profile', { ...profileData, avatar: userAvatar.avatar });
  } catch (err) {
    yield call(errorHandler, err);
    yield put({ type: profileConstants.PROFILE_GET_FAIL });
  }
}

function* getTags(action) {
  try {
    const { userId } = action.payload;
    const { data } = yield call(Api.users.getTagsPerUser, userId);
    yield put(profileActions.getTagsSuccess(data));
  } catch (e) {
    yield put(profileActions.getTagsFail);
  }
}

function* getProfileLicence(action) {
  try {
    const { userId } = action.payload;
    const { data } = yield call(Api.users.getUserDataLicence, userId);
    // TODO add exception when user not found
    yield put({ type: profileConstants.PROFILE_GET_LICENSE_SUCCESS, payload: data });

  } catch (err) {
    yield call(errorHandler, err);
    yield put({ type: profileConstants.PROFILE_GET_LICENSE_FAIL });
  }
}

function* updateProfile(action) {
  try {
    const { userData, callback } = action.payload;
    if (userData.old_password && userData.password) {
      const { data: { salt } } = yield call(Api.auth.getSalt, { username: userData.username });
      const old_encryption = md5(`${userData.old_password}${salt}`);
      userData.old_password = `${old_encryption}:${salt}`;

      const newSalt = Math.floor(Math.random() * 1000000000);
      const encryption = md5(`${userData.password}${newSalt}`);
      userData.password = `${encryption}:${newSalt}`;
    }

    yield call(Api.users.putUserData, userData);
    const { data } = yield call(Api.users.getUserData, userData.id);
    userData.language = translationKeys[userData.language.substring(0, 2)];
    yield put({ type: profileConstants.PROFILE_GET_REQUEST, payload: { userId: userData.id } });
    if (callback) {
      yield call(callback);
    }

  } catch (err) {
    yield call(errorHandler, err);
    yield put({ type: profileConstants.PROFILE_UPDATE_FAIL, payload: err });
    toast.error("Failed to update profile", { position: "bottom-center" });
  }
}

function* putUser(action) {
  const { userData, callBack } = action.payload;

  try {
    const salt = Math.floor(Math.random() * 1000000000);
    const encryption = md5(`${userData.password}${salt}`);
    userData.password = `${encryption}:${salt}`;
    userData.old_password = userData.password;
    userData.username = userData.email;

    const response = yield call(Api.users.setUserCreate, userData);
    yield put({ type: profileConstants.SET_USER_SUCCESS, payload: response });
    callBack(response);
    const auth = store.get("auth");

    if (auth) {
      yield put(employeeActions.employeeGet({ id: auth.user.id }));
    }

  } catch (err) {
    callBack(err?.response?.data);
    yield call(errorHandler, err);
    yield put({ type: profileConstants.SET_USER_FAIL, payload: err });
  }
}

function* deleteProfile(action) {
  try {
    const { userId } = action.payload;
    const { data } = yield call(Api.users.deleteUser, userId);

    yield put({ type: profileConstants.PROFILE_DELETE_SUCCESS, payload: data });
    yield put({ type: authConstants.AUTH_LOGOUT_REQUEST });
    store.clearAll();

  } catch (err) {
    yield call(errorHandler, err);
    yield put({ type: profileConstants.PROFILE_DELETE_FAIL });
  }
}

function* updateProfileLicence(action) {
  try {
    const { userId, payload } = action.payload;
    yield call(Api.users.putUserDataLicence, userId, payload);
    const { data } = yield call(Api.users.getUserDataLicence, userId);
    yield put({ type: profileConstants.PROFILE_GET_LICENSE_SUCCESS, payload: data });
  } catch (err) {
    yield call(errorHandler, err);
    yield put({ type: profileConstants.PROFILE_DELETE_FAIL });
  }
}

export function* profileSagas() {
  yield takeLatest(profileConstants.PROFILE_GET_REQUEST, getProfile);
  yield takeLatest(profileConstants.PROFILE_GET_TAGS_REQUEST, getTags);
  yield takeLatest(profileConstants.PROFILE_GET_LICENSE_REQUEST, getProfileLicence);
  yield takeLatest(profileConstants.PROFILE_UPDATE_REQUEST, updateProfile);
  yield takeLatest(profileConstants.SET_USER_REQUEST, putUser);
  yield takeLatest(profileConstants.PROFILE_DELETE_REQUEST, deleteProfile);
  yield takeLatest(profileConstants.PROFILE_UPDATE_LICENSE_REQUEST, updateProfileLicence);
}

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {useParams} from "react-router-dom";

/** Loading components **/
import AnimatedLoader from "@components/Common/AnimatedLoader";
import PageHeader from "@components/Layout/PageHeader";
import InvoiceDetails from "@containers/Invoices/Invoice/InvoiceDetails";

/** Loading translation helper **/
import {t} from "@helpers/i18n";
import * as invoiceActions from "@store/invoices/actions";

/** Loading styles **/
import './Invoice.style.scss';

const Invoice = () => {

  /**
   * extract params
   * @type {{}}
   */
  const params = useParams();

  /**
   * Redux selectors
   * @type {*}
   */
  const dispatch = useDispatch();
  const { loading, invoiceDetail } = useSelector(state => state.invoices);

  /**
   * fetch invoice by id effect
   */
  useEffect(() => {
    const { id } = params;
    dispatch(invoiceActions.invoiceDetailGet({ invoiceId: id }));
  }, []);

  /**
   * render header for Invoice details
   * @returns {JSX.Element}
   */
  const renderHeader = () => (
    <div className="header-invoice-row">
      <div className={'invoice-heading-title'}>
        { t('invoice_details') }
      </div>
    </div>
  );

  /**
   * render Loader
   * @returns {JSX.Element}
   */
  const renderAnimatedLoader = () => {
    return <AnimatedLoader />;
  };

  /**
   * render details page
   * @returns {JSX.Element}
   */
  const renderInvoiceContainer = () => (
    <div id="invoices-container" className="details-container">
      <PageHeader text={t("back")} profile={true} backLink={'/invoices'}>
        {renderHeader()}
      </PageHeader>
      <div className={'flex-1'}>
        <InvoiceDetails invoice={invoiceDetail} />
      </div>
    </div>
  );

  return(
    <>
      { loading && renderAnimatedLoader()}
      { !loading && renderInvoiceContainer() }
    </>
  );
};

export default Invoice;

import React from "react";
import PropTypes from "prop-types";

/** Load css styles **/
import "./SimpleCard.styles.scss";

const SimpleCard = ({
  renderRight,
  primaryContent,
  renderLeft,
  children,
  onClick,
  className,
}) => {
  return (
    <div className={`card ${className}`}>
      <div className={"card-inner"}>
        <div className="top-section" onClick={onClick}>
          <div className="left-section">
            {renderLeft()}
            <div className="text-container">{primaryContent()}</div>
          </div>
          <div className="right-section">{renderRight()}</div>
        </div>
        <div className={"bottom-section"}>{children}</div>
      </div>
    </div>
  );
};

SimpleCard.propTypes = {
  styles: PropTypes.array,
  primaryContent: PropTypes.func,
  renderRight: PropTypes.func,
  renderLeft: PropTypes.func,
  onClick: PropTypes.func,
  className: PropTypes.string
};

SimpleCard.defaultProps = {
  styles: [],
  primaryContent: () => {},
  renderRight: () => {},
  renderLeft: () => {},
  onClick: () => {},
  className: ''
};
export default SimpleCard;

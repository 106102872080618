import React from 'react';
import PropTypes from 'prop-types';
import './DailyRentNoReport.styles.scss';

const DailyRentNoReport = ({ title, message }) => {
  return (
    <div className="report-box-container">
      <div className="title-box">{ title }</div>
      <div className="message-box">{ message }</div>
    </div>
  );
};

DailyRentNoReport.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string
};

DailyRentNoReport.defaultProps = {
  title: '',
  message: ''
};

export default DailyRentNoReport;
import React from 'react';
import PropTypes from 'prop-types';
import './ReportingCard.styles.scss';


function ReportingCardComponent(props) {
  const {
    componentStyle,
    headerContentLeft,
    headerContentRight,
    additionalClass,
    children,
  } = props;

  return (
    <div className={`card-container ${additionalClass}`} style={componentStyle}>
      <div className={`reporting-card-header`}>
        {headerContentLeft}
        {headerContentRight}
      </div>
      {children}
    </div>
  );
}

ReportingCardComponent.propTypes = {
  componentStyle: PropTypes.object,
  additionalClass: PropTypes.string,
  headerContentLeft: PropTypes.node,
  headerContentRight: PropTypes.node,
  children: PropTypes.node,
};

ReportingCardComponent.defaultProps = {
  componentStyle: {},
  additionalClass: '',
  headerContentLeft: <></>,
  headerContentRight: <></>,
  children: <></>,
};
export default ReportingCardComponent;

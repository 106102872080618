import React from 'react';
import PropTypes from "prop-types";

/** Load components **/
import Toggler from '@components/Common/Toggler';
import {t} from '@helpers/i18n';
import FilterDatePicker from "@components/Common/FilterDatePicker";


const InvoiceFilterForm = (props) => {

  const { filters, invoiceDates, onChange, onDateChange } = props;


  return (<div className={'filter-rows'}>
    <div className="toggle">
      {
        filters.map((filterItem, index) =>
          (
            <label key={index}>
              <p>{t(filterItem.filter)}</p>
              <Toggler
                id={index.toString()}
                checked={filterItem.value}
                onChange={onChange} />
            </label>
          )
        )
      }
      { filters[6].value &&
      <div className="date-picker">
        <FilterDatePicker
          dateFrom={invoiceDates.dateFrom}
          dateTo={invoiceDates.dateTo}
          onDateChange={onDateChange}
        />
      </div> }
    </div>
  </div>);
};

InvoiceFilterForm.propTypes = {
  filters: PropTypes.array,
  invoiceDates: PropTypes.object,
  onChange: PropTypes.func,
  onDateChange: PropTypes.func
};

InvoiceFilterForm.defaultProps = {
  filters: [],
  invoiceDates: {},
  onChange: () => {},
  onDateChange: () => {}
};
export default InvoiceFilterForm;

import React from 'react';
import PropTypes from "prop-types";

import CloseIcon from "mdi-react/CloseIcon";
import './Modal.style.scss';

const Modal = (props) => {

  const { isVisible, className, children, onClose } = props;

  return (
    isVisible && <div className={`modal ${className}`}>
      <div className="modal-container">
        <div className="modal-header">
          <div className="close-button" onClick={onClose}>
            <CloseIcon size={64} />
          </div>
        </div>
        <div className="modal-content">
          {children}
        </div>
        <div className="modal-footer">

        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  isVisible: PropTypes.bool,
  children: PropTypes.object,
  onClose:  PropTypes.func,
};

Modal.defaultProps = {
  isVisible: false,
  children: PropTypes.object,
  onClose:  () => {},
};

export default Modal;

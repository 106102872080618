import React from 'react';
import PropTypes from "prop-types";
/** load components **/
import {LicensePlate} from "@components/Car/LicensePlate";
/** load services**/
import {dateFormatter, isValidDate} from "@services/formatters/util/DateTime.formatters";


const AppointmentRightBlock = (props) => {

  const { appointment, expanded }  = props;

  const { vehicleId, date, wishDate1, wishDate2, wishDate3 } = appointment;

  const startDate = date;

  const renderWishDates = () => {
    return (
      <div className="appointments-date">
        {
          !expanded &&
              <p> { isValidDate(startDate) ? dateFormatter(startDate) : `${dateFormatter(wishDate1)} ${isValidDate(wishDate2) ? '...' : ''} ` }</p>
        }
        { expanded &&
         <>
           {  isValidDate(startDate) ?
            <>
              <p>{ dateFormatter(startDate) }</p>
            </> :
            <>
              <p>{dateFormatter(wishDate1)}</p>
              <p>{dateFormatter(wishDate2)}</p>
              <p>{dateFormatter(wishDate3)}</p>
            </>}
         </>}
      </div>
    );
  };

  return (
    <>
      <div className="appointments-dates">
        { renderWishDates() }
      </div>
      <LicensePlate
        country="D"
        license={vehicleId}/>
    </>);
};

AppointmentRightBlock.propTypes = {
  appointment: PropTypes.object,
  expanded: PropTypes.bool
};

AppointmentRightBlock.defaultProps = {
  appointment: {},
  expanded: false
};

export default AppointmentRightBlock;

import React, { useEffect, useState } from "react";

/** Load store methods **/
import { useDispatch, useSelector } from "react-redux";
import * as carCheckActions from "@store/carcheck/actions";

/** load components **/
import SearchBarFilter from "@components/Common/SearchBar/withFilters";
import CarCheckFilter from "@components/CarCheck/Filter";
import MapContainer from "@containers/Map";
import  { CarCheckList } from "@components/CarCheck";

/** Load helpers **/
import { carCheckQueryFormatter } from "@components/CarCheck/Filter/helper";


const CarCheckListContainer = () => {
  /**
   * Redux selectors
   */
  const { auth } = useSelector((state) => state.auth);
  const { carchecks } = useSelector((state) => state);

  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");

  /**
   * React State
   * filter parameters
   */
  const [filters, setFilters] = useState([
    { id: 0, title: "show_all", value: true },
    { id: 1, title: "under_review", value: true },
    { id: 2, title: "car_check_successful", value: true },
    { id: 3, title: "car_check_unsuccessful", value: true },
    { id: 4, title: "visit_near", value: true },
    { id: 5, title: "visit_soon", value: true },
    { id: 6, title: "archiving", value: true },
    { id: 7, title: "between", value: false },
  ]);
  const [dateFilters, setDateFilters] = useState({
    dateFrom: null,
    dateTo: null,
  });
  const [filterVisible, setFilterVisibility] = useState(false);

  /**
   *  Initial Mounted hook
   * fetch car Checks
   **/
  useEffect(() => {
    dispatch(carCheckActions.carChecksGet(
      {
        id: auth.user.id,
        page: page,
        query: ""
      }
    ));
  }, []);

  /** Load car Checks with filters & search parameter **/
  const fetchCarChecks = () => {
    setPage(0);
    const filterQuery = carCheckQueryFormatter(
      filters,
      dateFilters.dateFrom,
      dateFilters.dateTo,
      search
    );
    dispatch(carCheckActions.carChecksGet(
      {
        id: auth.user.id,
        page: 0,
        query: filterQuery,
      }
    ));
  };

  /** Load car Checks when user preforms search **/
  useEffect(() => {
    fetchCarChecks();
  }, [search]);

  /** Load more car Checks when user scrolls to end of the page **/
  const loadMoreCarChecks = () => {
    const nextPage = page + 1;
    const filterQuery = carCheckQueryFormatter(
      filters,
      dateFilters.dateFrom,
      dateFilters.dateTo,
      search
    );
    dispatch(carCheckActions.carChecksLoadMore(
      {
        id: auth.user.id,
        page: nextPage,
        query: filterQuery,
      }
    ));
    setPage(nextPage);
  };
  /**
   * Filter slider toggled
   * @param {array} filters - array of filters
   */
  const filterChanged = (filters) => {
    setFilters(filters);
  };

  /**
   * date filed from Filter changed
   * @param {string} type - field type dateFrom/dateTo
   * @param {string} value - date value
   */
  const onDateChange = (type, value) => {
    setDateFilters({
      ...dateFilters,
      [type]: value,
    });
  };
  /** Reset Search input field
   * @param {object} event - MouseEvent e
   * **/
  const onSearch = (event = "") => {
    setSearch(event.target.value);
  };

  /** Reset Search input field **/
  const onClose = () => {
    setSearch("");
  };

  /** Toggle Car check Filter visibility **/
  const onFilterPress = () => {
    setFilterVisibility(!filterVisible);
  };

  /** Filter Props **/
  const carCheckFilterProps = {
    visible: filterVisible,
    filterValues: filters,
    dates: dateFilters,
    filterChanged,
    onDateChange,
    applyFilter: fetchCarChecks,
  };

  /** Search Bar Props **/
  const searchBarProps = { search, onClose, onSearch, onFilterPress };

  return (
    <div className="list-wrapper">
      <MapContainer />
      <div className={"list-view"}>
        <SearchBarFilter
          {...searchBarProps}
          children={<CarCheckFilter {...carCheckFilterProps} />}
        />
        <CarCheckList
          carChecks={carchecks.carChecks}
          loading={carchecks.loading}
          isLastPage={page === carchecks.lastPage}
          isEndReached={loadMoreCarChecks}
        />
      </div>
    </div>
  );
};

export default CarCheckListContainer;

import React from 'react';
import PropTypes from 'prop-types';

/** Load components **/
import NoDamagesContainer from "./NoDamagesContainer";
import DamageListContainer from './DamageListContainer';

/** load styles **/
import './style.scss';


const DamagePhotos = ({ damages }) => {

  return (
    <div className={'damage-row'}>
      { damages.length > 0 ?
          <DamageListContainer damages={damages} /> : <NoDamagesContainer />}
    </div>
  );
};

DamagePhotos.propTypes = {
  damages: PropTypes.array
};

DamagePhotos.defaultProps = {
  damages: []
};

export default DamagePhotos;

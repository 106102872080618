import React from 'react';
import PropTypes from "prop-types";
import SimpleCard from "@components/Card/SimpleCard";

const AppointmentExpandableCard = (props) => {

  const { id, expanded, onAppointmentClick, primaryContent, renderLeft, renderRight, children } = props;


  return (
    <SimpleCard
      id={id}
      onClick={onAppointmentClick}
      primaryContent={primaryContent}
      renderLeft={renderLeft}
      renderRight={renderRight}>
      { expanded && children }
    </SimpleCard>
  );
};

AppointmentExpandableCard.propTypes = {
  id: PropTypes.string,
  expanded: PropTypes.bool,
  onAppointmentClick: PropTypes.func,
  primaryContent: PropTypes.func,
  renderLeft: PropTypes.func,
  renderRight: PropTypes.func,
  children: PropTypes.element
};

AppointmentExpandableCard.defaultProps = {
  id: '0',
  expanded: false,
  onAppointmentClick: () => {},
  primaryContent:  () => {},
  renderLeft:  () => {},
  renderRight:  () => {},
  children: <></>
};


export default AppointmentExpandableCard;

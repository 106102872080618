import React, {forwardRef} from 'react';
import PropTypes from "prop-types";

import Calendar from "mdi-react/CalendarMonthIcon";

import './style.scss';

const DateInput = forwardRef(({value, onClick, onResetDate}, ref) => (


  <div className="calendar" ref={ref} onClick={onClick}>
    <span className="calendar-icon"> {value} </span>
    <Calendar/>
  </div>
));



DateInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  ref: PropTypes.object,
};

DateInput.defaultProps = {
  value: null,
  onClick: () => {},
  ref: {}
};


export default DateInput;

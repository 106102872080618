import React from 'react';
import {dateFormatted} from "@services/formatters";
import { LicensePlate } from "@components/Car/LicensePlate";
import PropTypes from "prop-types";

const DamageInfo = ({ vehicleId, damageNumber, date }) => {
  return (
    <>
      <p>{dateFormatted(date)}</p>
      <p>{damageNumber}</p>
      <LicensePlate
        country="D"
        license={vehicleId}
      />
    </>
  );
};

export default DamageInfo;

DamageInfo.propTypes = {
  vehicleId: PropTypes.string, // avatar image
  damageNumber: PropTypes.string, // damage state,
  date: PropTypes.string
};

DamageInfo.defaultProps = {
  vehicleId: '',
  damageNumber: '', // damage state
  date: null
};

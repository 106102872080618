import React from 'react';


const WithSidebar = ({ children, component: Component }) => {

  return (
    <>
      { children } {/* SIDEBAR ALWAYS VISIBLE */}
      { Component }
    </>
  );
};

export default WithSidebar;

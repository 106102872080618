export const CARCHECK_GET_REQUEST = "CARCHECK_GET_REQUEST";
export const CARCHECK_GET_SUCCESS = "CARCHECK_GET_SUCCESS";
export const CARCHECK_GET_FAIL = "CARCHECK_GET_FAIL";

export const CARCHECK_SINGLE_GET_REQUEST = "CARCHECK_SINGLE_GET_REQUEST";
export const CARCHECK_SINGLE_GET_SUCCESS = "CARCHECK_SINGLE_GET_SUCCESS";
export const CARCHECK_SINGLE_GET_FAIL = "CARCHECK_SINGLE_GET_FAIL";

export const CARCHECK_SINGLE_GET_REQUEST_CLEAR =
  "CARCHECK_SINGLE_GET_REQUEST_CLEAR";

export const CARCHECK_LOAD_MORE_REQUEST = "CARCHECK_LOAD_MORE_REQUEST";
export const CARCHECK_LOAD_MORE_SUCCESS = "CARCHECK_LOAD_MORE_SUCCESS";
export const CARCHECK_LOAD_MORE_FAIL = "CARCHECK_LOAD_MORE_FAIL";

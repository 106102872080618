import React from 'react';
import PropTypes from 'prop-types';
/** Load translation helper **/
import { t } from "@helpers/i18n";
/** load components **/
import DamageFilterForm from "./FilterForm";
import ApplyButton from "@components/Common/Button/ApplyButton";


const DamageFilter = ({ visible, dateTo, dateFrom, onDateChange, filterValues, filterChange, applyFilter }) => {

  return (
    visible &&
    <div className={'list-filter'}>
      { /** Filter form **/}
      <DamageFilterForm
        settings={filterValues}
        onChange={filterChange}
        dateTo={dateTo}
        dateFrom={dateFrom}
        onDateChange={onDateChange} />
      {/** Filter submit btn
         * todo switch with component
         **/ }
      <ApplyButton onClick={applyFilter} text={t('apply')}>
      </ApplyButton>
    </div>);
};

export default DamageFilter;

DamageFilter.propTypes = {
  visible: PropTypes.bool, // filter visibility
  dateTo: PropTypes.instanceOf(Date), // date from filter value
  dateFrom: PropTypes.instanceOf(Date),  // date To filter value
  onDateChange: PropTypes.func, // on changeDate func
  filterValues: PropTypes.array, // filter Values
  applyFilter: PropTypes.func // on apply filter callback
};

DamageFilter.defaultProps = {
  visible: false,
  dateTo: null,
  dateFrom: null,
  onDateChange: () => {},
  filterValues: [],
  applyFilter: () => {}
};


export const AUTH_LOGIN_REQUEST = "AUTH_LOGIN_REQUEST";
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGIN_FAIL = "AUTH_LOGIN_FAIL";

export const AUTH_LOGOUT_REQUEST = "AUTH_LOGOUT_REQUEST";
export const AUTH_LOGOUT_SUCCESS = "AUTH_LOGOUT_SUCCESS";
export const AUTH_LOGOUT_FAIL = "AUTH_LOGOUT_FAIL";

export const AUTH_RESET_PASSWORD_REQUEST = "AUTH_RESET_PASSWORD_REQUEST";
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS";
export const AUTH_RESET_PASSWORD_FAIL = "AUTH_RESET_PASSWORD_FAIL";

export const AUTH_RESET_PASSWORD_CLEAR = "AUTH_RESET_PASSWORD_CLEAR";
export const PROFILE_GET_SUCCESS = "PROFILE_GET_SUCCESS";

export const AUTH_INITIAL_LINK_SET = "AUTH_INITIAL_LINK_SET";

export const AUTH_CHALLENGE_RESET_PASSWORD_REQUEST = "AUTH_CHALLENGE_RESET_PASSWORD_REQUEST";
export const AUTH_CHALLENGE_RESET_PASSWORD_SUCCESS = "AUTH_CHALLENGE_RESET_PASSWORD_SUCCESS";
export const AUTH_CHALLENGE_RESET_PASSWORD_FAIL = "AUTH_CHALLENGE_RESET_PASSWORD_FAIL";

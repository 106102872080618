import React from 'react';
import { NavLink } from "react-router-dom";
import { t } from "@helpers/i18n";
import Grid from '@mui/material/Grid';

const ProfileLink = ({ route, index, activeIndex }) => {
  // Construct the `to` object conditionally based on the presence of `route.state`
  const toObject = route.state ? { pathname: route.path, state: route.state } : { pathname: route.path };

  return (
    <Grid item>
      <NavLink key={route.key} to={toObject} activeClassName="active">
        <li key={route.key} className={classnames({ active: index === activeIndex })}>
          {route.icon}
          <span className={'nav-text'}>{t(route.key)}</span>
        </li>
      </NavLink>
    </Grid>
  );

};

export default ProfileLink;

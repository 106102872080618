import React from 'react';
/** load components **/
import Avatar from "@components/User/Avatar";
/** Load icons **/
import PersonIcon from "mdi-react/AccountIcon";
/** load services **/
import { AppointmentsCardFormatters } from '@services/formatters/appointments';

const AppointmentLeftBlock = (props) => {

  const { appointment } = props;
  const { appState } = appointment;

  const appointmentStatusIcon = AppointmentsCardFormatters.AppointmentStatusIcon(appState);

  const appointmentStatusColor = AppointmentsCardFormatters.AppointmentSideColor(appState);

  return (
    <Avatar
      small
      icon={appointmentStatusIcon}
      iconBackgroundColor={appointmentStatusColor}
      missingIcon={<PersonIcon/>}
    />);
};

export default AppointmentLeftBlock;

import React, {useCallback, useMemo, useRef, useState} from 'react';
import TicketPageTemplateComponent from '@components/Ticket/TicketPageTemplate/TicketPageTemplate.component';
import {useDispatch, useSelector} from 'react-redux';
import MultipleUploadComponent from '@components/Common/MultipleUpload';
import {Grid, IconButton} from '@mui/material';
import TicketPdfPreviewComponent from '@components/Ticket/TicketPdfPreview';
import TicketImagePreviewComponent from '@components/Ticket/TicketImagePreview';
import {t} from '@helpers/i18n';
import {Field, Formik} from 'formik';
import {CmpInput} from '@components/Common/CmpInput/CmpInput.component';
import CmpDatePicker from '@components/Common/DatePicker';
import './AddTicket.style.scss';
import LicenseDropdownComponent from '@components/Common/LicensePlate/LicenseDropdown/LicenseDropdown.component';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';
import {Button} from '@components/Common/Button';
import {AMOUNT_RE, BUTTON_TYPES, EMAIL_REGEX, IBAN_REGEX} from '@constants';
import CheckIcon from 'mdi-react/CheckIcon';
import {ticketCreate} from '@store/tickets/actions';
import dayjs from 'dayjs';
import {getBase64} from '@helpers/common/BlobFunctions';
import {useHistory} from 'react-router-dom';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import AnimatedLoader from '@components/Common/AnimatedLoader';
import {DATE_FORMATS, EMPTY_LICENSE_PLATE, EMPTY_LICENSE_PLATE_SELECT_OPTION} from '@helpers/constants';
import * as Yup from 'yup';

const AddTicketContainer = () => {
  const [selectedLicense, setSelectedLicense] = useState({licensePlate: '', value: '', id: null});
  const [uploadedFile, setUploadedFile] = useState({type: [], content: [], files: []});
  const [fileForPreview, setFileForPreview] = useState(0);
  const [formErrors, setFormErrors] = useState({});
  const formRef = useRef();
  const history = useHistory();

  const {profile} = useSelector(state => state.profile);
  const {auth} = useSelector(state => state.auth);
  const {creatingTicket} = useSelector(state => state.tickets);

  const dispatch = useDispatch();

  const FormSchema = useMemo(() => (
    Yup.object().shape({
      file_number: Yup.string()
        .required('field_required'),
      ticket_date: Yup.string()
        .nullable()
        .required('field_required'),
      amount: Yup.string()
        .matches(AMOUNT_RE, {message: 'invalid_amount'})
        .required('field_required'),
      authority: Yup.string()
        .required('field_required'),
      email: Yup.string()
        .matches(EMAIL_REGEX, {message: 'invalid_address'})
        .required('field_required'),
      iban: Yup.string()
        .matches(IBAN_REGEX, {message: 'invalid_iban'})
        .required('field_required')
    })
  ), []);

  const onFileUpload = (event) => {
    if(event.target.files.length === 0) {
      return;
    }
    const filesArray = Array.from(event.target.files);
    const contentArray = [];
    const typesArray  = [];
    filesArray.forEach((file) => {
      const content = window.URL.createObjectURL(file);
      contentArray.push(content);
      typesArray.push( file.type.includes('pdf') ? 'pdf' : 'img');
    });
    setUploadedFile({type: [...uploadedFile.type, ...typesArray], content: [...uploadedFile.content, ...contentArray], files: [...uploadedFile.files, ...filesArray]});
  };

  const removeFile = () => {
    const filteredTypes = uploadedFile.type.filter((type, index) => index !== fileForPreview);
    const filteredContent = uploadedFile.content.filter((content, index) => index !== fileForPreview);
    const filteredFile = uploadedFile.files.filter((file, index) => index !== fileForPreview);
    setUploadedFile({type: filteredTypes, content: filteredContent, files: filteredFile});
  };

  /**
   * Extract license plates from user's vehicles
   */
  const getLicencePlates = useCallback( () => {
    const list = profile.cars?.map((vehicle, i) => ({licensePlate: vehicle.licencePlate, value: vehicle.licencePlate, id: i + 1}));
    list.unshift(EMPTY_LICENSE_PLATE_SELECT_OPTION);
    return list;
  }, [profile?.cars]);


  const itemListView = (option) => {
    return (<p>{ option.licensePlate }</p>);
  };

  const validateLicensePlate = (value = '') => {

    const firstPartRegex = /^[A-Za-zÄäÖöÜüß]+$/;
    const secondPartRegex = /^[a-zA-Z]+$/;
    // Third part of the license plate can accepet up to 4 digits and letter "E" for electric vehicle at the end
    const thirdPartRegex = /^\d{1,4}(e|E)?$/;

    // Split value of license palte in three separate fields
    const licensePlateParts = value?.split(/[- ]+/).filter(Boolean);

    const licensePlateObject = {
      firstPart: licensePlateParts[0] ?? '',
      secondPart: licensePlateParts[1] ?? '',
      thirdPart: licensePlateParts.slice(2).join(' ') ?? ''
    };

    if(!value) {
      return false;
    }

    if(value.length < 8) {
      return false;
    }
    
    const { firstPart, secondPart, thirdPart } = licensePlateObject;

    if(
      !firstPartRegex.test(firstPart) || firstPart === ''
      || !secondPartRegex.test(secondPart) || secondPart === ''
      || !thirdPartRegex.test(thirdPart) || thirdPart === ''
    ) {
      setFormErrors({licensePlate: 'field_required'});
      return false;
    }

    return true;
  };

  const previousFileButton = useMemo(() => (
    <IconButton
      color="primary" aria-label="upload picture" component="span"
      disabled={fileForPreview === 0}
      onClick={() => setFileForPreview(fileForPreview - 1)}>
      <ArrowCircleLeftIcon fontSize={'large'}/>
    </IconButton>
  ), [fileForPreview]);

  const nextFileButton = useMemo(() => (
    <IconButton
      color="primary" aria-label="upload picture" component="span"
      disabled={fileForPreview === uploadedFile.content.length - 1}
      onClick={() => setFileForPreview(fileForPreview + 1)}>
      <ArrowCircleRightIcon fontSize={'large'} />
    </IconButton>
  ), [fileForPreview, uploadedFile]);

  const ticketMediaPreview = useMemo(() => (
    <>
      {uploadedFile.type[fileForPreview]?.includes('pdf') ?
        <TicketPdfPreviewComponent pdfUrl={uploadedFile.content[fileForPreview]}/>
        :
        !!uploadedFile.content?.length &&
        <TicketImagePreviewComponent ticketImages={[uploadedFile.content[fileForPreview]]} />
      }
      {!!uploadedFile.content?.length &&
        <Button
          className={'remove-file-button'}
          icon={<TrashCanOutlineIcon />}
          onClick={removeFile}>
        </Button>}
      {uploadedFile.content.length > 1 &&
         <div className={'file-navigation'}>
           {previousFileButton}
           <p>File {fileForPreview + 1} / {uploadedFile.content.length}</p>
           {nextFileButton}
         </div>
      }
    </>
  ), [uploadedFile, fileForPreview]);

  /**
   * Amount is entered as a euro value, we need to save it as a cents value
   * @returns {number}
   */
  const formatAmount = (amount) => {
    // parsing from 13.256,55 notation to 13256.55 notation
    const parsedAmount = amount.replace('.', '').replace(',', '.');
    const floatAmount = parseFloat(parsedAmount).toFixed(2);
    return parseInt(floatAmount * 100);
  };

  const createTicket = async(formData) => {
    if(!selectedLicense.value || uploadedFile?.content?.length === 0){
      setFormErrors({
        licensePlate: selectedLicense.value ? '' : 'field_required',
        file: uploadedFile?.content?.length ? '' : 'file_required'
      });
      return;
    }

    const ticketData = {
      'amount': formatAmount(formData?.amount),
      'author-id': auth.user?.id,
      'customer-id': auth.info?.customerNumber,
      'government-email': formData?.email,
      'government-iban': formData?.iban,
      'government-reference': formData?.file_number,
      'government': formData?.authority,
      'licence-plate': selectedLicense.value,
      'event-date': dayjs(formData?.ticket_date).format(DATE_FORMATS.full_YMD_dash.toUpperCase())
    };
    if (auth.user?.accesslevel !== 'manager') {
      ticketData.assignee = auth.user?.id;
    }
    // get the blob of uploaded file
    const blobFilePromises = [];
    for(let i = 0; i < uploadedFile.files.length; i++) {
      blobFilePromises.push(getBase64(uploadedFile.files[i]));
    }
    const blobFiles = await Promise.all(blobFilePromises);
    dispatch(ticketCreate({history, ticketInfo: ticketData, media: {type: uploadedFile.type, content: blobFiles}}));
  };

  const setLicensePlateValue = (selectedPlate) => {
    // when selected plate from the dropdown
    if(selectedPlate.id) {
      setSelectedLicense(selectedPlate);
      setFormErrors({licensePlate: ''});
      return;
    }

    // checking for choosing empty license plate
    if(selectedPlate.value === EMPTY_LICENSE_PLATE) {
      setSelectedLicense({value: '', licensePlate: '', id: null});
      setFormErrors({licensePlate: 'field_required'});
      return;
    }

    //handling manual input of a plate
    setSelectedLicense({value: selectedPlate.value, licensePlate: selectedPlate.value, id: null});
    if(validateLicensePlate(selectedPlate.value)) {
      setFormErrors({licensePlate: ''});
    } else {
      setFormErrors({licensePlate: 'field_required'});
    }
  };

  const ticketForm = useMemo(() => (
    <Formik
      onSubmit={() => {}}
      innerRef={formRef}
      validateOnMount
      validateOnChange
      validationSchema={FormSchema}
      initialValues={
        {
          file_number: '',
          ticket_date: null,
          amount: '',
          authority: '',
          email: '',
          iban: '',
        }
      }>
      {({ values, setFieldValue, touched, setFieldTouched, isValid, errors }) => (
        <form
          className="ticket-add-form"
          onSubmit={(e) => { e.preventDefault(); }}>
          <div className="form-field">
            <Grid container rowSpacing={0.5} alignItems="center">
              <Grid item xs={5}>
                {t("file_number").concat('*')}
              </Grid>
              <Grid item xs={7}>
                <Field
                  name="file_number"
                  component={CmpInput}
                />
                {touched.file_number && errors.file_number && <p className={'error-message'}>{t(errors.file_number)}</p>}
              </Grid>
              <Grid item xs={5}>
                {t("ticket_date").concat('*')}
              </Grid>
              <Grid item xs={7}>
                <CmpDatePicker
                  onClickOutside={() => setFieldTouched('ticket_date', true)}
                  name="ticket_date"
                  value={values.ticket_date}
                  maxDate={new Date()}
                  onChange={setFieldValue}
                />
                {touched.ticket_date && errors.ticket_date && <p className={'error-message'}>{t(errors.ticket_date)}</p>}
              </Grid>
              <Grid item xs={5}>
                {t("amount").concat('*')}
              </Grid>
              <Grid item xs={7}>
                <Field
                  name="amount"
                  type={'number'}
                  value={values?.amount}
                  component={CmpInput}
                />
                {touched.amount && errors.amount && <p className={'error-message'}>{t(errors.amount)}</p>}
              </Grid>
              <Grid item xs={5}>
                {t("authority_").concat('*')}
              </Grid>
              <Grid item xs={7}>
                <Field
                  name="authority"
                  component={CmpInput}
                />
                {touched.authority && errors.authority && <p className={'error-message'}>{t(errors.authority)}</p>}
              </Grid>
              <Grid item xs={5}>
                {t("email").concat('*')}
              </Grid>
              <Grid item xs={7}>
                <Field
                  name="email"
                  type="email"
                  component={CmpInput}
                />
                {touched.email && errors.email && <p className={'error-message'}>{t(errors.email)}</p>}
              </Grid>
              <Grid item xs={12}>
                {t("government-iban").concat('*')}
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="iban"
                  component={CmpInput}
                />
                {touched.iban && errors.iban && <p className={'error-message'}>{t(errors.iban)}</p>}
              </Grid>
            </Grid>
          </div>

          <div className="submit-ticket-container">
            <Button
              onClick={() => createTicket(values)}
              text={t('confirm')}
              isDisabled={!isValid || !validateLicensePlate(selectedLicense.value) || !uploadedFile.content?.length}
              type={BUTTON_TYPES.SUCCESS}
              icon={<CheckIcon />}>
            </Button>
          </div>
        </form>
      )}
    </Formik>
  ), [selectedLicense, uploadedFile]);

  return (
    <TicketPageTemplateComponent>
      {creatingTicket && <AnimatedLoader />}
      <Grid container className={'add-ticket-container'}>
        <Grid item xs={12} md={4}>
          <MultipleUploadComponent
            multiple={true}
            withoutPreview
            allowFileType={'image/png, image/jpeg, application/pdf'}
            onChange={onFileUpload} />
          {formErrors.file &&
            <p className={'error-message p-b-2'}>{t(formErrors.file)}</p>
          }

          <LicenseDropdownComponent
            license={selectedLicense.value}
            licenseChange={value => setLicensePlateValue({value, licensePlate: value, id: null})}
            itemListView={itemListView}
            onOptionPress={setLicensePlateValue}
            options={getLicencePlates() || []} />
          {formErrors.licensePlate &&
            <p className={'error-message'} style={{paddingTop: '10px'}}>{t(formErrors.licensePlate)}</p>
          }

          <>
            {ticketForm}
          </>


        </Grid>

        <Grid item xs={8} style={{position: 'relative'}}>
          <>
            {ticketMediaPreview}
          </>
        </Grid>

      </Grid>
    </TicketPageTemplateComponent>
  );
};

export default AddTicketContainer;

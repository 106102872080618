import React from 'react';

/** load components **/
import { LicensePlate } from "@components/Car/LicensePlate";

/** load svg-s and formatters **/
import ArrowUp from "@assets/svg/vehicles/angel-up.svg";
import ArrowDown from "@assets/svg/vehicles/angel-down.svg";
import {mileageFormatter} from "@services/formatters";
import PropTypes from "prop-types";

/** load styless **/
import './VehicleInfo.styles.scss';

const VehicleInfo = (props) => {

  const { mileage, vehicleId, icon, expanded, onExpandButtonClick } = props;

  return (
    <>
      <p>{mileageFormatter(mileage)} km</p>
      <p>{icon.leftIcon}{icon.rightIcon}{icon.checkIcon} </p>
      <div style={{display:"flex"}}>
        <img className={'vehicle-expand-arrow'}
          src={expanded ? ArrowUp : ArrowDown} alt={'arrow'}
          onClick={onExpandButtonClick}
        />
        <LicensePlate
          country="D"
          license={vehicleId}
        />
      </div>
    </>
  );
};

export default VehicleInfo;

VehicleInfo.propTypes = {
  vehicleId: PropTypes.string,
  mileage: PropTypes.string,
  expanded: PropTypes.bool,
  onExpandButtonClick: PropTypes.func
};

VehicleInfo.defaultProps = {
  vehicleId: '',
  mileage:'0',
  expanded: false,
  onExpandButtonClick: () => {}
};

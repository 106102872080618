const moduleNames = {
  home: 'home',
  carCheck: 'car-check',
  damages: 'damages',
  employees: 'employees',
  vehicles: 'vehicles',
  trips: 'trips',
  reporting: 'reporting',
  appointments: 'appointments',
  profile: 'profile'
};
export default moduleNames;

import React, {useEffect, useState, memo} from "react";

/** Loading components **/
import TireInfoInput from "@components/Common/TireInfoInput";
import {TwoSidedButton} from "@components/Common/TwoSidedButton/TwoSidedButton.component";
import {Input} from "@components/Common/Input";
import Alert from "@components/Common/Alert/Alert.component";

/** Loading MUI components **/
import ArrowLeftIcon from "mdi-react/ArrowLeftIcon";
import ArrowRightIcon from "mdi-react/ArrowRightIcon";
import CloseIcon from "mdi-react/CloseIcon";

/** Loading Formik components and helper functions **/
import {Formik, Field} from "formik";
import {t} from "@helpers/i18n";
import {BUTTON_TYPES} from "@constants";
import Wheels from "@assets/svg/wheels.svg";

/** Loading styles **/
import "./index.scss";

/** Setting the initial state of the tire information. **/
const initialState = {
  tyre_fl: {state:false, val:''},
  tyre_fr: {state:false, val:''},
  tyre_rl: {state:false, val:''},
  tyre_rr: {state:false, val:''},
  message: ''
};


const TireInformation = (props) => {

  /**
   * extracting props and initial state for tires
   */
  const { changeTire,
    stepData,
    onChange,
    onCloseAppointment,
    alertClose,
    alertVisible,
    tireLeftClick,
    error } = props;

  /**
   * Local state for all changes in the step.
   *
   * On mount should be set to global data from parent,
   * so the form can be prefilled with data when going in between steps
   */
  const [data, setData] = useState(stepData || initialState);

  /**
   * This effect should update parent state once there is local changes
   */
  useEffect(() => {
    onChange(data);
  }, [data]);

  /**
   * "When a field changes, update the data object with the new value."
   *
   * The function is called with two parameters:
   *
   * - propertyName: the name of the field that changed
   * - value: the new value of the field
   *
   * The function uses the spread operator to create a new object with the same properties as the data object
   * @param propertyName - The name of the property to be updated.
   * @param value - The value of the input field.
   */
  const onFieldChange = (propertyName, value) => {
    setData({... data, [propertyName]: value});
  };

  /**
   * It takes in a property name, a sub-property name, and a value, and then it updates the data object with the new value
   * @param propertyName - The name of the property you want to change.
   * @param subName - the name of the sub property you want to change.
   * @param value - the value of the input field
   */
  const onFieldChangeObj = (propertyName, subName, value) => {
    if(subName === 'state') {
      setData({... data, [propertyName]: {...data[propertyName], ['val']: '', ['state']: !data[propertyName].state}});
    }else{
      setData({... data, [propertyName]: {...data[propertyName], [subName]: value}});
    }
  };

  /**
   * It takes a string, capitalizes the first letter, and returns the new string
   * @param string - The string to capitalize.
   * @returns The first letter of the string is being capitalized and the rest of the string is being returned.
   */
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <div className="create-appointment">
      <div className="right-section">
        <div className="need-done">
          <Formik
            enableReinitialize
            onSubmit={undefined}
            initialValues={{
              tyre_fl: data.tyre_fl.val,
              tyre_fr: data.tyre_fr.val,
              tyre_rl: data.tyre_rl.val,
              tyre_rr: data.tyre_rr.val,
              tyre_fl_state: data.tyre_fl.state,
              tyre_fr_state: data.tyre_fr.state,
              tyre_rl_state: data.tyre_rl.state,
              tyre_rr_state: data.tyre_rr.state,
              message: data.message
            }}
          >
            {({values}) => {
              return (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}>

                  <div className="need-done_head">
                    <p className="need-done__title">{t("appointments")}</p>
                    <div className="field-label need-done__subtitle">{t("tire_information")}</div>
                  </div>
                  <div className="tire-group">
                    <div className="tire-input">
                      <TireInfoInput
                        text={t("fl")}
                        onChange={(e) => onFieldChangeObj('tyre_fl','val', e.target.value)}
                        value={values.tyre_fl}
                        check={values.tyre_fl_state}
                        handleState={(val) => onFieldChangeObj('tyre_fl','state', val)}
                      />
                      <TireInfoInput
                        text={t("rl")}
                        onChange={(e) => onFieldChangeObj('tyre_rl','val', e.target.value)}
                        value={values.tyre_rl}
                        check={values.tyre_rl_state}
                        handleState={(val) => onFieldChangeObj('tyre_rl','state', val)}
                      />
                    </div>
                    <div id={'car-image'} style={{marginLeft: "-25px", marginRight: "-25px"}}>
                      <img src={Wheels} alt="wheels"/>
                    </div>
                    <div className="tire-input">
                      <TireInfoInput
                        text={t("fr")}
                        onChange={(e) => onFieldChangeObj('tyre_fr','val', e.target.value)}
                        value={values.tyre_fr}
                        check={values.tyre_fr_state}
                        handleState={(val) => onFieldChangeObj('tyre_fr','state', val)}
                      />
                      <TireInfoInput
                        text={t("rr")}
                        onChange={(e) => onFieldChangeObj('tyre_rr','val', e.target.value)}
                        value={values.tyre_rr}
                        check={values.tyre_rr_state}
                        handleState={(val) => onFieldChangeObj('tyre_rr','state', val)}
                      />
                    </div>
                  </div>

                  <div className={"tire-error"}>
                    {error?.tyre_fl && <p className={'error-message'}>{capitalizeFirstLetter(t('front-left'))}: {t(error?.tyre_fl)}</p>}
                    {error?.tyre_fr && <p className={'error-message'}>{capitalizeFirstLetter(t('front-right'))}: {t(error?.tyre_fr)}</p>}
                    {error?.tyre_rl && <p className={'error-message'}>{capitalizeFirstLetter(t('rear-left'))}: {t(error?.tyre_rl)}</p>}
                    {error?.tyre_rr && <p className={'error-message'}>{capitalizeFirstLetter(t('rear-right'))}: {t(error?.tyre_rr)}</p>}
                  </div>

                  <div className={'message_tire'}>
                    <Field
                      label={t("service_inspection_message_to_us")}
                      name="message"
                      component={Input}
                      type={'textArea'}
                      rows={5}
                      className={'dark-blue-input'}
                      style={{ fontSize: '16px' }}
                      value={values.message}
                      onChange={(e) => onFieldChange('message', e.target.value)}
                    />
                  </div>
                </form>);
            }}
          </Formik>

          <div className={`cancel-add-damage-button step-1`}>
            <button onClick={onCloseAppointment}>
              <CloseIcon/>
            </button>
          </div>
          <Alert visible={alertVisible} title={t('appointment_exit_title')} onClick={alertClose} type={BUTTON_TYPES.SUCCESS}/>

          <div className={"arrow_button"}>
            <TwoSidedButton
              iconLeft={<ArrowLeftIcon size={45}/>}
              iconRight={<ArrowRightIcon size={45}/>}
              onClickLeft={tireLeftClick}
              onClickRight={changeTire}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(TireInformation);

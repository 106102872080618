import {format, parseISO} from "date-fns";
import dayjs from "dayjs";

// default DATE format
const DATE_FORMAT = 'dd.MM.yyyy | HH:mm';
// default TIME format
const TIME_FORMAT = 'hh:mm';
export const DATE_FORMAT_YMD_DASH = 'yyyy-MM-dd';
export const DATE_FORMAT_DMY_DOT = 'dd.MM.yyyy';
export const DATE_FORMAT_DMY_DOT_AND_TIME = 'dd.MM.yyyy | HH:mm';

const formatDate = (date, stringFormat) => {
  if (!date || date.slice(0, 4) === "0000") {
    return "--:--";
  }
  return dayjs(date).format(DATE_FORMAT);
};


/**
 * Checks if the date is valid
 * @param {string} date
 * @returns {boolean}
 */
const isValidDate = (date) => !isNaN(parseISO(date).getUTCFullYear());

/**
 * Checks if the date is valid & formats it
 * @param {string} date
 * @returns {string}
 */
const dateFormatter = (date, dateFormat= DATE_FORMAT) => {
  if (date && isValidDate(date)) {
    return format(parseISO(date), dateFormat || DATE_FORMAT);
  }
  return '';
};

export {
  formatDate,
  isValidDate,
  dateFormatter
};

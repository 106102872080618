import React from 'react';
import PropTypes from "prop-types";

/** Load styles **/
import style from './style.scss';


const CarCheckImage = ({ imgSrc, alt, onImageClick }) => {

  return (
    <div className={'car-check-wrapper'} onClick={onImageClick}>
      <img className="car-check-image" src={`data:image/png;base64, ${imgSrc}`} alt={alt} />
    </div>
  );
};

CarCheckImage.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  onImageClick: PropTypes.func
};

CarCheckImage.defaultProps = {
  imgSrc: '',
  alt: "carCheck",
  onImageClick: () => {}
};


export default CarCheckImage;

import React from 'react';
import {creationDateFormatter} from "@services/formatters";

/** Load components **/
import { LicensePlate } from "@components/Car/LicensePlate";
import CarCheckStatusIcons from "./CarCheckStatusIcons";
import './RightInfoBlock.styles.scss';



const CarCheckRightInfoBlock = ({
  creationDate,
  vehicleId,
  isMaster,
  isStatusOkay,
  damageCount,
  isTyreOk}) => {

  return (
    <div className={'car-check-info'}>
      {/* render car Check creation date */}
      <p className={'py-3'}>{ creationDateFormatter(creationDate) }</p>
      <CarCheckStatusIcons
        isMaster={isMaster} 
        isTyreOk={isTyreOk} 
        isStatusOkay={isStatusOkay} 
        damageCount={damageCount}
      />
      {/* render Status icons for car Check */}
      <div className={'py-3'}>
        <LicensePlate
          country="D"
          license={vehicleId?.toUpperCase()}
        />
      </div>
    </div>
  );
};

export default CarCheckRightInfoBlock;

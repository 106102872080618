import React from 'react';
import Toggler from "@components/Common/Toggler";
/** Load translation helper **/
import { t } from "@helpers/i18n";

/** CarCheck Form Filter presentation component **/

import './styles.scss';
import FilterDatePicker from "@components/Common/FilterDatePicker";

const FilterForm = ({ filters, onChange, date, onDateChange }) => {

  return (
    <div className={'filter-rows'}>
      <div className="toggle">
        <label><p>{t('show_all')}</p>
          <Toggler
            id="0"
            checked={filters[0].value}
            onChange={onChange} />
        </label>
        <label><p>{t('under_review')}</p>
          <Toggler
            id="1"
            checked={filters[1].value}
            onChange={onChange} />
        </label>
        <label><p>{t('car_check_successful')}</p>
          <Toggler
            id="2"
            checked={filters[2].value}
            onChange={onChange}/>
        </label>
        <label><p>{t('car_check_unsuccessful')}</p>
          <Toggler
            id="3"
            checked={filters[3].value}
            onChange={onChange}/>
        </label>
        <label><p>{t('visit_near')}</p>
          <Toggler
            id="4"
            checked={filters[4].value}
            onChange={onChange} />
        </label>
        <label><p>{t('visit_soon')}</p>
          <Toggler
            id="5"
            checked={filters[5].value}
            onChange={onChange}/>
        </label>
        <label><p>{t('archiving')}</p>
          <Toggler
            id="6"
            checked={filters[6].value}
            onChange={onChange}/>
        </label>
        <label><p>{t('performed_between')}</p>
          <Toggler
            id="7"
            checked={filters[7].value}
            onChange={onChange} />
        </label>
        { filters[7].value && <div className="date-picker">
          <FilterDatePicker
            dateFrom={date.dateFrom}
            dateTo={date.dateTo}
            onDateChange={onDateChange}
          />
        </div> }
      </div>
    </div>
  );
};


export default FilterForm;

import React  from 'react';
import MapContainer from "@containers/Map";
import TimeTrackingListHolder from "@containers/TimeTracking/TimeTrackingListHolder";

const TimeTrackingList = () => {

  return (
    <div id={'tracking-list'} className={'list-wrapper'}>
      <MapContainer />
      <TimeTrackingListHolder />
    </div>
  );
};

export default TimeTrackingList;

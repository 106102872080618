import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";

/** load components **/
import AlertCmp from "@components/Common/Alert/Alert.component";
import AppointmentCardList from "@components/Appointment/AppointmentCardList/AppointmentCardList.component";
import SearchBarFilter from "@components/Common/SearchBar/withFilters";
import AppointmentFilter from "@components/Appointment/Filter/AppointmentFilter.component";
import AppointmentCalendarContainer from "@containers/Appointments/AppointmentCalendar/AppointmentCalendar.container";

/** Load services **/
import {AppointmentsQueryFormatter} from "@services/formatters/appointments/AppointmentFilter.formatter";
import AddAppointmentContainer from "@containers/Appointments/AddAppointment/AddAppointment.container";
import {t} from "@helpers/i18n";
import {useLocation} from 'react-router-dom';
import {APPOINTMENT_REQUEST_TYPES} from '@helpers/constants';

const filterInitialState = [
  {id: 0, filter: 'show_all', value: true},
  {id: 1, filter: 'confirmed', value: true},
  {id: 2, filter: 'status_pending', value: true},
  {id: 3, filter: 'canceled', value: true},
  {id: 4, filter: 'archiving', value: true}
];

const AppointmentsWrapper = (props) => {

  const {
    appointments,
    loading,
    applyAppointmentsFilter,
    endReached,
    lastPage,
    createLoading
  } = props;


  const [appointmentSearch, setAppointmentSearch] = useState('');
  const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [settings, setSettings] = useState(filterInitialState);
  const [appointmentDates, setAppointmentDates] = useState({
    dateFrom: '', dateTo: ''
  });
  const [showReportAppointment, setShowReportAppointment] = useState(false);
  const [isRequest, setIsRequest] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [preselectedGarage, setPreselectedGarage] = useState(null);

  const location = useLocation();

  useEffect(() => {
    if(location?.state) {
      setPreselectedGarage(location?.state?.service);
      setShowReportAppointment(true);
      setIsRequest(location.state.type === APPOINTMENT_REQUEST_TYPES.REQUEST);
    }
  }, [location]);

  useEffect(() => {
    applyAppointmentsFilter({
      page: page,
      filter: '',
      dateFrom: null,
      dateTo: null,
      search: '',
    });
  }, []);

  /** Toggle Filter visibility **/
  const filterVisibilityChange = () => {
    setShowFilter(!showFilter);
  };

  /**
   * Toggle Filter visibility
   * @param {array} values - filter values
   **/
  const onFilterChange = (values) => {
    setSettings(values);
  };

  /** Apply Appointments Filter **/
  const applyFilter = () => {
    const query = AppointmentsQueryFormatter(settings,
      appointmentDates.dateFrom,
      appointments.dateTo,
      appointmentSearch);
    setPage(0);
    applyAppointmentsFilter({
      page: 0,
      filter: query,
      dateFrom: appointmentDates.dateFrom,
      dateTo: appointments.dateTo,
      search: appointmentSearch,
    });
  };

  /** Appointment List End reached action **/
  const onEndReached = () => {
    const query = AppointmentsQueryFormatter(settings,
      appointmentDates.dateFrom,
      appointments.dateTo,
      appointmentSearch);
    setPage(page => page + 1);
    endReached({
      page: page + 1,
      filter: query,
      dateFrom: appointmentDates.dateFrom,
      dateTo: appointmentDates.dateTo,
      search: appointmentSearch,
    });
  };

  /**
   * Appointment List End reached action
   * @param {string} search - search param
   **/
  const onAppointmentSearch = (search = '') => {
    setAppointmentSearch(search);
    setPage(0);
    const query = AppointmentsQueryFormatter(settings,
      appointmentDates.dateFrom,
      appointments.dateTo,
      appointmentSearch);
    applyAppointmentsFilter({
      page: 0,
      filter: query,
      search: search,
      dateFrom: appointmentDates.dateFrom,
      dateTo: appointments.dateTo });
  };

  // todo simplify logic
  /**
   * Filter Appointment List by Dates
   * @param {object} dates - date object
   * @param {object} dates.from - from date
   * @param {object} dates.to - to date
   **/
  const filterAppointmentsByDates = (dates) => {
    setPage(0);
    if (dates) {
      const query = AppointmentsQueryFormatter(settings,
        dates.from,
        dates.to,
        appointmentSearch);
      setAppointmentDates({
        dateFrom: dates.from,
        dateTo: dates.to
      });
      applyAppointmentsFilter({
        page: 0,
        filter: query,
        search: appointmentSearch,
        dateFrom: dates.from,
        dateTo: dates.to });
    } else {
      const query = AppointmentsQueryFormatter(settings,
        null,
        null,
        appointmentSearch);
      setAppointmentDates({
        dateFrom: null,
        dateTo: null
      });
      applyAppointmentsFilter({
        page: 0,
        filter: query,
        search: appointmentSearch,
        dateFrom: null,
        dateTo: null });
    }
  };

  /**
   * Reset Appointment list
   * - preform initial fetch, used when appointment is canceled
   */
  const resetAppointmentsList = () => {
    setPage(0);
    setAppointmentSearch('');
    setAppointmentDates({
      dateFrom: '', dateTo: ''
    });
    applyAppointmentsFilter({
      page: 0,
      filter: '',
      search: '',
      dateFrom: null,
      dateTo: null });
  };

  /**
   * Reset Appointment list
   * - preform initial fetch, used when appointment is canceled
   */
  const onAppointmentComplete = () => {
    setAlertVisible(true);
    setShowReportAppointment(false);
    setPage(0);
    setAppointmentSearch('');
    setSettings(filterInitialState);
    applyAppointmentsFilter({
      page: 0,
      filter: '',
      dateFrom: null,
      dateTo: null,
      search: '',
    });
  };

  /**
   * Appointment Filter component Props
   */
  const appointmentFilterProps = {
    visible: showFilter,
    filterValues: settings,
    filterChanged: onFilterChange,
    applyFilter: applyFilter,
  };

  const renderAppointmentList = () => (
    <div className={''}>
      <SearchBarFilter
        search={appointmentSearch}
        onSearch={(e) => onAppointmentSearch(e.target.value)}
        onFilterPress={filterVisibilityChange}
        onClose={() => onAppointmentSearch('')}
        children={<AppointmentFilter {...appointmentFilterProps} />} />
      <AppointmentCardList
        loading={loading}
        isLastPage={lastPage === page}
        appointments={appointments}
        resetAppointmentsList={resetAppointmentsList}
        isEndReached={onEndReached}
      />
    </div>
  );

  /** Go to Add Damage Page
   * @param {boolean} isReq - flag to check is request/ report appointment
   * **/
  const reportAppointment = (isReq) => {
    setIsRequest(isReq);
    setShowReportAppointment(!showReportAppointment);
  };


  /**
   * Close Add Appointment Modal
   * **/
  const onCancelAddAppointment = () => {
    setShowReportAppointment(false);
  };

  const alertClose = () => {
    setAlertVisible(false);
  };

  /**
   * Add Appointment Stepper
   * @returns {JSX.Element}
   */
  const renderAddAppointment = () => {
    return (
      <>
        <AddAppointmentContainer
          preselectedGarage={preselectedGarage}
          cancelAddAppointment={onCancelAddAppointment}
          onAppointmentComplete={onAppointmentComplete}
          isRequest={isRequest} createLoading={createLoading}/>
      </>
    );
  };


  /**
   * Returns Appointment List & Calendar container
   * @returns {JSX.Element}
   */
  const renderAppointmentListAndCalendar = () => (
    <>
      <AlertCmp
        visible={alertVisible}
        title={t("appointment_create_message")}
        onClick={alertClose}
      />



      <div className="list-view grow">
        { renderAppointmentList() }
      </div>
      <AppointmentCalendarContainer
        appointments={appointments}
        filterAppointmentsByDates={filterAppointmentsByDates}
        reportAppointment={reportAppointment}
      />
    </>
  );

  return (
    <div className={'flex justify-between'}>
      {!showReportAppointment && renderAppointmentListAndCalendar()}
      {showReportAppointment && renderAddAppointment()}
    </div>
  );
};

AppointmentsWrapper.propTypes = {
  appointments: PropTypes.array,
  loading: PropTypes.bool,
  applyAppointmentsFilter: PropTypes.func,
  endReached: PropTypes.func,
  lastPage: PropTypes.number
};

AppointmentsWrapper.defaultProps = {
  appointments: [],
  loading: false,
  applyAppointmentsFilter: () => {},
  endReached: () => {},
  lastPage: 0
};

export default AppointmentsWrapper;

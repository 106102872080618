/**
 * It takes a number, converts it to a string, and returns it with a currency symbol
 * @param amount - The amount to be formatted.
 * @param [language=de-DE] - The language to use for the locale.
 * @param [currency=EUR] - The currency to use.
 * @returns A string with the amount formatted as currency.
 */
export const currencyToLocaleFormatter = (amount, language = 'de-DE', currency = 'EUR') => {
  if(amount) {
    return Number(amount).toLocaleString(language, { style: 'currency', currency: currency });
  } else {
    return '-';
  }
};

import React, {useCallback, useState} from "react";
import Grid from "@mui/material/Grid";

import {t} from "@helpers/i18n";
import {Api} from "@api";
import {isValidHttpUrl} from "@services/utils";
import {formatDateFromISO} from "@services/formatters/dailyRent/DailyRentFilter.formatter";
import {formatMileage} from "@services/formatters";
import ButtonGroup from "@components/Common/ButtonGroup";
import {Spinner} from "@components/Spinner";
import AlertCmp from "@components/Common/Alert";

import WalletIcon from "mdi-react/WalletIcon";
import FileDocumentIcon from "mdi-react/FileDocumentIcon";
import CloseIcon from "mdi-react/AlertCircleOutlineIcon";

import './style.scss';

const DailyRentReservation = (props) => {
  const { dailyRentDetail } = props;
  const [loader, setLoader] = useState(false);
  const [contractRequestedAlreadyPopup, setContractRequestedAlreadyPopup] = useState(false);
  const [contractRequestedSubmittedPopup, setContractRequestSubmittedPopup] = useState(false);
  const [paymentLinkNotAvailablePopup, setPaymentLinkNotAvailablePopup] = useState(false);

  const handleRequestContract = useCallback(async (reservationId) => {
    if(dailyRentDetail.isContractRequested){
      setContractRequestedAlreadyPopup(true);
    } else {
      setLoader(true);
      try {
        await Api.dailyRent.requestContract({ reservationId });
        setLoader(false);
        // Alert.alert('', translations.dailyRentDetails.contractRequestSubmitted);
        // loadReservationDetails(reservationId);
        setContractRequestSubmittedPopup(true);
      } catch (error) {
        setLoader(false);
        console.error('Error while requesting contract: ', error);
      }
    }
  }, [dailyRentDetail]);

  // Function to handle payment link opening
  const payNow = (URL) => {
    if(isValidHttpUrl(URL)){
      window.open(URL, "_blank");
    } else {
      setPaymentLinkNotAvailablePopup(true);
    }
  };

  // Configure buttons with respective actions
  const buttonGroup = [
    {
      label: dailyRentDetail?.isContractRequested ? t('dailyRentDetails.requestedContract') : t('dailyRentDetails.requestContract'),
      icon: <FileDocumentIcon />,
      action: () => handleRequestContract(dailyRentDetail.reservationId),
    },
    {
      label: t('dailyRentDetails.onlinePay'),
      icon: <WalletIcon />,
      action: () => payNow(dailyRentDetail?.paymentLink),
    },
  ];

  // Functions to close popups
  const closeContractRequestAlreadyPopup = () => { setContractRequestedAlreadyPopup(false); };
  const closeContractRequestSubmittedPopup = () => { setContractRequestSubmittedPopup(false); };
  const closePaymentPopup = () => { setPaymentLinkNotAvailablePopup(false); };
  const getSafeValue = (value, fallback = '') => (value !== undefined && value !== null && value!=='') ? value : fallback;

  const isValidNumber = value => {
    if (value === null || value === undefined || typeof value === 'boolean' || value === '') {
      return false;
    }
    const num = Number(value);
    return !isNaN(num) && typeof num === 'number';
  };

  const currencyFormatter = (currency) => {
    if (currency !== null) {
      const sum = Number(currency).toFixed(2);
      const replacedDecimals = sum.replace('.', ',');
      const formattedCurrency = replacedDecimals.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      return `${ formattedCurrency }`;
    }
    return '-';
  };

  if (!dailyRentDetail || Object.keys(dailyRentDetail).length === 0) {
    return (
      <div>No Reservation</div>
    );
  }

  const formattedAddOptions = [
    `• ${t('dailyRentDetails.moreKilometers')} ${
        isValidNumber(dailyRentDetail.excessMileageCost) ? currencyFormatter(dailyRentDetail.excessMileageCost) : '-'
    } ${t('dailyRentDetails.eurKM')}`,
    ...(dailyRentDetail.addOptions?.map(
        (option) =>
            `• ${option.description} \n${isValidNumber(option.amount) ? currencyFormatter(option.amount) : '-'} ${
                t('dailyRentDetails.eurPerDay')
            }`
    ) || [])
  ].join('\n');

  const mainOption = `• ${t('dailyRentDetails.moreKilometers')} ${
      isValidNumber(dailyRentDetail.excessMileageCost)
          ? currencyFormatter(dailyRentDetail.excessMileageCost)
          : '-'
  } ${t('dailyRentDetails.eurKM')}`;

  const additionalOptions = dailyRentDetail.addOptions
      ? dailyRentDetail.addOptions.map(({ description, amount }, index) => (
          <div className={'single-billing-row'} key={index}>
            <span>{`• ${description} ${isValidNumber(amount) ? currencyFormatter(amount) : '-'} ${t('dailyRentDetails.eurPerDay')}`}</span>
            <span>
              MISSING VALUE
              {/*{currencyFormatter(amount * dailyRentDetail.days)} €*/}
            </span>
          </div>
      ))
      : [];

  const leftRowInformation = [
    ['dailyRentDetails.reservationId', getSafeValue(dailyRentDetail.reservationNumber, '-')],
    ['dailyRentDetails.handover', getSafeValue(formatDateFromISO(dailyRentDetail.begin), '-')],
    ['dailyRentDetails.return', getSafeValue(formatDateFromISO(dailyRentDetail.end), '-')],
    ['dailyRentDetails.rentalDays', `${getSafeValue(dailyRentDetail.days, '0')} ${t('days')}`],
    [
      'mileage',
      `${dailyRentDetail.excessMileageCost == 0
        ? '∞'
        : getSafeValue(formatMileage(dailyRentDetail.allowedMileageTotal), '0')} ${t('lbl_km')}`],
    [
      'dailyRentDetails.mileagePerDay',
      `${dailyRentDetail.excessMileageCost == 0
        ? '∞'
        : getSafeValue(formatMileage(dailyRentDetail.allowedMileagePerDay), '0')} ${t('lbl_km')}`
    ],
    [
      'dailyRentDetails.handoverLocation',
      `${getSafeValue(dailyRentDetail.handoverLocation?.address_street, '')} ${getSafeValue(
          dailyRentDetail.handoverLocation?.address_number,
          ''
      )} ${getSafeValue(dailyRentDetail.handoverLocation?.address_zipcode, '')} ${getSafeValue(
          dailyRentDetail.handoverLocation?.address_city,
          ''
      )} ${getSafeValue(dailyRentDetail.handoverLocation?.address_country, '').toUpperCase()}`
    ],
    [
      'dailyRentDetails.returnLocation',
      `${getSafeValue(dailyRentDetail.returnLocation?.address_street, '')} ${getSafeValue(
          dailyRentDetail.returnLocation?.address_number,
          ''
      )} ${getSafeValue(dailyRentDetail.returnLocation?.address_zipcode, '')} ${getSafeValue(
          dailyRentDetail.returnLocation?.address_city,
          ''
      )} ${getSafeValue(dailyRentDetail.returnLocation?.address_country, '').toUpperCase()}`
    ]];

  const renderReservationDetails = () => (
    <Grid container>
      <Grid item xs={4}>
        <div className={'reservation-detailes-rows'}>
          {leftRowInformation.map(([headLine, info], index) => (
            <div className={'single-information-row'} key={index}>
              <span>{t(headLine)}:</span>
              <span>{info}</span>
            </div>
          ))}
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={'reservation-billing-rows'}>
          <div className='single-billing-row'>
            <span>Vereinbarte Mietrate {currencyFormatter(dailyRentDetail.dailyRate)} {t('dailyRentDetails.eurPerDay')}</span>
            <span>
              MISSING VALUE
              {/*{currencyFormatter(dailyRentDetail.taxAmount)} &euro;*/}
            </span>
          </div>
          <div className={'single-billing-row'}>
            <span>{mainOption}</span>
            <span>
              {/*{isValidNumber(dailyRentDetail.excessMileageCost) ?*/}
              {/*currencyFormatter(dailyRentDetail.excessMileageCost * dailyRentDetail.days)*/}
              {/*  : `${currencyFormatter(0)} €`}*/}
              MISSING VALUE
            </span>
          </div>
          <>{additionalOptions}</>
          <div className={'single-billing-row row-with-border'}>
            <span>{t('dailyRentDetails.totalRentNet')}:</span>
            <span>{currencyFormatter(dailyRentDetail.sumWithoutTax)} &euro;</span>
          </div>
          <div className={'single-billing-row'}>
            <span>{t('dailyRentDetails.taxes')}:</span>
            <span>{currencyFormatter(dailyRentDetail.taxAmount)} &euro;</span>
          </div>
          <div className={'single-billing-row row-with-border'}>
            <span>{t('dailyRentDetails.totalRentGross')}:</span>
            <span>{currencyFormatter(dailyRentDetail.sumWithTax)} &euro;</span>
          </div>
          <div className={'single-billing-row balance'}>
            <span>{t('dailyRentDetails.outstandingBalance')}:</span>
            <span>{currencyFormatter(dailyRentDetail.unpaidAmount)} &euro;</span>
          </div>
        </div>
      </Grid>
      <Grid item xs={4} style={{display: 'flex', justifyContent: 'center'}}>
        <div className="reservation-info">
          <p><CloseIcon/></p>
          <div style={{flexDirection: 'column'}}>
          <div style={{fontWeight: 500}}>{t('dailyRentDetails.reservationInfoTitle')}</div>
          <div>{t('dailyRentDetails.reservationInfoMessage')}</div>
          </div>
        </div>
      </Grid>
    </Grid>
  );


  return (
      <div className='reservation-details-container'>
        {loader && <Spinner/>}
        <div>{renderReservationDetails()}</div>
        <div style={{display: 'flex', justifyContent: 'center'}}>
        <ButtonGroup className={'reservation-btn-group'} buttonGroup={buttonGroup} />
      </div>
      <AlertCmp visible={contractRequestedAlreadyPopup} title={t('dailyRentDetails.contractRequestedAlready')} onClick={closeContractRequestAlreadyPopup} />
      <AlertCmp visible={contractRequestedSubmittedPopup} title={t('dailyRentDetails.contractRequestSubmitted')} onClick={closeContractRequestSubmittedPopup} />
      <AlertCmp visible={paymentLinkNotAvailablePopup} title={t('dailyRentDetails.noPaymentLinkTitle')} onClick={closePaymentPopup} />
    </div>
  );
};

export default DailyRentReservation;

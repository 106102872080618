import "./index.scss";
import CheckIcon from "mdi-react/CheckIcon";
import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const ApplyButton = ({ text, onClick }) => {

  return (
    <button
      className={classnames("button", "applyButton")}
      type="submit"
      onClick={onClick}
    >
      {text &&
      <div className="apply-text">
        <CheckIcon/>
        <span className="apply-span">{text}</span>
      </div>}
    </button>
  );
};

ApplyButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
};

ApplyButton.defaultProps = {
  onClick: null,
};

export default ApplyButton;

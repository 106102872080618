import React from "react";
import Toggler from "@components/Common/Toggler";
import "./index.scss";

const TireInfoInput = (props) => {

  /**
   * Props extraction and local state
   */
  const {text, onChange, value, check, handleState} = props;

  return (
    <div className="inputGroup">
      {text}
      <Toggler onChange={handleState} checked={check}/>
      <span className="slider round"></span>
      <label><input className={'input-tire'} disabled={!check} value={value} onChange={onChange}/> mm</label>
    </div>
  );
};

export default TireInfoInput;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SearchBarFilter from '@components/Common/SearchBar/withFilters';
import DailyRentCardList from "@components/DailyRent/DailyRentCardList/DailyRentCardList.component";
import { DailyRentsQueryFormatter } from '@services/formatters/dailyRent/DailyRentFilter.formatter';
import DailyRentFilter from "@components/DailyRent/Filter/DailyRentFilter.component";
import {useHistory} from "react-router-dom";

const filterInitialState = [
  {id: 0, filter: 'show_all', value: true},
  {id: 1, filter: 'dailyRentFilters.reservation', value: true},
  {id: 2, filter: 'dailyRentFilters.signed', value: true},
  {id: 3, filter: 'dailyRentFilters.archived', value: true},
  {id: 4, filter: 'dailyRentFilters.rentalsBetween', value: true}
];

const DailyRentWrapper = (props) => {
  const {
    dailyRents,
    loading,
    applyDailyRentFilter,
    endReached,
    lastPage,
  } = props;

  const [rentsSearch, setRentsSearch] = useState('');
  const [page, setPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [settings, setSettings] = useState(filterInitialState);
  const [rentDates, setRentDates] = useState({
    dateFrom: null, dateTo: null,
  });
  const history = useHistory();

  useEffect(() => {
    applyDailyRentFilter({
      page: page,
      filter: '',
      dateFrom: null,
      dateTo: null,
      search: '',
    });
  }, []);

  const filterVisibilityChange = () => {
    setShowFilter(!showFilter);
  };

  const onFilterChange = (values) => {
    setSettings(values);
    !settings[4].value && setRentDates({dateFrom: null, dateTo: null});
  };

  const onRentsSearch = (search = '') => {
    setRentsSearch(search);
    setPage(0);
    const query = DailyRentsQueryFormatter(settings);
    applyDailyRentFilter({
      page: 0,
      filter: query,
      search: search,
      dateFrom: rentDates.dateFrom,
      dateTo: rentDates.dateTo,
    });
  };

  const applyFilter = () => {
    const query = DailyRentsQueryFormatter(settings);
    setPage(0);
    applyDailyRentFilter({
      page: 0,
      filter: query,
      dateFrom: rentDates.dateFrom,
      dateTo: rentDates.dateTo,
      search: rentsSearch,
    });
  };

  const onEndReached = () => {
    const query = DailyRentsQueryFormatter(settings);
    setPage(page => page + 1);
    endReached({
      page: page + 1,
      filter: query,
      dateFrom: rentDates.dateFrom,
      dateTo: rentDates.dateTo,
      search: rentsSearch,
    });
  };

  const onDateChange = (type, value) => {
    setRentDates({
      ...rentDates,
      [type]: value,
    });
  };

  const onRentClick = (id, rentalType) => {
    history.push(`/daily-rent/${rentalType}/${id}`);
  };

  const rentFilterProps = {
    visible: showFilter,
    filterValues: settings,
    rentDates: rentDates,
    onDateChange: onDateChange,
    filterChanged: onFilterChange,
    applyFilter: applyFilter,
  };

  return (
    <div className="list-view grow">
      <SearchBarFilter
        search={rentsSearch}
        onSearch={(e) => onRentsSearch(e.target.value)}
        onFilterPress={filterVisibilityChange}
        onClose={() => onRentsSearch('')}
        children={<DailyRentFilter {...rentFilterProps} />}
      />
      <DailyRentCardList
        loading={loading}
        isLastPage={lastPage}
        dailyRents={dailyRents}
        isEndReached={onEndReached}
        onRentClick={onRentClick}
      />
    </div>
  );
};

DailyRentWrapper.propTypes = {
  dailyRents: PropTypes.array,
  loading: PropTypes.bool,
  applyRentsFilter: PropTypes.func,
  endReached: PropTypes.func,
  lastPage: PropTypes.bool,
};

DailyRentWrapper.defaultProps = {
  dailyRents: [],
  loading: false,
  applyRentsFilter: () => {},
  endReached: () => {},
  lastPage: false,
};

export default DailyRentWrapper;


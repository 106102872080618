import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';

import SelectSearch from './SearchSelect.component';


const SelectSearchWrapper = forwardRef((props, ref) => {

  const { apiCall, inputProps, renderListItem, itemClicked, setSearchTerm, children, addDriver } = props;

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [options, setOptions] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (page !== 0) {
        const data = await apiCall({ search: search, page: page });
        const updated = options.concat(data);
        setOptions(updated);
      }
    };
    fetchData();
  }, [page]);


  useEffect(() => {
    addDriver && setSearch(`${addDriver.name  } ${  addDriver.surname}`);
  }, [addDriver]);

  const onSearch = async (term) => {
    setSearch(term);
    setDropdownVisible(true);
    setLoading(true);
    const data = await apiCall({ search: term, page: 0 });
    setOptions(data);
    setLoading(false);
    setPage(0);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const onFocus = async () => {
    if (!options.length) {
      setDropdownVisible(true);
      setLoading(true);
      const data = await apiCall({ search: '', page: 0 });
      setOptions(data);
      setLoading(false);
    } else {
      setDropdownVisible(true);
    }

  };

  const itemClick = (item, index) => {
    const { userId, name } = item;
    const searchQuery = setSearchTerm(item);
    setSearch(searchQuery);
    setDropdownVisible(false);
    itemClicked(item, index);
  };

  const onEndReached = () => {
    setPage((page) => page + 1);
  };

  const itemWrapperRender = (item, index) => {
    return (<div key={index} className="select-search-item" onClick={() => itemClick(item, index)}>
      {renderListItem(item, index)}
    </div>);
  };

  useImperativeHandle(ref, () => ({
    setSearchParam: (param) => {
      setSearch(param);
    },
    setDropDown: (param) => {
      setDropdownVisible(param);
    }
  }));

  return (
    <div className={'select-wrapper'} ref={ref}>
      <SelectSearch
        search={search}
        onFocus={onFocus}
        onSearch={onSearch}
        options={options}
        dropdownVisible={dropdownVisible}
        toggleDropdown={toggleDropdown}
        renderListItem={itemWrapperRender}
        onEndReached={onEndReached}
        inputProps={inputProps}
        isLoading={loading}
      >{children}
      </SelectSearch>
    </div>
  );

});

export default SelectSearchWrapper;

import React from 'react';
import PropTypes from "prop-types";

/** Load default style tab style **/
import './Tabs.styles.scss';

const Tabs = ({ tabs, tabClass, activeTab = 0, tabClicked, children }) => {

  return (<div className={`tabs ${tabClass}`} >
    <ul className="nav">
      {
        tabs.map((tab, index) => (
          <li key={index}
            className={ activeTab === index ? 'active' : ''}
            onClick={(e) => tabClicked(e, tab)}>
            { tab.transl }
          </li>
        ))
      }
    </ul>
    <div className={'tab-content'}>
      { children }
    </div>
  </div> );
};

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  tabClass: PropTypes.string,
  activeTab: PropTypes.number,
  tabClicked: PropTypes.func
};

Tabs.defaultProps = {
  tabs: [],
  tabClass: '',
  activeTab: 0,
  tabClicked: () => {}
};

export default Tabs;

import "./TwoSidedButton.style.scss";
import PropTypes from "prop-types";
import classnames from "classnames";
import {BUTTON_TYPES} from "@constants";

export const TwoSidedButton = (props) => {
  const {
    textLeft,
    iconLeft,
    typeLeft,
    isDisabledLeft,
    onClickLeft,
    textRight,
    iconRight,
    typeRight,
    isDisabledRight,
    onClickRight,
  } = props;

  return (
    <div className={'split-button-container'}>
      <button
        onClick={onClickLeft}
        disabled={isDisabledLeft}
        className={classnames('split-button-left', typeLeft)}>
        {iconLeft}
        <p>
          {textLeft}
        </p>
      </button>
      <button
        onClick={onClickRight}
        disabled={isDisabledRight}
        className={classnames('split-button-right', typeRight)}>
        {iconRight}
        <p>
          {textRight}
        </p>
      </button>
    </div>
  );
};

TwoSidedButton.propTypes = {
  textLeft: PropTypes.string,
  iconLeft: PropTypes.node,
  typeLeft: PropTypes.oneOf(Object.values(BUTTON_TYPES)),
  isDisabledLeft: PropTypes.bool,
  onClickLeft: PropTypes.func,
  textRight: PropTypes.string,
  iconRight: PropTypes.node,
  typeRight: PropTypes.oneOf(Object.values(BUTTON_TYPES)),
  isDisabledRight: PropTypes.bool,
  onClickRight: PropTypes.func,
};

TwoSidedButton.defaultProps = {
  textLeft: '',
  iconLeft: null,
  typeLeft: BUTTON_TYPES.LINK,
  isDisabledLeft: false,
  onClickLeft: null,
  textRight: '',
  iconRight: null,
  typeRight: BUTTON_TYPES.LINK,
  isDisabledRight: false,
  onClickRight: null,
};

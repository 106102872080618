import React, { useState, forwardRef, useImperativeHandle } from "react";

/** load icons **/
import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";
import classnames from "classnames";

import "./SidePage.styles.scss";

const SidePage = forwardRef((props, ref) => {
  const { children, change, close } = props;

  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  useImperativeHandle(ref, () => ({
    openSidebar() {
      setOpen(true);
    },
  }));

  const toggleSideBar = () => {
    toggleOpen();
    change();
  };

  return (
    <div
      className={!!open || !close ? "side-page open" : "side-page"}
      ref={ref}
    >
      <div className={classnames("tongue", { open })} onClick={toggleSideBar}>
        <ChevronLeftIcon />
      </div>
      <div className="side-page-content">{children}</div>
    </div>
  );
});

export default SidePage;

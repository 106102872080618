import React from 'react';
import { TailSpin } from "react-loader-spinner";
import PropTypes from "prop-types";

const ScreenLoader = (props) => {

  const {isVisible, className} = props;

  if (isVisible) {

    return (
      <div className={`modal ${className} flex items-center justify-center`}>
        <div className="modal-content">
          <TailSpin
            color="#FFF"
            height={80}
            width={80}
          />
        </div>
      </div>
    );

  }
  return (<></>);
};

ScreenLoader.propTypes = {
  isVisible: PropTypes.bool,
  className: PropTypes.string
};

ScreenLoader.defaultProps = {
  isVisible: false,
  className: ''
};

export default ScreenLoader;

import React from 'react';
import PropTypes from "prop-types";
import './DriverInfo.styles.scss';

const DriverInfo = ({ driverName, driverSurname, tags }) => {
  return (
    <>
      <p>{ `${driverName ?? ''} ${driverSurname ?? ''}` }</p>
      <p className={'tags-list'}>{tags.length ? tags.map((tag) => tag.name).join(' | ') : ''}</p>
    </>
  );
};

export default DriverInfo;


DriverInfo.propTypes = {
  driverName: PropTypes.string, // avatar image
  driverSurname: PropTypes.string, // damage state,
  tags: PropTypes.array, // damage state,
};

DriverInfo.defaultProps = {
  driverName: '',
  driverSurname: '', // damage state
  tags: []
};

import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import { Button } from "@components/Common/Button";
import { ButtonList } from "@components/Common/Button/ButtonList";
import Toggler from "@components/Common/Toggler";
import AlertCmp from "@components/Common/Alert";
import usePrevious from "@services/hooks/usePrevious";
import Cookies from "universal-cookie";
import CheckIcon from "mdi-react/CheckIcon";
import Select from "react-select";
import { t } from "@helpers/i18n";
import "react-toggle/style.css";
import "./index.scss";

import {
  BUTTON_TYPES,
  SET_MAP_STATION,
  SET_MAP_SATELLITE,
  SET_MAP_SERVICE_PARTNERS,
  SET_SELECT_STATIONS,
  SET_TYPE_FUEL,
  MAP_CLEAR_SERVICE_PARTNER_LOCATIONS,
  MAP_CLEAR_STATION_LOCATIONS,
} from "@constants";
import { fuelTypes } from "@helpers/constants/fuelTypes";

const Setting = (props) => {

  const { auth } = useSelector((state) => state.auth);
  const {settings} = props;
  let { allStations } = settings;
  const { selectStation } = settings;
  const [state, setState] = useState({
    selectStat: null,
    satellite: false,
    showPopup: false,
    partner: true,
    station: true,
    type: "diesel"
  });
  const prevState = usePrevious(state);
  const cookies = new Cookies();
  const userId = auth.info.id;
  const dispatch = useDispatch();

  useEffect(() => {
    if (cookies.get(userId)) {
      setState(cookies.get(userId));
    }
  }, []);

  useEffect(() => {
    if (prevState?.type !== state.type) {
      dispatch({
        type: SET_TYPE_FUEL,
        payload: state.type
      });
    }

    if (prevState?.satellite !== state.satellite) {

      dispatch({
        type: SET_MAP_SATELLITE,
        payload: state.satellite
      });
    }

    if (prevState?.partner !== state.partner) {

      if (!state.partner) {
        dispatch({
          type: MAP_CLEAR_SERVICE_PARTNER_LOCATIONS,
        });
      }

      dispatch({
        type: SET_MAP_SERVICE_PARTNERS,
        payload: state.partner
      });
    }

    if (prevState?.station !== state.station) {

      if (!state.station) {
        dispatch({
          type: MAP_CLEAR_STATION_LOCATIONS,
        });
      }

      dispatch({
        type: SET_MAP_STATION,
        payload: state.station
      });
    }

    if (prevState?.selectStat !== state.selectStat) {
      dispatch({
        type: SET_SELECT_STATIONS,
        payload: state.selectStat
      });
    }
  }, [state]);

  const closePopup = () => {
    setState({
      ...state,
      showPopup: false
    });
  };

  const onToggle = (e) => {
    const { id } = e.currentTarget;
    setState({
      ...state,
      [id]: !state[id]
    });
  };

  const changeType = (e) => {
    if (e.target.value !== state.type) {
      setState({
        ...state,
        type: e.target.value
      });
    } else {
      setState({
        ...state,
        type: "diesel"
      });
    }
  };

  const selectStationHandle = (selectedOption) => {
    setState({
      ...state,
      selectStat: selectedOption
    });
  };

  const getOptions = () => {
    if (!!allStations.length) {
      allStations = allStations.filter((x) => x !== undefined && x !== null);
      allStations.sort((a, b) =>
          String(b).toUpperCase() < String(a).toUpperCase() ? 1 : -1
      );
    }

    const arr = [];
    allStations.map((val) => {
      arr.push({ value: val, label: val });
    });

    return arr;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    cookies.set(userId, JSON.stringify(state));
    setState({ ...state, showPopup: true });
  };

  return (
    <div className={"setting"}>
      {state.showPopup && <div className="blackout"/>}
      {state.showPopup && (
        <AlertCmp
          visible={state.showPopup}
          title={t("saved_preferences")}
          onClick={closePopup}
        />
      )}
      <h1>{t("settings")}</h1>
      <div className={"setting-row"}>
        <label>
          <p>{t("show_satellite_view")}</p>
          <Toggler id="satellite" onChange={onToggle} checked={state.satellite} />
        </label>
      </div>

      <div className={"setting-row"}>
        <label>
          <p>{t("show_fleetlink_partner")}</p>
          <Toggler id="partner" onChange={onToggle} checked={state.partner} />
        </label>
      </div>

      <div className={"setting-row"}>
        <label>
          <p>{t("show_gas_station")}</p>
          <Toggler id="station" onChange={onToggle} checked={state.station} />
        </label>
      </div>

      {
        <ButtonList>
          {fuelTypes.map((fuelType, ind) => {
            return (
              <button
                key={ind.toString()}
                value={fuelType.value}
                className={state.type === fuelType.value ? "active" : "no-active"}
                onClick={changeType}
              >
                {t(fuelType.translationKey)}
              </button>
            );
          })}
        </ButtonList>
      }

      <h2>{t("show_gas_stations")}</h2>
      <Select
        className={"select-box"}
        classNamePrefix="select"
        isDisabled={!allStations}
        isLoading={false}
        isClearable={true}
        disabled={true}
        onChange={selectStationHandle}
        isSearchable={true}
        placeholder={t('all')}
        name={"stations"}
        defaultValue={selectStation && selectStation}
        options={!!allStations && getOptions()}
      />
      <Button
        type={BUTTON_TYPES.PRIMARY}
        icon={state.showPopup ? <CheckIcon /> : null}
        text={t("save")}
        onClick={(e) => {
          handleSubmit(e);
        }}
        isSmall
      />
    </div>
  );
};

export default Setting;

import React from 'react';
/** Load translation helper **/
import { t } from "helpers/i18n";

const NoDamagesContainer = () => {
  return (<p className={'text-center empty-damages'}>{t('no_damages_yet')}</p>);
};


export default NoDamagesContainer;

const weekDayShorts = {
  'Monday': 'MO',
  'Tuesday': 'DI',
  'Wednesday': 'MI',
  'Thursday': 'DO',
  'Friday': 'FR',
  'Saturday': 'SA',
  'Sunday': 'SO',
};

const getMileagePerDayOfTheWeekObject = (perWeekDayData) => {
  const parsedData = {};
  Object.keys(perWeekDayData).map(key => {
    perWeekDayData[key].map(vehicleDataPerDay => {
      parsedData[weekDayShorts[key]] = {
        ...parsedData[weekDayShorts[key]],
        name: weekDayShorts[key],
        [vehicleDataPerDay.licencePlate]: vehicleDataPerDay.drivenKilometer || 0,
      };
    });
  });
  return parsedData;
};

const getAverageMileagePerDayOfTheWeekObject = (perWeekDayData) => {
  const parsedData = {};
  Object.keys(perWeekDayData).map(key => {
    perWeekDayData[key].map(vehicleDataPerDay => {
      parsedData[weekDayShorts[key]] = {
        ...parsedData[weekDayShorts[key]],
        name: weekDayShorts[key],
        [vehicleDataPerDay.licencePlate]: vehicleDataPerDay.averageDrivenKilometer || 0,
      };
    });
  });
  return parsedData;
};

export {
  getMileagePerDayOfTheWeekObject,
  getAverageMileagePerDayOfTheWeekObject
};

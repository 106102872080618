import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

/* Importing the components that are used in the TicketListContainer. */
import SearchBarFilter from "@components/Common/SearchBar/withFilters";
import {Button} from "@components/Common/Button";
import MapContainer from "@containers/Map";
import CardListTicket from "@components/Card/CardListTicket";
import TicketFilter from "@components/Ticket/Filter";

/* Importing the constants, actions and helpers. */
import {BUTTON_TYPES} from "@constants";
import MdiPlus from "mdi-react/PlusIcon";
import {t} from "@helpers/i18n";
import * as ticketsActions from "@store/tickets/actions";
import {TICKET_CARD_TYPES} from "@helpers/common/tickets";


const TicketListContainer = () => {

  /* Redux selectors */
  const dispatch = useDispatch();
  const {tickets, lastPage, loading} = useSelector(state => state.tickets);
  const { auth } = useSelector((state) => state.auth);
  const history = useHistory();
  const {accesslevel: userRole} = auth.user;

  /* Setting up the initial state of the component. */
  const initialFilters = [
    {id: 0, title: 'all', value: true},
    {id: 1, title: TICKET_CARD_TYPES.NEW, value: true},
    {id: 2, title: TICKET_CARD_TYPES.ASSIGNED, value: true},
    {id: 3, title: TICKET_CARD_TYPES.OBJECTED, value: true},
    {id: 4, title: TICKET_CARD_TYPES.OPEN, value: true},
    {id: 5, title: TICKET_CARD_TYPES.COMPLETED, value: true},
    {id: 6, title: 'tickets_between', value: false}
  ];
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [ticketSearch, setTicketSearch] = useState("");
  const [settings, setSettings] = useState(initialFilters);
  const [showSettings, setShowSettings] = useState(false);
  const [filters, setFilters] = useState({state: initialFilters, dates: {dateFrom: dateFrom, dateTo: dateTo}});
  const [page, setPage] = useState(0);

  /** Get Initial list of tickets **/
  const initialTicketsFetch = () => {
    setPage(0);
    dispatch(ticketsActions.ticketsGet({
      user: auth,
      page: 0,
      filters: filters,
      search: ticketSearch,
    }));
  };

  /* A hook that is called when the component is mounted. */
  useEffect(() => {
    initialTicketsFetch();
  }, [auth, filters, ticketSearch]);

  /**
   * Open Ticket on click
   * @param id - id of ticket
   **/
  const onTicketCardClick = (id) => {
    history.push(`/tickets/${id}`);
  };

  /**
   * If the user clicks on the "all" checkbox, then all the other checkboxes will be checked or unchecked. If the user clicks on any other checkbox, then the "all" checkbox will be checked if all the other checkboxes are checked, and unchecked if any of the other checkboxes are unchecked
   * @param key - the name of the filter that was clicked
   */
  const onSettingsChange = (key) => {
    const newFilter = [...settings];
    let i = 0;
    if (key === "all" && settings[0].value === true) {
      for (let i = 0; i < newFilter.length; i++) {
        newFilter[i].value = false;
      }
    } else if (key === "all" && settings[0].value === false) {
      for (i = 0; i < newFilter.length; i++) {
        newFilter[i].value = true;
      }
    } else {
      for (i = 1; i < newFilter.length; i++) {
        if (newFilter[i].title === key) {
          newFilter[i].value = !newFilter[i].value;
        }
      }
      newFilter[0].value = newFilter.slice(1).every(el => el.value);
    }
    setSettings(newFilter);
  };

  /**
   * toggle Filter component visibility
   **/
  const onSettingsClick = () => {
    setShowSettings(!showSettings);
  };

  /**
   * It sets the dateFrom or dateTo state variable depending on the name parameter
   * @param name - The name of the date picker.
   * @param date - The date that was selected
   */
  const onDateChange = (name, date) => {
    Object.is(name, 'dateFrom') && setDateFrom(date);
    Object.is(name, 'dateTo') && setDateTo(date);
  };

  /**
   * It takes the current state of the settings and the dates, and sets the filters to those values
   */
  const onApplyClick = () => {
    setFilters({state:settings,
      dates: {dateFrom: dateFrom, dateTo: dateTo}
    });
  };

  /** Load more tickets when user scrolls to end of the page **/
  const isEndReached = () => {
    setPage(page + 1);
    dispatch(
      ticketsActions.ticketsLoadMore({
        user: auth,
        page: page + 1,
        filters: filters,
        search: ticketSearch,
      })
    );
  };

  /** Ticket Card List Props **/
  const cardListTicketProps = {
    loading,
    tickets,
    lastPage,
    userRole,
    isEndReached,
    onTicketCardClick,
  };

  /** Ticket Filter component Props **/
  const ticketFilterProps = {
    visible: showSettings,
    filterValues: settings,
    dateFrom: dateFrom,
    dateTo: dateTo,
    onDateChange: onDateChange,
    filterChange: onSettingsChange,
    applyFilter: onApplyClick,
  };

  const renderTicketList = () => {
    return (
      <div className="list-view">
        <SearchBarFilter
          onSearch={(e) => setTicketSearch(e.target.value)}
          search={ticketSearch}
          onFilterPress={onSettingsClick}
          onClose={() => setTicketSearch("")}
          children={<TicketFilter {...ticketFilterProps} />}
        />
        <div>
          <CardListTicket {...cardListTicketProps} />
          <Button
            className={'list-add-button'}
            type={BUTTON_TYPES.WARNING}
            icon={<MdiPlus/>}
            text={t("add_ticket")}
            isSmall
            onClick={() => history.push('/tickets/add')}
          />
        </div>
      </div>);
  };

  return (
    <div className="list-wrapper">
      <MapContainer />
      {renderTicketList()}
    </div>
  );
};

export default TicketListContainer;

import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {EMAIL_REGEX} from "@constants";
import { Formik, Form, Field } from 'formik';
import Eye from "../../assets/svg/eye.svg";
import LoginIcon from "mdi-react/LoginIcon";
import React from "react";
import Popup from "@components/Popup";
import { t } from "@helpers/i18n";
import { Input } from "@components/Common/Input";
import {Button} from "@components/Common/Button";
import {BUTTON_TYPES} from "@constants";
import CarIcon from "mdi-react/CarIcon";
import AccountGroupIcon from "mdi-react/AccountGroupIcon";
import MapMarkerDistanceIcon from "mdi-react/MapMarkerDistanceIcon";
import CalendarEditIcon from "mdi-react/CalendarEditIcon";
import CurrencyEurIcon from "mdi-react/CurrencyEurIcon";
import { ReactComponent as Dude } from "@assets/svg/signup-dude.svg";
import * as authActions from "@store/auth/actions";
import {Spinner} from "@components/Spinner";
import "./Login.styles.scss";

import { useHistory } from "react-router-dom";

const Login = () => {

  const {auth} = useSelector(state => state);

  // use history hook
  const history = useHistory();

  const dispatch = useDispatch();
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [popup, setPopup] = useState(false);
  const [noValid, setNoValid] = useState(false);
  const [value, setValue] = useState('');
  const {resetPassword, loading} = auth;
  const [loggedInState, setLoggedInState] = useState(false);

  const login = (values) => {
    dispatch(authActions.authLogin({
      username: values.email,
      password: values.password,
    }));
  };

  useEffect(() => {
    if(loading){
      setLoggedInState(true);
    }
    else{
      setLoggedInState(false);
    }
  },[loading]);

  const togglePassword = () => {
    setIsPasswordShown(prevIsPasswordShown => !prevIsPasswordShown);
  };

  const openUp = () => {
    setPopup(true);
  };

  const closePopup = () => {
    setPopup(false);
  };

  const send = () => {
    if (value.match(EMAIL_REGEX)){
      dispatch(authActions.authResetPassword({
        username: value
      }));
      setPopup(false);
      setValue('');
    } else {
      setNoValid(true);
    }
  };

  const handleKeypress = e => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      send();
    }
  };

  const inputMail = (e) => {
    setValue(e.target.value);
    setNoValid(false);
  };

  const okClose = () => {
    dispatch(authActions.authPasswordClear());
  };

  const renderIconCircle = () => {

    return (
      <div className="icons-circle">
        <div className="dashed-circle" />
        <div className="icon-container car-icon">
          <CarIcon/>
        </div>
        <div className="icon-container account-group-icon">
          <AccountGroupIcon />
        </div>
        <div className="icon-container account-group-icon">
          <AccountGroupIcon />
        </div>
        <div className="icon-container map-marker-distance-icon">
          <MapMarkerDistanceIcon />
        </div>
        <div className="icon-container calendar-edit-icon">
          <CalendarEditIcon />
        </div>
        <div className="icon-container currency-eur-icon">
          <CurrencyEurIcon />
        </div>
      </div>
    );
  };

  const passwordResetPopup = () => (
    <>
      {!!popup &&
  <Popup
    icon={true}
    input={true}
    close={true}
    closePopup={closePopup}
    title={t('reset_password')}
    subTitle={t('popup_subtitle')}
    button={[
      {title:'send', color: 'green', event: send}
    ]}
    onChangeInput={(e) => {inputMail(e);}}
    value={value}
    noValid={noValid}
    onKeyDown={handleKeypress}
  />
      }

      {resetPassword !== null &&
  <Popup
    title={ !!resetPassword ? t('password_reset'): t('password_reset_error')}
    subTitle={!!resetPassword ? t('password_reset_desc'): null}
    button={[
      {title:'Okay', color: 'green', event: okClose}
    ]}
  />
      }
    </>
  );

  const LoginForm = () => {
    return (
      <Formik
        initialValues={
          {
            email: '',
            password: '',
          }
        }
        onSubmit={(values) => login(values)}
      >
        <Form className="form-container">
          <div className="right-title">
            {t('welcome_fleet')}
          </div>
          <Field
            label={t('e_mail_address')}
            name="email"
            component={Input}
          />
          <div className="forgot-eye">
            <Field
              label={t('login_password')}
              name="password"
              component={Input}
              inputType={isPasswordShown ? "text" : "password"}
            />
            <img src={Eye} onClick={togglePassword} alt="password"/>
          </div>
          <div className="forgot-password" onClick={openUp}>
            {t('forgot_your_password')}
          </div>
          <div className="actions">
            {loggedInState && <Spinner /> }
            <Button
              icon={<LoginIcon />}
              type={BUTTON_TYPES.WARNING}
              text="Login"
              isSubmit
            />
            <div className="sign-up">
              <span>{t('new_to_fleet')}</span>
                            &nbsp;
              <span
                className="sign-up-link"
                onClick={() => { history.push('/register');}}
              >
                {t('sign_up')}
              </span>
            </div>
          </div>
        </Form>
      </Formik>
    );
  };

  const serverError = status.hasOwnProperty('error');
  const serverStatus = status.hasOwnProperty('status');

  return(
    <div className={!!popup || serverError || serverStatus || resetPassword ? "login-container popup-over": "login-container"}>
      <div className="left-block">
        <Dude />
        {renderIconCircle()}
      </div>
      <div className="rectangle" />
      <div className="right-block">
        {passwordResetPopup()}
        {LoginForm()}
      </div>
    </div>
  );
};

export default Login;

import React from "react";
/** load icons **/
import CloseIcon from "mdi-react/CloseIcon";
import Send from "mdi-react/SendIcon";
/** load components **/
import {Input} from "@components/Common/Input";

import {t} from "@helpers/i18n";

import "./Popup.styles.scss";
import PropTypes from "prop-types";


const Popup = (props) => {

  const {title, subTitle, button, onChangeInput, value, noValid, input, close, closePopup, icon, onKeyDown} = props;

  const setButton = () => {
    return (
      button.map(((button, key) =>
        <div className={button.color ? `${button.color} button` : 'button'} key={key}
          onClick={button.event}
        >{icon && <Send/>}{button.title}</div>
      ))
    );
  };

  return (
    <div className="pop-up">
      {close && <CloseIcon className="pop-up_close" onClick={closePopup}/>}
      {title && <p className="pop-up_title">{title}</p>}
      {subTitle && <p className="pop-up_description">{subTitle}</p>}

      {!!input && <Input
        value={value}
        onChange={(e) => {
          onChangeInput(e);
        }}
        onKeyDown={onKeyDown}
      />}

      {!!noValid && <p className="pop-up_no-valid">{t('email_not_valid')}</p>}
      <div className="pop-up_row-btn">
        {!!button && setButton()}
      </div>
    </div>
  );
};

Popup.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  input: PropTypes.bool,
  close: PropTypes.bool,
  closePopup: PropTypes.func,
  onClickFirstBtn: PropTypes.func,
  onClickSecondBtn: PropTypes.func,
  onChangeInput: PropTypes.func,
  button: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string
  ]),
  onKeyDown: PropTypes.func
};

Popup.defaultProps = {
  title: '',
  subTitle: '',
  close: false,
  onKeyDown: () => {}
};

export default Popup;

import React, {useMemo} from 'react';
import {t} from "@helpers/i18n";
import {getVehicleInspectionIcon, getVehicleInspectionStatusName} from "@helpers/common/vehicles";


const VehicleForm = ({ details }) => {
  
  const { modelType,
    driver,
    tags,
    place,
    speed,
    kilometrage,
    fuelLevel,
    inspectionStatus,
    nextInspectionAtKm,
    nextInspectionAtDate
  } = details;

  const nextInspectionDateCount = useMemo(() =>
  nextInspectionAtDate ? `${Math.round((new Date(nextInspectionAtDate) - new Date()) / (1000 * 60 * 60 * 24))}  ${t('days').toLowerCase()}` : '-', [nextInspectionAtDate]);

  return (
    <>
      <div className={'details-column'}>
        <div className="details-line">
          <span className="details-row">{ t('vehicle-type')}:</span>
          { modelType || '-' }
        </div>
        <div className="details-driver">
          <div>
            <div className="details-line">
              <span className="details-row">{ t('vehicle-tags') }:</span>
              { tags || '-' }
            </div>
            <div className="details-line">
              <span className="details-row">{ t('vehicle-driver')}:</span>
              { driver || '-' }
            </div>
            <div className="details-line">
              <span className="details-row">{ t('vehicle-location') }:
              </span>
              { place || '-' }
            </div>
            <div className="details-line">
              <span className="details-row">{ t('vehicle-mileage') }:</span>
              { kilometrage ? kilometrage : '-' }
            </div>
            <div className="details-line">
              <span className="details-row">{ t('vehicle-velocity') }:</span>
              { speed || '-' }
            </div>
            <div className="details-line">
              <span className="details-row">{ t('vehicle-fuel') }:</span>
              { fuelLevel || '-' }
            </div>
            <div className="details-line">
              <span className="details-row">{ t('inspection_status') }:</span>
              <span className={'inspection-span'}>
                {inspectionStatus ? getVehicleInspectionStatusName(inspectionStatus) : '-'}
                {inspectionStatus ? getVehicleInspectionIcon(inspectionStatus) : ''}
              </span>
            </div>
            <div className="details-line">
              <span className="details-row">{ t('next_inspection') }:</span>
              { nextInspectionAtKm || '-' }
            </div>
            <div className={'details-line'}>
              <span className={'details-row'}>{t('or_in')}:</span>
              {nextInspectionDateCount}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


export default VehicleForm;


/** Appointments filter states  **/
export const FILTER_STATES = [
  '',
  'confirmed',
  'pending',
  'canceled',
  'archive',
];

const AppointmentsFilterFormatter = (e, filters) => {
  const { id, checked } = e.currentTarget;
  let updateFilters = [...filters];
  const filterId = parseInt(id);
  const filterIndex = filters.findIndex((filter) => filter.id === filterId);
  if (filterIndex === 0) {
    updateFilters = filters.map((filter) => ({ ...filter, value: checked }));
  } else {
    updateFilters[filterIndex].value = checked;
    const dd = updateFilters.filter((item, ind) => ind !== 0 && ind !== 7);
    const allTrue = dd.every((filter) => filter.value);
    updateFilters[0].value = allTrue;
  }
  return updateFilters;
};


/** Helper for Appointments List query
 * formats filters, search into query
 * @param {array} filters filter options array
 * @param {date} startDate - startDate only active when between filter active
 * @param {date} endDate - endDate only active when between filter active
 * @param {string} searchText - search text
 * **/
const AppointmentsQueryFormatter = (filters, startDate, endDate, searchText = '') => {
  let query = ``;
  if (filters[0].value) {
    return query;
  }
  filters.map((filter, index) => {
    if (index < 7 && filter.value) {
      query = query.concat(`&filterState[]=${ FILTER_STATES[index] }`);
    }
  });
  return query;
};


export {
  AppointmentsFilterFormatter,
  AppointmentsQueryFormatter
};

import React from "react";
import EditDriversLicenseComponent from "@components/User/EditDriversLicense";


const EditDriversLicense = ({profile, license, loading, onSaveClick, onPreviewImage}) => {
  return (
    <div className={'flex-1'}>
      <EditDriversLicenseComponent
        info={profile}
        license={license}
        loading={loading}
        onPreviewImage={onPreviewImage}
        onSave={(userData) => onSaveClick(userData)}
      />
    </div>
  );
};

export default  EditDriversLicense;

import { DriverPopup } from "../DriverPopup";
import ServicePartnerPopup from "../ServicePartnerPopup";
import GasStationPopup from "../GasStationPopup";
import { GET_TRIPS_REQUEST, PRICE_LEVELS } from "constants";

import "./Marker.styles.scss";
import { withRouter } from "react-router-dom";

import { useEffect, useState } from "react";

export const MARKER_STYLES = {
  DOT_MARKER: "dot-marker",
  ICON_MARKER: "icon-marker",
};

export const MARKER_TYPES = {
  CAR: "passenger_car",
  VAN: "small_transporter",
  TRAN: "transporter",
  SERVICE: "ServicePartner",
  STATION: "gas-station",
};

export const transportMarkers = [
  MARKER_TYPES.CAR,
  MARKER_TYPES.VAN,
  MARKER_TYPES.TRAN,
];

export const markersWithInfo = [
  MARKER_TYPES.CAR,
  MARKER_TYPES.VAN,
  MARKER_TYPES.TRAN,
];

@withRouter
@connect((state) => ({
  state: state,
}))
class Marker2 extends React.Component {
  state = { hovered: false };

  static propTypes = {
    type: PropTypes.oneOf(Object.values(MARKER_TYPES)),
    priceLevel: PropTypes.oneOf(Object.values(PRICE_LEVELS)),
    brand: PropTypes.string,
    price: PropTypes.number,
    licensePlate: PropTypes.string,
    carsCount: PropTypes.number,
    username: PropTypes.string,
    userCar: PropTypes.string,
    customerCity: PropTypes.string,
    customerPlace: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    speed: PropTypes.number,
    fuelLevel: PropTypes.number,
  };

  static defaultProps = {
    price: 0,
    speed: 0,
    brand: "",
    type: null,
    userCar: "",
    username: "",
    fuelLevel: 0,
    carsCount: 0,
    priceLevel: null,
    licensePlate: "",
    customerCity: "",
    customerPlace: "",
  };

  componentWillReceiveProps(nextProps, nextContext) {
    const {
      hoverMarker,
      marker,
      hoverMarkerStation,
      hoverMarkerService,
      selectAppointment,
    } = this.props;

    console.log(hoverMarker);

    /**
     Display popup if select Vehicles*/
    if (!!hoverMarker) {
      hoverMarker.id === marker.id
        ? this.setState({ hovered: true })
        : this.setState({ hovered: false });
    }

    /**
     Display popup if select Station*/
    if (!!hoverMarkerStation) {
      hoverMarkerStation.id === marker.id
        ? this.setState({ hovered: true })
        : this.setState({ hovered: false });
    }

    /**
     Display popup if select Service*/
    if (!!hoverMarkerService) {
      hoverMarkerService.id === marker.id
        ? this.setState({ hovered: true })
        : this.setState({ hovered: false });
    }

    /**
    Display popup if select Appointment*/
    if (!!selectAppointment) {
      selectAppointment.attributes.id === marker.id
        ? this.setState({ hovered: true })
        : this.setState({ hovered: false });
    }

    /**
     Remove popup if not select Vehicles, Station, Service*/
    if (
      !hoverMarker &&
      !hoverMarkerStation &&
      !hoverMarkerService &&
      !selectAppointment
    ) {
      this.setState({ hovered: false });
    }
  }

  get markerType() {
    return this.props.type === MARKER_TYPES.STATION
      ? MARKER_STYLES.DOT_MARKER
      : MARKER_STYLES.ICON_MARKER;
  }

  renderInfo() {
    const { type, customerCity, licensePlate, started } = this.props;

    if (markersWithInfo.includes(type)) {
      return (
        <React.Fragment>
          <div
            className={classnames("marker-info", type)}
            onMouseOver={() => this.setState({ hovered: true })}
            onMouseEnter={() => this.setState({ hovered: true })}
            onMouseLeave={() => this.setState({ hovered: false })}
          >
            <div className="info-line1">{licensePlate}</div>
            <div className="info-line2">{customerCity}</div>
          </div>
          <div className={classnames("marker-dot", { started })} />
        </React.Fragment>
      );
    } else {
      return null;
    }
  }

  renderPopup() {
    const {
      type,
      name,
      brand,
      price,
      speed,
      rating,
      userCar,
      fuelLevel,
      priceLevel,
      customerCity,
      customerPlace,
    } = this.props;

    if (!this.state.hovered) {
      return null;
    }

    if (type === MARKER_TYPES.STATION) {
      return (
        <GasStationPopup brand={brand} price={price} priceLevel={priceLevel} />
      );
    }

    if (type === MARKER_TYPES.SERVICE) {
      return (
        <ServicePartnerPopup
          name={name}
          rating={rating}
          customerPlace={customerPlace}
        />
      );
    }

    return (
      <DriverPopup
        speed={speed}
        username={name}
        userCar={userCar}
        fuelLevel={fuelLevel}
        customerCity={customerCity}
        customerPlace={customerPlace}
      />
    );
  }

  render() {
    const { type, priceLevel, markerIcon, onMarkerClick } = this.props;

    return (
      <div
        className="map-marker"
        onMouseOver={() => this.setState({ hovered: true })}
        onMouseEnter={() => this.setState({ hovered: true })}
        onMouseLeave={() => this.setState({ hovered: false })}
      >
        <div
          className={classnames(
            "marker-container",
            this.markerType,
            priceLevel,
            `${type}-mark`
          )}
          onClick={(e) => {
            /**
            Tripe History */
            const { marker } = this.props;
            if (!!marker) {
              this.props.dispatch({
                type: GET_TRIPS_REQUEST,
                payload: { id: marker.id },
              });

              // this.props.dispatch({
              //   type: GET_TRIPS_POSITIONS_REQUEST,
              //   payload: { id: telemetryId },
              // });
            }

            onMarkerClick(e);
          }}
        >
          {type !== MARKER_TYPES.STATION && (
            <React.Fragment>
              {this.renderInfo()}
              <div className="icon-background" />
              {markerIcon}
            </React.Fragment>
          )}
        </div>
        {this.renderPopup()}
      </div>
    );
  }
}

const Marker = ({
  type,
  name,
  brand,
  price,
  speed,
  rating,
  userCar,
  fuelLevel,
  priceLevel,
  customerCity,
  customerPlace,
  licensePlate,
  started,
  onMarkerClick,
  markerIcon,
  marker,
  hoverMarker,
  hoverMarkerStation,
  hoverMarkerService,
}) => {
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    setHovered(
      hoverMarkerStation?.id === marker.id ||
        hoverMarkerService?.id === marker.id ||
        (hoverMarker &&
          (hoverMarker?.[0] === marker.id || hoverMarker.id === marker.id))
    );
  }, [hoverMarker, marker, hoverMarkerStation, hoverMarkerService]);

  const markerType = () => {
    return type === MARKER_TYPES.STATION
      ? MARKER_STYLES.DOT_MARKER
      : MARKER_STYLES.ICON_MARKER;
  };

  const renderInfo = () => {
    if (markersWithInfo.includes(type)) {
      return (
        <>
          <div className={classnames("marker-info", type)}>
            <div className="info-line1">{licensePlate}</div>
            <div className="info-line2">{customerCity}</div>
          </div>
          <div className={classnames("marker-dot", { started })} />
        </>
      );
    }
  };

  const renderPopup = () => {
    if (!hovered) {
      return null;
    }

    if (type === MARKER_TYPES.STATION) {
      return (
        <GasStationPopup brand={brand} price={price} priceLevel={priceLevel} />
      );
    }

    if (type === MARKER_TYPES.SERVICE) {
      return (
        <ServicePartnerPopup
          name={name}
          rating={rating}
          customerPlace={customerPlace}
        />
      );

    }
    return (
      <DriverPopup
        speed={speed}
        username={name}
        userCar={userCar}
        fuelLevel={fuelLevel}
        customerCity={customerCity}
        customerPlace={customerPlace}
      />
    );
  };

  const isMarkerClicked = () =>
    hoverMarkerStation?.id === marker.id ||
    hoverMarkerService?.id === marker.id ||
    hoverMarker?.id === marker.id ||
    hoverMarker?.[0] === marker.id;

  return (
    <div
      className="map-marker"
      onMouseEnter={() => !isMarkerClicked() && setHovered(true)}
      onMouseLeave={() => !isMarkerClicked() && setHovered(false)}
    >
      <div
        className={classnames(
          "marker-container",
          markerType(),
          priceLevel,
          `${type}-mark`
        )}
        onClick={onMarkerClick}
      >
        {type !== MARKER_TYPES.STATION && (
          <>
            {renderInfo()}
            <div className="icon-background" />
            {markerIcon}
          </>
        )}
      </div>
      {renderPopup()}
    </div>
  );
};

export default Marker;

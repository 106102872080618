import { call, put, all, takeLatest } from "redux-saga/effects";
import { errorHandler } from "@helpers/saga";
import { Api } from "@api";

import {
  MAP_GET_DRIVERS_SUCCESS,
  MAP_GET_VEHICLE_LOCATIONS_SUCCESS,
  MAP_GET_STATION_LOCATIONS_REQUEST,
  MAP_GET_STATION_LOCATIONS_SUCCESS,
  MAP_GET_STATION_LOCATIONS_FAIL,
  MAP_GET_SERVICE_PARTNER_LOCATIONS_SUCCESS,
  SET_ALL_STATIONS, GET_ACTIVE_VEHICLE_TRIP_SUCCESS, GET_ACTIVE_VEHICLE_TRIP_FAIL, GET_ACTIVE_VEHICLE_TRIP_REQUSET,
} from "./constants";
import {toast} from "react-toastify";
import {t} from "@helpers/i18n";

function* getMapData(action) {
  try {
    const params = action.payload;
    // if radius is greater than 30, do not fetch stations

    if (params?.radius >= 31) {
      params["show-gas-stations"] = false;
    }

    const { data = {} } = yield call(Api.map.getStationsFromHome, params);
    const {
      gasStations = { stations: [] },
      gasStationBrands = [],
      servicePartners = [],
      positions: vehicles = [],
    } = data;

    const { data: drivers = {} } = yield call(Api.map.getDrivers, params.id);

    yield put({
      type: MAP_GET_DRIVERS_SUCCESS,
      payload: drivers,
    });

    yield put({
      type: MAP_GET_STATION_LOCATIONS_SUCCESS,
      payload: gasStations.stations,
    });

    yield put({
      type: MAP_GET_SERVICE_PARTNER_LOCATIONS_SUCCESS,
      payload: servicePartners,
    });

    yield put({
      type: MAP_GET_VEHICLE_LOCATIONS_SUCCESS,
      payload: vehicles,
    });
    yield put({ type: SET_ALL_STATIONS, payload: gasStationBrands });
  } catch (err) {
    yield call(errorHandler, err);
    yield put({ type: MAP_GET_STATION_LOCATIONS_FAIL });
  }
}
function* getActiveVehicleTrip(action) {
  try {
    const {carId, tripDate} = action.payload;
    const {data} = yield call(Api.trips.getSingleTrip, carId, tripDate || '');
    yield put({type: GET_ACTIVE_VEHICLE_TRIP_SUCCESS, payload: {...data, vehicleId:carId}});
    if (!data?.tracks?.length) {
      toast.info(t('vehicle-map-no-data'));
    }
  } catch (err) {
    yield call(errorHandler, err);
    yield put({type: GET_ACTIVE_VEHICLE_TRIP_FAIL, err});
    toast.info(t('vehicle-map-no-data'));
  }
}

function* getMapDataSaga() {
  yield takeLatest(MAP_GET_STATION_LOCATIONS_REQUEST, getMapData);
  yield takeLatest(GET_ACTIVE_VEHICLE_TRIP_REQUSET, getActiveVehicleTrip);
}

export function* mapSagas() {
  yield all([call(getMapDataSaga)]);
}

import React, {useState, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {IconButton} from '@mui/material';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import './TicketImagePreview.style.scss';
import Slider from '@components/Common/Slider';

const TicketImagePreviewComponent = (props) => {
  const {ticketImages} = props;
  const [previewIndex, setPreviewIndex] = useState(0);
  const sliderRef = useRef();

  /** Call back method when car check image is clicked **/
  const openImage = (index) => {
    sliderRef.current.previewImage(index);
  };

  const previousPageButton = useMemo(() => (
    <IconButton color="primary" aria-label="upload picture" component="span"
      disabled={previewIndex === 0}
      onClick={() => setPreviewIndex(previewIndex - 2)}>
      <ArrowCircleLeftIcon fontSize={'large'}/>
    </IconButton>
  ), [previewIndex]);

  const nextPageButton = useMemo(() => (
    <IconButton color="primary" aria-label="upload picture" component="span"
      disabled={previewIndex + 2 >= ticketImages.length}
      onClick={() => setPreviewIndex(previewIndex + 2)}>
      <ArrowCircleRightIcon fontSize={'large'} />
    </IconButton>
  ), [previewIndex]);

  return (
    <div className={'viewer-container'}>
      {previousPageButton}
      <div className={'ticket-image-container'}>
        {ticketImages[previewIndex] &&
        <img src={ticketImages[previewIndex]} alt={`ticket_image_${previewIndex}`} onClick={() => openImage(previewIndex)}/>
        }
        {ticketImages[previewIndex + 1] &&
          <img src={ticketImages[previewIndex + 1]} alt={`ticket_image_${previewIndex + 1}`} onClick={() => openImage(previewIndex + 1)}/>
        }
      </div>
      {nextPageButton}
      <Slider
        imageSrcLink
        ref={sliderRef}
        slides={ticketImages}
      />
    </div>
  );
};

TicketImagePreviewComponent.propTypes = {
  ticketImages: PropTypes.array.isRequired
};


export default TicketImagePreviewComponent;

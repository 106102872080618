export const MAP_GET_VEHICLE_LOCATIONS_REQUEST =
  "MAP_GET_VEHICLE_LOCATIONS_REQUEST";
export const MAP_GET_VEHICLE_LOCATIONS_SUCCESS =
  "MAP_GET_VEHICLE_LOCATIONS_SUCCESS";
export const MAP_GET_VEHICLE_LOCATIONS_FAIL = "MAP_GET_VEHICLE_LOCATIONS_FAIL";

export const MAP_GET_STATION_LOCATIONS_REQUEST =
  "MAP_GET_STATION_LOCATIONS_REQUEST";
export const MAP_GET_STATION_LOCATIONS_SUCCESS =
  "MAP_GET_STATION_LOCATIONS_SUCCESS";
export const MAP_GET_STATION_LOCATIONS_FAIL = "MAP_GET_STATION_LOCATIONS_FAIL";

export const MAP_GET_SERVICE_PARTNER_LOCATIONS_REQUEST =
  "MAP_GET_SERVICE_PARTNER_LOCATIONS_REQUEST";
export const MAP_GET_SERVICE_PARTNER_LOCATIONS_SUCCESS =
  "MAP_GET_SERVICE_PARTNER_LOCATIONS_SUCCESS";
export const MAP_GET_SERVICE_PARTNER_LOCATIONS_FAIL =
  "MAP_GET_SERVICE_PARTNER_LOCATIONS_FAIL";

export const MAP_CLEAR_SERVICE_PARTNER_LOCATIONS =
  "MAP_CLEAR_SERVICE_PARTNER_LOCATIONS";

export const MAP_GET_DRIVERS_REQUEST = "MAP_GET_DRIVERS_REQUEST";
export const MAP_GET_DRIVERS_SUCCESS = "MAP_GET_DRIVERS_SUCCESS";
export const MAP_GET_DRIVERS_FAIL = "MAP_GET_DRIVERS_FAIL";

export const MAP_CLEAR_STATION_LOCATIONS = "MAP_CLEAR_STATION_LOCATIONS";

export const MAP_COORDINATES_SET = "MAP_COORDINATES_SET";
export const MAP_COORDINATES_CLEAR = "MAP_COORDINATES_CLEAR";

export const SET_ALL_STATIONS = "SET_ALL_STATIONS";

export const GET_ACTIVE_VEHICLE_TRIP_REQUSET = "GET_ACTIVE_VEHICLE_TRIP_REQUEST";
export const GET_ACTIVE_VEHICLE_TRIP_SUCCESS = "GET_ACTIVE_VEHICLE_TRIP_SUCCESS";
export const GET_ACTIVE_VEHICLE_TRIP_FAIL = "GET_ACTIVE_VEHICLE_TRIP_FAIL";

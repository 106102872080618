import React from 'react';

import {ReactComponent as GreenGood} from '../../../../assets/svg/cleanliness/green-good.svg';
import {ReactComponent as GreenGoodGray} from '../../../../assets/svg/cleanliness/green-good-gray.svg';
import {ReactComponent as LightgreenGood} from '../../../../assets/svg/cleanliness/lightgreen-good.svg';
import {ReactComponent as LightgreenGoodGray} from '../../../../assets/svg/cleanliness/lightgreen-good-gray.svg';
import {ReactComponent as Neutral} from '../../../../assets/svg/cleanliness/neutral.svg';
import {ReactComponent as NeutralGray} from '../../../../assets/svg/cleanliness/neutral-gray.svg';
import {ReactComponent as OrangeBad} from '../../../../assets/svg/cleanliness/orange-bad.svg';
import {ReactComponent as OrangeBadGray} from '../../../../assets/svg/cleanliness/orange-bad-gray.svg';
import {ReactComponent as RedBad} from '../../../../assets/svg/cleanliness/red-bad.svg';
import {ReactComponent as RedBadGray} from '../../../../assets/svg/cleanliness/red-bad-gray.svg';

export const cleanlinessStates = [
  {
    id: 4,
    icon: <GreenGood />,
    smallIcon: <GreenGood width={19} height={19} />,
    iconGray: <GreenGoodGray />
  },
  {
    id: 3,
    icon: <LightgreenGood />,
    smallIcon: <LightgreenGood width={19} height={19} />,
    iconGray: <LightgreenGoodGray />
  },
  {
    id: 2,
    icon: <Neutral />,
    smallIcon: <Neutral width={19} height={19} />,
    iconGray: <NeutralGray />
  },
  {
    id: 1,
    icon: <OrangeBad />,
    smallIcon: <OrangeBad width={19} height={19} />,
    iconGray: <OrangeBadGray />
  },
  {
    id: 0,
    icon: <RedBad />,
    smallIcon: <RedBad width={19} height={19} />,
    iconGray: <RedBadGray />
  }
];

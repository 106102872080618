import React from 'react';

/** load components **/
import InfoBlock from "@components/User/InfoBlock";
import Avatar from "@components/User/Avatar";
import { LicensePlate } from "@components/Car/LicensePlate";


const ProfileInfoBlock = ({ name, surname, company, customerNumber, avatar, licensePlate }) => {
  return (
    <InfoBlock
      name={`${name} ${surname}`}
      customerName={company}
      customerNumber={customerNumber}
      avatarElement={
        <Avatar
          admin={true}
          src={avatar}
        />
      }
      bottomElement={
        <LicensePlate
          country="D"
          license={licensePlate}
        />
      }
    />
  );
};

ProfileInfoBlock.propTypes = {
  name: PropTypes.string,
  surname: PropTypes.string,
  company: PropTypes.string,
  customerNumber: PropTypes.string,
  avatar: PropTypes.string,
  licensePlate: PropTypes.string
};

ProfileInfoBlock.defaultProps = {
  name: '',
  surname: '',
  company: '',
  customerNumber: '',
  avatar: '',
  licensePlate: ''
};

export default ProfileInfoBlock;

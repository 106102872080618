import React, {useCallback} from 'react';

/** Loading components **/
import TripTrack from "@components/Trip/Card/TripTrack/TripTrack.component";

/** Loading styles **/
import './TripTrackList.styles.scss';


const TripTrackList = (props) => {

  const { tracks, drivers, selectedSubtrips, onTripClick } = props;

  /**
   * Single trip/track click event
   * @param {object} trip
   */
  const tripClicked = (trip) => {
    const { id } = trip;
    // check if trip is included
    const isTripOpened = selectedSubtrips.includes(id);
    if (isTripOpened) {
      onTripClick(trip, true);
    } else {
      onTripClick(trip, false);
    }
  };

  /** trip Item props */
  const tripItemProps = { selectedSubtrips, onTripClick: tripClicked };

  /**
   * Render Tracks List
   * @type {(...args: any[]) => any}
   */
  const renderTracksList = useCallback(() => {
    return(
      <div className={'trips-list list-container'}>
        {tracks.map((track, index) => {
          return (<TripTrack key={index} track={track} drivers={drivers} {...tripItemProps} />);
        })}
      </div>
    );
  }, [tracks, selectedSubtrips]);


  return (
    <>
      {renderTracksList()}
    </>
  );
};

export default TripTrackList;

import React from 'react';
import SelectSearch from '@components/Common/SearchSelect/SelectSearchWrapper.component';
import Avatar from "@components/User/Avatar";

import './DriverSelectSearch.styles.scss';

const DriverSelectSearch = ({ itemSelected, getDrivers, addDriver }) => {

  const renderDriver = (driver, index) => {
    if (driver) {
      return (<><Avatar src={driver.avatar} micro/><p className={'driver-dropdown-item'}>{driver.name} {driver.surname}</p></>);
    }
  };


  const setSearchTerm = (driver) => {
    const  searchTerm = `${driver.name} ${driver.surname}`;

    return searchTerm;
  };

  const itemClicked = (driver, index) => {
    itemSelected(driver, index);
  };

  return (
    <div className="driver-select">
      <SelectSearch
        apiCall={getDrivers}
        renderListItem={renderDriver}
        itemClicked={itemClicked}
        setSearchTerm={setSearchTerm}
        addDriver={addDriver}
      />
    </div>
  );
};

export default DriverSelectSearch;

const InvoiceSumFormatter = (invoiceSum) => {
  if (invoiceSum !== null) {
    const sum = invoiceSum.toFixed(2);
    const replacedDecimals = sum.replace('.', ',');
    const formattedInvoice = replacedDecimals.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return `${ formattedInvoice } €`;
  }
  return '0.00 €';
};


export {
  InvoiceSumFormatter
};

import { format, parseISO } from 'date-fns';

import {
  LANGUAGE_BG,
  LANGUAGE_DE,
  LANGUAGE_EN,
  LANGUAGE_PL,
  LANGUAGE_RO,
  LANGUAGE_TR,
  MISSING_TRANSLATION_MESSAGE,
} from "@constants";

import { store } from "index";
import {translationKeys} from '@constants';

const languages = {
  [LANGUAGE_BG]: require("translations/bg-BG.json"),
  [LANGUAGE_DE]: require("translations/de-DE.json"),
  [LANGUAGE_EN]: require("translations/en-GB.json"),
  [LANGUAGE_PL]: require("translations/pl-PL.json"),
  [LANGUAGE_RO]: require("translations/ro-RO.json"),
  [LANGUAGE_TR]: require("translations/tr-TR.json"),
};

export function t(key, params = {}) {
  const userLanguage = store.getState().profile.profile?.language ?
      store.getState().profile.profile?.language : 'de-DE';
  const locale = translationKeys[userLanguage.substring(0, 2)];

  const keyParts = key.split('.');

  let translation = languages?.[locale];
  for (const part of keyParts) {
    translation = translation?.[part];
    if (!translation) break;
  }

  if (!translation) {
    console.error(`Translation key not found for locale ${locale} and key ${key}`);
    translation = key;
  }

  const errorMessage = `${MISSING_TRANSLATION_MESSAGE}: ${key}`;
  const replacements = Object.keys(params);

  replacements.forEach(key => {
    translation = translation.replace(`:${key}`, params[key]);
  });

  return translation || errorMessage;
}

export function date(date) {
  if (!date || date.slice(0, 4) === "0000") {
    return "--.--.----";
  }
  return format(parseISO(date), 'dd.MM.yyyy');
}

export function dateTime(date) {
  if (!date || date.slice(0, 4) === "0000") {
    return "--.--.---- --:--";
  }
  return format(parseISO(date), 'dd.MM.yyyy hh:mm');
}

export function time(date) {
  if (!date || date.slice(0, 4) === "0000") {
    return "--:--";
  }
  return format(parseISO(date), 'hh:mm');
}
